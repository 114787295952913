import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import { SearchOutlined } from "@mui/icons-material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Switch from "../../components/common/Switch";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DeleteModal from "./DeleteModal";
import EditIconButton from "../../components/common/EditIconButton";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import HoverGreyCancelButton from "../../components/common/HoverGreyCancelButton";
import BlueButton from "../../components/common/BlueButton";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import ConfirmationModal from "./ConfirmationModal";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";
import Success_OK_Modal from "../../components/common/Success_OK_Modal";
import UpdatedModal from "./UpdatedModal";
import { Link, useNavigate, useLocation } from "react-router-dom";

// sort
import sortIcon from "../../../src/pages/img/sort_1.png";

//Action Icon Images
import action__Edit from "../../../src/img/Edit.svg";
import action__Delete from "../../../src/img/Delete.svg";
import SuccessModal from "../../components/common/SuccessModal";

const Board = () => {
  const token = sessionStorage.getItem("token");
  const [updatedBoard, setUpdatedBoard] = useState(false);
  const [updatedData, setUpdatedData] = useState();
  const [filterBoardValue, setFilterBoardValue] = useState();
  const [boardName, setBoardName] = useState();
  const [addBoard, setAddBoard] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [item, setItem] = useState();
  const [boardDetail, setBoardDetail] = useState({
    board: "",
    hasBoardError: false,
    boardErrorMessage: "",
  });
  const [updatedBoardId, setUpdatedBoardId] = useState();
  const [open, setOpen] = useState(false);
  const [modifiedDate, setModifiedDate] = useState({});
  const [toggleMessage, settoggleMessage] = useState();
  const [toggleConfirmation, setToggleConfirmation] = useState(false);
  const navigate = useNavigate();

  // sort
  const [sortOrder, setSortOrder] = useState(true);
  const [sortBy, setSortBy] = useState("");

  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);
  const handleOpen = async (item) => {
    setOpen(true);
    setItem(item);
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/boards/confirmation-api?id=${item.id}&operationType=DELETE`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setBoardName({ discription: response?.data?.data?.message });
  };
  const handleClose = () => setOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    setFilterBoardValue(updatedData);
  }, [updatedData]);
  const boardChangeHandler = (e) => {
    let value = e.target.value.toUpperCase();
    let hasBoardError = false;
    let boardErrorMessage = "";
    setBoardDetail({ board: value, hasBoardError, boardErrorMessage });
  };
  const updatedRoleHandler = (item) => {
    setUpdatedBoardId(item?.id);
    setBoardDetail({ board: item?.board });
    setAddBoard(false);
    setUpdatedBoard(true);
  };

  async function GetLastModifiedAt(ModifiedDate) {
    let token = sessionStorage.getItem("token");
    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/boards/last-modified-at`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((json) => {
        ModifiedDate(json.data)
      })
      .catch(err => console.log(err))
  }

  const fetchData = async (sortOrder) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/boards/page?pageSize=500&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setUpdatedData(response?.data?.data?.data);
  };
  useEffect(() => {
    fetchData(sortOrder);
  }, [sortOrder]);

  const [boardDisplayName, setDisplayBoardName] = useState("")

  const addAdminRoleHandler = async () => {
    const { board } = boardDetail;
    if (!board) {
      setBoardDetail({
        board: "",
        hasBoardError: true,
        boardErrorMessage: "Please Fill the Mandatry Field",
      });
      return;
    }
    const newValue = {
      discription: boardDetail.board,
      board: boardDetail.board,
    };
    setDisplayBoardName(board)
    axios({
      method: "post",
      url: `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/boards`,
      data: newValue,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function (response) {
        if (response.data.data) {
          setUpdatedData([...updatedData, response.data.data]);
          setAddBoard(false);
          setBoardDetail("");
          setSuccessCreateModal(true)
        }
      })
      .catch(function (error) {
        setBoardDetail({
          board: "",
          hasBoardError: true,
          boardErrorMessage: error?.response?.data?.message
            ? error.response.data.message
            : "Failed To Add Board",
        });
      });
  };
  const onSearch = async (event) => {
    let value = event?.target?.value?.toLowerCase();
    let result = updatedData;
    if (value) {
      result = updatedData?.filter((data) => {
        return data?.board?.toLowerCase().includes(value);
      });
    }
    setFilterBoardValue(result);
    setPage(0);
  };
  const updateToggle = () => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/boards/toggle-active?id=${toggleDetails?.id}&active=${toggleDetails?.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: toggleDetails?.value,
        }
      )
      .then(function (response) {
        if (response?.data) {
          const find = updatedData?.find(
            (item) => item.id === toggleDetails?.id
          );
          find.active = toggleDetails?.value;
          const clone = [...updatedData];
          clone.forEach((data) => {
            data = find;
          });
          setFilterBoardValue(clone);
          handleConfirmationClose();
        }
      })
      .catch(function (response) {
        // console.log(response);
      });
  };
  const [toggleDetails, setToggleDetails] = useState({});
  const switchHandler = async (id, e) => {
    setToggleDetails({});
    const value = e.target.checked;
    if (!value) {
      const response = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/boards/confirmation-api?id=${id}&operationType=TOGGLE`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setToggleConfirmation(true);
      settoggleMessage(response?.data?.data?.message);
      setToggleDetails({ id, value });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/boards/toggle-active?id=${id}&active=${value}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            data: value,
          }
        )
        .then(function (response) {
          if (response?.data) {
            const find = updatedData?.find((item) => item.id === id);
            find.active = value;
            const clone = [...updatedData];
            clone.forEach((data) => {
              data = find;
            });
            setFilterBoardValue(clone);
            handleConfirmationClose();
          }
        })
        .catch(function (response) {
          // console.log(response);
        });
    }
  };
  const handleConfirmationClose = () => {
    setToggleConfirmation(false);
    setToggleDetails({});
  };
  const deleteHandler = () => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/boards/${item?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (response?.data) {
          const find = updatedData?.filter((data) => data.id !== item?.id);
          setFilterBoardValue(find);
        }
        setOpen(false);
      })
      .catch(function (response) {
        // console.log(response);
      });
  };

  const [updatedName, setUpdatedName] = useState("")
  const editHandler = (id) => {
    const { board } = boardDetail;
    if (!board) {
      setBoardDetail({
        board: "",
        hasBoardError: true,
        boardErrorMessage: "Please Fill the Mandatry Field",
      });
      return;
    }
    const updatedValue = {
      discription: boardDetail.board,
      board: boardDetail.board,
    };
    axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/boards/${id}`,
        updatedValue,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (response?.data?.data) {
          const find = updatedData?.find(
            (item) => item.id === response?.data?.data?.id
          );
          find.board = boardDetail?.board;
          const clone = [...updatedData];
          clone.forEach((data) => {
            data = find;
          });
          setFilterBoardValue(clone);
          setUpdatedBoard(false);
          setSuccesUpdatesModal(true)
          setUpdatedName(board)
        }
      })
      .catch(function (response) {
        // console.log(response);
      });
  };
  const [succesUpdatesModal, setSuccesUpdatesModal] = useState(false)
  const [successCreateModal, setSuccessCreateModal] = useState(false)
  const closeSuccessModal = () => {
    setSuccessCreateModal(false)
  }

  const closeModal = () => {
    setSuccesUpdatesModal(false)
  }

  const CustomTooltip = ({ title, children, ...props }) => (
    <Tooltip
      title={title}
      arrow
      {...props}
      PopperProps={{
        modifiers: [
          {
            name: 'arrow',
            options: {
              element: '[data-popper-arrow]',
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, -16],
            },
          }
        ],
      }}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#ffa500',
            color: 'white',
            boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)',
            fontSize: '10px',
            fontWeight: 500,
            padding: '9px 20px',
            borderRadius: '5px',
          },
        },
        arrow: {
          sx: {
            color: '#ffa500',
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );

  return (
    <>
      <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span
            className={dashboardContentStyles.link_icon}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
              sessionStorage.removeItem("sideNavSubMenuNames");
              navigate("/dashboard/template");
            }}
          >
            <img
              src={dashHome}
              alt="no img"
              // width="15px"
              // height="20px"
              // style={{ verticalAlign: "middle" }}
              className={dashboardContentStyles.az_menu_icon}
            />
          </span>
          {/* {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>
          {"  "} */}
          <span
            className={dashboardContentStyles.az_menu_text}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
              sessionStorage.removeItem("sideNavSubMenuNames");
              navigate("/dashboard/template");
            }}
          >
            Home
          </span>

          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          {/* {"  "} */}
          <span className={dashboardContentStyles.az_menu_text}
            style={{ cursor: 'default' }}>
            Master
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          {/* {"  "} */}
          {/* <a>Board</a> */}
          <span
            className={`${dashboardContentStyles.az_selected_menu_text} cursorDefault`}
          >

            Board
          </span>

        </div>
        <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>Last Update: {modifiedDate.data}</p>
        </div>
      </article>
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            marginTop: "60px",
            gap: "16px",
          }}
        >
          {addBoard && (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#606060",
                  columnGap: "10px",
                }}
              >
                <ManageAccountsOutlinedIcon style={{ fontSize: "30px" }} />
                <div style={{ fontWeight: 600 }}>Add Board</div>
              </div>
              <TableContainer component={Paper}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "20px 20px",
                  }}
                >
                  <div>
                    <TextField
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#B5B6B6 !important",
                          fontSize: "14px/21px !important",
                        },
                        "& .MuiInputBase-root": {
                          height: "48px !important",
                          width: "270px !important",
                          borderRadius: "150px !important",
                          '&.Mui-focused fieldset': {
                            borderColor: '#ffa500',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#ffa500',
                          },
                          '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
                            color: '#ffa500',
                          }

                        },
                        "& .MuiInputBase-input": {
                          color: "#ffa500",

                        }
                      }}

                      id="demo-helper-text-aligned-no-helper"
                      label="*Board Name"
                      value={boardDetail.board}
                      type="text"
                      autoComplete="off"
                      onChange={(e) => boardChangeHandler(e)}
                    />
                    {boardDetail?.hasBoardError && (
                      <div style={{ color: "red" }}>
                        {boardDetail.boardErrorMessage}
                      </div>
                    )}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "1rem",
                    }}
                  >
                    <HoverGreyCancelButton
                      variant="outlined"
                      onClick={() => setAddBoard(false)}
                    >
                      Cancel
                    </HoverGreyCancelButton>
                    <BlueButton onClick={() => addAdminRoleHandler()}>
                      Create
                    </BlueButton>
                  </div>
                </div>
              </TableContainer>
            </div>
          )}
          {updatedBoard && (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#606060",
                }}
              >
                <ManageAccountsOutlinedIcon style={{ fontSize: "30px" }} />
                <div style={{ paddingLeft: "10px", fontWeight: 600 }}>
                  Edit Board
                </div>
              </div>
              <TableContainer component={Paper}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "20px 20px",
                  }}
                >
                  <div className="board-update-textbox">
                    <TextField
                      id="demo-helper-text-aligned-no-helper"
                      label="*Board Name"
                      value={boardDetail.board}
                      onChange={(e) => boardChangeHandler(e)}
                      style={{ fontSize: "15px" }}
                      sx={{
                        "& .MuiInputBase-root": {

                          borderRadius: "150px !important",

                        },
                        "& .MuiInputBase-input": {
                          color: "#ffa500",

                        }
                      }}


                    />
                    {boardDetail?.hasBoardError && (
                      <div style={{ color: "red", marginTop: "5px" }}>
                        {boardDetail.boardErrorMessage}
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "1rem",
                    }}
                  >
                    <HoverGreyCancelButton
                      variant="outlined"
                      onClick={() => setUpdatedBoard(false)}
                    >
                      Cancel
                    </HoverGreyCancelButton>
                    <BlueButton onClick={() => editHandler(updatedBoardId)}>
                      Update
                    </BlueButton>
                  </div>
                </div>
              </TableContainer>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "#606060",
                columnGap: "10px",
              }}
            >
              <ManageAccountsOutlinedIcon style={{ fontSize: "2.2vw" }} />
              <div
                style={{
                  fontSize: "1.1vw",
                  fontWeight: "600",
                }}
              >
                Board List
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "1rem",
              }}
            >
              <TextField
                type={"text"}
                placeholder={"Search..."}
                sx={{
                  width: { sm: 200, md: 300 },
                  "& .MuiInputBase-root": {
                    width: "247px !important",
                    height: "32px !important",
                    borderRadius: "50px !important",
                    background: "#fff !important",
                    lineHeight: "21px !important",
                    font: "normal normal normal 13px/20px Poppins !important",
                    marginLeft: "40px !important",
                    opacity: 1,
                  },
                }}
                onChange={onSearch}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <img
                        src={searchIcn}
                        style={{
                          verticalAlign: "top",
                          fontSize: "large",
                        }}
                        aria-hidden="true"
                      />
                    </IconButton>
                  ),
                }}
              />
              {!addBoard && !updatedBoard && (
                <GreenAddButton
                  onClick={() => {
                    setBoardDetail("");
                    setAddBoard(true);
                    setUpdatedBoard(false);
                  }}
                >
                  <AddCircleOutlineOutlinedIcon
                    style={{
                      marginRight: "10px",
                      marginBottom: "2px",
                      fontSize: "medium",
                    }}
                  />
                  Add Board
                </GreenAddButton>
              )}
            </div>
          </div>
          <Paper sx={{ width: "81vw", overflow: "hidden" }}>
            <TableContainer
              style={{ marginBottom: "90px" }}
              sx={{ maxHeight: 640, overflow: "auto" }}
            >
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader={true}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={dashboardContentStyles.table_head}>
                      #
                      {/* <img
                      style={{verticalAlign:"middle",paddingLeft:"3px"}}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("id");
                          // await initData(!sortOrder, "id");
                        }}
                      /> */}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={dashboardContentStyles.table_head}
                    >
                      Board Name
                      <img
                        style={{ verticalAlign: "middle", paddingLeft: "3px" }}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("board");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="right"
                      className={dashboardContentStyles.table_head}
                    >
                      Active
                      <img
                        style={{ verticalAlign: "middle", paddingLeft: "3px" }}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("active");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        //color: "blue",
                        //fontWeight: 600,
                        paddingRight: "60px",
                        // background: "#d9dcfe",
                        color: "#ffffff",
                        fontFamily: "poppins",
                        fontWeight: 400,
                        background: '#403E75',
                        fontSize: "1vw",
                        //textAlign: "center",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterBoardValue
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row, index) => (
                      <TableRow
                        key={row?.id}
                        sx={{
                          // "&:last-child td, &:last-child th": { border: 0 },

                          backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f8',
                          "&:last-child td, &:last-child th": {
                            borderBottom: "none !important",
                          },

                          "&:hover": {

                            "& td, & th": {
                              borderTop: "1px solid #ff9702",
                              borderBottom: "1px solid #ff9702",
                            },
                            " & th:first-of-type": {
                              borderLeft: "1px solid #ff9702",
                            },

                            "& td:first-of-type": {
                              borderLeft: "0",

                            },

                            "&:last-child td, &:last-child th": {
                              borderBottom: "1px solid #ff9702 !important",
                            },

                            "& td:last-of-type": {
                              borderRight: "1px solid #ff9702",

                            },

                            "& th:last-of-type": {
                              borderRight: "0",
                            },
                          },

                          "& td, & th": {
                            border: "1px solid transparent",
                            borderTop: "1px solid transparent",
                            borderBottom: "1px solid transparent",
                            "&:not(:last-child)": {
                              borderRight: "1px solid transparent",
                            },
                          },
                        }}
                      // hover={true}
                      // style={{
                      //   background: "#f9f8f8",
                      // }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={dashboardContentStyles.table_cell}

                        >
                          {page * rowsPerPage + (index + 1)}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={dashboardContentStyles.table_cell}
                        >
                          {row?.board}
                        </TableCell>
                        <TableCell align="right">
                          <div>
                            <Switch
                              checked={row?.active}
                              onChange={(e) => switchHandler(row?.id, e)}
                            />
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <EditIconButton
                            onClick={() => updatedRoleHandler(row)}
                          >
                            <CustomTooltip title="Edit" placement={"top"}>
                              {/* <EditOutlinedIcon style={{ fontSize: "1.7vw" }} /> */}
                              <img
                                className={
                                  dashboardContentStyles.aRoleActionBtn
                                }
                                src={action__Edit}
                                alt="Edit-Btn"
                              />
                            </CustomTooltip>
                          </EditIconButton>

                          <DeleteIconButton onClick={() => handleOpen(row)}>
                            <CustomTooltip title="Remove" placement={"top"}>
                              {/* <DeleteOutlinedIcon
                                style={{ fontSize: "1.7vw" }}
                              /> */}
                              <img
                                className={
                                  dashboardContentStyles.aRoleActionBtn
                                }
                                src={action__Delete}
                                alt="Dlt-Btn"
                              />
                            </CustomTooltip>
                          </DeleteIconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              style={{
                bottom: "0",
                width: "81vw",
                position: "fixed",
                backgroundColor: "white",
              }}
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={filterBoardValue?.length || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPage}
              showFirstButton={true}
              showLastButton={true}
            />
          </Paper>
        </div>
        <DeleteModal
          open={open}
          close={handleClose}
          title={boardName}
          deleteTitle={"Board Name"}
          deleteHandler={deleteHandler}
        />
        <ConfirmationModal
          open={toggleConfirmation}
          confirmationmessage={toggleMessage}
          close={handleConfirmationClose}
          updateToggle={updateToggle}
        />
        <Success_OK_Modal
          userType={"Board"}
          userName_Modal={
            boardDisplayName
          }
          open={successCreateModal}
          handleOk={closeSuccessModal}
        />
        <UpdatedModal
          userType={"Board"}
          userName_Modal={
            updatedName
          }
          open={succesUpdatesModal}
          handleOk={closeModal}
          close={closeModal}
        />
      </div>
    </>
  );
};

export default Board;
