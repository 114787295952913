import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import teacherliststyle from "../css/teacherIdList.module.css";
import changepasswordstyle from "../css/changePassword.module.css";
import sharestyle from "../css/shareModal.module.css";
import studentedit from "./../css/TeacherEditModal.module.css";
import TeacherIDControllerGet from "../API/teacher-controller/User_TeacherId_Get";
import TeacherControllerGet from "../API/teacher-controller/User_Teacher_Get";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import branchchangerstyle from "../css/School_Branch_Changer.module.css";
import Branches_Changer_School_Id from "../API/branch-controller/Branches_Changer_School_Id"; //branch list changer
import Pagination1 from "./pagination1";
import School_name from "./school_name"; //school name changer
import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import addacademicstyle from "./../css/AddAcademicModal.module.css";
import SchoolControllerAll from "../API/school-controller/user_schools_all";
import User_Branches_School_Id from "../API/branch-controller/User_Branches_School_Id";
import SchoolControllerIdGet from "../API/school-controller/User_School_Id";
import dashHome from "../../../src/img/dashHome.svg";
import moment from "moment";
//Action buttons
import LockIconButton from "../../components/common/LockIconButton";
import EditIconButton from "../../components/common/EditIconButton";
import ShareIconButton from "../../components/common/ShareIconButton";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import { Checkbox, Tooltip } from "@mui/material";
import teacheridstyles from "../css/TeacherId.module.css";
import ConfirmationModal from "./ConfirmationModal";
//Action Icon Images
import action_sa_Lock from "../../../src/img/password.svg";
import action_sa_Edit from "../../../src/img/Edit.svg";
import action_sa_Share from "../../../src/img/Share.svg";
import action_sa_Delete from "../../../src/img/Delete.svg";
import SchoolAdminBreadcrumb from "./SchoolAdminBreadcrumb";

// sort
import sortIcon from "../../../src/pages/img/sort_1.png";
import searchIcn from "../../../src/img/search.svg";
import changePasswordPut from "../ChangePassword";
import share from "../Share";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CommonDeleteModal from "./CommonDeleteModal";
import ClearIcon from "@mui/icons-material/Clear";
import {
  IconButton,
  TextField,
} from "@mui/material";
import Studentdynamic from "./../css/student32.module.css";
import SuccesModalGeneral from "../../components/common/SuccessModelGeneral";

const TeacherIdList = (props) => {
  const teacherList = props.teacherList;
  const navigate = useNavigate();
  const [schoolIdData, setSchoolIdData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showModalShare, setShowModalShare] = useState(false);
  const [loginPassword, setLoginPassword] = useState("");
  const [confirmLoginPassword, setConfirmLoginPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  // -------parth25-07----------
  const [showAddStudentModal, setShowAddStudentModal] = useState(false);
  const [schoolList, setSchoolList] = useState({});
  const prevLocation = useLocation();
  // const [showAddAcademicModal, setShowAddAcademicModal] = useState(prevLocation.state == "edit" ? false : true);
  const [showAddAcademicModal, setShowAddAcademicModal] = useState(false);
  const branchid = sessionStorage.getItem("branchid");

  const [studentDetails, setStudentDetails] = useState({
    studentschool: "",
    studentbranch: "",
    grade: "",
    section: "",
  });

  if (prevLocation.state === "edit") {
    prevLocation.state = null;
  }

  const [isValid, setValidation] = useState(false);
  const handleCross = (e) => {
    navigate("/dashboard/Registered%20Schools");
    setShowAddStudentModal(false);
    setShowAddAcademicModal(false);
  };

  const [successCreateModal, setSuccessCreateModal] = useState(false);
  const [successModalGeneral, setSuccessModalGeneral] = useState(false);
  const [SuccessDisplayName, setSuccessDisplayName] = useState("");

  const closeSuccessModal = () => {
    setSuccessModalGeneral(false);
  };
  // const handleAddAcademicSubmit = (e) => {
  //   e.preventDefault();

  //   if(!schoolDetails.school || !schoolDetails.branch){
  //     setValidation(true);
  //     return;
  //   }

  //   for (const input in schoolDetails) {
  //     if (schoolDetails[input] === "") {
  //       return;
  //     }
  //   }
  //   setShowAddAcademicModal(false);
  //   // navigate("/dashboard/teacherId");
  //   // props.handleHeaderTitle("Registered Schools"); // addedd by sg
  //   var schoolid = schoolDetails.school;
  //   sessionStorage.setItem("schoolid", schoolid);
  //   // var branchid = schoolDetails.branch;
  //   // sessionStorage.setItem("branchid", branchid);
  // };
  // ----------------------------------------------------
  const toggleLoginPasswordVisibility = (isShown) => {
    if (isShown === "show") {
      setPasswordShown(true);
    } else {
      setPasswordShown(false);
    }
  };
  const toggleconfirmLoginPasswordVisibility = (isConfirmShown) => {
    if (isConfirmShown === "Confirmshow") {
      setConfirmPasswordShown(true);
    }
    if (isConfirmShown === "ConfirmdontShow") {
      setConfirmPasswordShown(false);
    }
  };
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [isPassEmpty, setIsPassEmpty] = useState(false);
  const [shareEmail, setShareEmail] = useState("");
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [userName, setUserName] = useState();
  const [userId, setUserId] = useState();
  const [password, setPassword] = useState();
  const [regexPass, setRegexPass] = useState(true);
  const [totalele, setTotalelements] = useState(0);
  const [shareClick, setShareClick] = useState(false)
  const persona = "ACADEMIC_STAFF";
  // const [role, setRole] = useState("");
  // const [roleType, setRoleType] = useState("");

  // const [shareEmail,setShareEmail] = useState('');
  // const handleEditModal = (e) => {
  //   e.preventDefault();

  //   setShowModalEdit(true);
  // };
  const [filterValue, setFilterValue] = useState([]);
  const [toggleActive, setToggleActive] = useState("");
  const [updatedData, setUpdatedData] = useState();
  const [toggleDetails, setToggleDetails] = useState({});
  const token = sessionStorage.getItem("token");
  const [toggleMessage, settoggleMessage] = useState();
  const [toggleConfirmation, setToggleConfirmation] = useState(false);



  const updateToggle = () => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/${toggleDetails?.id}/active?active=${toggleDetails?.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: toggleDetails?.value,
        }
      )
      .then(function (response) {
        TeacherControllerGet(
          setTeacherData,
          setPageDetail,
          page,
          rowsInput,
          search,
          sortBy,
          sortOrder
        );
        handleConfirmationClose();
        if (response?.data) {
          const find = updatedData?.find(
            (item) => item.id === toggleDetails?.id
          );
          find.active = toggleDetails?.value;
          const clone = [...updatedData];
          clone.forEach((data) => {
            data = find;
          });
          setFilterValue(clone);
        }
      })
      .catch((err) => console.log(err));
  };

  const switchHandler = async (e, id) => {
    setToggleDetails({});
    const value = e.target.checked;
    setToggleDetails({ id, value });
    if (value === false) {
      setToggleConfirmation(true);
      settoggleMessage(`Are you sure you want to Deactivate this Academic Staff`);
    } else {
      setToggleConfirmation(true);
      settoggleMessage(`Are you sure you want to Activate this Academic Staff`);
    }
  }

  const handleConfirmationClose = () => {
    setToggleConfirmation(false);
    setToggleDetails({});
  };

  // const handletoggleChange = (id, e) => {
  //   const value = e.target.checked;
  //   setToggleActive(e.target.checked);

  //   axios.get(
  //     `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/${id}/active?active=${value}`,
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //       },
  //     }
  //   );
  // };

  const handleEditModalEdit = (e) => {
    setShowModalEdit(false);
    navigate("/dashboard/academicid");
  };

  const handleEditModalcross = (e) => {
    setShowModalEdit(false);
    // navigate("/dashboard/studentTable");
  };
  function validatePassword(passValue) {
    const regex = new RegExp(
      "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*+_()=])(?=\\S+$).{8,}$"
    );
    return regex.test(passValue);
  }
  const [successP, setPSuccess] = useState(false);
  const [showModalChangePassword, setShowModalChangePassword] = useState(false);

  const handleSubmit = (e) => {

    e.preventDefault();
    if (!loginPassword) {
      setIsPassEmpty(true);
      setPasswordMatch(true);
      return;
    }
    if (loginPassword !== confirmLoginPassword) {
      setIsPassEmpty(false);
      setPasswordMatch(false);
      return;
    }
    const pass = validatePassword(loginPassword);
    if (pass === false) {
      setRegexPass(pass);
      setIsTooltipOpen(true);
      return;
    }
    if (successP === true) {
      return;
    }

    changePasswordPut(userName, userId, password, persona, setPSuccess)
      .then((response) => {
        // console.log(">>>",response.data)
        setPasswordMatch(true);
        setShowModalChangePassword(false);
        setShowModal(false)
        setSuccessModalGeneral(true);
        setSuccessDisplayName(
          "Password updated successfully"
        );
        //alert("Password Updated")
      }).
      catch(err => {
        console.log("err >>> ", err.response.data.message)
        alert(err.response.data.message)
        // setIsError(true);
        // setShowError(err.response.data.message)
      })

  };

  const handleAddTeacher = (e) => {
    e.preventDefault();
    // navigate("/dashboard/Add%20Academic%20Staff",{state:"fromTeacher"});
    navigate("/dashboard/CreateTeacher", { state: "fromTeacher" });
  };

  // const handleChangePassword = (e) => {
  //   setShowModal(true);
  // };

  const handleUpdatedPassword = (e) => {
    setShowModal(false);
  };

  const handleShareModal = (e) => {
    setShareEmail(
      e.target.parentNode.parentNode.parentNode.parentNode.parentNode
        .children[4].textContent
    );
    setShowModalShare(true);
  };

  const handleShareModalOk = (e) => {
    setShowModalShare(false);
  };
  const [teacherData, setTeacherData] = useState([]);
  const [first, setFirst] = useState(true);
  const [updateddata, setUpdateData] = useState([])




  useEffect(() => {
    if (teacherData.length > 0 && first) {
      setUpdateData(teacherData);
      setFirst(false);
    }

  }, [teacherData])
  // search starts
  const [search, setSearch] = useState({
    search: "",
  });
  const [isSearch, setisSearch] = useState(true);
  const [showClear, setShowClear] = useState(false);
  const [query, setQuery] = useState("");
  const handleClear = () => {
    setQuery("");
    setShowClear(false);
    TeacherControllerGet(
      setTeacherData,
      setPageDetail,
      page,
      rowsInput,
      search,
      sortBy,
      sortOrder
    );
  };

  const handleInputChange = (e) => {
    const inputValue = e?.target?.value?.toLowerCase();
    if (inputValue) {
      let result = [...totalele];
      result = [...totalele]?.filter((data) => {
        return data?.userName?.toLowerCase().includes(inputValue);
      });
      setTeacherData(result)
    } else {
      TeacherControllerGet(
        setTeacherData,
        setPageDetail,
        page,
        rowsInput,
        search,
        sortBy,
        sortOrder
      );
    }
    setQuery(inputValue);
    setShowClear(inputValue !== "");
  };
  useEffect(() => {
    if (isSearch) {
      searchingData()
    }
  }, [])

  const searchingData = async () => {
    setisSearch(false);
    let token = sessionStorage.getItem("token");
    let schoolid = sessionStorage.getItem("schoolid");
    let branchid = sessionStorage.getItem("branchid");

    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers?pageNumber=0&pageSize=50&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search.search}&schoolId=${schoolid}&branchId=${branchid}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        }
      },)
    const totlaele = result?.data?.data?.totalElements
    let response = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers?pageNumber=0&pageSize=${totlaele}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search.search}&schoolId=${schoolid}&branchId=${branchid}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        }
      },)
    const totalData = response?.data?.data?.data
    setTotalelements(totalData);
  }


  // --------parth25/07------

  // search ends
  // pagination starts
  const [rowsInput, setRowsInputs] = useState({
    rows: 10,
  });
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const handlerRowsInputs = (e) => {
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
    setPage(1);
  };
  // pagination ends

  const [pageDetail, setPageDetail] = useState({});

  const [teacherIDData, setTeacherIDData] = useState({});
  const [teacherID, setTeacherID] = useState();

  // sort
  const [sortOrder, setSortOrder] = useState(false);
  const [sortBy, setSortBy] = useState("id");
  let schoolid = sessionStorage.getItem("schoolid");
  let branchidGet = sessionStorage.getItem("branchid");
  useEffect(() => {
    schoolid && branchidGet && TeacherControllerGet(
      setTeacherData,
      setPageDetail,
      page,
      rowsInput,
      search,
      sortBy,
      sortOrder
    );
    return () => { };
  }, [page, rowsInput, sessionStorage.getItem("branchid"), sortBy, sortOrder]);

  useEffect(() => {
    schoolid && branchidGet && TeacherControllerGet(
      setTeacherData,
      setPageDetail,
      page,
      rowsInput,
      search,
      sortBy,
      sortOrder
    );
    return () => { };
  }, []);
  //BranchController starts
  const [branchList, setBranchList] = useState([]);
  const [schoolDetails, setSchoolDetails] = useState({
    branch: sessionStorage.getItem("branchid"),
  });
  const handlerSchoolDetailsInputs = (e) => {
    setSchoolDetails({
      ...schoolDetails,
      [e.target.name]: e.target.value,
      schoolName: e.target.id,
    });
  };

  useEffect(() => {
    // branch input for school //
    // User_Branches_School_Id(schoolDetails, setBranchList, studentDetails);
    User_Branches_School_Id(setBranchList, studentDetails);

    return () => { };
  }, [studentDetails]);

  useEffect(() => {
    var branchid = schoolDetails.branch;
    sessionStorage.setItem("branchid", branchid);
    // User_Branches_School_Id(schoolDetails, setBranchList, studentDetails);
    Branches_Changer_School_Id(setBranchList);
    // Branches_Changer_School_Id(setBranchList);
    return () => { };
  }, [schoolDetails]);
  useEffect(() => {
    SchoolControllerIdGet(setSchoolIdData);
    return () => { };
  }, [teacherData]);
  //BranchController ends
  // last update time starts
  const [modifiedDate, setModifiedDate] = useState({});

  // useEffect(()=>{
  //   sessionStorage.getItem("schoolid") !== "" ? setShowAddAcademicModal(false) : setShowAddAcademicModal(true);
  // },[])

  useEffect(() => {
    // setShowAddAcademicModal(true);
    //school Input
    SchoolControllerAll(setSchoolList);
    // User_Branches_School_Id(schoolDetails, setBranchList, studentDetails);
    User_Branches_School_Id(setBranchList, studentDetails);

    GetLastModifiedAt(setModifiedDate);
  }, []);

  const [selectedItem, setSelectedItem] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const showDeleteModal = (id) => {
    setSelectedItem(id);
    console.log(id.id, "idd");
    setOpenDeleteModal(true);
  };

  const hideDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedItem("");
  };
  console.log(openDeleteModal, "modal");
  const userType = selectedItem.role;

  const deleteHandler = () => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/${selectedItem.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setOpenDeleteModal(false);
        TeacherControllerGet(
          setTeacherData,
          setPageDetail,
          page,
          rowsInput,
          search,
          sortBy,
          sortOrder
        );
      })
      .catch((error) => { });
  };

  // last update time ends
  useEffect(() => {
    if (search.search === "" && schoolid && branchidGet) {
      // var branchid = schoolDetails.branch;
      // sessionStorage.setItem("branchid", branchid);
      TeacherControllerGet(
        setTeacherData,
        setPageDetail,
        page,
        rowsInput,
        search,
        sortBy,
        sortOrder
      );
      return () => { };
    }
  }, [rowsInput, page, search]);

  const PassOnChange = (e) => {
    setLoginPassword(e.target.value);
    setPassword(e.target.value);
  };

  useEffect(() => {
    if (shareClick) { share(userName, userId, persona) };
  }, [userName, userId]);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTooltipOpen = () => {
    setIsTooltipOpen(false);

  };


  const handleTooltipClose = () => {
    setIsTooltipOpen(false);

  };

  const shareHandler = (row) => {
    setShareClick(true)
    var teacherid = row.id;
    sessionStorage.setItem("teacherid", teacherid);
    TeacherIDControllerGet(
      setTeacherIDData,
      teacherID
    );
    setUserId(row.userId);
    setUserName(row.userName);
    userId && share(userName, userId, persona);
    setShowModalShare(true);

  };


  const [selectAll, setSelectAll] = useState(false);
  const [individualCheckboxes, setIndividualCheckboxes] = useState([]);

  useEffect(() => {
    setIndividualCheckboxes(Array(teacherData.length).fill(false));
    console.log(">>> ", individualCheckboxes.length)
  }, [teacherData]);

  // Function to handle the change in state of the table header checkbox
  const handleSelectAll = (event) => {
    // Update the state to the new checked status of the table header checkbox
    const newState = event.target.checked;
    setSelectAll(newState);
    setIndividualCheckboxes(individualCheckboxes.map(() => newState));
    console.log("$$$$")
  };

  // Function to handle the change in state of individual table body checkboxes
  const handleIndividualCheckboxChange = (index) => {
    // Update the state of individual checkbox at the given index
    const newCheckboxes = [...individualCheckboxes];
    newCheckboxes[index] = !newCheckboxes[index];
    setIndividualCheckboxes(newCheckboxes);


  };

  return (
    <>
      <SchoolAdminBreadcrumb
        dashHome={dashHome}
        sectionName={'Add Academic staff'}
        date={modifiedDate.data}
        isSecondOne={false}
      />

      <article className={teacherliststyle.article_teacherid}>
        <div className={branchchangerstyle.Grid_school_name_branch_teacherid}>
          <div className={branchchangerstyle.school_name_teacherid}>
            {/* <School_name /> */}
            <i class="fa-solid fa-house-user" aria-hidden="true"></i>{" "}
            {schoolIdData.name}
          </div>
          <select
            className={branchchangerstyle.branchselect_Dropdown}
            name="branch"
            id="branch"
            value={schoolDetails.branch}
            onChange={handlerSchoolDetailsInputs}
          >
            {branchList.length > 0 ? (
              branchList.map((branchList) => {
                return (
                  <option value={branchList.branchId}>
                    {branchList.branch}
                  </option>
                );
              })
            ) : (
              <option className={branchchangerstyle.option} value=""></option>
            )}
          </select>
        </div>

        <div className={teacherliststyle.grid_container_header_plan_teacherid}>
          <div
            className={teacherliststyle.item_plan1_teacherid + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/branchplan");
            }}
          >
            Plan
          </div>
          <div
            className={teacherliststyle.item_plan2_teacherid + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/branchsection");
            }}
          >
            Sections
          </div>
          <div
            className={teacherliststyle.item_plan3_teacherid + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/teacherId");
            }}
          >
            Academic Staff
          </div>
          <div
            className={teacherliststyle.item_plan4_teacherid + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/student");
            }}
          >
            Students
          </div>
          <div
            className={teacherliststyle.item_plan5_teacherid + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/csp");
            }}
          >
            Change Student Profile
          </div>
          <div
            className={teacherliststyle.item_plan6_teacherid + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/TokenList");
            }}
          >
            Tokens
          </div>
          <div
            className={teacherliststyle.item_plan7_teacherid + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/logo");
            }}
          >
            Branch Logo
          </div>
        </div>

        <div className={teacherliststyle.teacher_list_teacherid}>
          <div className={teacherliststyle.list1_teacherid}>
            <i class="fa-solid fa-chalkboard-user" aria-hidden="true"></i>
            <span className={teacherliststyle.text_staff}>
              {" "}
              Academic Staff List
            </span>
          </div>
          <div className={teacherliststyle.list3_teacherid}>
            <div>
              <TextField
                onChange={handleInputChange}
                type={"text"}
                value={query}
                placeholder={"Search anything..."}
                sx={{
                  "& .MuiInputBase-root": {
                    width: "247px !important",
                    height: "32px !important",
                    borderRadius: "50px !important",
                    fontFamily: "Poppins !important",
                    fontSize: "13px",
                    marginLeft: "20px !important",
                    background: "#FFFFFF",

                    opacity: 1,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      {showClear && (
                        <IconButton onClick={handleClear}>
                          <ClearIcon
                            style={{ color: "#F05262", fontSize: "1vw" }}
                          />
                        </IconButton>
                      )}
                      {!showClear && (
                        <IconButton>
                          <img
                            src={searchIcn}
                            style={{
                              verticalAlign: "top",
                              fontSize: "large",
                            }}
                            aria-hidden="true"
                          />
                        </IconButton>
                      )}
                    </>
                  ),
                }}
              />

            </div>

            <div className={teacherliststyle.teacher5_teacherid}>
              <button
                className={teacherliststyle.create_button_teacher_teacherid}
                onClick={handleAddTeacher}
              >
                <i class="fa-solid fa-circle-plus"></i>
                <span className={teacherliststyle.button_text}>
                  Add Academic Staff
                </span>
              </button>
            </div>
          </div>
        </div>

        {/* <!-- ===========TABLE STARTS FROM HERE=========== --> */}

        <div className={teacherliststyle.div_table_teacher_list_teacherid}>
          <table
            className={teacherliststyle.table_section_teacher_list_teacherid + ' ' + 'az_table'}
          >
            <thead
              className={teacherliststyle.head_section_teacher_list_teacherid + ' ' + 'az_table_head'}
            >
              <tr
                className={
                  teacherliststyle.table_head_section_teacher_list_teacherid
                }
              >
                <td className={Studentdynamic.cbse_checkbox}>
                  <input className="az_checkbox" type="checkbox" id="test2"
                    onChange={handleSelectAll}
                    checked={selectAll} />
                  <label for="test2"></label>{" "}
                </td>
                {/* <td>
                  <input className="az_checkbox az_checkbox_td" type="checkbox"
                    onChange={handleSelectAll}
                    checked={selectAll}
                    id="az-super-admin-Academic-staff-checkbox" />
                </td> */}
                <td>
                  &nbsp;
                  &nbsp;
                  #{/* <i class="fa-solid fa-arrows-up-down"></i> */}
                </td>
                <td
                  className={teacherliststyle.list_table_teacher_list_teacherid}
                >
                  <div className={dashboardContentStyles.table_head}>
                    Email
                    {/* <i class="fa-solid fa-arrows-up-down"></i> */}
                    <img
                      style={{
                        verticalAlign: "middle",
                        paddingLeft: "3px",
                        marginBottom: "2px",
                      }}
                      className="cursor-pointer"
                      width="13"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("email");
                        // var sortedData = await commonsort(response.data.data.data || [], sortBy, orderV);
                        // console.log("sortedData", sortedData)
                      }}
                    />
                  </div>
                </td>
                {/* <td className={dashboardContentStyles.table_head}>
                  Password
                  <i class="fa-solid fa-arrows-up-down"></i> 
                  <img
                    style={{
                      verticalAlign: "middle",
                      paddingLeft: "3px",
                      marginBottom: "2px",
                    }}
                    className="cursor-pointer"
                    width="13"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("password");
                      await initData(!sortOrder, "id");
                    }}
                  />
                </td> */}
                <td className={dashboardContentStyles.table_head}>
                  Role
                  {/* <i class="fa-solid fa-arrows-up-down"></i>{" "} */}
                  <img
                    style={{
                      verticalAlign: "middle",
                      paddingLeft: "3px",
                      marginBottom: "2px",
                    }}
                    className="cursor-pointer"
                    width="13"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("role");
                      // await initData(!sortOrder, "id");
                    }}
                  />
                </td>
                <td className={dashboardContentStyles.table_head}>
                  {" "}
                  User Name
                  {/* <i class="fa-solid fa-arrows-up-down"></i> */}
                  <img
                    style={{
                      verticalAlign: "middle",
                      paddingLeft: "3px",
                      marginBottom: "2px",
                    }}
                    className="cursor-pointer"
                    width="13"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("userName");
                      // await initData(!sortOrder, "id");
                    }}
                  />
                </td>
                {/* <td className={dashboardContentStyles.table_head}>
                  User Id */}
                {/* <i class="fa-solid fa-arrows-up-down"></i> */}
                {/* <img
                    style={{
                      verticalAlign: "middle",
                      paddingLeft: "3px",
                      marginBottom: "2px",
                    }}
                    className="cursor-pointer"
                    width="13"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("userId");
                      // await initData(!sortOrder, "id");
                    }}
                  />
                </td> */}
                <td className={dashboardContentStyles.table_head}>
                  Last Login Time
                  {/* <i class="fa-solid fa-arrows-up-down"></i> */}
                  <img
                    style={{
                      verticalAlign: "middle",
                      paddingLeft: "3px",
                      marginBottom: "2px",
                    }}
                    className="cursor-pointer"
                    width="13"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("lastLoginTime");
                      // await initData(!sortOrder, "id");
                    }}
                  />
                </td>
                <td className={dashboardContentStyles.table_head}>
                  Active
                  {/* <i class="fa-solid fa-arrows-up-down"></i> */}
                  <img
                    style={{
                      verticalAlign: "middle",
                      paddingLeft: "3px",
                      marginBottom: "2px",
                    }}
                    className="cursor-pointer"
                    width="13"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("active");

                      // await initData(!sortOrder, "id");
                    }}
                  />
                </td>
                <td className={dashboardContentStyles.table_head}>
                  Action
                  {/* <i class="fa-solid fa-arrows-up-down"></i> */}
                </td>
              </tr>
            </thead>
            <tbody
              className={teacherliststyle.body_section_teacher_list_teacherid + ' ' + 'az_table_body'}
            >
              {teacherData.length > 0 ? (
                teacherData.map((teacherData, index) => {
                  return (
                    <tr
                      className={
                        teacherliststyle.table_body_section_teacher_list_teacherid
                      }
                      key={index}
                    >
                      <td className={Studentdynamic.cbse_checkbox}>
                        <input className="az_checkbox" type="checkbox" id="test2"
                          checked={individualCheckboxes[index]}
                          onChange={() => handleIndividualCheckboxChange(index)} />
                        <label for="test2"></label>{" "}
                      </td>
                      {/* <td className={teacherliststyle.plan_links_items16}>

                        <Checkbox
                          style={{
                            color: "#FFA500",
                            margin: "1px",
                            transform: "scale(0.8)",
                          }}
                          isChecked={individualCheckboxes[index]}
                          handleClick={() => handleIndividualCheckboxChange(index)}
                        />
                      </td> */}
                      <td className={dashboardContentStyles.table_cell}>
                        &nbsp;
                        &nbsp; {(page - 1) * rowsInput.rows + (index + 1)}{" "}
                      </td>

                      <td
                        // onClick={handleEditModal}
                        onClick={(e) => {
                          e.preventDefault();
                          // setTeacherID(teacherData.id);
                          // console.log(teacherData.id);
                          var teacherid = teacherData.id;
                          sessionStorage.setItem("teacherid", teacherid);

                          TeacherIDControllerGet(setTeacherIDData, teacherID);

                          setShowModalEdit(true);
                        }}
                        className={`${dashboardContentStyles.table_celll} cursorPointer`}
                      >
                        {teacherData.email}{" "}
                      </td>
                      {/* <td
                        className={dashboardContentStyles.table_cell}
                        style={{ color: "#354052" }}
                      >
                        {teacherData.password}{" "}
                      </td> */}
                      <td className={dashboardContentStyles.table_cell}>
                        {teacherData.academicStaffProfile}{" "}
                      </td>
                      <td className={dashboardContentStyles.table_cell}>
                        {teacherData.userName}
                      </td>
                      {/* <td className={dashboardContentStyles.table_cell}>
                        <div
                          style={{ fontSize: "0.7vw" }}
                          className={dashboardContentStyles.table_cell}
                        >
                          {" "}
                          {teacherData.userId.slice(25) + ".."}{" "}
                        </div>{" "}
                      </td> */}
                      <td className={dashboardContentStyles.table_cell}>

                        {teacherData.lastLoginTime !== null &&
                          moment(teacherData.lastLoginTime).format(
                            "DD-MM-YYYY, h:mm:ss a"
                          )}{" "}
                      </td>
                      <td className={dashboardContentStyles.table_cell}>
                        <label className={teacherliststyle.active_switch}>
                          <input
                            type="checkbox"
                            id=""
                            name=""
                            value=""
                            checked={teacherData.active}
                            onChange={(e) => {
                              switchHandler(e, teacherData.id);
                            }}
                          />
                          <span
                            className={teacherliststyle.active_slider}
                          ></span>
                        </label>
                      </td>

                      <td
                        className={
                          teacherliststyle.list_body_table_teache_list_teacherid
                        }
                        style={{ paddingLeft: 20 }}
                      >
                        <div className={teacheridstyles.wrapper_teacherid}>
                          <div
                            className={teacheridstyles.icon}
                            onClick={() => {
                              setShareClick(false)
                              setShowModal(true);
                              setUserId(teacherData.userId);
                              setUserName(teacherData.userName);
                              setLoginPassword("");
                              setConfirmLoginPassword("");
                              setRegexPass(true);
                            }}
                          >
                            <div className={teacheridstyles.tooltip + ' ' + 'az_tooltip'}>
                              Change Password
                            </div>
                            <span
                              id={teacheridstyles.change_password_teacherid}
                            >
                              <img
                                className={
                                  dashboardContentStyles.btnHoverEffect
                                }
                                src={action_sa_Lock}
                                alt="Lock-btn"
                              />
                            </span>
                          </div>
                          <div className={teacheridstyles.icon}>
                            <div className={teacheridstyles.tooltip + ' ' + 'az_tooltip'}>Edit</div>
                            <span
                              onClick={(e) => {
                                e.preventDefault();
                                var teacherid = teacherData.id;
                                sessionStorage.setItem("teacherid", teacherid);

                                navigate("/dashboard/academicid");
                              }}
                            >
                              <img
                                className={
                                  dashboardContentStyles.btnHoverEffect
                                }
                                src={action_sa_Edit}
                                alt="Lock-btn"
                              />
                            </span>
                          </div>
                          <div
                            className={teacheridstyles.icon}
                            // onClick={handleShareModal}
                            onClick={() => shareHandler(teacherData)}
                          >
                            <div className={teacheridstyles.tooltip + ' ' + 'az_tooltip'}>Share</div>
                            <span>
                              <img
                                className={
                                  dashboardContentStyles.btnHoverEffect
                                }
                                src={action_sa_Share}
                                alt="Lock-btn"
                              />
                            </span>
                          </div>
                          <div
                            className={teacheridstyles.icon}
                            onClick={() => {
                              showDeleteModal(teacherData);
                            }}
                          >
                            <div className={teacheridstyles.tooltip + ' ' + 'az_tooltip'}>
                              Remove
                            </div>
                            <span>
                              <img
                                onClick={() => showDeleteModal(teacherData)}
                                className={
                                  dashboardContentStyles.btnHoverEffect
                                }
                                src={action_sa_Delete}
                                alt="Dlt-btn"
                              />
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <td
                  colSpan="10"
                  style={{
                    // "white-space": "nowrap",/
                    height: "23rem",
                    // overflow: "scroll",
                    // "text-overflow": "clip",
                    // margin: "0px 10px",
                  }}
                >
                  NO DATA AVAILABLE
                </td>
              )}
            </tbody>
          </table>
        </div>

        {/* change password modal starts  */}

        <div
          className={changepasswordstyle.flex_container_teacherid}
          id={changepasswordstyle.modal_teacherid}
          style={showModal ? { display: "flex" } : { display: "none" }}
        >
          <form class={changepasswordstyle.setPassword_login_form}>
            <div class={changepasswordstyle.login_form_title}>
              <h5>Change Password</h5>
              <span>
                <i
                  class="fa-solid fa-xmark cursorPointer"
                  onClick={handleUpdatedPassword}
                ></i>
              </span>
            </div>
            <div class={changepasswordstyle.password_container}>
              <fieldset className={changepasswordstyle.password_fieldset}>
                <legend>New Password</legend>
                <input
                  type={passwordShown ? "text" : "password"}
                  name="userPassword"
                  id={changepasswordstyle.password}
                  value={loginPassword}
                  onChange={PassOnChange}
                />
                <i
                  className={`bi ${passwordShown ? "bi-eye" : "bi-eye-slash"} ${changepasswordstyle.bi_eye_slash
                    }`}
                  id={changepasswordstyle.togglePassword}
                  onMouseEnter={() => {
                    toggleLoginPasswordVisibility("show");
                  }}
                  onMouseLeave={() => {
                    toggleLoginPasswordVisibility("dontShow");
                  }}
                ></i>
              </fieldset>

            </div>
            <div class={changepasswordstyle.password_container}>
              <fieldset className={changepasswordstyle.password_fieldset}>
                <legend>Confirm Password</legend>
                <Tooltip title="Password length must be greater than or equal to 8,
                          must contain atleast one numeric, alphanumeric,
                          upper-case and lower-case alphabet"
                  enterTouchDelay={1000000}
                  open={isTooltipOpen}
                  onOpen={handleTooltipOpen}
                  onClose={handleTooltipClose}

                >
                  <input
                    type={confirmPasswordShown ? "text" : "password"}
                    name="confirmPassword"
                    id={changepasswordstyle.password}
                    value={confirmLoginPassword}
                    onChange={(e) => setConfirmLoginPassword(e.target.value)}
                  /></Tooltip>
                <i
                  className={`bi ${confirmPasswordShown ? "bi-eye" : "bi-eye-slash"
                    } ${changepasswordstyle.bi_eye_slash}`}
                  id={changepasswordstyle.togglePassword}
                  onMouseEnter={() => {
                    toggleconfirmLoginPasswordVisibility("Confirmshow");
                  }}
                  onMouseLeave={() => {
                    toggleconfirmLoginPasswordVisibility("ConfirmdontShow");
                  }}
                ></i>
              </fieldset>
              <span
                className={changepasswordstyle.error_span}
                style={isPassEmpty ? { display: "inline" } : { display: "none" }}
              >
                Password cannot be empty
              </span>
              <span
                class={changepasswordstyle.error_span}
                style={
                  passwordMatch ? { display: "none" } : { display: "inline" }
                }
              >
                Passwords do not match
              </span>

              <span
                class={changepasswordstyle.error_span}
                style={regexPass ? { display: "none" } : { display: "inline" }}
              >

              </span>
            </div>
            <div class={changepasswordstyle.login_container}>
              <button
                class={changepasswordstyle.sp_login_btn}
                onClick={handleSubmit}
              >
                UPDATE
              </button>
            </div>
          </form>
        </div>

        {/* change password modal ends  */}
        {/* pagination container - Start */}
        <div className={teacherliststyle.pagination}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <span className={teacherliststyle.rows}>
              <label for="rows">Rows per page</label>
              <select
                className="cursorPointer"
                name="rows"
                id="rows"
                value={rowsInput.rows}
                onChange={handlerRowsInputs}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </span>
          </form>
          <span>
            <Pagination1 pageDetail={pageDetail} handleChange={handleChange} />
          </span>
        </div>
        {/* pagination container - End */}

        {/* share modal starts  */}

        <div
          className={sharestyle.flex_container_teacherid}
          id={sharestyle.modal_teacherid}
          style={showModalShare ? { display: "flex" } : { display: "none" }}
        >
          <div
            className={sharestyle.main_content_teacherid}
            id={sharestyle.modal_content_teacherid}
          >
            <span className={sharestyle.img}>
              <CheckCircleOutlineIcon
                sx={{ fontSize: "70px", marginLeft: "10px", color: "#ffa500" }}
              />{" "}
            </span>

            <h5 className={sharestyle.head}>Successfully shared User Id</h5>

            <p className={sharestyle.para}> "{teacherIDData.userName}" </p>
            <div className={sharestyle.modal_buttons_teacherid}>
              <button
                className={sharestyle.ok_btn}
                id={sharestyle.ok_btn_1_teacherid}
                onClick={handleShareModalOk}
              >
                OK
              </button>
            </div>
          </div>
        </div>

        {/* Edit modal starts  */}

        <div
          className={studentedit.flex_container_teacherid}
          id={studentedit.modal_teacherid}
          style={showModalEdit ? { display: "flex" } : { display: "none" }}
        >
          <div
            className={studentedit.main_content_teacherid}
            id={studentedit.modal_content_teacherid}
          >
            <div className={studentedit.EditHeadContainer}>
              <h5 className={studentedit.head}>Academic Staff Details</h5>
              <i onClick={handleEditModalcross} class="fa-solid fa-xmark cursorPointer"></i>
            </div>
            <div className={studentedit.EditBox}>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}> First Name</label>
                <label className={studentedit.Input}>
                  {" "}
                  {teacherIDData.firstName}{" "}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}> Last Name</label>
                <label className={studentedit.Input}>
                  {teacherIDData.lastName}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}>
                  {" "}
                  Primary Phone no.{" "}
                </label>
                <label className={studentedit.Input}>
                  {" "}
                  {teacherIDData.mobile}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}> Primary Email</label>
                <label className={studentedit.Input}>
                  {" "}
                  {teacherIDData.email}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}> Date of Birth</label>
                <label className={studentedit.Input}>
                  {" "}
                  {teacherIDData.dob}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}> Gender </label>
                <label className={studentedit.Input}>
                  {" "}
                  {teacherIDData.gender}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}>
                  {" "}
                  Date of Joining
                </label>
                <label className={studentedit.Input}>
                  {" "}
                  {teacherIDData.joinDate}{" "}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}>
                  {" "}
                  Previous work experience{" "}
                </label>
                <label className={studentedit.Input}>
                  {" "}
                  {teacherIDData.previousWorkExp}{" "}
                </label>
              </div>
              {teacherIDData.academicStaffProfile === "COORDINATOR" && (
                <div className={studentedit.Edithead}>
                  <label className={studentedit.Input_head}>
                    {" "}
                    Coordinator Type{" "}
                  </label>
                  <label className={studentedit.Input}>
                    {" "}
                    {teacherIDData.coordinatorType}
                  </label>
                </div>
              )}
              {teacherIDData.academicStaffProfile !== "COORDINATOR" && (
                <div className={studentedit.Edithead}>
                  <label className={studentedit.Input_head}>
                    {" "}
                    Designation{" "}
                  </label>
                  <label className={studentedit.Input}>
                    {" "}
                    {teacherIDData.designation}
                  </label>
                </div>
              )}

              <div className={studentedit.Edithead} id={studentedit.Addressbox}>
                <label className={studentedit.Input_head}> Address </label>
                <label className={studentedit.Input}>
                  {" "}
                  {teacherIDData.address}{" "}
                </label>
              </div>
            </div>

            <div className={studentedit.modal_buttons_teacherid}>
              <button
                className={studentedit.Edit_btn}
                id={studentedit.Edit_btn_1_teacherid}
                onClick={handleEditModalEdit}
              >
                <i class="fa-solid fa-pen"></i> Edit
              </button>
            </div>
          </div>
        </div>
        <SuccesModalGeneral
          userType={"Teacher"}
          userName_Modal={SuccessDisplayName}
          open={successModalGeneral}
          handleOk={closeSuccessModal}
        />
        <CommonDeleteModal
          open={openDeleteModal}
          close={hideDeleteModal}
          deleteTitle={userType}
          deleteHandler={deleteHandler}
          deleteId={selectedItem?.id}
          deleteName={selectedItem?.name}
        />
        <ConfirmationModal
          open={toggleConfirmation}
          confirmationmessage={toggleMessage}
          close={handleConfirmationClose}
          updateToggle={updateToggle}
        />
      </article>

      {/* --------------------------------------parth25-07--------------------------- */}
    </>
  );
};

export default TeacherIdList;
