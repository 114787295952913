import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import chapterStyles from "./../css/createChapter.module.css";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import createschool from "./../css/CreateSchool.module.css";
import BlueButton from "../../components/common/BlueButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import WhiteAddButton from "../../components/common/WhiteButton";
import InactiveButton from "../../components/common/InactiveButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import SchoolAdminBreadcrumb from "./SchoolAdminBreadcrumb";
import { styled } from "@mui/material/styles";
import { makeStyles } from '@material-ui/core/styles';
import Pagination1 from "./pagination1";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { rightClickEnable, CDNBASEURL } from "../../../src/Serviceworker"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditIconButton from "../../components/common/EditIconButton";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import { SearchOutlined } from "@mui/icons-material";
// import GradesControllerGetall from "../API_CONTROLLER/grades-controller/grades_controller_getall";
import gradeListGet from "../API/grade-section-mapping-controller/grade_get";
import boards_controller_getall from "../API_CONTROLLER/boards-controller/boards_controller_getall";
import subjects_controller_getall from "../API_CONTROLLER/subject_controller/subjects_controller_getall";
import subtopic_filtered_get from "../API_CONTROLLER/subtopic-controller/subtopic_filtered_get";
import chapters_controller_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_get";
import chapters_controller_post from "../API_CONTROLLER/chapters-controller/chapters_controller_post";
import chapters_file_uploader_post from "../API_CONTROLLER/chapters-controller/chapters_file_uploader_post";
import chapters_last_modified_get from "../API_CONTROLLER/chapters-controller/chapters_last_modified_get";
import chapters_edit_getby_id from "../API_CONTROLLER/chapters-controller/chapters_edit_getby_id";
import chapters_controller_put from "../API_CONTROLLER/chapters-controller/chapters_controller_put";
import subtopics_controller_getall from "../API_CONTROLLER/subtopic-controller/subtopics_controller_getall";
import gradesWithBoard from "../API/gradesWithBoard";
import ChapterModel from "./ChapterModel";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";

// sort
import sortIcon from "../../../src/pages/img/sort_1.png";

// input choose file label image
import Cf_img from "../../../src/img/image_icon.png";
import ConfirmationModal from "./ConfirmationModal";

const useStyles = makeStyles({
  formControl: {
    margin: '8px',
    marginTop: '16px',
    width: '270px',
  },
  inputBaseRoot: {
    height: '48px !important',
    width: '270px !important',
    borderRadius: '25px !important',
    color: '#FFA500',
    '&:hover': {
      border: '2px solid #FFA500',
    },

  },
});

const CreateChapter = () => {

  const classes = useStyles();

  const [gradesName, setGrades] = useState({});
  const [board, setBoard] = useState({});
  const [subject, setSubject] = useState({});
  const [subtopic, setSubtopic] = useState({});
  const [chapters, setChapters] = useState({});
  //Edit
  const [subjectEdit, setSubjectEdit] = useState([]);
  const [subtopicEdit, setSubtopicEdit] = useState([]);
  const [gradeEdit, setGradeEdit] = useState([]);
  const [EditsubTopic, setEditSubTopic] = useState("");
  const [lastModifiedDate, setLastModifiedDate] = useState("");
  const [isAddChapter, setIsAddChapter] = useState(true);
  const [isError, setIsError] = useState(false);
  const [showError, setShowError] = useState("");
  const [success, setSuccess] = useState({});
  const [documenttype, setDocumenttype] = useState({});
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedBoard, setSelectedBoard] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedSubtopic, setSelectedSubtopic] = useState("");
  const [selectedChapter, setSelectedChapter] = useState("");
  const [selectedDocumenttype, setSelectedDocumenttype] = useState("");
  const [docType, setDoctype] = useState({});
  const [chapterModal, setChapterModal] = useState(false);
  const [toggleDetails, setToggleDetails] = useState({});
  const [toggleMessage, settoggleMessage] = useState();
  const [toggleConfirmation, setToggleConfirmation] = useState(false);
  const [gradeName, setGradeName] = useState("");
  const [chapterInputs, setChapterInputs] = useState({
    boardId: "",
    gradeId: "",
    subjectId: "",
    // subTopicId: "",
    chapter: "",
    // thumbnailPath: "",
    chapterStatus: "PENDING",
  });

  // sort
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState(false);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterValue, setFilterValue] = useState();
  const [gradeInput, setGradeInput] = useState("");
  const [subjectInput, setSubjectInput] = useState("");
  const [subTopicInput, setSubTopicInput] = useState("");
  const [uploadedFile, setUploadedFile] = useState();
  const UploadButton = styled(Button)(() => ({
    color: "#fff",
    // width: 170,
    width: 130,
    height: 35,
    // backgroundColor: "#FFA700",
    backgroundColor: "#FD8C00",
    textTransform: "capitalize",
    fontSize: "14px",
    borderRadius: 150,
    border: "1px solid #FFA700",
    "&:hover": {
      color: "#fff",
      // backgroundColor: "#FFA700",
      backgroundColor: "#FD8C00",
      border: 0,
      top: -2,
      boxShadow: "0px 3px 5px #FFA700",
    },
  }));
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const prevLocation = useLocation();

  if (prevLocation.state === "edit") {
    setIsAddChapter(false);
    prevLocation.state = null;
  }
  const handleValidation = () => {
    let isValid = true;
    if (!uploadedFile) isValid = false;
    if (!chapterInputs.boardId) isValid = false;
    if (!chapterInputs.gradeId) isValid = false;
    if (!chapterInputs.subjectId) isValid = false;
    if (!subTopicId && subtopicEdit.length !== 0) isValid = false;
    return isValid;
  };
  const [chapterOpen, setchapterModal] = useState(false);
  const handleCreateChapter = (e) => {
    e.preventDefault();
    // for (const input in chapterInputs) {
    if (!handleValidation()) {
      setIsError(true);
      setShowError("Please enter all required valules");
      return;
      // }
    } else {
      setIsError(false);
      setShowError("");
      chapters_controller_post(
        { ...chapterInputs, thumbnailPath: uploadedFile },
        subTopicId, chapName,
        setSuccess,
        setShowError
      );
      publishSubjectCDN();
      setchapterModal(true);
    }
  };

  /// CDN publish call
  const publishSubjectCDN = async () => {
    const response = await axios.post(
      `${CDNBASEURL}/v1/api/file/entity/upload-entity-json-s3?entityName=grade-subject`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    //alert(response);
    console.log("publishCDN >>> ", response);
  }

  const chapterModelClose = () => {
    setchapterModal(false);
    navigate("/dashboard/Upload%20Content");
  };

  const getSubjectAll = (chapterInputs) => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subject-mapping/subjects?boardId=${chapterInputs.boardId}&gradeId=${chapterInputs.gradeId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSubject(res.data.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getSubjectAll(chapterInputs);
  }, [chapterInputs]);

  useEffect(() => {
    if (!uploadedFile && chapterInputs.thumbnailPath)
      setUploadedFile(chapterInputs.thumbnailPath);
  }, [chapterInputs]);

  const [chapterUpdateOpen, setUpdateChapterModal] = useState(false);
  const handleUpdateChapter = (e) => {
    e.preventDefault();
    // for (const input in chapterInputs) {
    if (!handleValidation()) {
      setIsError(true);
      setShowError("Please enter all required valules");
      return;
      // }
    } else {
      setIsError(false);
      setShowError("");
      console.log(chapterInputs, "chapin");
      chapters_controller_put({
        ...chapterInputs,
        thumbnailPath: uploadedFile,
      }, subTopicId, chapName);
      // navigate("/dashboard/Upload%20Content");
      setUpdateChapterModal(true);
    }
  };

  const updateChapterModelClose = () => {
    setUpdateChapterModal(false);
    navigate("/dashboard/Upload%20Content");
  };
  // pagination starts
  const [rowsInput, setRowsInputs] = useState({
    rows: 10,
  });
  const [pageDetail, setPageDetail] = useState({});
  const handleChange = (e, value) => {
    setPage(value);
  };

  const handlerRowsInputs = (e) => {
    e.preventDefault();
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(1);
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
  };
  // pagination ends

  const handlerChapterInputs = (e) => {
    e.preventDefault();
    console.log(e, "e");
    console.log(chapterInputs, "chapterinputs");
    setSubTopicId(null)
    setChapterInputs({
      ...chapterInputs,
      [e.target.name]: e.target.value,
    });
    console.log(chapterInputs.subTopicId, "subtopicId")
    // if(chapterInputs.gradeId){setGradeName(gradeEdit.filter((grade)=>grade.idd === chapterInputs.gradeId))
    // console.log(gradeName,"gradeName")
    // }
  };
  const [chapName, setChapName] = useState("")
  const handleChpaterNameInput = (e) => {
    setChapName(e.target.value)
  }

  const [subTopicId, setSubTopicId] = useState("")
  const handleSubjectInput = (e) => {
    setSubTopicId(e.target.value)
  }

  const [search, setSearch] = useState({
    search: "",
  });
  const handleSearch = (e) => {
    e.preventDefault();

    const searchValue = {
      search: e.target.value,
    };
    setSearch(searchValue);
    chapters_controller_getall(
      setChapters,
      rowsInput,
      page,
      setPageDetail,
      searchValue,
      gradeInput,
      subjectInput,
      subTopicInput
    );
    setPage(1);
  };

  const addChapterHandler = (
    chapter,
    boardId,
    gradeId,
    subjectId,
    subTopicName,
    file
  ) => { };

  const imageChange = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    chapters_file_uploader_post(
      setSelectedImage,
      setChapterInputs,
      chapterInputs,
      formData
    );
  };

  const removeSelectedImage = () => {
    setChapterInputs({
      ...chapterInputs,
      logoUrl: null,
    });
  };

  useEffect(() => {
    if (success.success === false) {
      setIsError(true);
      setShowError(success.message);
      return () => { };
    }
    if (success.success === true) {
      setIsError(false);
    }
  }, [success]);

  useEffect(() => {
    setChapterInputs({
      ...chapterInputs,
      logourl: sessionStorage.getItem("logourl"),
    });
    return () => { };
  }, [sessionStorage.getItem("logoUrl")]);

  const [subtopcs, settopics] = useState([]);
  useEffect(() => {
    if (chapterInputs.subjectId) {
      subtopics_controller_getall(setSubtopicEdit, chapterInputs.subjectId);
    }
  }, [chapterInputs.subjectId]);

  useEffect(() => {
    if (chapterInputs.gradeId) {
      subjects_controller_getall(setSubjectEdit);
    }
  }, [chapterInputs.gradeId]);

  useEffect(() => {
    if (chapterInputs.boardId) {
      gradesWithBoard(setGradeEdit, chapterInputs.boardId);
    }
  }, [chapterInputs.boardId]);

  useEffect(() => {
    if (search.search === "") {
      chapters_controller_getall(
        setChapters,
        rowsInput,
        page,
        setPageDetail,
        search,
        gradeInput,
        subjectInput,
        subTopicInput,
        sortOrder,
        sortBy
      );
    }
    return () => { };
  }, [rowsInput, search, sortOrder, sortBy]);

  useEffect(() => {
    if (isAddChapter === false) {
      chapters_edit_getby_id(setChapterInputs, setSubTopicId, setChapName);
      return () => { };
    }
  }, []);
  // useEffect(()=>{
  //   chapterInputs.subTopicId = EditsubTopic
  // },[EditsubTopic])

  useEffect(() => {
    chapters_controller_getall(
      setChapters,
      rowsInput,
      page,
      setPageDetail,
      search,
      gradeInput,
      subjectInput,
      subTopicInput,
      sortOrder,
      sortBy
    );
  }, [rowsInput, page, sortOrder, sortBy]);

  useEffect(() => {
    chapters_controller_getall(
      setChapters,
      rowsInput,
      page,
      setPageDetail,
      search,
      gradeInput,
      subjectInput,
      subTopicInput,
      sortOrder,
      sortBy
    );
    return () => { };
  }, [search, gradeInput, subjectInput, subTopicInput, sortOrder, sortBy]);

  // useEffect(() => {
  //   subtopic_filtered_get(setSubtopic, chapterInputs);
  // }, [chapterInputs]);

  useEffect(() => {
    if (subjectInput) {
      subtopics_controller_getall(setSubtopic, subjectInput);
    }
  }, [subjectInput]);

  useEffect(() => {
    gradesWithBoard(setGrades, chapterInputs.boardId);
    boards_controller_getall(setBoard);
    subjects_controller_getall(setSubject);
    // subtopics_controller_getall(setSubtopic, subjectInput);
    subtopics_controller_getall(setSubtopic);

    chapters_last_modified_get(setLastModifiedDate);
    return () => { };
  }, []);
  const uploadedFileHandler = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    const token = sessionStorage.getItem("token");
    formData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_SWAGGER_URL}v1/api/file/upload?fileCategory=CONTENT_SERVICE&subFolders=CHAPTERS`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function (response) {
        if (response.data.data) {
          setUploadedFile(response?.data?.data);
        }
      })
      .catch(function (response) { });
  };
  // const statusHandler = () => {
  //   const { details: elem } = toggleConfirmation;
  //   const value = !elem?.active;
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/toggle/${elem.id}?active=${value}`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       setToggleConfirmation({ open: false, details: {} });
  //       chapters_controller_getall(
  //         setChapters,
  //         rowsInput,
  //         page,
  //         setPageDetail,
  //         search,
  //         gradeInput,
  //         subjectInput,
  //         subTopicInput
  //       );
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const handleConfirmationClose = () => {
  //   setToggleConfirmation({ open: false, details: {} });
  //   setToggleDetails({});
  // };
  const updateToggle = () => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/toggle/${toggleDetails?.id}?active=${toggleDetails?.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          data: !toggleDetails?.value,
        }
      )
      .then(function (response) {
        if (response?.data) {
          chapters_controller_getall(
            setChapters,
            rowsInput,
            page,
            setPageDetail,
            search,
            gradeInput,
            subjectInput,
            subTopicInput,
            sortOrder,
            sortBy
          );
          handleConfirmationClose();
        }
      })
      .catch(function (response) {
        // console.log(response);
      });
  };
  const switchHandler = async ({ id, active }) => {
    setToggleDetails({});
    const value = !active;
    if (!value) {
      const response = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/confirmation-api?id=${id}&operationType=TOGGLE`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      setToggleConfirmation(true);
      settoggleMessage(response?.data?.data?.message);
      setToggleDetails({ id, value });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/toggle/${id}?active=${value}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
            data: !value,
          }
        )
        .then(function (response) {
          if (response?.data) {
            chapters_controller_getall(
              setChapters,
              rowsInput,
              page,
              setPageDetail,
              search,
              gradeInput,
              subjectInput,
              subTopicInput,
              sortOrder,
              sortBy
            );
            handleConfirmationClose();
          }
        })
        .catch(function (response) {
          // console.log(response);
        });
    }
  };

  const handleConfirmationClose = () => {
    setToggleConfirmation(false);
    setToggleDetails({});
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        {/* <article>
          <div className={dashboardContentStyles.dashboard_link}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />
            </span>
            {"  "}
            <span className={dashboardContentStyles.link_text}>Home</span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            {"  "}
            <a>Upload Content</a>
          </div>
          <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>
              Last Update:
              {lastModifiedDate.data}
            </p>
          </div>
        </article> */}
        <SchoolAdminBreadcrumb
          dashHome={dashHome}
          sectionName={'Upload Content'}
          date={lastModifiedDate.data}
        />

        {/* create chapter */}
        <Paper
          style={{
            marginTop: "55px",
            paddingRight: "33px",
            position: "absolute",
            right: "15px",
            left: "15px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px 20px",
            }}
          >
            <div className={chapterStyles.chapter_container}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="demo-helper-text-aligned-no-helper"
                  label="*Chapter Name"
                  name="chapter"
                  type="text"
                  // style={{ fontWeight: "500" }}
                  className={chapterStyles.select_input}
                  // style={{ fontSize: "15px", width: "367px" }}
                  value={chapName}
                  onChange={handleChpaterNameInput}
                  InputProps={{
                    classes: {
                      root: classes.inputBaseRoot,
                    },
                  }}
                />
              </FormControl>
              <div className={chapterStyles.flex}>
                <FormControl
                  style={{
                    marginRight: "8px",
                    marginLeft: "8px",
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "48px !important",
                      width: "270px !important",
                      borderRadius: "25px !important",
                    },
                  }}
                // sx={{ width: 300 }}
                >
                  <InputLabel
                    className={chapterStyles.select_input}
                    id="demo-multiple-checkbox-label"
                  >
                    *Board
                  </InputLabel>
                  <Select
                    style={{
                      color: "#B5B6B6",
                    }}
                    name="boardId"
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    value={chapterInputs.boardId}
                    onChange={handlerChapterInputs}
                    input={<OutlinedInput label="Tag" />}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      style: {
                        maxHeight: "300px",
                      },
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        "&:hover": {
                          border: "2px solid #FFA500",
                          color: "#FFA500",
                        }
                      },
                    }}
                  // renderValue={(selected) => selected.join(", ")}
                  >
                    <MenuItem>Select</MenuItem>
                    {/* {optionsRender(board)} */}
                    {board.length > 0 ? (
                      board.map((board, i) => {
                        return (
                          <MenuItem
                            key={i}
                            value={board.id}
                            className={dashboardContentStyles.menu_item}
                            sx={{
                              "&.Mui-selected": {
                                backgroundColor: '#FFA500',
                                color: "#FFFFFF"
                              },
                              "&.Mui-selected:hover": {
                                backgroundColor: '#FFA500',
                                color: "#000000"
                              },
                              "&:not(.Mui-selected):hover": {
                                backgroundColor: '#fcedc2',
                                color: '#000000',
                              },
                            }}
                          >
                            <ListItemText primary={board.board} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                  </Select>
                </FormControl>
                <FormControl
                  style={{
                    marginRight: "8px",
                    marginLeft: "8px",
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "48px !important",
                      width: "270px !important",
                      borderRadius: "25px !important",
                    },
                  }}
                >
                  <InputLabel
                    className={chapterStyles.select_input}
                    id="demo-multiple-checkbox-label"
                    style={{ color: "#B5B6B6" }}
                  >
                    *Grade
                  </InputLabel>
                  <Select
                    name="gradeId"
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    input={<OutlinedInput label="Tag" />}
                    value={chapterInputs.gradeId}
                    onChange={handlerChapterInputs}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      style: {
                        maxHeight: "300px",
                      },
                    }}
                  >
                    {gradeEdit.length > 0 ? (
                      gradeEdit.map((elem, i) => {
                        return (
                          <MenuItem
                            key={i}
                            value={elem.id}
                            className={dashboardContentStyles.menu_item}
                            sx={{
                              "&.Mui-selected": {
                                backgroundColor: '#FFA500',
                                color: "#FFFFFF"
                              },
                              "&.Mui-selected:hover": {
                                backgroundColor: '#FFA500',
                                color: "#000000"
                              },
                              "&:not(.Mui-selected):hover": {
                                backgroundColor: '#fcedc2',
                                color: '#000000',
                              },
                            }}
                          >
                            <ListItemText primary={elem.grade} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                    {/* {optionsRender(gradesName)} */}
                  </Select>
                </FormControl>
                <FormControl
                  style={{
                    marginRight: "8px",
                    marginLeft: "8px",
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "48px !important",
                      width: "270px !important",
                      borderRadius: "25px !important",
                    },
                  }}
                // sx={{ width: 300 }}
                >
                  <InputLabel
                    className={chapterStyles.select_input}
                    id="demo-multiple-checkbox-label"
                    style={{ color: "#B5B6B6" }}
                  >
                    *Subjects
                  </InputLabel>

                  <Select
                    labelId="demo-multiple-checkbox-label"
                    name="subjectId"
                    id="demo-multiple-checkbox"
                    value={chapterInputs.subjectId}
                    onChange={handlerChapterInputs}
                    input={<OutlinedInput label="Tag" />}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      style: {
                        maxHeight: "300px",
                      },
                    }}
                  // renderValue={(selected) => selected.join(", ")}
                  //   MenuProps={MenuProps}
                  >
                    {subject.length > 0 ? (
                      subject.map((subject, i) => {
                        return (
                          <MenuItem
                            key={i}
                            value={subject.subjectId}
                            className={dashboardContentStyles.menu_item}
                            sx={{
                              "&.Mui-selected": {
                                backgroundColor: '#FFA500',
                                color: "#FFFFFF"
                              },
                              "&.Mui-selected:hover": {
                                backgroundColor: '#FFA500',
                                color: "#000000"
                              },
                              "&:not(.Mui-selected):hover": {
                                backgroundColor: '#fcedc2',
                                color: '#000000',
                              },
                            }}
                          >
                            <ListItemText primary={subject.subject} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                  </Select>
                </FormControl>
                <FormControl
                  style={{
                    marginRight: "8px",
                    marginLeft: "8px",
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "48px !important",
                      width: "270px !important",
                      borderRadius: "25px !important",
                    },
                  }}
                // sx={{ width: 250 }}
                >
                  <InputLabel
                    className={chapterStyles.select_input}
                    id="demo-multiple-checkbox-label"
                    style={{ color: "#B5B6B6" }}
                  >
                    *Subtopic
                  </InputLabel>

                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    name="subTopicId"
                    value={subTopicId}
                    onChange={handleSubjectInput}
                    input={<OutlinedInput label="Tag" />}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      style: {
                        maxHeight: "300px",
                      },
                    }}
                  // renderValue={(selected) => selected.join(", ")}
                  //   MenuProps={MenuProps}
                  >
                    {subtopicEdit.length > 0 ? (
                      subtopicEdit.map((subtopic, i) => {
                        return (
                          <MenuItem
                            key={i}
                            value={subtopic.id}
                            className={dashboardContentStyles.menu_item}
                            sx={{
                              "&.Mui-selected": {
                                backgroundColor: '#FFA500',
                                color: "#FFFFFF"
                              },
                              "&.Mui-selected:hover": {
                                backgroundColor: '#FFA500',
                                color: "#000000"
                              },
                              "&:not(.Mui-selected):hover": {
                                backgroundColor: '#fcedc2',
                                color: '#000000',
                              },
                            }}
                          >
                            <ListItemText primary={subtopic.subTopic} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
              <div className={chapterStyles.flex} style={{ marginTop: "15px" }}>
                <label
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    fontSize: "18px",
                  }}
                >
                  {/* Upload Chapter Thumbnail */}
                  *Upload File
                  <div>
                    {uploadedFile ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        <div style={{ fontSize: "18px" }}>
                          <span>
                            <img
                              src={uploadedFile}
                              alt="Thumb"
                              width="75"
                              height="75"
                            />
                          </span>
                        </div>
                        <div
                          onClick={() => setUploadedFile("")}
                          style={{
                            display: "flex",
                            fontSize: "15px",
                            cursor: "pointer",
                            color: "blue",
                          }}
                        >
                          <span style={{ borderBottom: "1px solid blue" }}>
                            Remove
                          </span>
                        </div>
                      </div>
                    ) : (
                      <>
                        <UploadButton
                          className="btn-choose"
                          variant="outlined"
                          component="span"
                        >
                          {/* <UploadFileRoundedIcon /> */}
                          <img
                            src={Cf_img}
                            alt="no-img"
                            style={{ marginRight: "2px" }}
                          />
                          Choose File
                          <input
                            type="file"
                            accept={"image/*"}
                            onChange={(e) => uploadedFileHandler(e)}
                            hidden
                          />
                        </UploadButton>
                        {/* <img src={chapterInputs.thumbnailPath} /> */}
                      </>
                    )}
                  </div>
                </label>
                {/* <FormControl style={{marginRight : '8px' , marginLeft : '8px' , marginTop : '16px'}} sx={{ width: 250 }}>
                    <InputLabel className={chapterStyles.select_input} id="demo-multiple-checkbox-label">
                      *Document Type
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      value={selectedDocumenttype}
                      onChange={(e)=> setSelectedDocumenttype(e.target.value)}
                      input={<OutlinedInput label="Tag" />}
                      
                    >
                    
                    <MenuItem value='HTML'>HTML</MenuItem>
                    <MenuItem  value='pdf'>PDF</MenuItem>
                    <MenuItem  value='png'>PNG</MenuItem>  
                    </Select>
                  </FormControl> */}

                {/* upload img */}
                {/* <div className={createschool.add_schools_btn}>
                  <div className={createschool.upload_logo_container}>
                    <span>*Upload File</span>
                    <span className={createschool.custom_file_container}>
                      <input
                        type="file"
                        name="logoUrl"
                        // id={sessionStorage.getItem("logoUrl")}
                        // filename={sessionStorage.getItem("logoUrl")}
                        // Value={chapterInputs.logoUrl}
                        // onError={setIsError(true)}
                        onChange={imageChange}
                        className={createschool.custom_file_input}
                      />

                      {/* {chapterInputs.logoUrl && (
                  <span>
                    <img
                      src={chapterInputs.logoUrl}
                      alt="Thumb"
                      width="75"
                      height="75"
                    />
                    <span>
                      <a>
                        {chapterInputs.logoUrl.substring(
                          chapterInputs.logoUrl.lastIndexOf("_") + 1,
                          chapterInputs.logoUrl.length
                        )}
                      </a>
                      <br></br>
                      <br></br>
                      <a 
                      onClick={removeSelectedImage} 
                      style={{color:'red' , textDecoration:'underline'}}
                      >Remove This Image</a>
                    </span>
                  </span>
                )}
                    </span>
                  </div>
                </div> */}

                <div
                  style={{
                    marginTop: "30px",
                    position: "absolute",
                    right: "35px",
                  }}
                >
                  <button className="az_cancel_btn"
                    onClick={() => {
                      navigate("/dashboard/upload%20content");
                    }}
                    style={{ marginRight: "12px" }}
                  >
                    BACK
                  </button>
                  {isAddChapter ? (
                    <button
                      style={{ maxHeight: "50px" }}
                      onClick={handleCreateChapter}
                      className={'az_confirm_btn'}
                    >
                      CREATE
                    </button>
                  ) : (
                    <button
                      style={{ maxHeight: "50px" }}
                      onClick={handleUpdateChapter}
                      className={'az_confirm_btn'}
                    >
                      UPDATE
                    </button>
                  )}
                </div>
              </div>

              <div
                style={
                  isError ? { visibility: "visible" } : { visibility: "hidden" }
                }
              >
                <p
                  style={{
                    display: "flex",
                    float: "right",
                    marginRight: "187px",
                    color: "red",
                  }}
                >
                  {showError}
                </p>
              </div>

              <hr style={{ width: "100%", margin: "20px auto" }} />

              {/* search btn row */}
              <div
                className={chapterStyles.flex}
                style={{
                  marginTop: "15px",
                  position: "relative",
                }}
              >
                <Box
                  // style={{ margin: "auto 8px" }}
                  sx={{ minWidth: 150 }}
                >
                  <FormControl fullWidth size="small">
                    <InputLabel
                      className={chapterStyles.select_input}
                      id="demo-select-small"
                      style={{ fontSize: "14px", color: "#7a7a7a" }}
                    >
                      Grade
                    </InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      label="Grade"
                      value={gradeInput}
                      onChange={(e) => {
                        setGradeInput(e.target.value);
                        setSubjectInput("");
                        setSubTopicInput("");
                      }}
                      style={{
                        background: "#fff",
                        borderRadius: 150,
                        height: "32px",
                        width: "75%",
                        top: "1px",
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        style: {
                          maxHeight: "300px",
                        },
                      }}
                    //   MenuProps={MenuProps}
                    >
                      {/* <MenuItem value="">All</MenuItem> */}
                      {/* {optionsRender(gradesName)} */}
                      {gradesName.length > 0 ? (
                        gradesName?.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            className={dashboardContentStyles.menu_item}
                          >
                            <ListItemText primary={item.grade} />
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>Select</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Box>
                <Box style={{ margin: "auto 8px" }} sx={{ minWidth: 170 }}>
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "32px !important",
                        width: "156px !important",
                        borderRadius: "150px !important",
                      },
                    }}
                  >
                    <InputLabel
                      className={chapterStyles.select_input}
                      id="demo-select-small"
                      style={{ fontSize: "14px", color: "#7a7a7a" }}
                    >
                      {/* subject */}
                      Select Subject
                    </InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={subjectInput}
                      label="subject"
                      onChange={(e) => {
                        setSubjectInput(e.target.value);
                        setSubTopicInput("");
                      }}
                      style={{
                        background: "#fff",
                        borderRadius: 150,
                        height: "32px",
                        width: "85%",
                        top: "1px",
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        style: {
                          maxHeight: "300px",
                        },
                      }}
                    //   MenuProps={MenuProps}
                    >
                      {/* <MenuItem value="">All</MenuItem> */}
                      {subject.length > 0 ? (
                        subject.map((subject, i) => {
                          return (
                            <MenuItem
                              key={i}
                              value={subject.id}
                              className={dashboardContentStyles.menu_item}
                            >
                              <ListItemText primary={subject.subject} />
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem>Select</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Box>

                <Box style={{ margin: "auto 8px" }} sx={{ minWidth: 160 }}>
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "32px !important",
                        width: "156px !important",
                        borderRadius: "150px !important",
                      },
                    }}
                  >
                    <InputLabel
                      className={chapterStyles.select_input}
                      id="demo-select-small"
                      style={{ fontSize: "14px", color: "#7a7a7a" }}
                    >
                      {/* Sub Topic */}
                      Subtopic
                    </InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      label="subTopic"
                      style={{
                        background: "#fff",
                        borderRadius: 150,
                        height: "32px",
                        width: "85%",
                        top: "1px",
                      }}
                      value={subTopicInput}
                      onChange={(e) => setSubTopicInput(e.target.value)}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        style: {
                          maxHeight: "300px",
                        },
                      }}
                    //   MenuProps={MenuProps}/
                    >
                      {/* <MenuItem value="">All</MenuItem> */}
                      {subtopic.length > 0 ? (
                        subtopic.map((subtopic, i) => {
                          return (
                            <MenuItem
                              key={i}
                              value={subtopic.id}
                              className={dashboardContentStyles.menu_item}
                            >
                              <ListItemText primary={subtopic.subTopic} />
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem>Select</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Box>

                <div
                  style={{
                    position: "absolute",
                    right: "35px",
                  }}
                >
                  <div
                    className={
                      dashboardContentStyles.global_list_search_container_1
                    }
                  >
                    <div
                      className={
                        dashboardContentStyles.global_list_search_container
                      }
                    >
                      <input
                        type={"text"}
                        name="search"
                        // type='search'
                        placeholder={"Search...."}
                        onChange={handleSearch}
                        value={search.search}
                        style={{ width: "247px", height: "32px" }}
                      />
                      <span>
                        <img
                          src={searchIcn}
                          alt="no img"
                          style={{
                            verticalAlign: "top",
                            fontSize: "large",
                          }}
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* table */}
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer
              sx={{
                overflow: "auto",
              }}
            >
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader={true}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={dashboardContentStyles.table_head}>
                      #
                    </TableCell>
                    <TableCell
                      align="left"
                      className={dashboardContentStyles.table_head}
                    >
                      Chapter
                      <img
                        style={{
                          verticalAlign: "middle",
                          paddingLeft: "3px",
                          marginBottom: "2px",
                          marginLeft: "45px",
                        }}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("chapter");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      Board
                      <img
                        style={{
                          verticalAlign: "middle",
                          paddingLeft: "3px",
                          marginBottom: "2px",
                          marginLeft: "5px",
                        }}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("board");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      Grade
                      <img
                        style={{
                          verticalAlign: "middle",
                          paddingLeft: "3px",
                          marginBottom: "2px",
                          marginLeft: "5px",
                        }}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("grade");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      Subject
                      <img
                        style={{
                          verticalAlign: "middle",
                          paddingLeft: "3px",
                          marginBottom: "2px",
                          marginLeft: "5px",
                        }}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("subject");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      Subtopic
                      <img
                        style={{
                          verticalAlign: "middle",
                          paddingLeft: "3px",
                          marginBottom: "2px",
                          marginLeft: "5px",
                        }}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("subTopic");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      Status
                    </TableCell>
                    {/* <TableCell
                      align="center"
                      className={chapterStyles.table_head}
                    ></TableCell> */}
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      Operation
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {chapters.length > 0 ? (
                    chapters.map((elem, i) => (
                      <TableRow>
                        <TableCell className={chapterStyles.table_cell}>
                          {(page - 1) * rowsPerPage + (i + 1)}
                        </TableCell>

                        <TableCell className={chapterStyles.table_cell}>
                          {elem.chapter}
                        </TableCell>

                        <TableCell
                          className={chapterStyles.table_cell}
                          align="center"
                        >
                          {elem.board}
                        </TableCell>

                        <TableCell
                          className={chapterStyles.table_cell}
                          align="center"
                        >
                          {elem.grade}
                        </TableCell>

                        <TableCell
                          className={chapterStyles.table_cell}
                          align="center"
                        >
                          {elem.subject}
                        </TableCell>

                        <TableCell
                          className={chapterStyles.table_cell}
                          size="small"
                          align="center"
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            {elem.subTopic}
                            {/* {elem.subTopics?.length > 0 ? (
                              elem.subTopics.map((data) => (
                                <p style={{ color: "black" }}>
                                  {data.subTopic}
                                </p>
                              ))
                            ) : (
                              <p>-</p>
                            )} */}
                          </div>
                        </TableCell>
                        {/* active btn */}
                        <TableCell
                          className={chapterStyles.table_cell}
                          align="center"
                        >
                          <button
                            className={chapterStyles.green_btn}
                            style={
                              elem.active
                                ? { backgroundColor: "green" }
                                : { backgroundColor: "red" }
                            }
                            onClick={(e) => switchHandler(elem)}
                          >
                            {elem.active ? "Active" : "In-Active"}
                          </button>
                        </TableCell>
                        <TableCell
                          align="right"
                          className={chapterStyles.table_cell}
                          style={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Link
                            style={{ textDecoration: "none" }}
                            to="/dashboard/AddTeacherFiles"
                          >
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/dashboard/AddTeacherFiles");
                                var chapterId = elem.id;
                                sessionStorage.setItem("chapterId", chapterId);
                              }}
                              style={{
                                backgroundColor: "transparent",
                                color: "black",
                                border: "1px solid",
                              }}
                              className={chapterStyles.white_btn}
                            >
                              <AddCircleOutlineOutlinedIcon
                                style={{
                                  marginRight: "6px",
                                  height: "16px",
                                  width: "16px",
                                }}
                              />
                              Add Teacher Files
                            </button>
                          </Link>

                          <Link
                            style={{ textDecoration: "none" }}
                            to="/dashboard/AddStudentFiles"
                          >
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/dashboard/AddStudentFiles");
                                var chapterId = elem.id;
                                sessionStorage.setItem("chapterId", chapterId);
                              }}
                              style={{
                                backgroundColor: "transparent",
                                color: "black",
                                border: "1px solid",
                              }}
                              className={chapterStyles.white_btn}
                            >
                              <AddCircleOutlineOutlinedIcon
                                style={{
                                  marginRight: "6px",
                                  height: "16px",
                                  width: "16px",
                                }}
                              />
                              Add Student Files
                            </button>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <td
                      colSpan="10"
                      style={{
                        height: "25rem",
                        textAlign: "center",
                        color: "grey",
                      }}
                    >
                      {" "}
                      NO DATA AVAILABLE{" "}
                    </td>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {/* pagination container - Start */}
            <div className={chapterStyles.pagination} style={{ width: "100%" }}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <span className={chapterStyles.rows}>
                  <label for="rows">Rows per page</label>
                  <select
                  className="cursorPointer"
                    name="rows"
                    id="rows"
                    value={rowsInput.rows}
                    onChange={handlerRowsInputs}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                  </select>
                </span>
              </form>
              <span>
                <Pagination1
                  pageDetail={pageDetail}
                  handleChange={handleChange}
                />
              </span>
            </div>
            {/* pagination container - End */}
            <ChapterModel
              open={chapterOpen}
              close={chapterModelClose}
              deleteName="Chapter Created Successfully"
            />
            <ChapterModel
              open={chapterUpdateOpen}
              close={updateChapterModelClose}
              deleteName="Chapter Updated Successfully"
            />
            <ConfirmationModal
              open={toggleConfirmation}
              confirmationmessage={toggleMessage}
              close={handleConfirmationClose}
              updateToggle={updateToggle}
            />
          </Paper>
        </Paper>
      </div>
    </>
  );
};

export default CreateChapter;
