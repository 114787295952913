import React, { useEffect, useRef, useState } from "react";
import Pagination1 from "../pages/js/pagination1";
import chapterStyles from "../pages/css/createChapter.module.css";
import report from "../img/file.png";
import axios from "axios";
import dashboardContentStyles from "../../src/pages/css/dashboardContent.module.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { selectedDashboardData } from "../actions/dashboardTableAction";
import sortIcon from "../pages/img/sort_1.png";
import { v4 as uuidv4 } from "uuid";
import {
    fetchQuizPerformance,
} from "../pages/js/Teacher/Apis/TeacherDashboard";
import {
    FormControl, InputLabel, OutlinedInput, Select, ListItemText,
    MenuItem,
} from "@mui/material";
const sortData = (data, key, direction) => {
    return data && data.slice().sort((a, b) => {
        let comparison = 0;
        if (a[key] > b[key]) {
            comparison = 1;
        } else if (a[key] < b[key]) {
            comparison = -1;
        }
        return direction === 'asc' ? comparison : -comparison;
    });
}
const sortByField = (array, field, order) => {
    if (array?.length > 0) {
        var nullsFirst = false;
        if (order == "asc") {
            nullsFirst = true
        }
        return array.sort((a, b) => {
            if (a[field] === null && b[field] === null) return 0;
            if (a[field] === null) return nullsFirst ? -1 : 1;
            if (b[field] === null) return nullsFirst ? 1 : -1;

            if (typeof a[field] === 'string') {
                if (order === 'asc') {
                    return a[field].localeCompare(b[field]);
                } else {
                    return b[field].localeCompare(a[field]);
                }
            } else if (typeof a[field] === 'number' || a[field] instanceof Date) {
                if (order === 'asc') {
                    return a[field] - b[field];
                } else {
                    return b[field] - a[field];
                }
            }
        });
    } else {
        return []
    }
};
function formatDateToDMY(dateString) {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;

    return dateString != null && dateString != "" ? formattedDate : '';
}

const DashboardTableData = ({
    userType,
    handleSelect,
    gradeList,
    selectedGrade,
    isTableSubList,
    isTableSubId,
    isOnTriggerProgressData,
    selectedTeacher,
    VsetSelectedSubject
}) => {

    const teacherId = sessionStorage.getItem('teacherId');
    const navigate = useNavigate();
    const [academicYearList, setAcademicYearList] = useState([]);
    const [selectedAcademicYear, setSelectedAcademicYear] = useState("");
    const [subjectList, setSubjectList] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState('');
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPageOptions] = useState([10, 15, 20]);
    const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const isInitialRender = useRef(true);
    const token = sessionStorage.getItem("token");

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchAcademicYearData = async () => {
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/all`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                setAcademicYearList(res?.data.data)
                setSelectedAcademicYear(res?.data.data[0].id)
            } catch (error) {
                console.error("Error fetching academic year data:", error);
                setAcademicYearList([]);
            }
        };

        fetchAcademicYearData();
    }, [])

    const subjectChangeHandler = (e) => {
        setSelectedSubject(e.target.value);
        // console.log("17-6-24 setSelectedSubject", e.target.value)
        isTableSubId(e.target.value);
    };

    useEffect(() => {
        const fetchSubjectData = async () => {
            let boardId = sessionStorage.getItem('boardId')
            let url;
            // switch (userType) {
            //     case "Teacher":
            //         url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/subjects?teacherId=${teacherId}&gradeId=${selectedGrade}`
            //         break;
            //     case "Principal":
            //         url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subject-mapping/subjects?boardId=${boardId}&gradeId=${selectedGrade}`
            //         break;
            // }
            url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/subjects?teacherId=${teacherId}&gradeId=${selectedGrade}`

            const response = await axios.get(url,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            // console.log(response.data.data,);
            // console.log(response?.data?.data, "27-6-24 required",userType)
            // if(userType =="Teacher"){
            //     response.data.data = response?.data?.data?.filter(v => v.skilledSubject === true) || [];
            // }else if(userType="Principal"){
            // response.data.data = response?.data?.data?.filter(v => v.skilledSubject === "true") || [];
            // }
            setSubjectList(response?.data?.data);
            setSelectedSubject(response?.data?.data[0]?.subjectId);
            // console.log("17-6-24 setSelectedSubject", response.data.data[0].subjectId)
            isTableSubList(response?.data?.data)
            isTableSubId(response?.data?.data[0]?.subjectId);
        };

        if (selectedGrade && userType === "Teacher") {
            fetchSubjectData();
        } else if (selectedGrade) {
            fetchSubjectData();
        }
    }, [selectedGrade])

    useEffect(() => {
        if (isInitialRender.current) {
            console.log('This runs only on initial render');
            fetchTableData();
            isInitialRender.current = false;
        }
    }, [])
    const DataStructureWithSubtopic = (item, subtopic, data, elem) => {
        let arr = {
            id: uuidv4(),
            grade: data.grade,
            gradeId: data.gradeId,
            section: item.section,
            sectionId: item.sectionId,
            subject: elem.subject,
            subjectId: elem.subjectId,
            averageMessage: subtopic.averageMessage,
            practiceAttemptRateMessage: subtopic.practiceAttemptRateMessage,
            unitAttemptRateMessage: subtopic.unitAttemptRateMessage,
            unitGlobalQuizAttemptRate: subtopic.unitGlobalQuizAttemptRate,
            unitQuizAttemptRate: subtopic.unitQuizAttemptRate,
            practiceGlobalQuizAttemptRate: subtopic.practiceGlobalQuizAttemptRate,
            practiceQuizAttemptRate: subtopic.practiceQuizAttemptRate,
            globalQuizAverageScorePercentage: subtopic.globalQuizAverageScorePercentage,
            quizAverageScorePercentage: subtopic.quizAverageScorePercentage,
            subtopic: subtopic.subtopic,
            subtopicId: subtopic.subtopicId,
            skilledSubject: elem?.skilledSubject
        };

        return arr;
    };
    const DataStructureWithoutSubtopic = (item, data, elem) => {
        let arr = {
            id: uuidv4(),
            grade: data.grade,
            gradeId: data.gradeId,
            section: item.section,
            sectionId: item.sectionId,
            subject: elem.subject,
            subjectId: elem.subjectId,
            averageMessage: elem.averageMessage || "",
            practiceAttemptRateMessage: elem.practiceAttemptRateMessage || "",
            unitAttemptRateMessage: elem.unitAttemptRateMessage || "",
            unitGlobalQuizAttemptRate: elem.unitGlobalQuizAttemptRate || 0,
            unitQuizAttemptRate: elem.unitQuizAttemptRate || 0,
            practiceGlobalQuizAttemptRate: elem.practiceGlobalQuizAttemptRate || 0,
            practiceQuizAttemptRate: elem.practiceQuizAttemptRate || 0,
            globalQuizAverageScorePercentage:
                elem.globalQuizAverageScorePercentage || 0,
            quizAverageScorePercentage: elem.quizAverageScorePercentage || 0,
            subtopic: "",
            subtopicId: "",
            skilledSubject: elem?.skilledSubject
        };

        return arr;
    };
    const getDataStructureFormatted = (cdata) => {
        let formatted = [];
        cdata?.grades?.forEach((data) => {
            if (data?.sections) {
                data?.sections?.forEach((item) => {
                    item?.subjects?.forEach((elem) => {
                        console.log("elem", elem)
                        if (elem?.subtopics) {
                            elem?.subtopics?.forEach((subtopic) => {
                                let arr = DataStructureWithSubtopic(item, subtopic, data, elem);
                                formatted.push(arr);
                            });
                        } else {
                            let arr = DataStructureWithoutSubtopic(item, data, elem);
                            formatted.push(arr);
                        }
                    });
                });
            } else {
                data?.subjects?.forEach((elem) => {
                    if (elem?.subtopics) {
                        elem?.subtopics?.forEach((subtopic) => {
                            let arr = DataStructureSubtopicWithoutSection(data, elem, subtopic);
                            formatted.push(arr);
                        });
                    } else {
                        let arr = DataStructureWithoutSubtopicWithoutSection(data, elem);
                        formatted.push(arr);
                    }
                });
            }
        });
        console.log("formatted", formatted)
        return formatted;
    };

    const DataStructureSubtopicWithoutSection = (data, elem, subtopic) => {
        let arr = {
            id: uuidv4(),
            grade: data.grade,
            gradeId: data.gradeId,
            section: "",
            sectionId: "",
            subject: elem.subject,
            subjectId: elem.subjectId,
            averageMessage: subtopic.averageMessage,
            practiceAttemptRateMessage: subtopic.practiceAttemptRateMessage,
            unitAttemptRateMessage: subtopic.unitAttemptRateMessage,
            unitGlobalQuizAttemptRate: subtopic.unitGlobalQuizAttemptRate,
            unitQuizAttemptRate: subtopic.unitQuizAttemptRate,
            practiceGlobalQuizAttemptRate: subtopic.practiceGlobalQuizAttemptRate,
            practiceQuizAttemptRate: subtopic.practiceQuizAttemptRate,
            globalQuizAverageScorePercentage: subtopic.globalQuizAverageScorePercentage,
            quizAverageScorePercentage: subtopic.quizAverageScorePercentage,
            subtopic: subtopic.subtopic,
            subtopicId: subtopic.subtopicId,
        };

        return arr;
    };

    const DataStructureWithoutSubtopicWithoutSection = (data, elem) => {
        let arr = {
            id: uuidv4(),
            grade: data.grade,
            gradeId: data.gradeId,
            section: "",
            sectionId: "",
            subject: elem.subject,
            subjectId: elem.subjectId,
            averageMessage: elem.averageMessage || "",
            practiceAttemptRateMessage: elem.practiceAttemptRateMessage || "",
            unitAttemptRateMessage: elem.unitAttemptRateMessage || "",
            unitGlobalQuizAttemptRate: elem.unitGlobalQuizAttemptRate || 0,
            unitQuizAttemptRate: elem.unitQuizAttemptRate || 0,
            practiceGlobalQuizAttemptRate: elem.practiceGlobalQuizAttemptRate || 0,
            practiceQuizAttemptRate: elem.practiceQuizAttemptRate || 0,
            globalQuizAverageScorePercentage:
                elem.globalQuizAverageScorePercentage || 0,
            quizAverageScorePercentage: elem.quizAverageScorePercentage || 0,
            subtopic: "",
            subtopicId: "",
        };
        return arr;
    };

    const fetchTableData = async () => {
        if (selectedGrade && selectedSubject) {
            try {
                const response = await fetchQuizPerformance(selectedGrade);
                const cdata = response;
                var formatted = getDataStructureFormatted(cdata);
                // console.log("4-7-24", formatted)
                formatted = formatted?.filter(v => v.skilledSubject === true) || [];
                // console.log("aftr", formatted, selectedGrade, selectedSubject)
                var specificsubj = formatted?.find((v) => v.gradeId === selectedGrade && v.subjectId === selectedSubject);
                // console.log("specificsubj", specificsubj)
                VsetSelectedSubject(specificsubj || {});
                // setSelectedSubject(formatted[0]);
            } catch (error) {
                console.error("Error fetching Quiz performance data:", error);
                VsetSelectedSubject({});
            }
            let boardId = sessionStorage.getItem('boardId');
            let schoolId = sessionStorage.getItem('schoolId');
            let branchId = sessionStorage.getItem('branchId');
            // let url;

            // switch (userType) {
            //     case "Teacher":
            //         url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/dashboard/chapter-wise-quiz-performance-dashboard?teacherId=${teacherId}&gradeId=${selectedGrade}&subjectId=${selectedSubject}`
            //         break;
            //     case "Principal":
            //         url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/dashboard/chapter-wise-quiz-performance-dashboard-admin?boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&gradeId=${selectedGrade}&subjectId=${selectedSubject}`
            //         break;
            //     default:
            //         throw new Error("Invalid userType");
            // }
            // try {
            //     const response = await axios.get(url,
            //         {
            //             headers: {
            //                 "Content-Type": "application/json",
            //                 Authorization: `Bearer ${token}`,
            //             },
            //         }
            //     );

            var item = {
                "boardId": sessionStorage.getItem("boardId"),
                "schoolId": sessionStorage.getItem("schoolId"),
                "branchId": sessionStorage.getItem("branchId"),
                "gradeId": selectedGrade,
                "subjectId": selectedSubject,
                teacherId: teacherId // sessionStorage.getItem('userId')
            };
            try {

                const response = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/file/report/dashTableReport`, item, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });

                console.log("response", response?.data);
                const data = response?.data.map(obj => {
                    // Check if releaseDate exists and is valid
                    if (obj?.releaseDate) {
                        let releaseDate = new Date(obj.releaseDate);
                        releaseDate.setTime(releaseDate.getTime() + (5 * 60 + 30) * 60 * 1000);
                        const formattedDate = releaseDate.toISOString().split('T')[0];
                        return {
                            ...obj,
                            quizRelease: formattedDate,
                            releaseDate: formattedDate
                        };
                    }
                    return obj;
                });
                var updatdArr = {
                    chapters: data
                }
                console.log("updatedArray", updatdArr)
                setTableData(updatdArr);
                setCurrentPage(1);
            } catch (error) {
                console.error("Error fetching academic year data:", error);
                setTableData([]);
            }
            isOnTriggerProgressData();
        }
    };

    const isNavigateChapterReport = (item) => {
        navigate("/dashboard/Chapter%20Performance");
        let data = {
            selectedTableGrade: selectedGrade,
            selectedTableSubject: selectedSubject,
            teacher: selectedTeacher ? selectedTeacher : '',
            ...item
        }
        dispatch(selectedDashboardData(data))
        sessionStorage.setItem("sideNavMainMenuNames", "Reports");
        sessionStorage.setItem("sideNavSubMenuNames", "Chapter Performance");
    }

    const handleRowsPerPageChange = (e) => {
        setSelectedRowsPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    // Sorting Table Functionality Start 

    const requestSort = (key) => {
        console.log("requestsort", key)
        let direction = 'asc';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        } else {
            console.log("need to see here")
        }
        setSortConfig({ key, direction });
    };

    useEffect(() => {
        console.log('ssd', gradeList);
    }, [gradeList]);

    const sortedDataArray = sortByField(userType === "Teacher" ? tableData?.chapters : tableData, sortConfig?.key, sortConfig?.direction);
    console.log("sortedDataArray", sortedDataArray, tableData?.chapters, tableData, sortConfig?.direction)

    // Sorting Table Functionality End 

    const indexOfLastItem = currentPage * selectedRowsPerPage;
    const indexOfFirstItem = indexOfLastItem - selectedRowsPerPage;
    const currentItems = sortedDataArray?.slice(indexOfFirstItem, indexOfLastItem);
    const nPages = Math.ceil(sortedDataArray?.length / selectedRowsPerPage) || [];

    return <div className="dashboard-table-container">
        <div className="dashboard-dropdown-list-header">
            <div className="dropdown-list">
                {/* <select className="dropdown cursorPointer" onChange={handleSelect} value={selectedGrade}>
                        <option className="cursorPointer" value="">select Grade</option>
                        {gradeList && gradeList?.map((o) => {
                            return (
                                <option className="cursorPointer" value={o?.value}>{o?.label}</option>
                            )
                        })}
                    </select> */}
                <FormControl fullWidth>
                    <InputLabel
                        sx={{
                            fontSize: '13px',
                            color: '#e7ab3c',
                            top: '-10px',
                            '&.MuiInputLabel-shrink': {
                                top: '0',
                            },
                            '&.Mui-focused': {
                                color: '#e7ab3c'
                            }
                        }}
                        className='dropDownLabel'
                        id="demo-simple-select-label"
                    >
                        Grade
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        input={<OutlinedInput label='Grades' />}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    width: '150px',
                                    marginTop: '2px',
                                    maxHeight: '190px',
                                    overflowY: 'auto',
                                    '&::-webkit-scrollbar': {
                                        width: '2px',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        background: '#f1f1f1',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        background: '#888',
                                        borderRadius: '4px',
                                    },
                                    '&::-webkit-scrollbar-thumb:hover': {
                                        background: '#555',
                                    },
                                },
                            },
                            MenuListProps: {
                                sx: {
                                    padding: '0px',
                                    overflowY: 'auto',
                                },
                            },
                        }}
                        IconComponent={(props) => (
                            <ExpandMoreIcon
                                {...props}
                                sx={{ fontSize: "1.5vw" }}
                            />
                        )}
                        sx={{
                            width: '150px',
                            height: '30px',
                            padding: '10px',
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#000',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#000',
                            },
                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#e7ab3c',
                            }
                        }}
                        className='dropdownSelect'
                        value={selectedGrade}
                        onChange={handleSelect}
                    >
                        {gradeList && gradeList?.map((o) => {
                            return (
                                <MenuItem
                                    value={o?.value}
                                    key={o?.value}
                                    className={dashboardContentStyles.menu_item}
                                    sx={{
                                        fontSize: '10px',
                                        backgroundColor: 'white',
                                        '&:hover': {
                                            backgroundColor: '#f4d788',
                                        },
                                        '&.Mui-selected': {
                                            backgroundColor: '#e7ab3c',
                                            '&:hover': {
                                                backgroundColor: '#f4d788',
                                            },
                                        },
                                    }}
                                >
                                    <ListItemText primary={o?.label} />
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel
                        sx={{
                            fontSize: '13px',
                            color: '#e7ab3c',
                            top: '-10px',
                            '&.MuiInputLabel-shrink': {
                                top: '0',
                            },
                            '&.Mui-focused': {
                                color: '#e7ab3c'
                            }
                        }}
                        className='dropDownLabel'
                        id="demo-simple-select-label"
                    >
                        Subject
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        input={<OutlinedInput label='Subjects ' />}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    width: '150px',
                                    marginTop: '2px',
                                    maxHeight: '190px',
                                    overflowY: 'auto',
                                    '&::-webkit-scrollbar': {
                                        width: '2px',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        background: '#f1f1f1',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        background: '#888',
                                        borderRadius: '4px',
                                    },
                                    '&::-webkit-scrollbar-thumb:hover': {
                                        background: '#555',
                                    },
                                },
                            },
                            MenuListProps: {
                                sx: {
                                    padding: '0px',
                                    overflowY: 'auto',
                                },
                            },
                        }}
                        IconComponent={(props) => (
                            <ExpandMoreIcon
                                {...props}
                                sx={{ fontSize: "1.5vw" }}
                            />
                        )}
                        sx={{
                            width: '150px',
                            height: '30px',
                            padding: '10px',
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#000',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#000',
                            },
                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#e7ab3c',
                            }
                        }}
                        className='dropdownSelect'
                        value={selectedSubject}
                        onChange={subjectChangeHandler}
                    >
                        {subjectList && subjectList?.map((o) => {
                            return (
                                <MenuItem
                                    value={o?.subjectId}
                                    key={o?.subjectId}
                                    className={dashboardContentStyles.menu_item}
                                    sx={{
                                        fontSize: '10px',
                                        backgroundColor: 'white',
                                        '&:hover': {
                                            backgroundColor: '#f4d788',
                                        },
                                        '&.Mui-selected': {
                                            backgroundColor: '#e7ab3c',
                                            '&:hover': {
                                                backgroundColor: '#f4d788',
                                            },
                                        },
                                    }}
                                >
                                    <ListItemText primary={o?.subject} />
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel
                        sx={{
                            fontSize: '13px',
                            color: '#e7ab3c',
                            top: '-10px',
                            '&.MuiInputLabel-shrink': {
                                top: '0',
                            },
                            '&.Mui-focused': {
                                color: '#e7ab3c'
                            }
                        }}
                        className='dropDownLabel'
                        id="demo-simple-select-label"
                    >
                        Year
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        input={<OutlinedInput label='Years ' />}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    width: '150px',
                                    marginTop: '2px',
                                    maxHeight: '190px',
                                    overflowY: 'auto',
                                    '&::-webkit-scrollbar': {
                                        width: '2px',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        background: '#f1f1f1',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        background: '#888',
                                        borderRadius: '4px',
                                    },
                                    '&::-webkit-scrollbar-thumb:hover': {
                                        background: '#555',
                                    },
                                },
                            },
                            MenuListProps: {
                                sx: {
                                    padding: '0px',
                                    overflowY: 'auto',
                                },
                            },
                        }}
                        IconComponent={(props) => (
                            <ExpandMoreIcon
                                {...props}
                                sx={{ fontSize: "1.5vw" }}
                            />
                        )}
                        sx={{
                            width: '150px',
                            height: '30px',
                            padding: '10px',
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#000',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#000',
                            },
                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#e7ab3c',
                            }
                        }}
                        className='dropdownSelect'
                        value={selectedAcademicYear}
                        disabled
                    >
                        {academicYearList && academicYearList?.map((o) => {
                            return (
                                <MenuItem
                                    value={o?.id}
                                    key={o?.id}
                                    className={dashboardContentStyles.menu_item}
                                    sx={{
                                        fontSize: '10px',
                                        backgroundColor: 'white',
                                        '&:hover': {
                                            backgroundColor: '#f4d788',
                                        },
                                        '&.Mui-selected': {
                                            backgroundColor: '#e7ab3c',
                                            '&:hover': {
                                                backgroundColor: '#f4d788',
                                            },
                                        },
                                    }}
                                >
                                    <ListItemText primary={o?.academicYear} />
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                {/* <div className="dropdown-container ">
                    <select className="dropdown cursorPointer" onChange={subjectChangeHandler} value={selectedSubject}>
                        <option className="cursorPointer" value="">Select Subject</option>
                        {subjectList && subjectList?.map((o) => {
                            return (
                                <option className="cursorPointer" value={o?.subjectId}>{o?.subject}</option>
                            )
                        })}
                    </select>
                </div> */}
                {/* <div className="dropdown-container">
                    <select className="dropdown cursorPointer" disabled value={selectedAcademicYear}>
                        <option className="cursorPointer" value="">Select Year</option>
                        {academicYearList && academicYearList?.map((o) => {
                            return (
                                <option className="cursorPointer" value={o?.id}>{o?.academicYear}</option>
                            )
                        })}
                    </select>
                </div> */}
                <div className="dropdown-container">
                    <button className="submit-btn" onClick={fetchTableData}>Submit</button>
                </div>
            </div>
            <div className="search-container">
                {/* <input type="text" id="dashboard-search-input" placeholder="Search for names.." /> */}
            </div>
        </div>
        <div className="dashoard-table-content">
            {userType && userType === "Teacher" ? <table class="dashboard-table">
                <thead>
                    {userType === "Principal" && <th style={{ textAlign: "start" }} onClick={() => requestSort('firstName')}>Teacher <span><img className="cursorPointer" src={sortIcon} /></span></th>}
                    <th style={{ textAlign: "start" }} onClick={() => requestSort('section')}>Section <span><img className="cursorPointer" src={sortIcon} /></span></th>
                    <th style={{ textAlign: "start" }} onClick={() => requestSort('chapter')}>Chapter Name <span><img className="cursorPointer" src={sortIcon} /></span></th>
                    <th style={{ textAlign: "start" }} onClick={() => requestSort('startDate')}>Start Date <span><img className="cursorPointer" src={sortIcon} /></span></th>
                    <th style={{ textAlign: "start" }} onClick={() => requestSort('endDate')}>End Date <span><img className="cursorPointer" src={sortIcon} /></span></th>
                    <th style={{ textAlign: "start" }} onClick={() => requestSort('quizRelease')}>Quiz Release <span><img className="cursorPointer" src={sortIcon} /></span></th>
                    <th onClick={() => requestSort('classAvg')}>Avg Quiz Score <span><img className="cursorPointer" src={sortIcon} /></span></th>
                    <th onClick={() => requestSort('attendancePct')}>Attendance <span><img className="cursorPointer" src={sortIcon} /></span></th>
                    <th onClick={() => requestSort('section')}>Reports <span><img className="cursorPointer" src={sortIcon} /></span></th>
                </thead>
                {currentItems && currentItems?.length > 0 ?
                    <tbody>
                        {currentItems && currentItems?.map((o, i) => {
                            return (
                                <tr key={i}>
                                    {userType === "Principal" && <td style={{ textAlign: "start" }}>{o?.firstName + " " + o?.lastName}</td>}
                                    <td style={{ textAlign: "start" }}>{o?.section}</td>
                                    <td style={{ textAlign: "start" }}>{o?.chapter}</td>
                                    <td style={{ textAlign: "start", textWrap: "nowrap" }}>{formatDateToDMY(o?.startDate)}</td>
                                    <td style={{ textAlign: "start", textWrap: "nowrap" }}>{formatDateToDMY(o?.endDate)}</td>
                                    <td style={{ textAlign: "start", textWrap: "nowrap" }}>{formatDateToDMY(o?.quizRelease)}</td>
                                    <td>{(o?.quizRelease != "" && o?.quizRelease != null && o?.classAvg !=="" && o?.classAvg !== null) ? `${Math.round(o?.classAvg)}%` : "-"}</td>
                                    <td>{(o?.quizRelease != "" && o?.quizRelease != null && o?.attendancePct !=="" && o?.attendancePct !== null) ? `${Math.round(o?.attendancePct)}%` : "-"}</td>
                                    <td>
                                        <button disabled={o?.quizRelease != "" && o?.quizRelease != null ? false : true} style={{ border: "none", cursor: o?.quizRelease != "" && o?.quizRelease != null ? "pointer" : "no-drop" }} onClick={() => isNavigateChapterReport(o)}>
                                            <img style={{ opacity: o?.quizRelease != "" && o?.quizRelease != null ? "" : "0.3" }} src={report} alt="report-icon" />
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody> :
                    <tbody>
                        <tr><td colspan="8">No data</td></tr>
                    </tbody>}
            </table> : <table class="dashboard-table">
                <thead>
                    {
                        console.log("currentItems", currentItems)
                    }
                    {userType === "Principal" && <th style={{ textAlign: "start" }} onClick={() => requestSort('firstName')}>Teacher <span><img className="cursorPointer" src={sortIcon} /></span></th>}
                    <th style={{ textAlign: "start" }} onClick={() => requestSort('section')}>Section <span><img className="cursorPointer" src={sortIcon} /></span></th>
                    <th style={{ textAlign: "start" }} onClick={() => requestSort('chapter')}>Chapter Name <span><img className="cursorPointer" src={sortIcon} /></span></th>
                    <th style={{ textAlign: "start" }} onClick={() => requestSort('startDate')}>Start Date <span><img className="cursorPointer" src={sortIcon} /></span></th>
                    <th style={{ textAlign: "start" }} onClick={() => requestSort('endDate')}>End Date <span><img className="cursorPointer" src={sortIcon} /></span></th>
                    <th style={{ textAlign: "start" }} onClick={() => requestSort('releaseDate')}>Quiz Release <span><img className="cursorPointer" src={sortIcon} /></span></th>
                    <th onClick={() => requestSort('classAvg')}>Avg Quiz Score <span><img className="cursorPointer" src={sortIcon} /></span></th>
                    <th onClick={() => requestSort('attendancePct')}>Attendance <span><img className="cursorPointer" src={sortIcon} /></span></th>
                    <th onClick={() => requestSort('section')}>Reports <span><img className="cursorPointer" src={sortIcon} /></span></th>
                </thead>
                {currentItems && currentItems?.length > 0 ?
                    <tbody>
                        {currentItems && currentItems?.map((o, i) => {
                            console.log(o, "obj")
                            return (
                                <tr key={i}>
                                    {userType === "Principal" && <td style={{ textAlign: "start" }}>{o?.firstName + " " + o?.lastName}</td>}
                                    <td style={{ textAlign: "start" }}>{o?.section}</td>
                                    <td style={{ textAlign: "start" }}>{o?.chapter}</td>
                                    <td style={{ textAlign: "start", textWrap: "nowrap" }}>{formatDateToDMY(o?.startDate)}</td>
                                    <td style={{ textAlign: "start", textWrap: "nowrap" }}>{formatDateToDMY(o?.endDate)}</td>
                                    <td style={{ textAlign: "start", textWrap: "nowrap" }}>{formatDateToDMY(o?.releaseDate != null && o?.releaseDate != "" ? o?.releaseDate : '')}</td>
                                    <td>{(o?.releaseDate != "" && o?.releaseDate != null  && o?.classAvg !=="" && o?.classAvg !== null) ? `${Math.round(o?.classAvg)}%` : "-"}</td>
                                    <td>{(o?.releaseDate != "" && o?.releaseDate != null  && o?.attendancePct !=="" && o?.attendancePct !== null) ? `${Math.round(o?.attendancePct)}%` : "-"}</td>
                                    <td>
                                        <button disabled={o?.releaseDate != "" && o?.releaseDate != null ? false : true} style={{ border: "none", cursor: o?.releaseDate != "" && o?.releaseDate != null ? "pointer" : "no-drop" }} onClick={() => isNavigateChapterReport(o)}>
                                            <img style={{ opacity: o?.releaseDate != "" && o?.releaseDate != null ? "" : "0.3" }} src={report} alt="report-icon" />
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody> :
                    <tbody>
                        <tr><td colspan="8">No data</td></tr>
                    </tbody>}
            </table>}
            {/* pagination container - Start */}
            {currentItems && currentItems.length > 0 && <div className={chapterStyles.pagination} style={{ width: "100%", margin: 0 }}>
                <form>
                    <span className={chapterStyles.rows}>
                        <label for="rows">Rows per page</label>
                        <select
                            className="cursorPointer"
                            name="rows"
                            id="rows"
                            onChange={handleRowsPerPageChange}
                            value={selectedRowsPerPage}
                        >
                            {rowsPerPageOptions.map(option => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </select>
                    </span>
                </form>
                <span>
                    <Pagination
                        nPages={nPages}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </span>
            </div>}
            {/* pagination container - End */}
        </div>
    </div>
}

export default DashboardTableData;


export const Pagination = ({ nPages, currentPage, setCurrentPage }) => {

    const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

    const goToNextPage = () => {
        if (currentPage !== nPages) setCurrentPage(currentPage + 1)
    }
    const goToPrevPage = () => {
        if (currentPage !== 1) setCurrentPage(currentPage - 1)
    }

    return (
        <div className="dashboard-pagination">
            <span className="cursorPointer" onClick={goToPrevPage} >&laquo;</span>
            {pageNumbers.map(pgNumber => (<span className={`cursorPointer ${currentPage == pgNumber ? 'active' : ''}`} onClick={() => setCurrentPage(pgNumber)}>{pgNumber}</span>))}
            <span className="cursorPointer" onClick={goToNextPage}>&raquo;</span>
        </div>
    )
}