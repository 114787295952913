import React, { useState, useEffect } from "react";
import navStyles from "./../css/navbar.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import azvasaLogoSmall from "./../img/Azvasa_logo1.png";
import addstudentstyle from "./../css/NavbarAddStudentModal.module.css";
import addacademicstyle from "./../css/AddAcademicModal.module.css";
import { login } from "../../actions/userAction";
import validatetoken_token_Get from "../API/auth-controller/validatetoken_token_Get";
import SchoolControllerAll from "../API/school-controller/user_schools_all";
import User_Branches_School_Id from "../API/branch-controller/User_Branches_School_Id";
import GradesControllerGetall from "../API_CONTROLLER/grades-controller/grades_controller_getall";
import SectionsControllerGetall from "../API_CONTROLLER/section-Controller/section_controller_getall";
import dashHome from "../../../src/img/dashHome.svg";
import masterIc from "../../../src/img/Masters.svg";
import userIC from "../../../src/img/user.svg";
import admin from "../../../src/img/Adminuser.svg";
import { height, width } from "@mui/system";
import upload_content from "../../../src/img/upload_content.svg";
import global_training from "../../../src/img/global_training.svg";
import concept_tree from "../../../src/img/concept_tree.svg";
import view_concept from "../../../src/img/Create_Edit.svg";
import associate_concept from "../../../src/img/assosiate_concept.svg";
import master from "../../../src/img/masters1.svg";
import add_training from "../../../src/img/add_training.svg";
import schoolsIc from "../../../src/img/schools.svg";
import teachIc from "../../../src/img/teach.svg";
import reportIc from "../../../src/img/Reports.svg";
import learningIcn from "../../../src/img/Learning.png";
import assignIcn from "../../../src/img/Masters.png";
import upSkillsIcn from "../../../src/img/Upskill.png";
import questionBankIcn from "../../../src/img/Question Bank.png";
import StudentIcn from "../../../src/img/Student report.png";
import { FileCopy } from "@mui/icons-material";
import CordinatorMenuItem from "./CordinatorMenuItem";
import Principal_SideNav_Menu from "./PrincipalModule/Principal_SideNav_Menu";
import RevisionReviewerMenuItem from "./RevisionReviewerMenuItem";
import viewConceptIcn from "../../../src/img/view-concept-tree.png";
import SideIcon1 from "../../../src/img/SideIcon1.png";
import SideIcon2 from "../../../src/img/SideIcon2.png";
import createQuizIcn from "../../../src/img/create-quiz.png";
import blueprintIcn from "../../../src/img/Blueprint.png";
import viewQuizIcn from "../../../src/img/view-quizzes.png";
import activitiesIcon from "../../../src/img/Activities.png";
import uploadAssessmentIcon from "../../../src/img/upload-assesments.png";
import mapsRevIcon from "../../../src/img/maps.png";
import Student_SideNavbar from "./Student/Student_SideNavbar";
import TeacherNew_SideNavbar from "./TeacherNew_SideNavbar";
import { useDispatch } from "react-redux";
import { set_navbar_is_expanded } from "../../actions/navbarAction";
import { useSelector } from 'react-redux';
import { setRightClickEnable } from '../../../src/Serviceworker';


import {
  ListItemText,
  Menu,
  MenuItem,
  ListSubheader
} from "@mui/material";
import mixpanel from "mixpanel-browser";

const Navbar = (props) => {
  const location = useLocation();
  const hideNavBarRoutes = [
    "dashboard/quiz",
    "enrich/quiz",
    "enrich/quiz/resume",
    "enrich/quiz/analysis",
    "enrich/quiz/resume/analysis",
    "Student_Enrich_Quiz/quiz",
    "Student_Enrich_Quiz/quiz/resume",
    "Student_Enrich_Quiz/quiz/analysis",
    "Student_Enrich_Quiz/quiz/resume/analysis",
    "formal%20assessment%20quiz/quiz",
    "formal%20assessment%20quiz/quiz/resume",
    "formal%20assessment%20quiz/quiz/analysis",
    "formal20assessment%20quiz/quiz/resume/analysis"
  ]

  const isHiddenRoute = hideNavBarRoutes.some(route => location.pathname.includes(route));
  const userFirstName = useSelector((state) => state.user.firstName);
  const userLastName = useSelector((state) => state.user.lastName);
  const userid = useSelector((state) => state.user.id);
  const userUserName = useSelector((state) => state.user.userName);
  console.log('ddddd',userid);

  const [schoolSublinkVisible, setSchoolSublinkVisible] = useState(false);
  const [school1SublinkVisible, setSchool1SublinkVisible] = useState(false);


  const handleLinkToggle = () => {
    console.log("schoolSublinkVisible", schoolSublinkVisible)
    setSchoolSublinkVisible(!schoolSublinkVisible);
    // setSchool1SublinkVisible(false);
  };
  const handleLinkToggle1 = () => {
    setSchool1SublinkVisible(school1SublinkVisible ? false : true);
    setSchoolSublinkVisible(false);
  };

  let initalSchoolSubMenu = [
    { title: "Registered Schools", routeParam: "registeredSchool" },
    { title: "School Admins", routeParam: "" },
    { title: "Add Student", routeParam: "" },
    { title: "Add Academic Staff", routeParam: "" },
    { title: "Management Id", routeParam: "" },
  ];
  const [schoolSubmenuList, setSchoolSubMenus] = useState(initalSchoolSubMenu);

  const [navBarVis, setNavBarVis] = useState(true);
  const [showAddStudentModal, setShowAddStudentModal] = useState(false);
  const [showAddAcademicModal, setShowAddAcademicModal] = useState(false);
  const [isCordinator, setIsCordinator] = useState(true);
  const [isPrincipal, setIsPrincipal] = useState(true);
  const [isStudentt, setIsStudentt] = useState(true);
  const [isTeacher, setIsteacher] = useState(true);
  const linkText = document.getElementsByClassName(navStyles.link_text);
  const [NavbarData, setNavbarData] = useState({});

  const dispatch = useDispatch();
  dispatch(set_navbar_is_expanded(navBarVis));

  useEffect(() => {
    if (showAddAcademicModal) {
      SchoolControllerAll(setSchoolList);
    }
  }, [showAddAcademicModal]);
  const navBarToggle = () => {
    if (navBarVis) {
      //  link_text display none
      Array.from(linkText).forEach((element) => {
        element.style.display = "none";
      });
    } else {
      // link text display block
      Array.from(linkText).forEach((element) => {
        element.style.display = "block";
      });
    }

    setNavBarVis(navBarVis ? false : true);
  };

  // useEffect(() => {
  //   setNavBarVis(
  //     navBarVis
  //       ? false
  //       : localStorage.getItem("slider") === "true"
  //       ? true
  //       : false
  //   );
  // }, [navBarVis]);

  const handleAddStudent = (e) => {
    setShowAddStudentModal(true);
    // navigate("/dashboard/registeredSchool");
  };

  const Dashboard = (e) => {
    setShowAddStudentModal(true);
  };

  const handleCross = (e) => {
    setShowAddStudentModal(false);
    setShowAddAcademicModal(false);
  };
  //----Onclick Function For Student Page start----//
  const handleAddStudentSubmit = (e) => {
    setShowAddStudentModal(false);
    navigate("/dashboard/student");
    props.handleHeaderTitle("Registered Schools"); // addedd by sg
    var schoolid = studentDetails.studentschool;
    sessionStorage.setItem("schoolid", schoolid);
    var branchid = studentDetails.studentbranch;
    sessionStorage.setItem("branchid", branchid);
    var gradeid = studentDetails.grade;
    sessionStorage.setItem("gradeid", gradeid);
    var sectionid = studentDetails.section;
    sessionStorage.setItem("sectionid", sectionid);
  };
  //----Onclick Function For Student Page End----//
  const handleAddAcademic = (e) => {
    setShowAddAcademicModal(true);
    //navigate("/dashboard/registeredSchool");
  };
  // useEffect(()=> {
  //   setShowAddAcademicModal(true)
  // },[])

  const handleAddAcademicSubmit = (e) => {
    // e.preventDefault();
    for (const input in schoolDetails) {
      if (schoolDetails[input] === "") {
        return;
      }
    }
    setShowAddAcademicModal(false);
    navigate("/dashboard/teacherId");
    props.handleHeaderTitle("Registered Schools"); // addedd by sg
    var schoolid = schoolDetails.school;
    sessionStorage.setItem("schoolid", schoolid);
    var branchid = schoolDetails.branch;
    sessionStorage.setItem("branchid", branchid);
  };
  const handleLogout = (e) => {
    if(userWho ==="STUDENT"){
      mixpanel.track('Student_Logged_Out', {
        "user_id": userid,
        "username":userUserName
      });

    mixpanel.reset();

    }else{
      mixpanel.track(`${userWho}_Logged_Out`, {
        "user_id": userid,
        "username":userUserName,
        "role":userWho
      });

    mixpanel.reset();
    }

    sessionStorage.clear();
    localStorage.clear();
    setRightClickEnable(false);
    // sessionStorage.removeItem("studentid");


    navigate("/");
  };

  // }

  //added by sg
  const handleSubMenu = (val, route) => {
    if (val === "Add Academic Staff") {
      props.handleHeaderTitle("Registered Schools");
      handleAddAcademic();
    } else if (val === "Add Student") {
      props.handleHeaderTitle("Registered Schools");
      handleAddStudent();
    } else {
      props.handleHeaderTitle(val);
      navigate(`/dashboard/${route}`);
    }
  };

  const [schoolDetails, setSchoolDetails] = useState({
    school: "",
    branch: "",
  });
  //----Define inputs for AddStudents Modal----//
  const [studentDetails, setStudentDetails] = useState({
    studentschool: "",
    studentbranch: "",
    grade: "",
    section: "",
  });

  const handlerSchoolDetailsInputs = (e) => {
    setSchoolDetails({
      ...schoolDetails,
      [e.target.name]: e.target.value,
      schoolName: e.target.value,
    });
  };
  //=====Target Add Student values ======//
  const handlerStudentDetailsInputs = (e) => {
    setStudentDetails({
      ...studentDetails,
      [e.target.name]: e.target.value,
    });
  };
  //-------Define Usestate values for inputs -------//
  const [schoolList, setSchoolList] = useState({});
  const [branchList, setBranchList] = useState({});
  const [gradeList, setGradesName] = useState({});
  const [sectionList, setSectionsName] = useState({});

  const [showPopup, setShowPopup] = useState(false);
  const [selectedMenuName, setSelectedMenuName] = useState("");
  const navigate = useNavigate();
  const [subMenuNameValue, setSubMenuNameValue] = useState("");
  const access = JSON.parse(localStorage.getItem("access"));
  // console.log(access)

  const tempMenu = localStorage.getItem("menus");

  const tempPurchasedFeatures = localStorage.getItem("purchasedFeatures");
  const userWho = localStorage.getItem("role");
  console.log("userWho",userWho,)
  let purchasedFeatures;
  if (tempPurchasedFeatures !== "undefined") {
    purchasedFeatures = JSON.parse(tempPurchasedFeatures);
  }
  // const Navmenu = (access == "false") ? JSON.parse(tempMenu)?.filter((menuData,idx)=> !(menuData.menuId == "402892888e0e01bb018e50431e4800e0" )) : JSON.parse(tempMenu);
  var Navmenu = JSON.parse(tempMenu)
  if (JSON.parse(tempPurchasedFeatures)?.length == 0 && userWho === "TEACHER") {
    Navmenu = [Navmenu[0]]
  }
  // console.log("Navmenu",Navmenu)
  // console.log("Navmenu", Navmenu,tempPurchasedFeatures,JSON.parse(tempPurchasedFeatures)?.length,tempPurchasedFeatures, tempPurchasedFeatures ==="[]",typeof tempPurchasedFeatures,[Navmenu[0]]);

  const logoutFnc = () => {
    localStorage.clear();
    sessionStorage.clear();
  };


  // useEffect(() => {
  //   //school Input
  //   SchoolControllerAll(setSchoolList);
  //   //Grade input
  //   GradesControllerGetall(setGradesName);
  //   //section input for student//
  //   SectionsControllerGetall(setSectionsName);
  //   return () => {};
  // }, []);

  // useEffect(() => {
  //   // branch input for school //
  //   User_Branches_School_Id(schoolDetails, setBranchList, studentDetails);
  //   return () => {};
  // }, [schoolDetails.school, studentDetails]);

  const handleChange = (headerName) => {
    props.handleHeaderTitle(headerName);
  };

  // useEffect(() => {
  //   validatetoken_token_Get(setNavbarData);
  //   return () => {};
  // }, []);
  const handleRoute = (routeName) => {
    switch (routeName) {
      case "dashboard":
        return localStorage.getItem("role") === "REVISION_ADMIN"
          ? "RevisionAdminDash"
          : userWho === "TEACHER"
            ? "TeacherDashboard"
            : "template";
      case "view quizzes":
        return localStorage.getItem("role") === "REVISION_REVIEWER"
          ? "ViewReviewerQuizzes"
          : "View Quizzes";
      case "question bank":
        return localStorage.getItem("role") === "REVISION_REVIEWER"
          ? "QbReviewPending"
          : "QuestionBank";

      // case "subjective papers":
      //   return userWho === "TEACHER" && "subjective%20papers";
      // case "student revision":
      //   return userWho === "TEACHER" && "student%20revision";
      // case "learning plan":
      //   return userWho === "TEACHER" && "learning%20plan";
      // case "assign quiz":
      //   return userWho === "TEACHER" && "assign%20quiz";

      default:
        return routeName;
    }
  };

  useEffect(() => {
    const user_role = localStorage.getItem("role");
    if (user_role === "COORDINATOR") {
      setIsCordinator(true);
    } else {
      setIsCordinator(false);
    }
  }, []);

  useEffect(() => {
    const user_role = localStorage.getItem("role");
    if (user_role === "PRINCIPAL") {
      setIsPrincipal(true);
    } else {
      setIsPrincipal(false);
    }
  }, []);

  useEffect(() => {
    const user_role = localStorage.getItem("role");
    if (user_role === "STUDENT") {
      setIsStudentt(true);
    } else {
      setIsStudentt(false);
    }
  }, []);

  useEffect(() => {
    const user_role = localStorage.getItem("role");
    if (user_role === "TEACHER") {
      setIsteacher(true);
    } else {
      setIsteacher(false);
    }
  }, []);

  let filteredMenus;
  if (tempPurchasedFeatures !== "undefined") {
    filteredMenus = Navmenu?.filter((menu) => {
      const purchasedFeature = purchasedFeatures.find(
        (feature) => feature.name === menu.menu
      );
      return (
        !purchasedFeature || (purchasedFeature && purchasedFeature.toggleStatus)
      );
    });
  }

  //-------| Highlight the Sidemenu Starts |-------//
  let menuName = sessionStorage.getItem("sideNavMainMenuNames");
  let subNavMenuName = sessionStorage.getItem("sideNavSubMenuNames");

  const handleHighlight = (clicked) => {
    if ((clicked === "Schools"
      || clicked === "Assessments" // Revision Admin //
      || clicked === "Master" // Master Admin //
      || clicked === "Concept Tree" // Content Admin //
      || clicked === "Blueprint") && !navBarVis) {

      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
    setSelectedMenuName(clicked);
    sessionStorage.setItem("sideNavMainMenuNames", clicked);
    sessionStorage.removeItem("sideNavSubMenuNames");
  };

  const handleHighlightSubMenu = (subMenuclicked, mainMenuClicked) => {
    // console.log(">>handleHighlightSubMenu>>", subMenuclicked, mainMenuClicked);
    setShowPopup(false);
    if (subMenuclicked && mainMenuClicked === "Schools" ||
      subMenuclicked && mainMenuClicked === "Assessments" ||
      subMenuclicked && mainMenuClicked === "Master" ||
      subMenuclicked && mainMenuClicked === "Concept Tree" ||
      subMenuclicked && mainMenuClicked === "Blueprint") {
      sessionStorage.setItem("sideNavSubMenuNames", subMenuclicked);
      sessionStorage.setItem("sideNavMainMenuNames", mainMenuClicked);
    } else {
      sessionStorage.setItem("sideNavSubMenuNames", subMenuclicked);
      sessionStorage.removeItem("sideNavMainMenuNames");
    }
  }


  const handleClose = (reportValue) => {
    // console.log(">>>>>>>>>>>>>>>", reportValue);
    setSubMenuNameValue(reportValue);
    setShowPopup(false);
    if (reportValue === "Create/Edit Concept") {
      navigate(`/dashboard/Create/Edit%20Concept`);
    } else if (reportValue === "Create/Edit Sub & Root Concepts") {
      navigate(`/dashboard/Create/Edit%20Sub%20&%20Root%20Concepts`);
    } else {
      navigate(`/dashboard/${encodeURIComponent(reportValue)}`);
    }

  };
  //-------| Highlight the Sidemenu Starts |-------//

  return (
    <>
      {!isHiddenRoute && (
        <nav
          className={navStyles.nav}
          id="nav-bar-side"
          style={
            navBarVis
              ? {
                width: "20%",
                minWidth: "180px",
                zIndex: "1",
                backgroundColor: "white",
                boxShadow: "5px 0px 10px #00000017",
                overflowY: "hidden",
              }
              : {
                width: "0%",
                minWidth: "70px",
                zIndex: "1",
                backgroundColor: "white",
                boxShadow: "5px 0px 10px #00000017",
                overflowY: "hidden",
              }
          }
        >
          <div
            onClick={navBarToggle}
            // localStorage.getItem("slider") === "true"
            className={navStyles.nav_logo}
            style={navBarVis ? { height: "fitContent", borderRadius: "30px", overflowY: "hidden", } : { height: "107.8px", borderRadius: "30px", overflowY: "hidden" }}
          >
            <a
              style={
                { display: "block", marginTop: "24px", color: "white" }

              }
              onClick={(e) => {
                e.preventDefault();
                var side_bar = document.getElementById("studentSideBar");
                console.log(">>", side_bar);
              }}
            >
              <i
                className="fa-solid fa-bars fa-md"
                style={{
                  color: "grey",
                  height: "auto",
                  cursor: "pointer",
                }}
              ></i>
            </a>
            {navBarVis ? (
              <img className="cursorPointer"
                src={navBarVis ? "/img/Azvasa_logo.png" : azvasaLogoSmall}
                style={
                  navBarVis
                    ? {}
                    : {
                      width: "56px",
                      height: "32px",
                      marginLeft: "5px",
                      cursor: "pointer",
                    }
                }
                alt="nav logo"
              />
            ) :
              (
                <></>
              )
            }
          </div>
          <ul
            style={{ overflowY: "scroll", paddingBottom: "200px", paddingTop: "0px !important" }}
            className={navStyles.nav_links}
          >


            {/* //Revision reviewer  */}
            {Navmenu && userWho === "REVISION_REVIEWER"
              ? Navmenu.map((elem, i) => {
                return (
                  <RevisionReviewerMenuItem
                    key={i}
                    elem={elem}
                    i={i}
                    id={`az-revision-reviewer-sidemenu-${i}`}
                    handleLinkToggle={handleLinkToggle}
                    schoolSublinkVisible={schoolSublinkVisible}
                    navBarVis={navBarVis}
                  />
                );
              })
              : null}

            {/* //cordinator */}

            {Navmenu && isCordinator
              ? filteredMenus &&
              filteredMenus.map((elem, i) => {
                return (
                  <CordinatorMenuItem
                    key={i}
                    elem={elem}
                    i={i}
                    id={`az-coordinator-sidemenu-${i}`}
                    handleLinkToggle={handleLinkToggle}
                    schoolSublinkVisible={true}
                    navBarVis={navBarVis}
                    Navmenu={Navmenu}
                  />
                );
              })
              : null}

            {Navmenu && isPrincipal
              ? filteredMenus &&
              filteredMenus.map((elem, i) => {
                return (
                  <Principal_SideNav_Menu
                    key={i}
                    elem={elem}
                    i={i}
                    id={`az-principal-sidemenu-${i}`}
                    handleLinkToggle={handleLinkToggle}
                    schoolSublinkVisible={schoolSublinkVisible}
                    navBarVis={navBarVis}
                    Navmenu={Navmenu}
                  />

                );
              })
              : null}

            {/*Student Side Navbr*/}
            {Navmenu && isStudentt
              ? filteredMenus &&
              filteredMenus.map((elem, i) => {
                return (
                  // <></>
                  <Student_SideNavbar
                    key={i}
                    elem={elem}
                    i={i}
                    id={`az-student-sidemenu-${i}`}
                    handleLinkToggle={handleLinkToggle}
                    schoolSublinkVisible={schoolSublinkVisible}
                    navBarVis={navBarVis}
                    Navmenu={Navmenu}
                  />
                );
              })
              : null}

            {/*Teacher Side Navbr*/}
            {Navmenu && isTeacher
              ? filteredMenus &&
              filteredMenus.map((elem, i) => {
                // console.log(filteredMenus, "filmenu")
                return (
                  <TeacherNew_SideNavbar
                    key={i}
                    elem={elem}
                    i={i}
                    handleLinkToggle={handleLinkToggle}
                    schoolSublinkVisible={schoolSublinkVisible}
                    navBarVis={navBarVis}
                    Navmenu={Navmenu}
                  />
                );
              })
              : null}



            {Navmenu &&
              !isCordinator &&
              !isPrincipal &&
              !isStudentt &&
              !isTeacher &&
              userWho != "REVISION_REVIEWER" &&
              Navmenu.map((elem, i) => {
                return (
                  <React.Fragment key={elem.menuId}>
                    {elem.menu === "Schools" // Super Admin //
                      || elem.menu === "Assessments" // Revision Admin //
                      || elem.menu === "Master" // Master Admin //
                      || elem.menu === "Concept Tree" // Content Admin //
                      || elem.menu === "Blueprint" ? ( // Content Admin //
                      <div

                      >
                        <li
                          key={elem.menuId}
                          className="test az_nav_menu_li az_cursor_pointer"
                          id={`az-sidemenu-${elem.menuId.toLowerCase().replace(/ /g, "_")}`}
                          //className={navStyles.toggle_btn}
                          // onClick={elem.subMenus && handleLinkToggle}
                          onClick={() => {
                            elem.subMenus && handleLinkToggle();
                            handleHighlight(elem.menu);
                          }}
                          style={{
                            color: menuName === elem.menu ? "" : "#ffa500",
                          }}
                        >
                          <p>
                            <span
                              className={navStyles.link_icon}
                              style={{
                                filter:
                                  menuName === elem.menu
                                    ? "invert(71%) sepia(54%) saturate(3684%) hue-rotate(0deg) brightness(104%) contrast(104%)"
                                    : "",
                              }}
                            >
                              {elem.menu === "Dashboard" ? (
                                <img
                                  src={dashHome}
                                  alt="no img"
                                // width="24px"
                                // height="22px"
                                />
                              ) : elem.menu === "Master" ? (
                                <img
                                  src={
                                    localStorage.getItem("role") === "CONTENT_ADMIN"
                                      ? master
                                      : masterIc
                                  }
                                  alt="no img"
                                />
                              ) : elem.menu === "Upload Content" ? (
                                <img src={upload_content} alt="no img" />
                              ) : elem.menu === "Global Training" ? (
                                <img src={global_training} alt="no img" />
                              ) : elem.menu === "Concept Tree" ? (
                                <img src={concept_tree} alt="no img" />
                              ) : elem.menu === "Associate Concepts" ? (
                                <img src={associate_concept} alt="no img" />
                              ) : elem.menu === "Learning Plan" ? (
                                <img src={learningIcn} alt="no img" />
                              ) : elem.menu === "Student Revision" ? (
                                <img src={StudentIcn} alt="no img" />
                              ) : elem.menu === "Assign Quiz" ? (
                                <img src={assignIcn} alt="no img" />
                              ) : elem.menu === "Upskills" ? (
                                <img src={upSkillsIcn} alt="no img" />
                              ) : elem.menu === "Schools" ? (
                                <img
                                  src={schoolsIc}
                                  alt="no img"
                                // className={navStyles.school_img}
                                />
                              ) : elem.menu === "Subjective papers" ? (
                                <img src={add_training} alt="no img" />
                              ) : elem.menu === "Teach" ? (
                                <img src={teachIc} alt="no img" />
                              ) : elem.menu === "Reports" ? (
                                <img src={reportIc} alt="no img" />
                              ) : elem.menu === "Training" ? (
                                <img src={masterIc} alt="no img" />
                              ) : elem.menu === "View Concept Tree" ? (
                                <img src={viewConceptIcn} alt="no img" />
                              ) : elem.menu === "Maps" ? (
                                <img src={mapsRevIcon} alt="no img" />
                              ) : elem.menu === "Question Bank" ? (
                                <img src={questionBankIcn} alt="no img" />
                              ) : elem.menu === "View All Questions" ? (
                                <img src={questionBankIcn} alt="no img" />
                              ) : elem.menu === "Create Quiz" ? (
                                <img src={createQuizIcn} alt="no img" />
                              ) : elem.menu === "Blue Print" ? (
                                <img src={blueprintIcn} alt="no img" />
                              ) : elem.menu === "View Quizzes" ? (
                                <img src={viewQuizIcn} alt="no img" />
                              ) : elem.menu === "Activities" ? (
                                <img src={activitiesIcon} alt="no img" />
                              ) : elem.menu === "Blueprint Overview" ? (
                                <img src={blueprintIcn} alt="no img" />
                              ) : elem.menu === "Upload Assessments" ? (
                                <img src={uploadAssessmentIcon} alt="no img" />
                              ) : (
                                <img
                                  src={admin}
                                  alt="no img"
                                  width="27px"
                                  height="27px"
                                />
                              )}

                              {/* <i className={elem.menu === 'Master' ? "fas fa-user-cog" : "fa-solid fa-memo-circle-check"}></i> */}
                            </span>
                            {/* {localStorage.getItem("test") ==="ADmin" ?"/admin-question-bank":"viec-question-bank"} */}
                            {/* <span  key={elem.menuId} className={navStyles.link_text}>{elem.menu}</span> */}
                            {elem.subMenus ? (
                              <span
                                key={elem.menuId}
                                className={navStyles.link_text + ' ' + 'az_nav_span_text'}
                                style={
                                  menuName === elem.menu ? { color: "#ffa500" } : { color: "#1b1b1b" }
                                }
                              >
                                {elem.menu}
                              </span>
                            ) : (
                              <Link
                                style={{ textDecoration: "none" }}
                                to={handleRoute(elem.menu.toLowerCase().trim())}
                                id={`az-sidemenu-${elem.menu.toLowerCase().replace(/ /g, "_")}`}
                                onClick={() => {
                                  if (elem.menu === "Content Approval") {
                                    sessionStorage.removeItem("boardId");
                                    sessionStorage.removeItem("gradeId");
                                    sessionStorage.removeItem("subjectId");
                                    sessionStorage.removeItem("subTopicId");
                                    sessionStorage.removeItem("isStatus");
                                  }
                                }}
                              >
                                <span className={navStyles.link_text}
                                  style={
                                    menuName === elem.menu ? { color: "#ffa500" } : { color: "#1b1b1b" }
                                  }>
                                  {elem.menu}
                                </span>
                              </Link>
                            )}
                            <span className={navStyles.link_dropdown}>
                              <i
                                className={
                                  schoolSublinkVisible && navBarVis && elem.menu === sessionStorage.getItem("sideNavMainMenuNames")
                                    ? "fa-solid fa-angle-down" + ' ' + "az_nav_span_text"
                                    : "fa-solid fa-angle-right" + ' ' + "az_nav_span_text"
                                }
                                style={
                                  menuName === elem.menu ? { color: "#ffa500" } : { color: "#1b1b1b" }
                                }
                                id="toggle_btn_icon"
                              ></i>
                            </span>
                          </p>

                        </li>

                        {showPopup && elem.menu === selectedMenuName && (
                          <Menu
                            id="basic-menu"
                            open={showPopup}
                            onClose={() => setShowPopup(false)}
                            transformOrigin={{ horizontal: "left", vertical: "center" }}
                            anchorOrigin={{ horizontal: "left", vertical: "center" }}
                            PaperProps={{
                              style: {
                                borderRadius: "20px", // Adjust the border radius as needed
                                paddingTop: "0px"
                              },
                            }}

                          >
                            <ListSubheader
                              sx={{
                                typography: 'h8',
                                color: "#1b1b1b",
                                background: "#ffa500"
                              }}>{selectedMenuName}</ListSubheader>

                            {elem.menu === selectedMenuName && elem.subMenus !== null &&
                              elem.subMenus.map((item) => (
                                console.log(">>>> menus >>>", item, elem.subMenus, elem.menuId),
                                <MenuItem
                                  key={item.subMenuId}
                                  onClick={() => handleClose(item.subMenuName)}
                                  id={`az-sidemenu-${elem.menu.toLowerCase().replace(/ /g, "_")}`}
                                >

                                  <ListItemText
                                    sx={{
                                      typography: 'h8',
                                      // backgroundColor : subMenuNameValue === item.subMenuName ? "#ffa500" : "#000000",
                                      color: subMenuNameValue === item.subMenuName ? "#ffa500" : "#000000",
                                    }}>
                                    {item.subMenuName === 'Formal Report'
                                      ? 'Formal Assessment Report'
                                      : item.subMenuName}
                                  </ListItemText>
                                </MenuItem>
                              ))
                            }
                          </Menu>
                        )}
                      </div>
                    ) :
                      (
                        <Link
                          style={{ textDecoration: "none" }}
                          to={handleRoute(elem.menu.toLowerCase().trim())}
                          id={`az-sidemenu-${elem.menu.toLowerCase().replace(/ /g, "_")}`}
                          onClick={() => {
                            if (elem.menu === "Content Approval") {
                              sessionStorage.removeItem("boardId");
                              sessionStorage.removeItem("gradeId");
                              sessionStorage.removeItem("subjectId");
                              sessionStorage.removeItem("subTopicId");
                              sessionStorage.removeItem("isStatus");
                            }
                          }}
                        >
                          <li
                            key={elem.menuId}
                            id={`az-sidemenu-${elem.menu.toLowerCase().replace(/ /g, "_")}`}
                            onClick={() => {
                              elem.subMenus && handleLinkToggle();
                              handleHighlight(elem.menu);
                            }}
                            className="az_nav_menu_li"
                            style={{
                              background: menuName === elem.menu ? "#ffa500" : "",
                            }}
                          >
                            <p>
                              <span
                                className={navStyles.link_icon}
                                style={{
                                  filter:
                                    menuName === elem.menu
                                      ? "invert(71%) sepia(54%) saturate(3684%) hue-rotate(0deg) brightness(104%) contrast(104%)"
                                      : "",
                                }}
                              >
                                {elem.menu === "Dashboard" ? (
                                  <img
                                    src={dashHome}
                                    alt="no img"
                                  // width="24px"
                                  // height="22px"
                                  />
                                ) : elem.menu === "Master" ? (
                                  <img
                                    src={
                                      localStorage.getItem("role") ===
                                        "CONTENT_ADMIN"
                                        ? master
                                        : masterIc
                                    }
                                    alt="no img"
                                  />
                                ) : elem.menu === "Upload Content" ? (
                                  <img src={upload_content} alt="no img" />
                                ) : elem.menu === "Global Training" ? (
                                  <img src={global_training} alt="no img" />
                                ) : elem.menu === "Concept Tree" ? (
                                  <img src={concept_tree} alt="no img" />
                                ) : elem.menu === "Associate Concepts" ? (
                                  <img src={associate_concept} alt="no img" />
                                ) : elem.menu === "Learning Plan" ? (
                                  <img src={learningIcn} alt="no img" />
                                ) : elem.menu === "Student Revision" ? (
                                  <img src={StudentIcn} alt="no img" />
                                ) : elem.menu === "Assign Quiz" ? (
                                  <img src={assignIcn} alt="no img" />
                                ) : elem.menu === "Upskills" ? (
                                  <img src={upSkillsIcn} alt="no img" />
                                ) : elem.menu === "Schools" ? (
                                  <img
                                    src={schoolsIc}
                                    alt="no img"
                                  //className={navStyles.school_img}
                                  />
                                ) : elem.menu === "Subjective papers" ? (
                                  <img src={add_training} alt="no img" />
                                ) : elem.menu === "Teach" ? (
                                  <img src={teachIc} alt="no img" />
                                ) : elem.menu === "Reports" ? (
                                  <img src={reportIc} alt="no img" />
                                ) : elem.menu === "Training" ? (
                                  <img src={masterIc} alt="no img" />
                                ) : elem.menu === "View Concept Tree" ? (
                                  <img src={viewConceptIcn} alt="no img" />
                                ) : elem.menu === "Maps" ? (
                                  <img src={mapsRevIcon} alt="no img" />
                                ) : elem.menu === "Question Bank" ? (
                                  <img src={questionBankIcn} alt="no img" />
                                ) : elem.menu === "View All Questions" ? (
                                  <img src={questionBankIcn} alt="no img" />
                                ) : elem.menu === "Create Quiz" ? (
                                  <img src={createQuizIcn} alt="no img" />
                                ) : elem.menu === "Blue Print" ? (
                                  <img src={blueprintIcn} alt="no img" />
                                ) : elem.menu === "View Quizzes" ? (
                                  <img src={viewQuizIcn} alt="no img" />
                                ) : elem.menu === "Activities" ? (
                                  <img src={activitiesIcon} alt="no img" />
                                ) : elem.menu === "Blueprint Overview" ? (
                                  <img src={blueprintIcn} alt="no img" />
                                ) : elem.menu === "Upload Assessments" ? (
                                  <img src={uploadAssessmentIcon} alt="no img" />
                                ) : (
                                  <img
                                    src={admin}
                                    alt="no img"
                                    width="27px"
                                    height="27px"
                                  />
                                )}

                                {/* <i className={elem.menu === 'Master' ? "fas fa-user-cog" : "fa-solid fa-memo-circle-check"}></i> */}
                              </span>
                              {/* {localStorage.getItem("test") ==="ADmin" ?"/admin-question-bank":"viec-question-bank"} */}
                              {/* <span  key={elem.menuId} className={navStyles.link_text}>{elem.menu}</span> */}
                              {elem.subMenus ? (
                                <span
                                  key={elem.menuId}
                                  className={navStyles.link_text}
                                  style={
                                    menuName === elem.menu ? { color: "#ffa500" } : { color: "#1b1b1b" }
                                  }
                                >
                                  {elem.menu}
                                </span>
                              ) : (
                                <span
                                  className={navStyles.link_text + ' ' + 'az_nav_span_text'}
                                  style={
                                    menuName === elem.menu ? { color: "#fff" } : { color: "#1b1b1b" }
                                  }
                                >
                                  {elem.menu}
                                </span>
                              )}
                              <span className={navStyles.link_dropdown}>
                                <i
                                  className={
                                    schoolSublinkVisible && navBarVis
                                      ? "fa-solid fa-angle-down"
                                      : "fa-solid fa-angle-right"
                                  }
                                  style={
                                    elem.subMenus !== null
                                      ? { color: "#ffa500" }
                                      : { display: "none" }
                                  }
                                  id="toggle_btn_icon"
                                ></i>
                              </span>
                            </p>
                          </li>
                        </Link>
                      )}

                    <li
                      key={i}
                      className={navStyles.schools_sublink_container}
                      style={
                        schoolSublinkVisible && elem.menu === sessionStorage.getItem("sideNavMainMenuNames")
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <ul style={{ overflowY: "scroll", height: "108%", paddingTop: "0px" }}>
                        {elem.subMenus &&
                          elem.subMenus.map((item) => {
                            return (
                              <Link
                                style={{ textDecoration: "none" }}
                                to={
                                  userWho === "TEACHER" &&
                                    item.subMenuName === "Chapter performance"
                                    ? "Chapter%20performance"
                                    : userWho === "TEACHER" &&
                                      item.subMenuName === "Student Report"
                                      ? "Student%20Report"
                                      : item.subMenuName
                                }
                              >
                                <li
                                  className={`${navStyles.schools_sublink}`}
                                  //  id="registered_schools_link"
                                  key={item.subMenuId}
                                  style={{
                                    display: navBarVis ? "block" : "none",
                                    background: subNavMenuName === item.subMenuName ? "#ffa500" : "",

                                  }}
                                  //onClick={(e)=>handleSubMenu(item.title)}
                                  id={`az-sidemenu-${elem.menu.toLowerCase().replace(/ /g, "_")}`}
                                  onClick={() => handleHighlightSubMenu(item.subMenuName, elem.menu)}
                                >
                                  <p>
                                    <span
                                      key={item.subMenuId}
                                      className={navStyles.link_text}
                                      style={{
                                        marginLeft: "8px",
                                        fontSize: "15px",
                                        color: subNavMenuName === item.subMenuName ? "#fff" : ""
                                      }}
                                    >
                                      <span className={navStyles.icon_link}
                                      >
                                        {item.subMenuName ===
                                          "Create/Edit Concept" ? (
                                          <img
                                            src={view_concept}
                                            alt="no img"
                                            style={{
                                              // marginRight: "15px",
                                              width: "1px",
                                              height: "2px",
                                              color: menuName !== item.subMenuName ? "#ffa500" : ""

                                            }}
                                          />
                                        ) : item.subMenuName ===
                                          "Create/Edit Sub & Root Concepts" ? (
                                          <img
                                            src={view_concept}
                                            alt="no img"
                                            style={{
                                              // marginRight: "15px",
                                              width: "1px",
                                              height: "2px",
                                              color: menuName !== item.subMenuName ? "#ffa500" : ""

                                            }}
                                          />
                                        ) : item.subMenuName ===
                                          "View Concept Tree" ? (
                                          <img
                                            src={view_concept}
                                            alt="no img"
                                            style={{
                                              // marginRight: "15px",
                                              width: "1px",
                                              height: "2px",
                                              color: menuName !== item.subMenuName ? "#ffa500" : ""

                                            }}
                                          />
                                        ) : item.subMenuName ===
                                          "Add Training Category" ? (
                                          <img
                                            src={add_training}
                                            alt="no img"
                                            style={{
                                              // marginRight: "15px",
                                              width: "1px",
                                              height: "2px",
                                              color: menuName !== item.subMenuName ? "#ffa500" : ""

                                            }}
                                          />
                                        ) : item.subMenuName ===
                                          "Chapter Performance" ? (
                                          <img
                                            src={add_training}
                                            alt="no img"
                                            style={{
                                              // marginRight: "15px",
                                              width: "1px",
                                              height: "2px",
                                              color: menuName !== item.subMenuName ? "#ffa500" : ""

                                            }}
                                          />
                                        ) : item.subMenuName ===
                                          "Student Report" ? (
                                          <img
                                            src={add_training}
                                            alt="no img"
                                            style={{
                                              // marginRight: "15px",
                                              width: "1px",
                                              height: "2px",
                                              color: menuName !== item.subMenuName ? "#ffa500" : ""

                                            }}
                                          />
                                        ) : item.subMenuName ===
                                          "Upload Assessment" ? (
                                          <img
                                            src={
                                              menuName === item.subMenuName
                                                ? SideIcon2
                                                : SideIcon1
                                            }
                                            // alt="no img"
                                            style={{
                                              // marginRight: "15px",
                                              width: "1px",
                                              height: "2px",
                                              color: menuName !== item.subMenuName ? "#ffa500" : ""

                                            }}
                                          />
                                        ) : item.subMenuName ===
                                          "Assign Assessment" ? (
                                          <img
                                            src={
                                              menuName === item.subMenuName
                                                ? SideIcon2
                                                : SideIcon1
                                            }
                                            alt="no img"
                                            style={{
                                              width: "1px",
                                              height: "2px",
                                              color: menuName !== item.subMenuName ? "#ffa500" : ""
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                      {item.subMenuName}
                                    </span>
                                  </p>
                                </li>
                              </Link>
                            );
                          })}
                      </ul>
                    </li>
                  </React.Fragment>
                );
              })}
            <li className={navStyles.logout_container + ' ' + 'az_nav_menu_li'}>
              {/* <a href="/login" onClick={() => localStorage.removeItem("token")}> */}
              <a
                style={{
                  textDecoration: "none",
                  display: "flex",
                  // position: "fixed",
                  bottom: "0",
                  width: !navBarVis ? "4%" : userWho === "REVISION_ADMIN" || userWho === "REVISION_REVIEWER" ? "12.5%" : "16%",
                  borderRadius: " 0 20px 20px 0",
                }}
                // href="/"
                id={`az-logout-sidemenu`}
                onClick={handleLogout}
              >
                <span className={navStyles.link_icon}>
                  <i className="fa-solid fa-arrow-right-from-bracket"></i>
                </span>
                <span className={navStyles.link_text + ' ' + 'az_nav_span_text'}>Logout</span>
              </a>
            </li>


          </ul>
        </nav>
      )}
      {/* modal Add School Begin */}
      <div
        className={addstudentstyle.flex_container}
        id={addstudentstyle.modal}
        style={showAddStudentModal ? { display: "flex" } : { display: "none" }}
      >
        <div
          className={addstudentstyle.main_content}
          id={addstudentstyle.modal_content}
        >
          <div className={addstudentstyle.main_content_head}>
            <div>Add Student</div>
            <div>
              <i className="fa-solid fa-xmark" onClick={handleCross}></i>
            </div>
          </div>

          <div className={addstudentstyle.main_content_box_head}>
            School Details
          </div>
          <div className={addstudentstyle.main_content_box}>
            <div className={addstudentstyle.input_element}>
              <select
                className={addstudentstyle.form_select_element}
                name="studentschool"
                id="studentschool"
                value={studentDetails.studentschool}
                onChange={handlerStudentDetailsInputs}
              >
                <option value="Select">Select</option>
                {schoolList.length > 0 ? (
                  schoolList.map((schoolList, index) => {
                    return (
                      <option key={index} value={schoolList.schoolId}>
                        {schoolList.schoolName}
                      </option>
                    );
                  })
                ) : (
                  <option className={addstudentstyle.option} value=""></option>
                )}
              </select>
              <label htmlFor="SchoolName">*School Name</label>
            </div>
            <div className={addstudentstyle.input_element}>
              <select
                className={addstudentstyle.form_select_element}
                name="studentbranch"
                id="studentbranch"
                value={studentDetails.studentbranch}
                onChange={handlerStudentDetailsInputs}
              >
                <option value="">Select</option>
                {branchList.length > 0 ? (
                  branchList.map((branchList, index) => {
                    return (
                      <option key={index} value={branchList.branchId}>
                        {branchList.branch}
                      </option>
                    );
                  })
                ) : (
                  <option className={addstudentstyle.option} value=""></option>
                )}
              </select>
              <label htmlFor="BranchName">*Branch Name</label>
            </div>

            <div className={addstudentstyle.input_element}>
              <select
                className={addstudentstyle.form_select_element}
                name="grade"
                id="Grade"
                value={studentDetails.grade}
                onChange={handlerStudentDetailsInputs}
              >
                <option value="">Select</option>
                {gradeList && gradeList.length > 0 ? (
                  gradeList.map((gradeList, index) => {
                    return (
                      <option key={index} value={gradeList.id}>
                        {gradeList.grade}
                      </option>
                    );
                  })
                ) : (
                  <option className={addstudentstyle.option} value=""></option>
                )}
              </select>
              <label htmlFor="Grade">*Grade</label>
            </div>
            <div className={addstudentstyle.input_element}>
              <select
                className={addstudentstyle.form_select_element}
                name="section"
                id="section"
                value={studentDetails.section}
                onChange={handlerStudentDetailsInputs}
              >
                <option value="">Select</option>
                {sectionList && sectionList.length > 0 ? (
                  sectionList.map((sectionList, index) => {
                    return (
                      <option key={index} value={sectionList.id}>
                        {sectionList.section}
                      </option>
                    );
                  })
                ) : (
                  <option className={addstudentstyle.option} value=""></option>
                )}
              </select>
              <label htmlFor="section">*Section</label>
            </div>
          </div>
          <div className={addstudentstyle.modal_buttons}>
            <button
              className={addstudentstyle.choosefile_button_2}
              id={addstudentstyle.choose_button_id_2}
              onClick={handleAddStudentSubmit}
            >
              SUBMIT
            </button>
          </div>
        </div>
      </div>
      {/* modal ADD School End */}

      {/* modal Add School Begin */}
      <div
        className={addacademicstyle.flex_container}
        id={addacademicstyle.modal}
        style={showAddAcademicModal ? { display: "flex" } : { display: "none" }}
      >
        <div
          className={addacademicstyle.main_content}
          id={addacademicstyle.modal_content}
        >
          <div className={addacademicstyle.main_content_head}>
            <div>Add Academic Staff</div>
            <div>
              <i className="fa-solid fa-xmark" onClick={handleCross}></i>
            </div>
          </div>

          <div className={addacademicstyle.main_content_box_head}>
            School Details
          </div>
          <div className={addacademicstyle.main_content_box}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className={addacademicstyle.input_element}>
                <select
                  className={addacademicstyle.form_select_element}
                  name="school"
                  id={schoolDetails.schoolName}
                  value={schoolDetails.school}
                  onChange={handlerSchoolDetailsInputs}
                >
                  <option value="">Select</option>
                  {schoolList.length > 0 ? (
                    schoolList.map((schoolList, index) => {
                      return (
                        <option
                          key={index}
                          value={schoolList.schoolId}
                          id={schoolList.schoolName}
                        >
                          {schoolList.schoolName}
                        </option>
                      );
                    })
                  ) : (
                    <option
                      className={addacademicstyle.option}
                      value=""
                    ></option>
                  )}
                </select>
                <label htmlFor="SchoolName">*School Name</label>
              </div>
              <div className={addacademicstyle.input_element}>
                <select
                  className={addacademicstyle.form_select_element}
                  name="branch"
                  id="branch"
                  value={schoolDetails.branch}
                  onChange={handlerSchoolDetailsInputs}
                >
                  <option value="">Select</option>
                  {branchList.length > 0 ? (
                    branchList.map((branchList, index) => {
                      return (
                        <option key={index} value={branchList.branchId}>
                          {branchList.branch}
                        </option>
                      );
                    })
                  ) : (
                    <option
                      className={addacademicstyle.option}
                      value=""
                    ></option>
                  )}
                </select>
                <label htmlFor="BranchName">*Branch Name</label>
              </div>
            </form>
          </div>
          <div className={addacademicstyle.modal_buttons}>
            <button
              className={addacademicstyle.choosefile_button_2}
              id={addacademicstyle.choose_button_id_2}
              onClick={handleAddAcademicSubmit}
            >
              SUBMIT
            </button>
          </div>
        </div>
      </div>
      {/* modal ADD School End */}
    </>
  );
};

export default Navbar;
