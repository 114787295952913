import React, { useState, useRef } from "react";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import mapStyles from "../css/map.module.css";
import axios from "axios";
import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import createschool from "./../css/CreateSchool.module.css";
import ChapterModel from "./ChapterModel";
import ImageListModal from "./QuestionBank/CommonComponents/ImageListModal";
import {
	Box,
	Button,
	FormControl,
	IconButton,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Paper,
	Select,
	Table,
	Stack,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Tooltip,
} from "@mui/material";
import EditIconButton from "../../components/common/EditIconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import map_content_get from "../API_CONTROLLER/map-controller/map_content_get";
import UploadMapModal from "./UploadMapModal";
import { SearchOutlined } from "@mui/icons-material";
import { useEffect } from "react";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";
import BreadCrumbs from "../js/RevisionAdmin/General/BreadCrumbs";

const Maps = () => {
	const [mapcontent, setMapcontent] = useState({});
	const [createModal, setCreateModal] = useState(false);
	const token = sessionStorage.getItem("token");
	const openCreateModal = () => {
		setCreateModal(true);
	};
	const [isEdit, setIsEdit] = useState(false);
	const [maptypes, setMaptypes] = useState([]);
	const [modifiedDate, setModifiedDate] = useState({});
	const [selectedmaptype, setselectedmaptype] = useState("");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [openImageListModal, setOpenImageListModal] = React.useState(false);
	const [selectedImageName, setSelectedImageName] = useState("");
	const [selectedImageUrl, setSelectedImageUrl] = useState();
	const [filteredData, setFilteredData] = useState([]);
	const [chapterOpen, setchapterModal] = useState(false);
	const handleImageListModalOpen = () => setOpenImageListModal(true);
	const handleImageListModalClose = () => setOpenImageListModal(false);
	const removeUploadImgMap = useRef(null);
	useEffect(() => {
		GetLastModifiedAt(setModifiedDate);
		return () => { };
	}, []);
	const getMaptypes = () => {
		axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/misc/map-type`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(function (response) {
				setMaptypes(response?.data?.data);
			})
			.catch((err) => console.log(err));
	};
	const [updatamaps, setUpdateMpas] = useState();
	const [isError, setIsError] = useState(false);
	const [isUpdate, setUpdate] = useState(false);
	const mapUpdateHandler = () => {
		console.log("map url >>> ", sessionStorage.getItem("mapUrl"));
		let existingImg = sessionStorage.getItem("mapUrl");
		if (!title) {
			setIsError(true);
			return;
		}
		if (radio != "Machine") {
			var body = {
				mapType: selectedmaptype,
				mapUrl: uploadedmapurl ? uploadedmapurl : existingImg,
				title: title,
			};
		} else {
			var body = {
				mapType: selectedmaptype,
				mapUrl: uploadedmapurl ? uploadedmapurl : existingImg,
				title: title,
			};
		}
		axios
			.put(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/map/${id}`,
				body,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((res) => {
				setUpdateMpas(res?.data?.data);
				map_content_get(setMapcontent);

				setuploadedmapurl("");
				setTitle("");
				setMapUrl("");
				setSelectedImageName("");
				setIsEdit(false);
				setTimeout(() => {
					setchapterModal(true);
				}, 700);
				if (res.data?.message === "Created map entry successfully") {
					setUpdate(false);
				} else {
					setTimeout(() => {
						setUpdate(true);
					}, 500);
				}
			})
			.catch((err) => console.log(err));
	};

	const closeCreateModal = () => {
		setCreateModal(false);
	};
	const closeModalAPI = () => {
		setCreateModal(false);
		setTimeout(() => {
			setchapterModal(true);
		}, 500);
		map_content_get(setMapcontent);
	};
	const chapterModelClose = () => {
		setchapterModal(false);
		setUpdate(false);
	};
	const [title, setTitle] = useState();
	const [mapUrl, setMapUrl] = useState();
	console.log(mapUrl, "sree url");
	const [id, setId] = useState();
	const EditMapsHandeler = (e, elem) => {
		console.log(elem.mapUrl, "elem")
		const { title, mapUrl, id, mapType } = elem;
		setTitle(title);
		setselectedmaptype(mapType);
		setMapUrl(mapUrl);
		console.log(mapUrl, "mapurl")
		setId(id);
		setIsEdit(true);
	};
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handlemaptype = (value) => {
		axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/map?&pageSize=100&sortOrder=true&sortBy=id&mapType=${value}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((res) => {
				setMapcontent(res.data.data.data);
				setFilteredData(res.data.data.data);
				setPage(0);
			})
			.catch((err) => console.log(err));
	};

	const [uploadedmapurl, setuploadedmapurl] = useState("");
	const imageChange = (e) => {
		e.preventDefault();
		const formData = new FormData();

		formData.append("file", e.target.files[0]);

		axios
			.post(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/file/upload?fileCategory=CONTENT_SERVICE&subFolders=INTERACTIVE_CONTENT`,
				formData,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((res) => {
				console.log("maplog >>> ", res?.data?.data);
				setuploadedmapurl(res?.data?.data);
				sessionStorage.setItem("mapUrl", res.data.data)
			})
			.catch((err) => console.log(err));
	};
	const handleSearch = async (event) => {
		let value = event?.target?.value?.toLowerCase();
		if (value) {
			let result = [...filteredData];

			let temp = [...result]?.filter((data) => {
				return data?.title?.toLowerCase().includes(value);
			});
			setMapcontent(temp);
			setPage(0);
		} else {
			let result = [...filteredData];
			setMapcontent(result);
			setPage(0);
		}
	};

	const [radio, setRadio] = useState("");
	const handleChange = (event) => {
		setRadio(event.target.value);
		if (event.target.value === "Repository") {
			handleImageListModalOpen();
		}
	};

	useEffect(() => {
		map_content_get(setMapcontent, setFilteredData);
		getMaptypes();
	}, []);
	return (
		<div style={{ width: "100%" }}>
			<article>
				<BreadCrumbs currentPage={"Maps"} />
				{/* <div className={dashboardContentStyles.dashboard_link}>
					<span className={dashboardContentStyles.link_icon}>
						<img
							src={dashHome}
							alt="no img"
							width="15px"
							height="20px"
							style={{ verticalAlign: "middle" }}
						/>{" "}
					</span>
					{"  "}
					<span className={dashboardContentStyles.link_text}>
						Home
					</span>
					{"  "}
					<span>
						<i className="fa-solid fa-angle-right"></i>
					</span>
					<span>
						<i className="fa-solid fa-angle-right"></i>
					</span>
					{"  "}
					<a>Maps</a>
				</div> */}
				{/* <div className={dashboardContentStyles.dashboard_last_updated}>
					<p>Last Update: {modifiedDate.data}</p>
				</div> */}
			</article>

			<div style={{ display: "flex", flexDirection: "column" }}>
				<div
					style={{
						display: "flex",
						marginTop: "50px",
						width: "100%",
					}}
				>
					<div
						style={{
							display: "flex",
							width: "50%",
							position: "relative",
						}}
					>
						<div
							style={{
								display: "flex",
								position: "absolute",
								left: "15px",
								columnGap: "8px",
								alignContent: "baseline"
							}}
						>
							<i style={{ alignContent: "center", color: "#403e75", }} class="fa-regular fa-map"></i>
							<p style={{ alignContent: "center", color: "#403e75", }}>
								Map List
							</p>
						</div>
						<button
							id={`az-revisionadmin-map-uploadbutton`}
							onClick={setCreateModal}
							style={{ position: "absolute", display: "flex", right: "6px" }}
							className={mapStyles.green_btn}
						>
							<i class="bi bi-plus-circle"></i>Upload Map
						</button>
					</div>

					<div>
						<div
							style={{
								display: "flex",
								marginLeft: "15px",
								columnGap: "8px",
							}}
						>
							<i style={{ alignContent: "center", color: "#403e75", }} class="fa-regular fa-map"></i>
							<p style={{ alignContent: "center", color: "#403e75", }}>
								Edit Map
							</p>
						</div>
					</div>
				</div>

				<div
					style={{
						width: "100%",
						marginTop: "40px",
						display: "flex",
					}}
				>
					<Paper
						style={{
							boxShadow: "1px 1px 14px 2px #00000029",
							width: "50%",
							marginLeft: "15px",
							marginRight: "8px",
						}}
					>
						<div
							style={{
								display: "flex",
								position: " relative",
								padding: "22px",
							}}
						>
							<Box sx={{ minWidth: 140 }}>
								<FormControl fullWidth size="small">
									<InputLabel
										className={mapStyles.select_input}
										id="demo-select-small"
										style={{
											color: 'orange'
										}}
									>
										Map Type
									</InputLabel>
									<Select
										labelId="demo-select-small"
										id="demo-select-small"
										label="Grade"
										value={selectedmaptype}
										onChange={(e) => {
											setselectedmaptype(e.target.value);
											handlemaptype(e.target.value);
										}}
										style={{
											background: "#fff",
											borderRadius: 150,
										}}
									>
										{maptypes?.length > 0 &&
											maptypes.map((v, i) => {
												return (
													<MenuItem
														value={v?.code}
														key={i}
														id={`az-revisionadmin-maptypedropdown-${i}`}
														className={
															dashboardContentStyles.menu_item
														}
														sx={{
															"&.Mui-selected": {
																backgroundColor: 'orange',
																color: "#FFFFFF"
															},
															"&.Mui-selected:hover": {
																backgroundColor: '#fcedc2',
																color: "#000000"
															},
															"&:not(.Mui-selected):hover": {
																backgroundColor: '#fcedc2',
																color: '#000000',
															},
														}}
													>
														{v?.name}
													</MenuItem>
												);
											})}
									</Select>
								</FormControl>
							</Box>
							<div
								style={{ position: "absolute", right: "10px" }}
							>
								<input
									type="search"
									name="search"
									style={{
										width: "14vw",
										height: "6vh",
										borderRadius: "25px",
										border: "1px solid #9b9fa7",
										padding: "0 34px 0 10px",
										opacity: 1,
									}}
									id="search"
									placeholder="Search..."
									onChange={handleSearch}
								/>
								{/* <span>
									<img
										src={searchIcn}
										style={{
											verticalAlign: "top",
											fontSize: "large",
										}}
									/>
								</span> */}
							</div>
						</div>
						<div>
							{mapcontent.length > 0 ? (
								mapcontent
									?.slice(
										page * rowsPerPage,
										page * rowsPerPage + rowsPerPage
									)
									?.map((elem, i) => {
										return (
											<div
												style={{
													display: "flex",
													width: "100%",
													position: "relative",
												}}
											>
												<li
													className={
														mapStyles.plan_links_items
													}
													style={{ width: "100%" }}
												>
													<a
														className={
															mapStyles.plan_container_link
														}
													>
														<span
															className={
																mapStyles.plan_link_text
															}
														>
															{elem.title}
														</span>
													</a>
												</li>
												<div
													style={{
														position: "absolute",
														right: "10px",
														top: "15px",
														alignItems: "center"
													}}
												>
													<EditIconButton
														id={`az-revisionadmin-mapedit-button-${i}`}
														onClick={(e) => {
															e.preventDefault();
															EditMapsHandeler(
																e, elem
															);
														}}
													>
														<Tooltip
															title="Edit"
															placement={"top"}
														>
															<EditOutlinedIcon
																style={{
																	fontSize:
																		"25px",
																	color: "orange"
																}}
															/>
														</Tooltip>
													</EditIconButton>
												</div>
											</div>
										);
									})
							) : (
								<p>NO DATA AVAILABLE</p>
							)}
						</div>
					</Paper>

					{isEdit ? (
						<Paper
							style={{
								boxShadow: "1px 1px 14px 2px #00000029",
								width: "50%",
								marginLeft: "15px",
								marginRight: "8px",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									margin: "23px",
									marginTop: "-35px",
								}}
							>
								<div>
									<input
										style={{
											marginTop: "73px",
											marginBottom: "20px",
											border: "1px solid grey",
											borderRadius: "6px",
											padding: "8px",
											width: "350px",
										}}
										name="title"
										type="text"
										value={title}
										onChange={(e) => {
											setTitle(e.target.value);
										}}
										placeholder="Title"
									/>
									{isError && !title && (
										<p
											style={{
												fontSize: "10px",
												color: "red",
												padding: "5px",
											}}
										>
											Title is Required
										</p>
									)}
								</div>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
									}}
								>
									<div>
										<p style={{ color: "#354052" }}>
											Existing Image
										</p>
										<img
											src={mapUrl}
											width="290"
											height="250px"
											alt="Existing uploaded Image"
										/>
									</div>

									<div style={{ marginLeft: "10px" }}>
										{uploadedmapurl && (
											<>
												<p style={{ color: "#354052" }}>
													Upload Image
												</p>
												<img
													src={uploadedmapurl}
													width="290"
													height="250px"
													alt=""
												/>
											</>
										)}
									</div>
								</div>
								<div
									style={{
										margin: "15px",
										width: "100%",
										display: "flex",
									}}
								>
									<div style={{ display: "flex" }}>
										<div
											style={{
												display: "flex",
												marginRight: "50px",
											}}
										>
											<input
												type="radio"
												style={{
													accentColor: "orange",
												}}
												name="selection"
												value="Machine"
												onChange={handleChange}
												className="cursorPointer"
											/>
											<p
												style={{
													color: "#354052 ",
													fontSize: "1vw",
												}}
											>
												Upload from my machine
											</p>
										</div>
										<div style={{ display: "flex" }}>
											<input
												type="radio"
												style={{
													accentColor: "orange",
												}}
												name="selection"
												value="Repository"
												onChange={handleChange}
												className="cursorPointer"
											/>
											<p
												style={{
													color: "#354052 ",
													fontSize: "1vw",
												}}
											>
												Upload from Reposotory
											</p>
										</div>
									</div>
								</div>
								{radio == "Machine" && (
									<div
										style={{
											margin: "15px",
											width: "100%",
											display: "flex",
											flexDirection: "column",
										}}
									>
										<p style={{ color: "#354052 " }}>
											*Upload Image
										</p>
										<input
											type="file"
											name="contentUrl"
											style={{
												width: "10rem",
												marginTop: "20px",
											}}
											// onError={setIsError(true)}
											onChange={imageChange}
											className={
												createschool.custom_file_input
											}
										/>
									</div>
								)}
								{radio == "Repository" && (
									<span>{selectedImageName}</span>
								)}
								<div
									style={{
										position: "relative",
										height: "36px",
										margin: "40px",
									}}
								>
									<button
										id={`az-revisionadmin-map-submitbutton`}
										style={{
											right: "10px",
											position: "absolute",
										}}
										className={mapStyles.green_btn}
										onClick={() => {
											mapUpdateHandler();
										}}
									>
										SUBMIT
									</button>
								</div>
							</div>
						</Paper>
					) : (
						<p>NO DATA AVAILABLE</p>
					)}
					<TablePagination
						style={{
							bottom: "0",
							width: "37.5%",
							height: "50px",
							marginLeft: "654px",
							position: "fixed",
							backgroundColor: "white",
						}}
						rowsPerPageOptions={[5, 10, 25, 50]}
						component="div"
						count={mapcontent?.length || 0}
						page={page}
						onPageChange={handleChangePage}
						rowsPerPage={rowsPerPage}
						onRowsPerPageChange={handleRowsPerPage}
					/>
				</div>
			</div>
			<UploadMapModal
				open={createModal}
				close={closeCreateModal}
				closeModalAPI={closeModalAPI}
				handleImageListModalOpen={handleImageListModalOpen}
				uploadImgMapData={() => { }}
				removeUploadImgMap={removeUploadImgMap}
				selectedImageName={selectedImageName}
			/>
			<ImageListModal
				isMaps={true}
				openImageModal={openImageListModal}
				closeImageModal={handleImageListModalClose}
				selectedImage={setSelectedImageUrl}
				selectedImageName={setSelectedImageName}
			/>
			<ChapterModel
				open={chapterOpen}
				deleteName={
					isUpdate
						? "Map Updated Successfully"
						: "Map Uploaded Successfully"
				}
				close={chapterModelClose}
			/>
		</div>
	);
};

export default Maps;
