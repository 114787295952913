import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import chapterStyles from "./../css/createChapter.module.css";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import createschool from "./../css/CreateSchool.module.css";
import Pagination1 from "./pagination1";
// get create school css in this css file
import BlueButton from "../../components/common/BlueButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import WhiteAddButton from "../../components/common/WhiteButton";
import InactiveButton from "../../components/common/InactiveButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import TopRightPopup from "../../components/common/TopRightPopup";
import { MenuProps } from "./Data";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import add_icon from "../../../src/img/add-circle-outline.svg";
// sort
import sortIcon from "../../../src/pages/img/sort_1.png";
import SchoolAdminBreadcrumb from "./SchoolAdminBreadcrumb";

import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditIconButton from "../../components/common/EditIconButton";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import { SearchOutlined } from "@mui/icons-material";
// import GradesControllerGetall from "../API_CONTROLLER/grades-controller/grades_controller_getall";
import gradeListGet from "../API/grade-section-mapping-controller/grade_get";
import boards_controller_getall from "../API_CONTROLLER/boards-controller/boards_controller_getall";
import subjects_controller_getall from "../API_CONTROLLER/subject_controller/subjects_controller_getall";
import subtopics_controller_getall from "../API_CONTROLLER/subtopic-controller/subtopics_controller_getall";
import chapters_controller_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_get";
// import documentType_controller_getall from '../API_CONTROLLER/document-category-controller/documentType_controller_getall';
import chapters_controller_post from "../API_CONTROLLER/chapters-controller/chapters_controller_post";
import chapters_file_uploader_post from "../API_CONTROLLER/chapters-controller/chapters_file_uploader_post";
import chapters_last_modified_get from "../API_CONTROLLER/chapters-controller/chapters_last_modified_get";
import chapters_edit_getby_id from "../API_CONTROLLER/chapters-controller/chapters_edit_getby_id";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";
import ConfirmationModal from "./ConfirmationModal";
import DeleteBlanksModal from "./QuestionBank/CommonComponents/DeleteBlanksModal";

const UploadContent = () => {
  const [gradesName, setGrades] = useState({});
  const [show, setShow] = useState(false);
  const [board, setBoard] = useState({});
  const [subject, setSubject] = useState({});
  const [subtopic, setSubtopic] = useState([]);
  const [chapters, setChapters] = useState({});
  const [chapter, setChapter] = useState("");
  const [lastModifiedDate, setLastModifiedDate] = useState("");
  const [documenttype, setDocumenttype] = useState({});
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedBoard, setSelectedBoard] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedSubtopic, setSelectedSubtopic] = useState("");
  const [selectedChapter, setSelectedChapter] = useState("");
  const [selectedDocumenttype, setSelectedDocumenttype] = useState("");
  const [docType, setDoctype] = useState({});
  const [boardInput, setBoardInput] = useState("")
  const [gradeInput, setGradeInput] = useState("");
  const [boardList, setBoardList] = useState();
  const [subjectInput, setSubjectInput] = useState("");
  const [subTopicInput, setSubTopicInput] = useState("");
  const [isActive, setIsActive] = useState(null);
  const navigate = useNavigate();
  const [toggleMessage, settoggleMessage] = useState();
  const [toggleConfirmation, setToggleConfirmation] = useState(false);

  // sort
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState(false);
  const token = sessionStorage.getItem("token");

  const [chapterInputs, setChapterInputs] = useState({
    // selectedBoard : "",
    // selectedGrade : "",
    // selectedSubject: "",
    // selectedSubtopic:"",
    // selectedChapter:"",
    boardId: "",
    gradeId: "",
    subjectId: "",
    chapter: "",
    docType: "",
    logoUrl: "",
    chapterStatus: "PENDING",
    active: true,
  });

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterValue, setFilterValue] = useState();
  const { state } = useLocation();

  //Edit Active / In-Active
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const handleCreateChapter = (e) => {
    e.preventDefault();
    chapters_controller_post(chapterInputs);
    // setChapterInputs("")
    navigate("/dashboard/coadminDashboard");
  };

  const handlerChapterInputs = (e) => {
    e.preventDefault();
    setChapterInputs({
      ...chapterInputs,
      [e.target.name]: e.target.value,
    });
  };

  // pagination starts
  const [rowsInput, setRowsInputs] = useState({ rows: 10 });
  const [page, setPage] = useState(1);
  const [pageDetail, setPageDetail] = useState({});
  const handleChange = (e, value) => {
    console.log("handleChange", e, value, page)
    setPage(value);
  };

  const handlerRowsInputs = (e) => {
    console.log("handlerRowsInputs", e)
    // e.preventDefault();
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(1);
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
  };

  // pagination ends

  const [search, setSearch] = useState({
    search: "",
  });
  const handleSearch = (e) => {
    e.preventDefault();
    const searchValue = {
      search: e.target.value,
    };
    setSearch(searchValue);
    chapters_controller_getall(
      setChapters,
      rowsInput,
      page,
      setPageDetail,
      searchValue,
      gradeInput,
      subjectInput,
      subTopicInput
    );
    setPage(1);
  };

  const updateToggle = () => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/toggle/${toggleDetails?.id}?active=${toggleDetails?.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          data: !toggleDetails?.value,
        }
      )
      .then(function (response) {
        if (response?.data) {
          chapters_controller_getall(
            setChapters,
            rowsInput,
            page,
            setPageDetail,
            search,
            gradeInput,
            subjectInput,
            subTopicInput,
            sortOrder,
            sortBy,
            boardInput
          );
          handleConfirmationClose();
        }
      })
      .catch(function (response) {
        // console.log(response);
      });
  };
  const [toggleDetails, setToggleDetails] = useState({});
  const switchHandler = async ({ id, active }) => {
    setToggleDetails({});
    const value = !active;
    if (!value) {
      const response = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/confirmation-api?id=${id}&operationType=TOGGLE`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      setToggleConfirmation(true);
      settoggleMessage(response?.data?.data?.message);
      setToggleDetails({ id, value });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/toggle/${id}?active=${value}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
            data: !value,
          }
        )
        .then(function (response) {
          if (response?.data) {
            chapters_controller_getall(
              setChapters,
              rowsInput,
              page,
              setPageDetail,
              search,
              gradeInput,
              subjectInput,
              subTopicInput,
              sortOrder,
              sortBy,
              boardInput
            );
            handleConfirmationClose();
          }
        })
        .catch(function (response) {
          // console.log(response);
        });
    }
  }

  const handleConfirmationClose = () => {
    setToggleConfirmation(false);
    setToggleDetails({});
  };

  const addChapterHandler = (
    chapter,
    boardId,
    gradeId,
    subjectId,
    subTopicName,
    file
  ) => { };

  const imageChange = (e) => {
    const formData = new FormData();

    formData.append("file", e.target.files[0]);
    chapters_file_uploader_post(
      setSelectedImage,
      setChapterInputs,
      chapterInputs,
      formData
    );
    window.alert("iiiiii");
  };

  const fetchBoardData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/boards/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setBoardList(response?.data?.data);
  };

  const removeSelectedImage = () => {
    setChapterInputs({
      ...chapterInputs,
      logoUrl: null,
    });
  };

  useEffect(() => {
    setChapterInputs({
      ...chapterInputs,
      logourl: sessionStorage.getItem("logourl"),
    });
    return () => { };
  }, [sessionStorage.getItem("logoUrl")]);
  //cmtd on 12-7-24 bcz of sorting
  // useEffect(() => {
  //   if (search.search === "") {
  //     chapters_controller_getall(
  //       setChapters,
  //       rowsInput,
  //       page,
  //       setPageDetail,
  //       search,
  //       gradeInput,
  //       subjectInput,
  //       subTopicInput,
  //       sortOrder,
  //       sortBy,
  //       boardInput
  //     );
  //   }
  //   return () => { };
  // }, [rowsInput, search, sortOrder, sortBy]);

  useEffect(() => {
    fetchBoardData()
  }, [])

  //cmtd on 12-7-24 bcz of sorting
  useEffect(() => {
    chapters_controller_getall(
      setChapters,
      rowsInput,
      page,
      setPageDetail,
      search,
      gradeInput,
      subjectInput,
      subTopicInput,
      sortOrder,
      sortBy,
      boardInput
    );
  }, [rowsInput, page, sortOrder, sortBy]);

  useEffect(() => {
    if (search !== "") {
      chapters_controller_getall(
        setChapters,
        rowsInput,
        page,
        setPageDetail,
        search,
        gradeInput,
        subjectInput,
        subTopicInput,
        sortOrder,
        sortBy,
        boardInput
      );
    }
    return () => { };
  }, [rowsInput, search, gradeInput, subjectInput, subTopicInput, sortOrder, sortBy, boardInput]);//rowsInput dont come 12/7/24 b4 sort


  useEffect(() => {
    if (subjectInput) {
      subtopics_controller_getall(setSubtopic, subjectInput);
    }
  }, [subjectInput]);

  useEffect(() => {
    gradeListGet(setGrades);
    boards_controller_getall(setBoard);
    subjects_controller_getall(setSubject);
    subtopics_controller_getall(setSubtopic);
    // chapters_controller_getall(setChapters);
    chapters_last_modified_get(setLastModifiedDate);
    // documentType_controller_getall(setDocumenttype);

    return () => { };
  }, []);

  useEffect(() => {
    if (state && state === "CREATION_SUCCESS") {
      setShow(true);
    }
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 1500);
  }, [show]);

  const onHideModal = () => {
    setShowAlert(false);
  }
  const labelStyle = {
    fontSize: "14px",
    "&.Mui-focused": {
      color: "orange",
      fontWeight: 600,
      fontFamily: "Poppins",
      fontSize: "13px",
    },
  };




  return (
    <>
      <div style={{ width: "100%" }}>
        <SchoolAdminBreadcrumb
          dashHome={dashHome}
          sectionName={'Content Admin Dashboard'}
          date={lastModifiedDate.data}
          customstyle={{ top: "85px" }}
        />
        {/* create chapter */}
        <Paper
          style={{
            marginTop: "55px",
            width: "100%",
            padding: "10px"
          }}
        >
          <div style={{ padding: "10px 10px" }} >
            <div>
              {/* search btn row */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Box>
                    <FormControl
                      size="small"
                      sx={{
                        "& .MuiInputLabel-root": {
                          fontSize: "14px !important",
                          opacity: 0.9,
                          lineHeight: 0.9,
                        },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#FFFFFF",
                          borderRadius: "50px",
                          width: "100px",
                          height: "28px",
                        },
                      }}>
                      <InputLabel id="grade-select">
                        Board
                      </InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        sx={{
                          "&:hover": {
                            border: "2px solid #FFA500 !important",
                            background: "rgb(252, 237, 194)",
                            color: "#FFA500"
                          }, color: "#FFA500"
                        }}
                        input={
                          <OutlinedInput
                            sx={{ fontSize: "10px !important" }}
                            label="BoardSel"
                          />
                        }
                        value={boardInput}
                        onChange={(e) => setBoardInput(e.target.value)}
                        MenuProps={MenuProps}
                        IconComponent={(props) => (
                          <ExpandMoreIcon
                            {...props} sx={{ fontSize: "18px", }}
                          />
                        )}
                      >
                        {boardList?.length > 0 ? (
                          boardList?.map((item) => (
                            <MenuItem
                              key={item.id}
                              value={item.id}
                              className={dashboardContentStyles.menu_item}
                              sx={{
                                "&.Mui-selected": {
                                  backgroundColor: '#FFA500',
                                  color: "#FFFFFF"
                                },
                                "&.Mui-selected:hover": {
                                  backgroundColor: '#FFA500',
                                  color: "#000000"
                                },
                                "&:not(.Mui-selected):hover": {
                                  backgroundColor: '#fcedc2',
                                  color: '#000000',
                                },
                              }}
                            >
                              <ListItemText
                                sx={{ overflow: 'hidden !important', }}
                                primary={item.board}
                              />
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem className="cursorDefault">Select</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box>
                    <FormControl
                      size="small"
                      sx={{
                        "& .MuiInputLabel-root": {
                          fontSize: "14px !important",
                          opacity: 0.9,
                          lineHeight: 0.8,
                        },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#FFFFFF",
                          borderRadius: "50px",
                          width: "100px",
                          height: "28px",
                        },
                      }}
                    >
                      <InputLabel id="grade-select">
                        Grade
                      </InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        input={
                          <OutlinedInput
                            sx={{ fontSize: "10px !important" }}
                            label="GradeSel"
                          />
                        }
                        value={gradeInput}
                        onChange={(e) => setGradeInput(e.target.value)}
                        MenuProps={MenuProps}
                        IconComponent={(props) => (
                          <ExpandMoreIcon
                            {...props} sx={{ fontSize: "18px", }}
                          />
                        )}
                        sx={{
                          "&:hover": {
                            border: "2px solid #FFA500 !important",
                            background: "rgb(252, 237, 194)",
                            color: "#FFA500"
                          }, color: "#FFA500"
                        }}
                      >
                        {gradesName?.length > 0 ? (
                          gradesName?.map((item) => (
                            <MenuItem
                              key={item.id}
                              value={item.id}
                              className={dashboardContentStyles.menu_item}
                              sx={{
                                "&.Mui-selected": {
                                  backgroundColor: '#FFA500',
                                  color: "#FFFFFF"
                                },
                                "&.Mui-selected:hover": {
                                  backgroundColor: '#FFA500',
                                  color: "#000000"
                                },
                                "&:not(.Mui-selected):hover": {
                                  backgroundColor: '#fcedc2',
                                  color: '#000000',
                                },
                              }}
                            >
                              <ListItemText
                                sx={{ overflow: 'hidden !important', }}
                                primary={item.grade}
                              />
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem className="cursorDefault">Select</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box>
                    <FormControl
                      size="small"
                      sx={{
                        "& .MuiInputLabel-root": {
                          fontSize: "14px !important",
                          opacity: 0.9,
                          lineHeight: 0.8,
                        },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#FFFFFF",
                          borderRadius: "50px",
                          width: "110px",
                          height: "28px",
                        },
                      }}
                    >
                      <InputLabel id="subject-select">
                        Subject
                      </InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={subjectInput}
                        input={
                          <OutlinedInput
                            sx={{ fontSize: "10px !important" }}
                            label="Select-Subject select"
                          />
                        }
                        onChange={(e) => setSubjectInput(e.target.value)}
                        MenuProps={MenuProps}
                        IconComponent={(props) => (
                          <ExpandMoreIcon
                            {...props} sx={{ fontSize: "18px", }}
                          />
                        )}
                        sx={{
                          "&:hover": {
                            border: "2px solid #FFA500 !important",
                            background: "rgb(252, 237, 194)",
                            color: "#FFA500"
                          }, color: "#FFA500"
                        }}
                      >
                        {subject?.length > 0 ? (
                          subject.map((subject, i) => {
                            return (
                              <MenuItem
                                key={i}
                                value={subject.id}
                                className={dashboardContentStyles.menu_item}
                                sx={{
                                  "&.Mui-selected": {
                                    backgroundColor: '#FFA500',
                                    color: "#FFFFFF"
                                  },
                                  "&.Mui-selected:hover": {
                                    backgroundColor: '#FFA500',
                                    color: "#000000"
                                  },
                                  "&:not(.Mui-selected):hover": {
                                    backgroundColor: '#fcedc2',
                                    color: '#000000',
                                  },
                                }}
                              >
                                <ListItemText
                                  sx={{ overflow: 'hidden !important', }}
                                  primary={subject.subject}
                                />
                              </MenuItem>
                            );
                          })
                        ) : (
                          <MenuItem className="cursorDefault">Select</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box>
                    <FormControl
                      size="small"
                      sx={{
                        "& .MuiInputLabel-root": {
                          fontSize: "14px !important",
                          opacity: 0.9,
                          lineHeight: 0.8,
                        },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#FFFFFF",
                          borderRadius: "50px",
                          width: "110px",
                          height: "28px",
                        },
                      }}
                    >
                      <InputLabel id="subTopic-select">
                        Subtopic
                      </InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        input={
                          <OutlinedInput
                            sx={{ fontSize: "10px !important" }}
                            label="SelSubTopic"
                          />
                        }
                        value={subTopicInput}
                        onChange={(e) => setSubTopicInput(e.target.value)}
                        MenuProps={MenuProps}
                        IconComponent={(props) => (
                          <ExpandMoreIcon
                            {...props} sx={{ fontSize: "18px", }}
                          />
                        )}
                        sx={{
                          "&:hover": {
                            border: "2px solid #FFA500 !important",
                            background: "rgb(252, 237, 194)",
                            color: "#FFA500"
                          }, color: "#FFA500"
                        }}
                      >
                        {subtopic?.length > 0 ? (
                          subtopic.map((subtopic, i) => {
                            return (
                              <MenuItem
                                key={i}
                                value={subtopic.id}
                                className={dashboardContentStyles.menu_item}
                                sx={{
                                  "&.Mui-selected": {
                                    backgroundColor: '#FFA500',
                                    color: "#FFFFFF"
                                  },
                                  "&.Mui-selected:hover": {
                                    backgroundColor: '#FFA500',
                                    color: "#000000"
                                  },
                                  "&:not(.Mui-selected):hover": {
                                    backgroundColor: '#fcedc2',
                                    color: '#000000',
                                  },
                                }}
                              >
                                <ListItemText primary={subtopic.subTopic} />
                              </MenuItem>
                            );
                          })
                        ) : (
                          <MenuItem className="cursorDefault">Select</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <TextField
                    type={"search"}
                    name="search"
                    // type='search'
                    sx={{
                      "& .MuiInputBase-root": {
                        width: "247px !important",
                        height: "28px !important",
                        borderRadius: "50px !important",
                        background: "#fff !important",
                        lineHeight: "21px !important",
                        opacity: 1,
                        font: "normal normal normal 13px/20px Poppins !important",
                        borderColor: "red"
                      },
                    }}
                    // style={{width:'14vw' , height : '12vh'}}
                    placeholder={"Search...."}
                    onChange={handleSearch}
                    Value={search.search}
                    InputProps={{
                      endAdornment: (
                        <IconButton>
                          <img
                            src={searchIcn}
                            alt="no img"
                            style={{
                              verticalAlign: "top",
                              fontSize: "large",
                            }}
                            aria-hidden="true"
                          />
                        </IconButton>
                      ),
                    }}
                  />
                  <div>
                    <Link
                      className={dashboardContentStyles.add_Chapter_Btn}
                      style={{ textDecoration: "none" }}
                      to="/dashboard/CreateChapter"
                    >
                      <img src={add_icon} />
                      <div>ADD CHAPTER</div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* table */}
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer
              sx={{
                overflow: "auto",
              }}
            >
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader={true}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={dashboardContentStyles.table_head}>
                      #
                    </TableCell>
                    <TableCell
                      align="left"
                      className={dashboardContentStyles.table_head}
                    >
                      Chapter
                      <img
                        style={{
                          verticalAlign: "middle",
                          paddingLeft: "3px",
                          marginBottom: "2px",
                        }}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("chapter");
                          // await initData(!sortOrder, "id");

                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      className={dashboardContentStyles.table_head}
                    >
                      Board
                      <img
                        style={{
                          verticalAlign: "middle",
                          paddingLeft: "3px",
                          marginLeft: "5px",
                          marginBottom: "2px",
                        }}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("board");
                          // await initData(!sortOrder, "id");

                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      Grade
                      <img
                        style={{
                          verticalAlign: "middle",
                          marginLeft: "5px",
                          paddingLeft: "3px",
                          marginBottom: "2px",
                        }}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("grade");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      Subject
                      <img
                        style={{
                          verticalAlign: "middle",
                          marginLeft: "5px",
                          paddingLeft: "3px",
                          marginBottom: "2px",
                        }}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("subject");

                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      Subtopic
                      <img
                        style={{
                          verticalAlign: "middle",
                          marginLeft: "5px",
                          paddingLeft: "3px",
                          marginBottom: "2px",
                        }}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("subTopic");

                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      Status
                      <img
                        style={{
                          verticalAlign: "middle",
                          marginLeft: "5px",
                          paddingLeft: "3px",
                          marginBottom: "2px",
                        }}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("active");

                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    ></TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      Operation
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{

                }}>
                  {chapters?.length > 0 ? (
                    chapters.map((elem, i) => (
                      <TableRow sx={{
                        "&:nth-child(even)": {
                          background: "#F5F5F8"
                        }, "&:hover": {
                          border: "10px solid red !important",
                          background: "#fff !important",
                        }
                      }}>
                        {/* index */}
                        <TableCell className={chapterStyles.table_cell}>
                          {(page - 1) * rowsPerPage + (i + 1)}
                        </TableCell>
                        {/* index */}
                        <TableCell className={`${chapterStyles.table_cell} cursorDefault`}>
                          <Tooltip title={elem.chapter} arrow>
                            <span>
                              {elem.chapter.length > 28
                                ? `${elem.chapter.substring(0, 28)}...`
                                : elem.chapter}
                            </span>
                          </Tooltip>
                        </TableCell>
                        {/* index */}
                        <TableCell
                          className={chapterStyles.table_cell}
                          align="center"
                        >
                          {elem.board}
                        </TableCell>
                        {/* index */}
                        <TableCell
                          className={chapterStyles.table_cell}
                          align="center"
                        >
                          {elem.grade}
                        </TableCell>
                        {/* index */}
                        <TableCell
                          className={chapterStyles.table_cell}
                          align="center"
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            {elem.subject}
                          </div>
                        </TableCell>
                        {/* index */}
                        <TableCell
                          className={chapterStyles.table_cell}
                          size="small"
                          align="center"
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            {elem.subTopic}
                            {/* {elem?.subTopics?.length > 0 ? (
                              elem.subTopics.map((data, i) => (
                                <p style={{ color: "black" }}>
                                  {(i ? "," : "") + data.subTopic}
                                </p>
                              ))
                            ) : (
                              <p>-</p>
                            )} */}
                          </div>
                        </TableCell>
                        {/* active btn */}
                        <TableCell className={chapterStyles.table_cell}>
                          <button
                            className={chapterStyles.green_btn}
                            style={
                              elem.active
                                ? { backgroundColor: "green" }
                                : { backgroundColor: "red" }
                            }
                            onClick={(e) => switchHandler(elem)}
                          >
                            {elem.active ? "Active" : "In-Active"}
                          </button>
                        </TableCell>
                        <TableCell
                          className={chapterStyles.table_cell}
                          align="center"
                        >
                          <div style={{ display: "flex" }}>
                            {/* <i class="fa-solid fa-rotate" style={{ fontSize: '1.3vw', color: 'grey', margin: 'auto 5px' }}></i> */}
                            <i
                              className="bi bi-pencil"
                              onClick={(e) => {
                                if (!elem.active) {
                                  setShowAlert(true);
                                  setAlertMessage(`You cannot Edit the In-Active chapter, 
                                  For the Edit You have to first Active the Chapter`);
                                } else {
                                  e.preventDefault();
                                  var chapterId = elem.id;
                                  navigate("/dashboard/CreateChapter", {
                                    state: "edit",
                                  });
                                  sessionStorage.setItem("chapterId", chapterId);
                                  // chapters_edit_getby_id(setChapterInputs)
                                }
                              }}
                              style={{
                                color: "white",
                                backgroundColor: "orange",
                                padding: "0.3vw",
                                borderRadius: "50%",
                              }}
                            ></i>
                          </div>
                        </TableCell>
                        {/* index */}
                        <TableCell
                          align="right"
                          className={chapterStyles.table_cell}
                          style={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              navigate("/dashboard/AddTeacherFiles");
                              var chapterId = elem.id;
                              sessionStorage.setItem("chapterId", chapterId);
                            }}
                            style={{
                              backgroundColor: "transparent",
                              color: "black",
                              border: "1px solid",
                            }}
                            className={chapterStyles.white_btn}
                          >
                            <AddCircleOutlineOutlinedIcon
                              style={{
                                marginRight: "6px",
                                height: "16px",
                                width: "16px",
                              }}
                            />
                            Add Teacher Files
                          </button>

                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              navigate("/dashboard/AddStudentFiles");
                              var chapterId = elem.id;
                              sessionStorage.setItem("chapterId", chapterId);
                            }}
                            style={{
                              backgroundColor: "transparent",
                              color: "black",
                              border: "1px solid",
                            }}
                            className={chapterStyles.white_btn}
                          >
                            <AddCircleOutlineOutlinedIcon
                              style={{
                                marginRight: "6px",
                                height: "16px",
                                width: "16px",
                              }}
                            />
                            Add Student Files
                          </button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <td
                      colSpan="10"
                      style={{
                        height: "25rem",
                        textAlign: "center",
                        color: "grey",
                      }}
                    >
                      {" "}
                      NO DATA AVAILABLE{" "}
                    </td>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {/* pagination container - Start */}
            <div className={chapterStyles.pagination} style={{ width: "100%" }}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <span className={chapterStyles.rows}>
                  <label for="rows">Rows per page</label>
                  <select
                    className="cursorPointer"
                    name="rows"
                    id="rows"
                    value={rowsInput.rows}
                    onChange={handlerRowsInputs}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                  </select>
                </span>
              </form>
              <span>
                <Pagination1
                  pageDetail={pageDetail}
                  handleChange={handleChange}
                />
              </span>
            </div>
            {/* pagination container - End */}
          </Paper>
        </Paper>
        <div
          className="box animate fadeInUp one"
          style={
            show
              ? {
                color: "red",
                position: "absolute",
                right: "10px",
                top: "10px",
              }
              : { display: "none" }
          }
        >
          <TopRightPopup
            style={{ color: "red", position: "absolute", right: "10px" }}
          />
          <ConfirmationModal
            open={toggleConfirmation}
            confirmationmessage={toggleMessage}
            close={handleConfirmationClose}
            updateToggle={updateToggle}
          />
          <DeleteBlanksModal
            open={showAlert}
            close={onHideModal}
            alert={alertMessage}
          />
        </div>
      </div>
    </>
  );
};

export default UploadContent;
