import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import dashboardContentStyles from "./../css/dashboardContent.module.css";

// import contentApprovalStyles from './../css/fileStatus.module.css'
import contentApprovalStyles from "../css/contetnApproval.module.css";

import sharestyle from "../css/shareModal.module.css"; //share modal css
import tickImage from "./../img/tick-inside-a-circle.svg";
import GradesControllerGetall from "../API_CONTROLLER/grades-controller/grades_controller_getall";
import boards_controller_getall from "../API_CONTROLLER/boards-controller/boards_controller_getall";
import subjects_controller_getall from "../API_CONTROLLER/subject_controller/subjects_controller_getall";
import subtopics_controller_getall from "../API_CONTROLLER/subtopic-controller/subtopics_controller_getall";
import chapters_last_modified_get from "../API_CONTROLLER/chapters-controller/chapters_last_modified_get";
import dashHome from "../../../src/img/dashHome.svg";

// import fileStatus_approved_get from '../API_CONTROLLER/chapters-controller/chapters_controller_get';
import fileStatus_approved_get from "../API_CONTROLLER/File-status-controller/conceptTree_approved_get";
import chapter_reason_by_id_get from "../API_CONTROLLER/chapters-controller/chapters_reason_by_id_get";
import Pagination1 from "./pagination1";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import BlueButton from "../../components/common/BlueButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import WhiteAddButton from "../../components/common/WhiteButton";
import InactiveButton from "../../components/common/InactiveButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import searchIcn from "../../../src/img/search.svg";

// sort
import sortIcon from "../../../src/pages/img/sort_1.png";

const ConceptTreeApproval = () => {
  const [gradesName, setGradesName] = useState({});
  const [board, setBoard] = useState({});
  const [subject, setSubject] = useState({});
  const [subtopic, setSubtopic] = useState({});
  const [chapters, setChapters] = useState({});
  const [lastmodifieddate, setLastModifiedDate] = useState("");

  const [boardInput, setBoardInput] = useState("");
  const [gradeInput, setGradeInput] = useState("");
  const [subjectInput, setSubjectInput] = useState("");
  const [subTopicInput, setSubTopicInput] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterValue, setFilterValue] = useState();
  const [showModalShare, setShowModalShare] = useState(false);
  const token = sessionStorage.getItem("token");

  // sort
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState(false);

  const navigate = useNavigate();

  const handleShareModal = () => {
    setShowModalShare(true);
  };

  const handleShareModalOk = (e) => {
    setShowModalShare(false);
  };
  // searching
  // pagination starts
  const [rowsInput, setRowsInputs] = useState({ rows: 10 });
  const [page, setPage] = useState(0);
  const [pageDetail, setPageDetail] = useState({});
  const handleChange = (e, value) => {
    setPage(value);
  };

  const handlerRowsInputs = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  // pagination ends

  const [search, setSearch] = useState({
    search: "",
  });
  const handleSearch = (e) => {
    e.preventDefault();
    const search = {
      search: e.target.value,
    };
    fileStatus_approved_get(
      setChapters,
      rowsInput,
      page,
      setPageDetail,
      search,
      subjectInput
    );
  };

  useEffect(() => {
    fileStatus_approved_get(
      setChapters,
      rowsInput,
      page,
      setPageDetail,
      search,
      subjectInput,
      sortBy,
      sortOrder
    );
  }, [rowsInput, page, subjectInput, sortBy, sortOrder]);
  const [subjectName, setSubjectName] = useState(null);

  useEffect(() => {
    if (subjectInput) {
      const temp = subject.find((v) => v.id == subjectInput);

      setSubjectName(temp.subject);
    }
  }, [subjectInput]);

  useEffect(() => {
    GradesControllerGetall(setGradesName);
    boards_controller_getall(setBoard);
    subjects_controller_getall(setSubject);
    subtopics_controller_getall(setSubtopic);
    chapters_last_modified_get(setLastModifiedDate);
    return () => { };
  }, []);
  const refresh = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concepts/sub-roots-mapping?pageSize=50&sortOrder=${sortOrder}&sortBy=${sortBy}&subjectId=${subjectInput}&reviewStatus=APPROVED`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setChapters(response.data.data.data);
  };
  const [searchvalue, setSearchValue] = useState("");
  const globalsearch = async (searchkey) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concepts/sub-roots-mapping?pageSize=50&sortOrder=${sortOrder}&sortBy=${sortBy}&subjectId=${subjectInput}&reviewStatus=APPROVED&search=${searchkey}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setChapters(response.data.data.data);
  };
  useEffect(() => {
    if (!searchvalue || searchvalue == "") {
      refresh();
    }
  }, [searchvalue]);

  return (
    <>
      <div style={{ width: "100%" }}>
        {/* article start  */}
        <article>

          {/* <div className={dashboardContentStyles.dashboard_link}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />{" "}
            </span>
            {"  "}
            <span className={dashboardContentStyles.link_text}>Home</span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            {"  "}
            <a>Concept Approval</a>
          </div> */}

          <div className={dashboardContentStyles.dashboard_link}>

            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                className={dashboardContentStyles.az_menu_icon}
              />
            </span>
            <span
              className={dashboardContentStyles.az_menu_text}
              style={{ cursor: 'pointer' }}
              onClick={() => navigate("/dashboard/template")}
            >
              Home
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span
              className={dashboardContentStyles.az_selected_menu_text}
            >
              Concept Approval
            </span>
          </div>

          <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>
              Last Update: {lastmodifieddate.data}
            </p>
          </div>
        </article>
        {/* article end  */}
        {/* drop down search row start  */}
        <div
          className={contentApprovalStyles.flex}
          style={{ marginTop: "55px", position: "relative" }}
        >
          <div className={contentApprovalStyles.flex}>
            <i
              style={{ color: "grey", fontSize: "36px", margin: "10px" }}
              class="fa-solid fa-user-gear"
            ></i>
            <p
              style={{ color: "grey ", fontSize: "1.2vw", margin: "auto 10px" }}
            >
              Concept Tree Approval List
            </p>
          </div>

          <Box style={{ margin: "auto 8px" }} sx={{ minWidth: 180 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-select-small">Select subject</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={subjectInput}
                label="Subject"
                onChange={(e) => setSubjectInput(e.target.value)}
                style={{ background: "#fff", borderRadius: 150 }}
              //   MenuProps={MenuProps}
              >
                <MenuItem value="">All</MenuItem>
                {subject.length > 0 ? (
                  subject.map((subject, i) => {
                    return (
                      <MenuItem key={i} value={subject.id}
                        id={`az-content-subjectdropdown-${subject.id}`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: '#FFA500',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#FFA500',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}>
                        <ListItemText primary={subject.subject} />
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem>Select</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>

          <TextField
            sx={{
              width: { sm: 200, md: 300 },
              "& .MuiInputBase-root": {
                width: "247px !important",
                height: "32px !important",
                borderRadius: "50px !important",
                background: "#fff !important",
                lineHeight: "21px !important",
                font: "normal normal normal 13px/20px Poppins !important",
                opacity: 1,
              },
            }}
            style={{ position: "absolute", right: "20px", width: "290px" }}
            type={"search"}
            placeholder={"Search...."}
            name="search"
            autoComplete="off"
            onChange={(event) => {
              setSearchValue(event.target.value);
              globalsearch(event.target.value);
            }}
            Value={searchvalue}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <img
                    src={searchIcn}
                    style={{
                      verticalAlign: "top",
                      fontSize: "large",
                    }}
                    aria-hidden="true"
                  />
                </IconButton>
              ),
            }}
          />
        </div>
        {/* drop down search row  end  */}

        {/* content start  */}
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            marginTop: " 15px",
            marginLeft: "15px",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "49px",
              backgroundColor: "white",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0px",
                display: "flex",
                width: "100%",
              }}
            >
              <p
                style={{
                  width: "33%",
                  textAlign: "center",
                  padding: "8px",
                  borderRight: "1px solid lightgrey",
                  cursor: 'pointer'
                }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/dashboard/ConceptTreeApprovalPending");
                }}
              >
                Pending
              </p>

              <p
                style={{
                  width: "33%",
                  textAlign: "center",
                  padding: "8px",
                  borderRight: "1px solid lightgrey",
                  cursor: 'pointer'
                }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/dashboard/ConceptTreeApprovalRejected");
                }}
              >
                Rejected
              </p>
              <p
                style={{
                  width: "33%",
                  textAlign: "center",
                  padding: "8px",
                  color: "#FD8C00",
                  borderRight: "1px solid lightgrey",
                  borderBottom: "7px solid #FD8C00",
                  cursor: 'pointer'
                }}
              >
                Approved
              </p>
            </div>
          </div>
        </Paper>

        {/* content start  */}

        <Paper
          sx={{ width: "100%", overflow: "hidden" }}
          style={{ marginLeft: "15px" }}
        >
          <TableContainer sx={{ maxHeight: 500, overflow: "auto" }}>
            <Table
              sx={{ minWidth: 650 }}
              stickyHeader={true}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow
                >
                  <TableCell
                    style={{
                      color: "white",
                      fontWeight: 600,
                      background: "#403e75",
                    }}
                  >
                    #
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: "white",
                      fontWeight: 600,
                      background: "#403e75",
                    }}
                  >
                    Concept
                    <img
                      style={{
                        verticalAlign: "middle",
                        marginLeft: "25px",
                        marginBottom: "2px",
                      }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("concept");
                        // await initData(!sortOrder, "id");
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "white",
                      fontWeight: 600,
                      background: "#403e75",
                    }}
                  >
                    Sub-Concept
                    <img
                      style={{
                        verticalAlign: "middle",
                        marginLeft: "25px",
                        marginBottom: "2px",
                      }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("subConcept");
                        // await initData(!sortOrder, "id");
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "white",
                      fontWeight: 600,
                      background: "#403e75",
                    }}
                  >
                    Root-Concept
                    <img
                      style={{
                        verticalAlign: "middle",
                        marginLeft: "25px",
                        marginBottom: "2px",
                      }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("rootConcept");
                        // await initData(!sortOrder, "id");
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "white",
                      fontWeight: 600,
                      background: "#403e75",
                    }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {chapters && chapters.length > 0 ? (
                  chapters?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                    ?.map((elem, i) => (
                      <TableRow
                        style={{ backgroundColor: (i % 2 === 0) ? "white" : "#f5f5f8" }}
                        className={contentApprovalStyles.table_rw}>
                        {/* index */}
                        <TableCell className={contentApprovalStyles.table_cell}>
                          {page * rowsPerPage + (i + 1)}
                        </TableCell>
                        {/* index */}
                        <TableCell className={contentApprovalStyles.table_cell}>
                          {elem.concept}
                        </TableCell>
                        {/* index */}
                        <TableCell
                          className={contentApprovalStyles.table_cell}
                          align="center"
                        >
                          {elem?.subConcepts?.length > 0 &&
                            elem.subConcepts.map((v, i) => {
                              return (
                                <span>
                                  {v.subConcept}
                                  {i == elem.subConcepts.length - 1 ? "" : ","}
                                </span>
                              );
                            })}
                        </TableCell>
                        {/* index */}
                        <TableCell
                          className={contentApprovalStyles.table_cell}
                          align="center"
                        >
                          {elem?.rootConceptResponses?.length > 0 &&
                            elem.rootConceptResponses.map((v, i) => {
                              return (
                                <span>
                                  {v.rootConcept}
                                  {i == elem.rootConceptResponses.length - 1
                                    ? ""
                                    : ","}
                                </span>
                              );
                            })}
                        </TableCell>

                        {/* active btn */}

                        <TableCell
                          className={contentApprovalStyles.table_cell}
                          align="center"
                        >
                          <button
                            className={contentApprovalStyles.orange_btn}
                            onClick={(e) => {
                              e.preventDefault();
                              let previewDetails = {
                                concept: elem.concept,
                                subject: subjectName,
                              };
                              localStorage.setItem(
                                "previewDetails",
                                JSON.stringify(previewDetails)
                              );
                              localStorage.setItem("status", "approved");
                              navigate(`/dashboard/ConceptTreeView/${elem?.id}`);
                            }}
                          >
                            Preview
                          </button>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <td
                    colSpan="10"
                    style={{ height: "25rem", textAlign: "center" }}
                  >
                    {" "}
                    NO DATA AVAILABLE{" "}
                  </td>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* pagination container - Start */}
          <TablePagination
            style={{
              bottom: "0",
              width: "80.5%",
              position: "fixed",
              backgroundColor: "white",
            }}
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={chapters?.length || 0}
            page={page}
            onPageChange={handleChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handlerRowsInputs}
            showFirstButton={true}
            showLastButton={true}
          />
          {/* pagination container - End */}
        </Paper>

        <div
          className={contentApprovalStyles.flex_container}
          id={contentApprovalStyles.modal_id}
          style={
            showModalShare
              ? { display: "flex", zIndex: "10" }
              : { display: "none" }
          }
        >
          <div
            className={contentApprovalStyles.main_container}
            id={contentApprovalStyles.modal_content}
          >
            <div style={{ padding: "20px 23px 25px", position: "relative" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <p style={{ fontSize: "16px" }}>Chapter name</p>
                <p
                  style={{
                    marginLeft: "auto",
                    fontSize: "16px",
                    color: "black",
                    marginRight: "36px",
                  }}
                >
                  15 files Rejected
                </p>
                <p
                  onClick={handleShareModalOk}
                  style={{
                    position: "absolute",
                    right: "15px",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                >
                  X
                </p>
              </div>
              <div style={{ marginTop: "20.5px" }}>
                <p
                  style={{
                    fontSize: "13px",
                    marginRight: "auto",
                    display: "block",
                  }}
                >
                  Reason
                </p>
                <p style={{ fontSize: "14px", color: "black" }}>
                  {" "}
                  The href attribute requires a valid value to be accessible.
                  Provide a valid, navigable address as the href value. If you
                  cannot provide a valid href, but still need the element to
                  resemble a link, use a butto{" "}
                </p>
              </div>
              <div
                style={{ position: "absolute", left: "34%", marginTop: "12px" }}
              >
                <button
                  onClick={handleShareModalOk}
                  style={{
                    padding: "10px 32px",
                    borderRadius: "21px",
                    backgroundColor: "#FD8C00",
                    color: "white",
                    border: "none",
                  }}
                >
                  UPLOAD AGAIN
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* own modal end  */}
      </div>
    </>
  );
};

export default ConceptTreeApproval;
