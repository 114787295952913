import React, { useEffect, useState } from "react";
import axios from "axios";
import CSPForm from "./../css/csppg34.module.css";
import { useNavigate } from "react-router-dom";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import branchchangerstyle from "../css/School_Branch_Changer.module.css";
import Branches_Changer_School_Id from "../API/branch-controller/Branches_Changer_School_Id";
import Pagination1 from "./pagination1";
import StudentsControllerGet from "../API/student-controller/userStudentsGet";
import StudentsControllerIdGet from "../API/student-controller/userStudentsIdGet";
import GradesControllerGetall from "../API_CONTROLLER/grades-controller/grades_controller_getall";
import SectionsControllerGetall from "../API_CONTROLLER/section-Controller/section_controller_getall";
import StudentControllerIdPut from "../API/student-controller/user_student_Id_put";
import School_name from "./school_name"; //school name changer
import GetLastModifiedAt from "../API/student-controller/GetLastModifiedAt";
import gradeSectionGet from "../API/grade-section-mapping-controller/grade_section_get";
import sectionFromgrade_get from "../API_CONTROLLER/section-Controller/sectionFromgrade_get";
import Studentdynamic from "./../css/student32.module.css";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";
import studentIcn from "../../../src/img/student-list.png";
import Success_OK_Modal_SubjectMapping from "./Success_OK_Modal_SubjectMapping";
import change_student_DeActive from "../API/student-controller/change_student_De-Active";
import ClearIcon from "@mui/icons-material/Clear";
import SchoolAdminBreadcrumb from "./SchoolAdminBreadcrumb";
import {
  IconButton,
  TextField,
  Button
} from "@mui/material";
// sort
import sortIcon from "../../../src/pages/img/sort_1.png";
import { FlashOnRounded } from "@material-ui/icons";

const CSP = (props) => {
  const StudentList = props.StudentList;

  const [changeStudentProfile, setChangeStudentProfile] = useState({
    sectionId: "",
    gradeId: "",
  });

  const handlerChangeProfile = (e) => {
    setChangeStudentProfile({
      ...changeStudentProfile,
      [e.target.name]: e.target.value,
    });

    getSectionFromGrade(e.target.value, setSectionNameTo);
  };
  const [gradeSection, setGradeSection] = useState({});
  const [gradeInput, setGradeInput] = useState("");
  const [sectionInput, setSectionInput] = useState("");
  const [isSelected, setSelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [optionSel, setOptionSelected] = useState("");
  const [isYearAssesment, setYearAssesment] = useState(false);
  const [isSearch, setisSearch] = useState(true);
  const [totalele, setTotalelements] = useState(0);
  const [searchInput, setSearchInput] = useState({
    search: "",
  });

  const [studentListData, setStudentListData] = useState([]);
  const [studentIdData, setStudentIdData] = useState({});
  const [gradesName, setGradesName] = useState({});
  const [sectionName, setSectionName] = useState({});
  const [sectionNameTo, setSectionNameTo] = useState({});
  function gradesGet(setGradesName) {
    let token = sessionStorage.getItem("token");
    let schoolid = sessionStorage.getItem("schoolid");
    let branchid = sessionStorage.getItem("branchid");
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section/grades?branchId=${branchid}&schoolId=${schoolid}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((json) => {
        setGradesName(json.data.data);
      })
      .catch((err) => console.log(err));
  }

  // async function sectionGet(setGradeSection) {
  //   let token = sessionStorage.getItem("token");
  //   let schoolid = sessionStorage.getItem("schoolid");
  //   let branchid = sessionStorage.getItem("branchid");
  //   // let gradeId = sessionStorage.getItem("branchId");

  //     // let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section?schoolId=${schoolid}&branchId=${branchid}`,
  //     let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section/sections?gradeId=${gradeIdGet}&branchId=${branchid}&schoolId=${schoolid}`,

  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((response)=>{
  //       if (response.data.length !== 0) {
  //
  //         setGradeSection(response.data.data);
  //       }
  //     })
  //     .catch(err=>console.log(err))
  // }

  useEffect(() => {
    gradesGet(setGradesName);

    // gradeSectionGet(setGradeSection);
    // SectionsControllerGetall(setSectionsName);
    return () => { };
  }, []);



  // search starts
  const [updatedData, setUpdatedData] = useState([]);
  const [isFirst, setFirst] = useState(true);

  useEffect(() => {
    if (studentListData.length > 0 && isFirst) {
      setUpdatedData(studentListData);
      setFirst(false);
    }

  }, [studentListData])
  const [search, setsearch] = useState({
    search: "",
  });
  const [showClear, setShowClear] = useState(false);
  const [query, setQuery] = useState("");
  const handleClear = () => {
    setQuery("");
    setShowClear(false);
    StudentsControllerGet(
      setStudentListData,
      setPageDetail,
      page,
      rowsInput,
      search,
      gradeInput,
      sectionInput
    );
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value.toLowerCase();
    if (inputValue) {
      let result = [...totalele];
      result = [...totalele]?.filter((data) => {
        return data?.userName?.toLowerCase().includes(inputValue);
      });
      setStudentListData(result)
    } else {
      StudentsControllerGet(
        setStudentListData,
        setPageDetail,
        page,
        rowsInput,
        search,
        gradeInput,
        sectionInput
      );
    }
    setQuery(inputValue);
    setShowClear(inputValue !== "");
  };
  useEffect(() => {
    if (isSearch) {
      searchingData()
    }
  }, [])

  const searchingData = async () => {
    setisSearch(false);
    let token = sessionStorage.getItem("token");

    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/students?pageNumber=0&pageSize=50&sortBy=${sortBy || "id"}&sortOrder=${sortOrder || false}&branchId=${sessionStorage.getItem("branchid")}&schoolId=${sessionStorage.getItem("schoolid")}&search=${search.search}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        }
      },)
    const totlaele = result?.data?.data?.totalElements
    let response = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/students?pageNumber=0&pageSize=${totlaele}&sortBy=${sortBy || "id"}&sortOrder=${sortOrder || false}&branchId=${sessionStorage.getItem("branchid")}&schoolId=${sessionStorage.getItem("schoolid")}&search=${search.search}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        }
      },)
    const totalData = response?.data?.data?.data
    setTotalelements(totalData);
  }
  const handleGradesInput = (e) => {
    setGradeInput(e.target.value);
    setSectionInput("")
    //setGrade(e.target.value);
    getSectionFromGrade(e.target.value, setSectionName);
  };
  const handleSections = (e) => {
    const sectionSelected = e.target.value;
    setSectionInput(sectionSelected);
  };
  const getSectionFromGrade = (gradeId, setSectionName) => {
    let schoolid = sessionStorage.getItem("schoolid");
    let branchid = sessionStorage.getItem("branchid");
    let token = sessionStorage.getItem("token");
    // let gradeId = sessionStorage.getItem("gradeId")

    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section/sections?gradeId=${gradeId}&branchId=${branchid}&schoolId=${schoolid}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setSectionName(response?.data.data);
      })
      .catch((err) => console.log(err));
  };

  // search ends

  const editStudentProfile = () => { };

  // pagination starts
  const [rowsInput, setRowsInputs] = useState({
    rows: 10,
  });
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const handlerRowsInputs = (e) => {
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
    setPage(1);
  };
  const [pageDetail, setPageDetail] = useState({});
  const [modifiedDate, setModifiedDate] = useState({});
  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);

  const [branchList, setBranchList] = useState([]);

  const [schoolDetails, setSchoolDetails] = useState({
    branch: sessionStorage.getItem("branchid"),
  });

  const handlerSchoolDetailsInputs = (e) => {
    setStudentIdData({
      sectionId: "",
      gradeId: "",
    });
    setSectionName([])
    setGradeInput(null);
    setSectionInput(null);
    setStudentListData([]);
    setChangeStudentProfile({
      gradeId: "",
      sectionId: "",
    });
    sessionStorage.setItem("branchid", e.target.value);
    setSchoolDetails({
      ...schoolDetails,
      [e.target.name]: e.target.value,
      schoolName: e.target.id,
    });
    gradesGet(setGradesName);
  };

  useEffect(() => {
    var branchid = schoolDetails.branch;
    sessionStorage.setItem("branchid", branchid);
    Branches_Changer_School_Id(setBranchList);
    return () => { };
  }, [schoolDetails]);
  const [successCreateModal, setSuccessCreateModal] = useState(false);
  const closeSuccessModal = () => {
    setSuccessCreateModal(false)
  }


  const [inValid, setinValid] = useState(false);

  const handleSubmit = (e) => {


    if (optionSel != 'De-Activate') {
      if (!changeStudentProfile.sectionId || !changeStudentProfile.gradeId || !optionSel || (changeStudentProfile.sectionId == studentIdData.sectionId && changeStudentProfile.gradeId == studentIdData.gradeId)) {
        setinValid(true);
        return null;
      }
      StudentControllerIdPut(studentIdData, changeStudentProfile, optionSel, isYearAssesment, selectedItems);
      // StudentsControllerGet(
      //   setStudentListData,
      //   setPageDetail,
      //   page,
      //   rowsInput,
      //   search,
      //   gradeInput,
      //   sectionInput
      // );
      setStudentListData([]);
      setSelectedItems([])
      setChangeStudentProfile({
        sectionId: "",
        gradeId: "",
      });
      setStudentIdData({
        sectionId: "",
        gradeId: "",
      });
      setGradeInput(null);
      setSectionInput(null);
      setSelected(false);
      setSuccessCreateModal(true);
      setinValid(false);
    } else if (optionSel == 'De-Activate') {
      change_student_DeActive(selectedItems)
      setStudentListData([]);
      setSelectedItems([])
      setStudentIdData({
        sectionId: "",
        gradeId: "",
      });
      setSuccessCreateModal(true);
      setinValid(false);
    }
  }
  // sort
  const [sortOrder, setSortOrder] = useState(true);
  const [sortBy, setSortBy] = useState("userName");


  useEffect(() => {
    if (gradeInput && sectionInput) {
      StudentsControllerGet(
        setStudentListData,
        setPageDetail,
        page,
        rowsInput,
        search,
        gradeInput,
        sectionInput
      );
      setChangeStudentProfile({
        sectionId: "",
        gradeId: "",
      });
      setinValid(false);
    };
  }, []);
  // sort



  useEffect(() => {
    if (gradeInput && sectionInput) {
      StudentsControllerGet(
        setStudentListData,
        setPageDetail,
        page,
        rowsInput,
        search,
        gradeInput,
        sectionInput,
        sortBy,
        sortOrder
      );
      setFirst(true);
    }
    return () => { };
  }, [
    rowsInput,
    page,
    sessionStorage.getItem("branchid"),
    sortOrder,
    gradeInput,
    sectionInput,
  ]);

  const handleCheckboxSelecet = (event) => {
    const temp = event.target.checked;
    if (temp) {
      setSelectedItems(
        studentListData.length > 0 && studentListData.map((v) => v.id)
      );
    } else {
      setSelectedItems([]);
    }
    setSelected(temp);
  };

  const individualItemPush = (event, id) => {
    console.log(id);
    const temp = event.target.checked;
    if (temp) {
      setSelectedItems([...selectedItems, id]);
    } else {
      const tem = selectedItems.filter((v) => v != id);
      setSelectedItems(tem);
    }
  };

  const navigate = useNavigate();
  return (
    <>

      <SchoolAdminBreadcrumb
        dashHome={dashHome}
        sectionName={'Student Details'}
        date={modifiedDate.data}
        isSecondOne={false}
      />
      <article className={CSPForm.article_csp34}>
        <div style={{ marginTop: "5px" }} className={branchchangerstyle.Grid_school_name_branch_teacherid}>
          <div className={branchchangerstyle.school_name_teacherid}>
            <School_name />
          </div>
          <select
            className={branchchangerstyle.branchselect_Dropdown}
            name="branch"
            id="branch"
            value={schoolDetails.branch}
            onChange={handlerSchoolDetailsInputs}
          >
            {branchList.length > 0 ? (
              branchList.map((branchList) => {
                return (
                  <option value={branchList.branchId}>
                    {branchList.branch}
                  </option>
                );
              })
            ) : (
              <option className={branchchangerstyle.option} value=""></option>
            )}
          </select>
        </div>

        <div className={CSPForm.grid_container_header_plan_csp34}>
          <div
            className={CSPForm.item_plan1_csp34 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/branchplan");
            }}
          >
            Plan
          </div>
          <div
            className={CSPForm.item_plan2_csp34 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/branchsection");
            }}
          >
            Sections
          </div>
          <div
            className={CSPForm.item_plan3_csp34 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/teacherId");
            }}
          >
            Academic Staff
          </div>
          <div
            className={CSPForm.item_plan4_csp34 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/student");
            }}
          >
            Students
          </div>
          <div
            className={CSPForm.item_plan5_csp34 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/csp");
            }}
          >
            Change Student Profile
          </div>
          <div
            className={CSPForm.item_plan6_csp34 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/tokenList");
            }}
          >
            Tokens
          </div>
          <div
            className={CSPForm.item_plan7_csp34 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/logo");
            }}
          >
            Branch Logo
          </div>
        </div>

        <div className={CSPForm.grid_container_col}>
          <div className={CSPForm.grid_item_col1}>
            <div className={CSPForm.grid_container_row}>
              <label
                className={CSPForm.grid_content_label}
                style={{ color: "orange" }}
              >
                From
              </label>
              <div className={CSPForm.grid_item_row}>
                <div className={CSPForm.input_element_csp34}>
                  <select
                    className={`${CSPForm.form_select_element_csp34 + ' ' + 'az_select_1'} cursorPointer`}
                    name="Grade"
                    id="Grade"
                    onChange={(e) => {
                      setSectionName([]);
                      //setSectionInput(null)
                      setStudentIdData({
                        ...studentIdData,
                        gradeId: e.target.value,
                        sectionId: null
                      });

                      handleGradesInput(e);
                    }}
                    value={studentIdData.gradeId}
                  >
                    <option value="">Select</option>
                    {/* <option value="">Grade </option> */}
                    {gradesName.length > 0 ? (
                      gradesName.map((gradesName) => {
                        return (
                          <option
                            className={CSPForm.option}
                            value={gradesName.id}
                          >
                            {gradesName.grade}
                          </option>
                        );
                      })
                    ) : (
                      <option className={CSPForm.option} value="">
                        Select
                      </option>
                    )}
                  </select>
                  {/* <label for="Grade">*Grade</label> */}
                  <label
                    htmlFor="Grade"
                    className={
                      studentIdData.gradeId === "" ? CSPForm.std_cat : "NONE"
                    }
                  >
                    *Grade
                  </label>
                  <label
                    htmlFor="Grade"
                    className={
                      studentIdData.gradeId !== "" ? CSPForm.std_cat : "NONE"
                    }
                  >
                    *Grade
                  </label>
                </div>
              </div>
            </div>
            <div className={CSPForm.grid_container_row_1}>
              <div className={CSPForm.grid_item_row}>
                <div className={CSPForm.input_element_csp34}>
                  <select
                    className={CSPForm.form_select_element_csp34 + ' ' + 'az_select_1' + ' ' + 'cursorPointer'}
                    name="section"
                    id="section"
                    value={studentIdData.sectionId}
                    onChange={(e) => {
                      setStudentIdData({
                        ...studentIdData,
                        sectionId: e.target.value,
                      });
                      handleSections(e);
                    }}
                  >
                    <option value="">Select</option>
                    {/* <option value="">Section</option> */}
                    {sectionName.length > 0 ? (
                      sectionName.map((sectionName) => {
                        return (
                          <option
                            className={Studentdynamic.option}
                            value={sectionName.id}
                          >
                            {sectionName.section}
                          </option>
                        );
                      })
                    ) : (
                      <option className={Studentdynamic.option} value="">
                        Select
                      </option>
                    )}
                  </select>
                  {/* <label for="section">*Section</label> */}
                  <label
                    htmlFor="section"
                    className={
                      studentIdData.sectionId === "" ? CSPForm.std_cat : "NONE"
                    }
                  >
                    *Section
                  </label>
                  <label
                    htmlFor="section"
                    className={
                      studentIdData.sectionId !== "" ? CSPForm.std_cat : "NONE"
                    }
                  >
                    *Section
                  </label>
                </div>
              </div>
            </div>
          </div>

          <span className={CSPForm.arrow_icon}>
            <i class="fa fa-arrow-circle-right"> </i>
          </span>


          <div className={CSPForm.grid_item_col2}>
            <div className={CSPForm.grid_container_row}>
              <label
                className={CSPForm.grid_content_label}
                style={{ color: "orange" }}
              >
                To
              </label>
              <div className={CSPForm.grid_item_row}>
                <div className={CSPForm.input_element_csp34}>
                  <select
                    className={CSPForm.form_select_element_csp34 + ' ' + 'az_select_1' + ' ' + 'cursorPointer'}
                    name="gradeId"
                    id="gradeId"
                    value={changeStudentProfile.gradeId}
                    onChange={(e) => {
                      setChangeStudentProfile({
                        ...changeStudentProfile,
                        gradeId: e.target.value,
                      });
                      handlerChangeProfile(e);
                    }}
                    disabled={
                      selectedItems.length == 0 || optionSel == "De-Activate"
                    }
                  >
                    <option value="">Select</option>
                    {/* <option value="">Select</option> */}

                    {gradesName.length > 0 ? (
                      gradesName.map((gradesName) => {
                        return (
                          <option
                            className={CSPForm.option}
                            value={gradesName.id}
                          >
                            {gradesName.grade}
                          </option>
                        );
                      })
                    ) : (
                      <option className={CSPForm.option} value="">
                        Select
                      </option>
                    )}
                  </select>
                  {/* <label for="Section">*Grade</label> */}
                  <label
                    htmlFor="gradeId"
                    className={
                      changeStudentProfile.gradeId === ""
                        ? CSPForm.std_cat
                        : "NONE"
                    }
                  >
                    *Grade
                  </label>
                  <label
                    htmlFor="gradeId"
                    className={
                      changeStudentProfile.gradeId !== ""
                        ? CSPForm.std_cat
                        : "NONE"
                    }
                  >
                    *Grade
                  </label>
                </div>
                {inValid && !changeStudentProfile.gradeId &&
                  <span style={{ fontSize: "small", color: "red" }}>Grade is required</span>
                }
                {inValid && (changeStudentProfile.gradeId == studentIdData.gradeId &&
                  changeStudentProfile.sectionId == studentIdData.sectionId
                ) &&
                  <span style={{ fontSize: "small", color: "red" }}>From & To Grades are not Same</span>
                }
              </div>
            </div>
            <div className={CSPForm.grid_container_row_1}>
              <div className={CSPForm.grid_item_row}>
                <div className={CSPForm.input_element_csp34}>
                  <select
                    className={CSPForm.form_select_element_csp34 + ' ' + 'az_select_1' + ' ' + 'cursorPointer'}
                    name="sectionId"
                    id="sectionId"
                    value={changeStudentProfile.sectionId}
                    disabled={
                      selectedItems.length == 0 || optionSel == "De-Activate"
                    }
                    onChange={(e) => {
                      setChangeStudentProfile({
                        ...changeStudentProfile,
                        sectionId: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Select
                    </option>
                    {sectionNameTo.length > 0 ? (
                      sectionNameTo.map((sectionName) => {
                        return (
                          <option
                            className={CSPForm.option}
                            value={sectionName.id}
                          >
                            {sectionName.section}
                          </option>
                        );
                      })
                    ) : (
                      <option className={CSPForm.option} value="">
                        Select
                      </option>
                    )}
                  </select>
                  {/* <label for="Section">*Section</label> */}
                  <label
                    htmlFor="sectionId"
                    className={
                      changeStudentProfile.sectionId === ""
                        ? CSPForm.std_cat
                        : "NONE"
                    }
                  >
                    *Section
                  </label>
                  <label
                    htmlFor="sectionId"
                    className={
                      changeStudentProfile.sectionId !== ""
                        ? CSPForm.std_cat
                        : "NONE"
                    }
                  >
                    *Section
                  </label>
                </div>
                {inValid && !changeStudentProfile.sectionId && (
                  <span style={{ fontSize: "small", color: "red" }}>
                    Section is required
                  </span>
                )}
                {inValid &&
                  changeStudentProfile.sectionId == studentIdData.sectionId &&
                  changeStudentProfile.gradeId == studentIdData.gradeId &&
                  (
                    <span style={{ fontSize: "small", color: "red" }}>
                      From & To Sections are not same
                    </span>
                  )}
              </div>
            </div>
          </div>
          <div className={CSPForm.grid_item_col3}>
            <div className={CSPForm.radio_grid}>
              <div className={CSPForm.radio}>
                <input
                  type="radio"
                  id="test1"
                  className="az_input_radio"
                  name="radio"
                  value="Change"
                  onChange={(event) => {
                    setOptionSelected(event.target.value);
                  }}
                  disabled={selectedItems.length == 0}
                />
                <label for="test1">Change</label>
              </div>
              <div className={CSPForm.radio}>
                <input
                  type="radio"
                  id="test1"
                  name="radio"
                  className="az_input_radio"
                  value="De-Activate"
                  onChange={(event) => {
                    setOptionSelected(event.target.value);
                    setChangeStudentProfile({
                      sectionId: "",
                      gradeId: "",
                    });
                  }}
                  disabled={selectedItems.length == 0}
                />
                <label for="test2">De-Active</label>
              </div>
            </div>
            {inValid && !optionSel &&
              <span style={{ fontSize: "small", color: "red", marginTop: "-50px", marginLeft: "40px" }}>Change is Required</span>
            }
            {/* <div></div>
                        <div></div> */}
            <div className={CSPForm.cbse_checkbox}>
              <input
                type="checkbox"
                className="az_checkbox"
                id="test3"
                checked={isYearAssesment}
                onChange={(event) => {
                  setYearAssesment(event.target.checked);
                }}
                disabled={selectedItems.length == 0}
              />

              <label htmlFor="test3">Year End Process </label>

              <div className={CSPForm.wrapper_teacherid}>
                <div className={CSPForm.icon}>
                  <span className={CSPForm.i_button}>
                    <i className="fas fa-info-circle cursorPointer"></i>
                  </span>
                  <div className={CSPForm.tooltip}>
                    Check year end process only when you are promoting students
                    to the next academic year
                  </div>
                </div>
              </div>
            </div>
            <div className={`${CSPForm.artical_button_container_csp34} cursorPointer`}>
              <Button
                id="az-super-admin-add-student-submit-btn"
                sx={{
                  backgroundColor: "#403E75 !important",
                  color: "#fff !important",
                  width: "150px",
                  fontSize: "14px",
                  borderRadius: "150px",
                  "&:hover": {
                    backgroundColor: "#2A2859 !important",
                  }
                }}
                disabled={selectedItems.length == 0}
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
        <div className={CSPForm.schoollist_head_csp34}>
          <div>
            <img
              src={studentIcn}
              style={{
                verticalAlign: "middle",
              }}
              alt=""
            />{" "}
            Student List
          </div>
          <div className={CSPForm.school_list_btn_container_csp34}>
            <div>
              <TextField
                onChange={handleInputChange}
                type={"text"}
                value={query}
                placeholder={"Search anything..."}
                sx={{
                  "& .MuiInputBase-root": {
                    width: "247px !important",
                    height: "32px !important",
                    borderRadius: "50px !important",
                    fontFamily: "Poppins !important",
                    fontSize: "13px",
                    marginLeft: "250px !important",
                    background: "#FFFFFF",

                    opacity: 1,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      {showClear && (
                        <IconButton onClick={handleClear}>
                          <ClearIcon
                            style={{ color: "#F05262", fontSize: "1vw" }}
                          />
                        </IconButton>
                      )}
                      {!showClear && (
                        <IconButton>
                          <img
                            src={searchIcn}
                            style={{
                              verticalAlign: "top",
                              fontSize: "large",
                            }}
                            aria-hidden="true"
                          />
                        </IconButton>
                      )}
                    </>
                  ),
                }}
              />

            </div>
          </div>
        </div>
        <div className={CSPForm.schoollist_csp34}>
          <div className={CSPForm.section_list_table}>
            <table className={CSPForm.table_section + ' ' + 'az_table'}>
              <thead className={CSPForm.thead_csp34 + ' ' + 'az_table_head'}>
                <tr className={CSPForm.table_head_section}>
                  <th className={CSPForm.cbse_checkbox_thead}>
                    <input
                      type="checkbox"
                      className="az_checkbox"
                      id="test2"
                      checked={isSelected}
                      onChange={handleCheckboxSelecet}
                    />
                    <label for="test2"></label>
                  </th>
                  <th className={CSPForm.cbse_table_head}>
                    #{/* <i class="fa-solid fa-arrows-up-down"></i> */}
                  </th>
                  <th className={CSPForm.cbse_table_head}>
                    <div className={CSPForm.name}>
                      User Name
                      {/* <i class="fa-solid fa-arrows-up-down"></i> */}
                      <img
                        style={{
                          verticalAlign: "middle",
                          paddingLeft: "3px",
                          marginBottom: "2px",
                        }}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("firstName");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </div>
                  </th>
                  <th className={CSPForm.cbse_table_head}>
                    {" "}
                    Grades
                    {/* <i class="fa-solid fa-arrows-up-down"></i> */}
                    <img
                      style={{
                        verticalAlign: "middle",
                        paddingLeft: "3px",
                        marginBottom: "2px",
                      }}
                      className="cursor-pointer"
                      width="14"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("grade");
                        // await initData(!sortOrder, "id");
                      }}
                    />
                  </th>
                  <th className={CSPForm.cbse_table_head1}>
                    Section
                    {/* <i class="fa-solid fa-arrows-up-down"></i> */}
                    <img
                      style={{
                        verticalAlign: "middle",
                        paddingLeft: "3px",
                        marginBottom: "2px",
                      }}
                      className="cursor-pointer"
                      width="14"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("section");
                        // await initData(!sortOrder, "id");
                      }}
                    />
                  </th>
                  <th className={CSPForm.cbse_table_head2}>
                    Email
                    {/* <i class="fa-solid fa-arrows-up-down"></i> */}
                    <img
                      style={{
                        verticalAlign: "middle",
                        paddingLeft: "3px",
                        marginBottom: "2px",
                      }}
                      className="cursor-pointer"
                      width="14"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("email");
                        // await initData(!sortOrder, "id");
                      }}
                    />
                  </th>
                  <th className={CSPForm.cbse_table_head3}>
                    Phone
                    {/* <i class="fa-solid fa-arrows-up-down"></i> */}
                    <img
                      style={{
                        verticalAlign: "middle",
                        paddingLeft: "3px",
                        marginBottom: "2px",
                      }}
                      className="cursor-pointer"
                      width="14"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("mobile");
                        // await initData(!sortOrder, "id");
                      }}
                    />
                  </th>
                  <th className={CSPForm.cbse_table_head3}>
                    <div className={CSPForm.userId}>
                      User Id
                      {/* <i class="fa-solid fa-arrows-up-down"></i> */}
                      <img
                        style={{
                          verticalAlign: "middle",
                          paddingLeft: "3px",
                          marginBottom: "2px",
                        }}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("userId");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </div>
                  </th>
                  <th className={CSPForm.cbse_table_head3}>
                    Status
                    {/* <i class="fa-solid fa-arrows-up-down"></i> */}
                  </th>
                </tr>
              </thead>
              <tbody className="az_table_body">
                {studentListData.length > 0 ? (
                  studentListData.map((studentListData, index) => {
                    return (
                      <tr
                        tabIndex="1"
                        key={studentListData.id}
                        className={CSPForm.data_row}
                      >
                        <td className={CSPForm.cbse_checkbox_thead}>

                          <input className="az_checkbox" type="checkbox" id="test2" onChange={(event) => {
                            individualItemPush(event, studentListData?.id)
                          }} checked={(selectedItems.includes(studentListData.id)) ? true : false} />

                          <label for="test2"></label>
                        </td>
                        <td className={CSPForm.data_csp34}>
                          {(page - 1) * rowsInput.rows + (index + 1)}
                        </td>
                        <td
                          className={CSPForm.data1_csp34}
                        // onClick={(e) => {
                        //   e.preventDefault();
                        //   var studentid = studentListData.id;
                        //   sessionStorage.setItem("studentid", studentid);
                        //   StudentsControllerIdGet(setStudentIdData);
                        //   // setShowModalEdit(true);
                        // }}
                        >
                          <div className={CSPForm.name}>
                            {studentListData.userName}
                          </div>
                        </td>
                        <td className={CSPForm.data_csp34}>
                          {studentListData.grade}
                        </td>
                        <td className={CSPForm.data_csp34}>
                          {studentListData.section}
                        </td>
                        <td className={CSPForm.data_csp34}>
                          {studentListData.email}
                        </td>
                        <td className={CSPForm.data_csp34}>
                          {studentListData.mobile}
                        </td>
                        <td className={CSPForm.data_csp34}>
                          <div className={CSPForm.userId}>
                            {studentListData.userId}
                          </div>
                        </td>
                        <td className={CSPForm.data2_csp34}>
                          {studentListData.active ? (
                            <span>Active</span>
                          ) : (
                            <span>De-Active</span>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <td
                    colSpan="10"
                    style={{
                      // "white-space": "nowrap",/
                      height: "23rem",
                      // overflow: "scroll",
                      // "text-overflow": "clip",
                      // margin: "0px 10px",
                    }}
                  >
                    NO DATA AVAILABLE
                  </td>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className={CSPForm.pagination}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <span className={CSPForm.rows}>
              <label for="rows">Rows per page</label>
              <select
                className="cursorPointer"
                name="rows"
                id="rows"
                value={rowsInput.rows}
                onChange={handlerRowsInputs}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </span>
          </form>
          <span>
            <Pagination1 pageDetail={pageDetail} handleChange={handleChange} />
          </span>
          <Success_OK_Modal_SubjectMapping
            userType={"You have successfully Changed  Student Profile"}
            open={successCreateModal}
            handleOk={closeSuccessModal}
          />
        </div>
      </article>
    </>
  );

};
export default CSP
