import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import contentApprovalStyles from "../css/contetnApproval.module.css";

import {
  TextField,
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableRow,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import chapterStyles from "./../css/createChapter.module.css";
import WhiteAddButton from "../../components/common/WhiteButton";
import axios from "axios";
import ApprovalModal from "./ApprovalModal";
import RejectedModal from "./RejectedModal";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";

const ConceptTreeView = () => {
  const [modifiedDate, setModifiedDate] = useState({});
  const token = sessionStorage.getItem("token");
  const [conceptId, setConceptId] = useState();
  const [approveModel, setapproveModal] = useState(false);
  const [rejecteModel, setrejecteModal] = useState(false);
  const [rejectComment, setrejecetComment] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    if (id) {
      setConceptId(id);
      initData();
    }
    return () => { };
  }, [id]);
  const approveModal = (elem) => {
    setConceptId(elem?.id);
    setapproveModal(true);
  };

  const approved = () => {
    const value = {
      id,
      status: "APPROVED",
      comment: "Data is outdated according to current system.",
    };
    axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concepts/review`,
        value,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        console.log(response);

        navigate("/dashboard/ConceptTreeApprovalPending");
        localStorage.removeItem("previewDetails");
      })
      .catch((err) => console.log(err));
  };

  const approve = () => {
    approved();
    setapproveModal(false);
  };

  const [subjectDetails, setSubjectDetails] = useState("");
  const initData = async () => {
    let previewdetails = JSON.parse(localStorage.getItem("previewDetails"));
    setSubjectDetails(previewdetails);
    var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/sub-root/preview?pageSize=10&conceptId=${id}`;
    const response = await axios.get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    let arr = response?.data?.data?.data;

    previewdetails.subConcept = arr;

    console.log(previewdetails);

    setData([previewdetails]);
  };

  const rejecteModal = (elem) => {
    setConceptId(elem?.id);
    setrejecteModal(true);
  };
  const closerejectmodel = () => {
    setrejecteModal(false);
  };
  const rejecte = () => {
    const value = {
      id,
      status: "REJECTED",
      comment: rejectComment,
    };
    axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concepts/review`,
        value,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        console.log(response);
        setrejecteModal(false);
        navigate("/dashboard/ConceptTreeApprovalPending");
        localStorage.removeItem("previewDetails");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ width: "100%" }}>
      <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span className={dashboardContentStyles.link_icon}>
            <img
              src={dashHome}
              alt="no img"
              width="15px"
              height="20px"
              style={{ verticalAlign: "middle" }}
            />{" "}
          </span>
          {"  "}
          <span className={`${dashboardContentStyles.link_text} cursorDefault`}>Home</span>
          {"  "}
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          {"  "}
          <a className="cursorDefault">ConceptTreeView</a>
        </div>
        <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>Last Update: {modifiedDate.data}</p>
        </div>
      </article>
      <div
        className={contentApprovalStyles.flex}
        style={{ marginTop: "55px", position: "relative" }}
      >
        <div className={contentApprovalStyles.flex}>
          <i
            style={{ color: "grey", fontSize: "36px", margin: "10px" }}
            className="fa-solid fa-user-gear"
          ></i>
          <p style={{ color: "grey ", fontSize: "1.2vw", margin: "auto 10px" }}>
            Concept Tree View
          </p>
        </div>
        <TextField
          style={{ right: "20px", position: "absolute" }}
          sx={{
            width: { sm: 200, md: 300 },
            "& .MuiInputBase-root": {
              width: "247px !important",
              height: "32px !important",
              borderRadius: "50px !important",
              background: "#fff !important",
              lineHeight: "21px !important",
              font: "normal normal normal 13px/20px Poppins !important",
              opacity: 1,
            },
          }}
          type={"search"}
          placeholder={"Search...."}
          name="search"
          //onChange={handleSearch}
          //Value={search.search}
          InputProps={{
            endAdornment: (
              <IconButton>
                <img
                  src={searchIcn}
                  style={{
                    verticalAlign: "top",
                    fontSize: "large",
                  }}
                  aria-hidden="true"
                />
              </IconButton>
            ),
          }}
        />
      </div>
      <div style={{ backgroundColor: "white", margin: "40px" }}>
        <div style={{ paddingLeft: "20px", paddingTop: "20px" }}>
          <h3 style={{ color: "orange" }}>*Subject</h3>
          <p style={{ paddingLeft: "20px" }}>{subjectDetails.subject}</p>
        </div>
        <div style={{ marginTop: "30px" }}>
          <TableContainer
            sx={{
              overflow: "auto",
            }}
          >
            <Table
              //sx={{ minWidth: 650 }}
              stickyHeader={true}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  {/* <TableCell className={chapterStyles.table_head}></TableCell> */}
                  <TableCell
                    align="center"
                    className={chapterStyles.table_head}
                  >
                    Concept
                  </TableCell>
                  <TableCell
                    align="center"
                    className={chapterStyles.table_head}
                  >
                    Sub-Concepts
                  </TableCell>
                  <TableCell
                    align="center"
                    className={chapterStyles.table_head}
                  >
                    Root-Concepts
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={chapterStyles.table_body}>
                {data.length > 0 ? (
                  data.map((elem, i) => (
                    <TableRow className={chapterStyles.table_row}>
                      {/* index */}
                      {/* <TableCell
                        className={chapterStyles.table_cell}
                        align="center"
                        style={{borderBottom:"none",background:"transparent linear-gradient(180deg, #FCFCFC 0%, #F7F7F7 100%) 0% 0% no-repeat padding-box"}}
                      > */}
                      {/* {i + 1} */}
                      {/* </TableCell> */}
                      {/* index */}
                      <TableCell
                        className={chapterStyles.table_cell}
                        align="center"
                        style={{
                          borderBottom: "none",
                          borderRight: "1px solid darkgray",
                          borderLeft: "1px solid darkgray",
                        }}
                      // background:"transparent linear-gradient(180deg, #FCFCFC 0%, #F7F7F7 100%) 0% 0% no-repeat padding-box"
                      >
                        <div className={chapterStyles.cell}>{elem.concept}</div>
                      </TableCell>
                      {/* index */}
                      <TableCell
                        className={chapterStyles.table_cell}
                        align="center"
                        style={{
                          borderBottom: "none",
                          borderRight: "1px solid darkgray",
                          borderLeft: "1px solid darkgray",
                        }}
                      >
                        {elem?.subConcept.length > 0 && (
                          <div className={chapterStyles.cell}>
                            {elem?.concept}
                          </div>
                        )}
                        <div>
                          {/* {i != 0 && elem?.subConcept} */}
                          {elem?.subConcept.map((item) => {
                            return (
                              <div className={chapterStyles.sub_cell}>
                                {item.subConcept}
                              </div>
                            );
                          })}
                        </div>
                      </TableCell>

                      <TableCell
                        className={chapterStyles.table_cell}
                        align="center"
                        style={{
                          borderBottom: "none",
                          borderRight: "1px solid darkgray",
                          borderLeft: "1px solid darkgray",
                        }}
                      >
                        {" "}
                        {elem?.subConcept?.filter(
                          (item) => item?.rootConceptResponses?.length > 0
                        )
                          .map((item) => {
                            return (
                              <>
                                <div
                                  className={chapterStyles.sub_cell}
                                  style={{ paddingLeft: "20px" }}
                                >
                                  {item?.rootConceptResponses.length > 0 &&
                                    item?.subConcept}
                                </div>
                                {item?.rootConceptResponses.map((item2) => {
                                  return (
                                    <div className={chapterStyles.root_cell}>
                                      {item2?.rootConcept}
                                    </div>
                                  );
                                })}
                              </>
                            );
                          })}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <td
                    colSpan="10"
                    style={{ height: "25rem", textAlign: "center" }}
                  >
                    {" "}
                    NO DATA AVAILABLE{" "}
                  </td>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div style={{ marginTop: "20px", marginBottom: "10px" }}>
          <div>
            <button
              className={contentApprovalStyles.orange_btn}
              onClick={(e) => {
                e.preventDefault();
                navigate("/dashboard/concept%20tree%20approval");
                localStorage.removeItem("previewDetails");
              }}
            >
              BACK
            </button>

            <span style={{ float: "right" }}>
              {localStorage.getItem("status") === "rejected" ? null : (
                <WhiteAddButton
                  style={{ marginRight: "12px" }}
                  onClick={(e) => {
                    rejecteModal();
                  }}
                >
                  REJECT
                </WhiteAddButton>
              )}
              {localStorage.getItem("status") === "approved" ? null : (
                <button
                  className={contentApprovalStyles.orange_btn}
                  onClick={(e) => {
                    approveModal();
                  }}
                >
                  APPROVE
                </button>
              )}
            </span>
          </div>
        </div>
      </div>
      <ApprovalModal open={approveModel} approve={approve} />
      <RejectedModal
        open={rejecteModel}
        close={closerejectmodel}
        rejecte={rejecte}
      >
        <TextField
          label="Reason"
          rows={5}
          multiline
          style={{ width: 450 }}
          value={rejectComment}
          onChange={(event) => setrejecetComment(event.target.value)}
        />
      </RejectedModal>
    </div>
  );
};

export default ConceptTreeView;

{
  /* <div>
                          {elem?.id !== undefined && i == 1
                            ? null
                            : elem?.subConcept}
                        </div> */
}
{
  /* {elem?.rootConceptResponses?.length > 0 &&
                          elem?.rootConceptResponses.map((v, i) => {
                            return (
                              <div style={{ paddingLeft: "25px" }}>
                                {v?.rootConcept}
                              </div>
                            );

                            // <span>
                            //   {v.rootConcept}{" "}
                            //   {i == elem?.rootConceptResponses?.length - 1
                            //     ? ""
                            //     : ","}
                            // </span>
                          })} */
}

// let topics = arr.filter((x) => x.rootConceptResponses?.length > 0);
// previewdetails.subConcept = topics[0]?.subConcept;
// arr.unshift(previewdetails);

// let arr2 = arr.map((item) => {
//   if (item?.rootConceptResponses?.length > 0) {
//     return {
//       ...item,
//       rootConceptResponses: item.rootConceptResponses.map((item2) => {
//         return { ...item2, subConcept: item.subConcept };
//       }),
//     };
//   } else {
//     return item;
//   }
// });

// [
//   {
//     concept: "Concept-1",
//     subConcept: [
//       {
//         subConcept: "Sub-1",
//         rootConcept: [{ rootConcept: "root-1" }, { rootConcept: "root-2" }],
//       },
//       {
//         subConcept: "Sub-2",
//         rootConcept: [{ rootConcept: "root-1" }, { rootConcept: "root-2" }],
//       },
//     ],
//   },
//   {
//     concept: "Concept-1",
//     subConcept: [
//       {
//         subConcept: "Sub-1",
//         rootConcept: [{ rootConcept: "root-1" }, { rootConcept: "root-2" }],
//       },
//       {
//         subConcept: "Sub-2",
//         rootConcept: [{ rootConcept: "root-1" }, { rootConcept: "root-2" }],
//       },
//     ],
//   },
// ];
