import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Studentprofile from "./../css/Studentpg31.module.css";
import Studentdynamic from "./../css/student32.module.css";
import studentmodal from "./../css/StudentModal.module.css";
import teacheridstyles from "../css/TeacherId.module.css";

import tickImage from "./../img/tick-inside-a-circle.svg";
import sample_csv from "./../../img/SampleCSVFile.csv";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import changepasswordstyle from "../css/changePassword.module.css"; //change password modal css
import sharestyle from "../css/shareModal.module.css"; //share modal css
import studentedit from "./../css/EditModel.module.css";
import GradesControllerGetall from "../API_CONTROLLER/grades-controller/grades_controller_getall";
import SectionsControllerGetall from "../API_CONTROLLER/section-Controller/section_controller_getall";
import StudentCategoriesControllerGetall from "../API_CONTROLLER/student-categories-controller/student_categories_controller_getall";
import StudentsControllerPost from "../API/student-controller/User_Student_post";
import LanguagesControllerGetall from "../API_CONTROLLER/language-controller/master_languages_get";
import Gender_Controller_Get from "../API/misc-controller/gender_controller_get";
import StudentsControllerGet from "../API/student-controller/userStudentsGet";
import StudentControllerIdPut from "../API/student-controller/user_student_Id_put";
import StudentsControllerIdGet from "../API/student-controller/userStudentsIdGet";
import branchchangerstyle from "../css/School_Branch_Changer.module.css";
import Branches_Changer_School_Id from "../API/branch-controller/Branches_Changer_School_Id";
import Student_update_Id_put from "../API/student-controller/student_update_Id_put";
import Pagination1 from "./pagination1";
import CSVfile_upload_controller_post from "../API/file-upload-controller/CSVfile_student_upload_controller_post";
import School_name from "./school_name"; //school name changer
import GetLastModifiedAt from "../API/student-controller/GetLastModifiedAt";
import sectionFromgrade_get from "../API_CONTROLLER/section-Controller/sectionFromgrade_get";
import axios from "axios";
import sortIcon from "../../../src/pages/img/sort_1.png";
import dashHome from "../../../src/img/dashHome.svg";
import ConfirmationModal from "./ConfirmationModal";
//Action Icon Images
import action_sa_Lock from "../../../src/img/password.svg";
import action_sa_Edit from "../../../src/img/Edit.svg";
import action_sa_Share from "../../../src/img/Share.svg";
import action_sa_Delete from "../../../src/img/Delete.svg";
import * as XLSX from 'xlsx';
import EditIconButton from "../../components/common/EditIconButton";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import LockIconButton from "../../components/common/LockIconButton";
import ShareIconButton from "../../components/common/ShareIconButton";
import { Tooltip } from "@mui/material";
import studentIcn from "../../../src/img/student-list.png";
import Download from "../../../src/img/Downloads.svg";

// import ImageUploading from "./uploadImage";

import gradeSectionGet from "../API/grade-section-mapping-controller/grade_section_get";
import GreenAddButton from "../../components/common/GreenAddButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import searchIcn from "../../../src/img/search.svg";
import changePasswordPut from "../ChangePassword";
import CsvFile from "./CsvFile";
import share from "../Share";
// input choose file label image
import Cf_img from "../../../src/img/csv.svg";
import Switch from "../../components/common/Switch";
import CommonDeleteModal from "./CommonDeleteModal";
import Success_OK_Modal from "../../components/common/Success_OK_Modal";

import { size } from "lodash";
import ClearIcon from "@mui/icons-material/Clear";
import SchoolAdminBreadcrumb from "./SchoolAdminBreadcrumb";
import {
  IconButton,
  TextField,
} from "@mui/material";
import SuccesModalGeneral from "../../components/common/SuccessModelGeneral";

import commonsort from "../js/CommonSort"




const Student = (props) => {
  const [phoneNumber, setphoneNumber] = useState("");
  const [regexPass, setRegexPass] = useState(true);
  const [SuccessDisplayName, setSuccessDisplayName] = useState("");

  const navigate = useNavigate();
  const [studentInputs, setStudentInputs] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dob: "",
    gender: "",
    sectionId: sessionStorage.getItem("sectionid")
      ? sessionStorage.getItem("sectionid")
      : "",
    // sectionId: "",

    gradeId: sessionStorage.getItem("gradeid")
      ? sessionStorage.getItem("gradeid")
      : "",
    // gradeId: "",

    firstLanguageId: "",
    secondLanguageId: "",
    admissionDate: "",
    studentCategoryId: "",
    address: "",
  });

  const [isAddStudent, setIsAddStudent] = useState(true);
  const prevLocation = useLocation();
  if (prevLocation.state === "edit") {
    setIsAddStudent(false);
    prevLocation.state = null;
  }
  useEffect(() => {
    if (isAddStudent === false) {
      StudentsControllerIdGet(setStudentIdData, setStudentInputs);

      return () => { };
    }
  }, []);

  const handleEditStudent = () => {
    setIsAddStudent(false);
    StudentsControllerIdGet(setStudentIdData, setStudentInputs);
  };

  const [isError, setIsError] = useState(false);
  const [showError, setShowError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [shareFirstName, setShareFirstName] = useState("");
  const [shareLastName, setShareLastName] = useState("");
  const [showModalUpload, setShowModalUpload] = useState(false);
  const [showModalUpdated, setShowModalUpdated] = useState(false);
  const [shareFile, setShareFile] = useState("");
  const [success, setSuccess] = useState({});
  const [toggle, setToggle] = useState(false);
  const [selectedCSV, setSelectedCSV] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [uploadCSV, setUploadCSV] = useState({});
  const [sortOrder, setSortOrder] = useState(false);
  const [sortBy, setSortBy] = useState("id");
  const [csvData, setCsvData] = useState("");
  const [expanded, setExpanded] = useState(false);

  // const [expanded, setExpanded] = useState(false);
  const [isSearch, setisSearch] = useState(true);
  const [totalele, setTotalelements] = useState(0);
  const handleCSV = (e) => {
    console.log("clicked")
    setSelectedCSV(e.target.files[0]);
    setShareFile(e.target.files[0].name);
    setIsFilePicked(true);
  };
  const templateName = "STUDENTS_CSV";
  const handleCsvFile = (e) => {
    CsvFile(templateName, setCsvData);
    window.open(csvData);
  };

  const [userName, setUserName] = useState();
  const [userId, setUserId] = useState();
  const [password, setPassword] = useState();
  const persona = "STUDENT";
  const [num, setNum] = useState("")

  const [message, setMessage] = useState("")

  const handleUploadModal = (e) => {
    // const formData = e.target.value;
    const formData = new FormData();
    formData.append("file", selectedCSV);

    CSVfile_upload_controller_post(formData, setUploadCSV, setMessage);

    setShowModalUpload(true);


  };

  const downloadSucessCSV = () => {
    const fieldsToRemove = ['userId', 'documentUrl', 'firstLanguageId', 'secondLanguageId', 'studentCategoryId', 'gradeId', 'sectionId', 'branchId', 'schoolId'
      , 'boardId', 'isPromoted', 'yearEndProcess', 'numberOfQuiz', 'lastUnitQuizSubmission', 'lastLoginTime', 'examAttended', 'lmsEnv'
    ];
    var modifiedPassedCsv = uploadCSV.data.data.passedCSV.map(val => {
      let modifiedVal = { ...val };
      fieldsToRemove.forEach(element => delete modifiedVal[element]);
      return modifiedVal;
    });
    uploadCSV.data.data.passedCSV = modifiedPassedCsv;
    const excelBuffer = convertToExcel(uploadCSV.data.data.passedCSV);
    downloadExcel(excelBuffer, 'studentpassedcsv.xlsx');
  }

  const downloadFailureCSV = () => {
    const excelBuffer = convertToExcel(uploadCSV.data.data.failedCSV);
    downloadExcel(excelBuffer, 'studentfailedcsv.xlsx');
  }

  const convertToExcel = (jsonData) => {
    const ws = XLSX.utils.json_to_sheet(jsonData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    return excelBuffer;
  };

  const downloadExcel = (excelBuffer, fileName) => {
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };



  const handleUploadModalOk = (e) => {
    setShowModalUpload(false);
    navigate("/dashboard/student");
  };

  // const gradeSection = (studentInputs) => {
  //   let schoolid = sessionStorage.getItem("schoolid");
  //   let branchid = sessionStorage.getItem("branchid");
  //   let token = sessionStorage.getItem("token");
  //   // let gradeId = sessionStorage.getItem("gradeId")

  //   axios .get(
  //       `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section/sections?gradeId=${studentInputs.gradeId}&branchId=${branchid}&schoolId=${schoolid}`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       setGradeSection(response?.data.data);
  //       console.log(response.data,"aaaaa")
  //     })
  //     .catch((err) => console.log(err));

  // }
  useEffect(() => {
    sectionFromgrade_get(studentInputs, setSectionsName);
  }, [studentInputs.gradeId]);

  const handlerStudentInputs = (e) => {
    e.preventDefault();
    setStudentInputs({
      ...studentInputs,
      [e.target.name]: e.target.value,
    });



    // if(e.target.name == "gradeId"){
    //   let schoolid = sessionStorage.getItem("schoolid");
    //   let branchid = sessionStorage.getItem("branchid");
    //   let token = sessionStorage.getItem("token");
    //   axios
    //     .get(
    //       `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section/sections?gradeId=${e.target.value}&branchId=${branchid}&schoolId=${schoolid}`,
    //       {
    //         headers: {
    //           "Content-Type": "application/json",
    //           Authorization: `Bearer ${token}`,
    //         },
    //       }
    //     )
    //     .then((response) => {
    //       setSectionsName(response?.data.data);
    //       console.log(response.data,"aaaaa")
    //     })
    //     .catch((err) => console.log(err));

    // }
  };

  const handleKeyPress = (e) => {
    const result = e.target.value.replace(/[^0-9]/g, "");
    setNum(result);
  };

  function validateFirstName(firstNameValue) {
    const regex = new RegExp(/^[a-z ,.'-]+$/i);
    return regex.test(firstNameValue);
  }

  function validateLastName(lastNameValue) {
    const regex = new RegExp(/^[a-z ,.'-]+$/i);
    return regex.test(lastNameValue);
  }

  function validateEmail(emailValue) {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(emailValue);
  }

  function validateMobile(mobileValue) {
    const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return regex.test(mobileValue);
  }
  function validatePassword(passValue) {
    const regex = new RegExp(
      "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*+_()=])(?=\\S+$).{8,}$"
    );
    return regex.test(passValue);
  }

  function validateDates(dobValue, admissionDateValue) {
    const [dobYear, dobMonth, dobDay] = dobValue.split("-").map(Number);
    const [admissionYear, admissionMonth, admissionDay] = admissionDateValue.split("-").map(Number);

    if (dobYear > admissionYear) return false;
    if (dobYear < admissionYear) return true;

    if (dobMonth > admissionMonth) return false;
    if (dobMonth < admissionMonth) return true;

    return dobDay < admissionDay;
  }

  const handleStudentTable = (e) => {
    console.log("clicked")
    console.log(studentInputs, "inputs")
    e.preventDefault();
    // for (const input in studentInputs) {
    //   if (studentInputs[input] === "") {
    //     setIsError(true);
    //     setShowError("Please enter all the required values");
    //     return;
    //   }
    // }

    if (studentInputs.firstName === "" ||
      studentInputs.lastName === "" ||
      num === "" ||
      studentInputs.gender === "" ||
      studentInputs.gradeId === "" ||
      studentInputs.sectionId === "") {
      setIsError(true);
      setShowError("Please enter all the required values");
      return;
    }

    // other validation condtions
    if (!validateMobile(num)) {
      setIsError(true);
      setShowError("Phone number invalid");
      return;
    }

    if (studentInputs.email !== '' && !validateEmail(studentInputs.email)) {
      setIsError("true");
      setShowError("Please enter a valid email address");
      return;
    }

    if (!validateFirstName(studentInputs.firstName)) {
      setIsError("true");
      setShowError("Incorrect First Name");
      return;
    }

    if (!validateLastName(studentInputs.lastName)) {
      setIsError("true");
      setShowError("Incorrect Last Name");
      return;
    }

    if (studentInputs.dob !== '' && studentInputs.admissionDate !== '') {
      if (!validateDates(studentInputs.dob, studentInputs.admissionDate)) {
        setIsError(true);
        //alert("The DOB must be before the Admission date.")
        setShowError("The DOB must be before the admission date.");
        return;
      }
    }


    // setShowModal(true);

    // console.log("post");
    setAddDisabled(true);
    StudentsControllerPost(studentInputs, num, setSuccess, () => {

      setSuccessDisplayName(
        studentInputs.firstName + " " + studentInputs.lastName
      );
      StudentsControllerGet(
        setStudentListData,
        setPageDetail,
        page,
        rowsInput,
        search,
        gradeInput,
        sectionInput
      );

      setNum("")
      setStudentInputs({
        firstName: "",
        lastName: "",
        email: "",
        // mobile: "",
        dob: "",
        gender: "",
        sectionId: sessionStorage.getItem("sectionid")
          ? sessionStorage.getItem("sectionid")
          : "",
        // sectionId: "",

        gradeId: sessionStorage.getItem("gradeid")
          ? sessionStorage.getItem("gradeid")
          : "",
        // gradeId: "",

        firstLanguageId: "",
        secondLanguageId: "",
        admissionDate: "",
        studentCategoryId: "",
        address: "",
      });
      setTimeout(() => {
        setSuccessCreateModal(true);
      }, 500);
    });
    setIsError(true);
    setShowError(success.message);

    setShareFirstName(
      e.target.parentNode.parentNode.parentNode.parentNode.parentNode
        .children[0].children[0].children[0].children[0].value
    );
    setShareLastName(
      e.target.parentNode.parentNode.parentNode.parentNode.parentNode
        .children[0].children[0].children[1].children[0].value
    );
    setAddDisabled(false);
  };

  useEffect(() => {
    if (success.success === false) {
      setIsError(true);
      setShowError(success.message);
      return () => { };
    }
    if (success.success === true) {
      setShowModal(true);
      return () => { };
    }
  }, [success]);

  const handlesStudentNow = (e) => {
    navigate("/dashboard/studenttable");
  };
  // add navidation to create branch page with school name parameters

  const handleStudentUpdate = (e) => {
    // updateStudentList(e, studentInputs);
    setShowModalUpdated(false);
    // navigate("/dashboard/Add%20Students");
    StudentsControllerGet(
      setStudentListData,
      setPageDetail,
      page,
      rowsInput,
      search,
      gradeInput,
      sectionInput
    );
    setNum("")
    setStudentInputs({
      firstName: "",
      lastName: "",
      email: "",
      // mobile: "",
      dob: "",
      gender: "",
      // sectionId: "",
      // gradeId: "",
      firstLanguageId: "",
      secondLanguageId: "",
      admissionDate: "",
      studentCategoryId: "",
      address: "",
    });
  };

  const handleStudentOk = (e) => {
    // updateStudentList(e, studentInputs);
    setShowModal(false);
    // navigate("/dashboard/Add%20Students");
    StudentsControllerGet(
      setStudentListData,
      setPageDetail,
      page,
      rowsInput,
      search,
      gradeInput,
      sectionInput
    );
    setNum("")
    setStudentInputs({
      firstName: "",
      lastName: "",
      email: "",
      // mobile: "",
      dob: "",
      gender: "",
      // sectionId: "",
      // gradeId: "",
      firstLanguageId: "",
      secondLanguageId: "",
      admissionDate: "",
      studentCategoryId: "",
      address: "",
    });
  };

  // search starts
  const [search, setsearch] = useState({
    search: "",
  });
  const [showClear, setShowClear] = useState(false);
  const [query, setQuery] = useState("");
  const handleClear = () => {
    setQuery("");
    setShowClear(false);
    StudentsControllerGet(
      setStudentListData,
      setPageDetail,
      page,
      rowsInput,
      search,
      gradeInput,
      sectionInput
    );
  };

  const handleInputChange = (e) => {
    const inputValue = e?.target?.value?.toLowerCase();
    if (inputValue) {
      let result = [...totalele];
      result = [...totalele]?.filter((data) => {
        return data?.userName?.toLowerCase().includes(inputValue);
      });
      setStudentListData(result)
    } else {
      StudentsControllerGet(
        setStudentListData,
        setPageDetail,
        page,
        rowsInput,
        search,
        gradeInput,
        sectionInput
      );
    }
    setQuery(inputValue);
    setShowClear(inputValue !== "");
  };
  useEffect(() => {
    if (isSearch) {
      searchingData()
    }
  }, [])

  const searchingData = async () => {
    setisSearch(false);
    let token = sessionStorage.getItem("token");

    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/students?pageNumber=0&pageSize=50&sortBy=${sortBy || "id"}&sortOrder=${sortOrder || false}&branchId=${sessionStorage.getItem("branchid")}&schoolId=${sessionStorage.getItem("schoolid")}&search=${search.search}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        }
      },)
    const totlaele = result?.data?.data?.totalElements
    let response = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/students?pageNumber=0&pageSize=${totlaele}&sortBy=${sortBy || "id"}&sortOrder=${sortOrder || false}&branchId=${sessionStorage.getItem("branchid")}&schoolId=${sessionStorage.getItem("schoolid")}&search=${search.search}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        }
      },)
    const totalData = response?.data?.data?.data;
    // console.log(totalData, sortOrder, sortBy)
    var sortedData = await BranchControllerGet1(response?.data?.data?.data || [], sortBy, sortOrder, totlaele, 0);
    // console.log("sortedData", sortedData)
    if (sortedData) {
      setTotalelements(sortedData);
    }
  }



  async function BranchControllerGet1(array, field, order, pageSize, pageNumber) {
    console.log(array, field, order, "common sorting file");
    order = order === true ? "desc" : "asc";
    if (array?.length > 0) {
      let nullsFirst = order === "asc";

      // Sorting function
      const sortedArray = array.sort((a, b) => {
        const aValue = a[field];
        const bValue = b[field];

        // Handle null and empty values
        if (aValue == null || aValue === '') {
          if (bValue == null || bValue === '') return 0;
          return nullsFirst ? -1 : 1;
        }
        if (bValue == null || bValue === '') {
          return nullsFirst ? 1 : -1;
        }

        // Special handling for 'grade' field
        if (field === 'grade') {
          const numA = parseInt(aValue.match(/\d+/), 10);
          const numB = parseInt(bValue.match(/\d+/), 10);
          return order === 'asc'
            ? numA - numB
            : numB - numA;
        }

        // Handle different data types
        if (typeof aValue === 'boolean') {
          return order === 'asc'
            ? (aValue === bValue ? 0 : aValue ? 1 : -1)
            : (aValue === bValue ? 0 : aValue ? -1 : 1);
        } else if (typeof aValue === 'string') {
          return order === 'asc'
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        } else if (typeof aValue === 'number' || aValue instanceof Date) {
          return order === 'asc'
            ? aValue - bValue
            : bValue - aValue;
        } else {
          // Handle any other types if necessary
          return 0;
        }
      });

      // Pagination logic
      const totalRecords = sortedArray.length;
      const startIndex = (pageNumber - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const paginatedArray = sortedArray.slice(startIndex, endIndex);

      return {
        data: paginatedArray,
        totalRecords: totalRecords,
        pageSize: pageSize,
        pageNumber: pageNumber
      };
    } else {
      return {
        data: [],
        totalRecords: 0,
        pageSize: pageSize,
        pageNumber: pageNumber
      };
    }
  }


  // search ends
  // pagination starts
  const [rowsInput, setRowsInputs] = useState({
    rows: 10,
  });
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const handlerRowsInputs = (e) => {
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
    setPage(1);
  };
  console.log(rowsInput, "RI");

  const [pageDetail, setPageDetail] = useState({});
  // pagination ends
  // last update time starts
  const [modifiedDate, setModifiedDate] = useState({});
  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);
  // last update time ends
  const [gradesName, setGradesName] = useState([]);
  const [sectionName, setSectionsName] = useState([]);
  const [studentCategoryName, setStudentCategoriesName] = useState({});
  const [languageName, setLanguageName] = useState([]);
  const [genderData, setGenderType] = useState([]);
  const [studentListData, setStudentListData] = useState([]);
  const [gradeInput, setGradeInput] = useState("");
  const [sectionInput, setSectionInput] = useState("");
  const [gradesection, setGradeSection] = useState({});
  useEffect(() => {
    gradesGet();
    StudentCategoriesControllerGetall(setStudentCategoriesName);
    LanguagesControllerGetall(setLanguageName);
    Gender_Controller_Get(setGenderType);
    // StudentsControllerGet(rowsInput, setStudentListData, setPageDetail, page);
    gradeSectionGet(setGradeSection);
    return () => { };
  }, []);

  useEffect(() => {
    StudentsControllerGet(
      setStudentListData,
      setPageDetail,
      page,
      rowsInput,
      search,
      gradeInput,
      sectionInput,
      sortBy
    );

    return () => { };
  }, [
    rowsInput,
    page,
    gradeInput,
    sectionInput,
    sessionStorage.getItem("branchid"),
  ]);

  const [branchList, setBranchList] = useState([]);

  const [schoolDetails, setSchoolDetails] = useState({
    branch: sessionStorage.getItem("branchid"),
  });

  const handlerSchoolDetailsInputs = (e) => {
    sessionStorage.setItem("branchid", e.target.value);
    setSchoolDetails({
      ...schoolDetails,
      [e.target.name]: e.target.value,
      schoolName: e.target.id,
    });
    setGradeInput("");
    setSectionInput("");
    setStudentListData([]);
    gradesGet();
  };

  useEffect(() => {
    var branchid = schoolDetails.branch;
    sessionStorage.setItem("branchid", branchid);
    Branches_Changer_School_Id(setBranchList);
    return () => { };
  }, [schoolDetails]);

  //BranchController ends

  // modal stats
  const [shareName, setShareName] = useState("");
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [studentIdData, setStudentIdData] = useState({});
  const [showModalChangePassword, setShowModalChangePassword] = useState(false);
  const [showModalShare, setShowModalShare] = useState(false);
  const [loginPassword, setLoginPassword] = useState("");
  const [confirmLoginPassword, setConfirmLoginPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [isPassEmpty, setIsPassEmpty] = useState(false);
  const [shareNo, setShareNo] = useState("");
  const [addDisabled, setAddDisabled] = useState(false);
  const [shareClick, setShareClick] = useState(false)

  const handleUpdatedPassword = (e) => {
    setShowModalChangePassword(false);
  };
  const toggleLoginPasswordVisibility = (isShown) => {
    if (isShown === "show") {
      setPasswordShown(true);
    } else {
      setPasswordShown(false);
    }
  };
  const toggleconfirmLoginPasswordVisibility = (isConfirmShown) => {
    if (isConfirmShown === "Confirmshow") {
      setConfirmPasswordShown(true);
    }
    if (isConfirmShown === "ConfirmdontShow") {
      setConfirmPasswordShown(false);
    }
  };

  const handleSubmit = (e) => {


    e.preventDefault();
    if (!loginPassword) {
      setIsPassEmpty(true);
      setPasswordMatch(true);
      return;
    }
    if (loginPassword !== confirmLoginPassword) {
      setIsPassEmpty(false);
      setPasswordMatch(false);
      return;
    }
    const pass = validatePassword(loginPassword);
    if (pass === false) {
      setRegexPass(pass);
      setIsTooltipOpen(true);

      return;
    }
    // setPasswordMatch(true);
    // setShowModalChangePassword(false);

    changePasswordPut(userName, userId, password, persona)
      .then((response) => {
        // console.log(">>>",response.data)
        setPasswordMatch(true);
        setShowModalChangePassword(false);
        setSuccessModalGeneral(true);
        setSuccessDisplayName(
          "Password updated successfully"
        );
        //alert("Password Updated")
      }).
      catch(err => {
        console.log("err >>> ", err.response.data.message)
        alert(err.response.data.message)
        // setIsError(true);
        // setShowError(err.response.data.message)
      })
  };

  const handleEditModalEdit = (e) => {
    setShowModalEdit(false);
    setExpanded(true);
    navigate("/dashboard/student");
    StudentsControllerIdGet(setStudentIdData, setStudentInputs);
    setIsAddStudent(false);
  };
  const handleEditModalcross = (e) => {
    setShowModalEdit(false);
    navigate("/dashboard/student");
  };

  const handleShareModal = (e) => {
    setShareName(
      e.target.parentNode.parentNode.parentNode.parentNode.parentNode
        .children[5].textContent
    );
    setShareNo(
      e.target.parentNode.parentNode.parentNode.parentNode.parentNode
        .children[4].textContent
    );

    setShowModalShare(true);
  };
  const handleShareModalOk = (e) => {
    setShowModalShare(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsError(false);
    }, 2000);
  }, [isError]);

  // modal ends
  const [updatedData, setUpdatedData] = useState();
  const [toggleDetails, setToggleDetails] = useState({});
  const token = sessionStorage.getItem("token");

  const [toggleMessage, settoggleMessage] = useState();
  const [toggleConfirmation, setToggleConfirmation] = useState(false);
  const [filterValue, setFilterValue] = useState([]);
  const handleConfirmationClose = () => {
    setToggleConfirmation(false);
    setToggleDetails({});
  };

  const updateToggle = () => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/students/${toggleDetails?.id}/active?active=${toggleDetails?.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: toggleDetails?.value,
        }
      )
      .then(function (response) {
        StudentsControllerGet(
          setStudentListData,
          setPageDetail,
          page,
          rowsInput,
          search,
          gradeInput,
          sectionInput, sortBy
        );
        handleConfirmationClose();
        if (response?.data) {
          const find = updatedData?.find(
            (item) => item.id === toggleDetails?.id
          );
          find.active = toggleDetails?.value;
          const clone = [...updatedData];
          clone.forEach((data) => {
            data = find;
          });
          setFilterValue(clone);
        }
      })
      .catch((err) => console.log(err));
  };

  const switchHandler = async (e, id) => {
    setToggleDetails({});
    const value = e.target.checked;
    setToggleDetails({ id, value });
    if (value === false) {
      setToggleConfirmation(true);
      settoggleMessage("Are you sure you want to Deactivate this Student");
    } else {
      setToggleConfirmation(true);
      settoggleMessage("Are you sure you want to Activate this Student");
    }
  }

  const PassOnChange = (e) => {
    setLoginPassword(e.target.value);
    setPassword(e.target.value);
  };

  useEffect(() => {
    if (shareClick) { share(userName, userId, persona) };
  }, [userName, userId]);

  const [selectedItem, setSelectedItem] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const showDeleteModal = (id) => {
    setSelectedItem(id);
    console.log(id.id, "idd");
    setOpenDeleteModal(true);
  };

  const hideDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedItem("");
  };
  console.log(openDeleteModal, "modal");

  const deleteHandler = () => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/students/${selectedItem.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setOpenDeleteModal(false);
        StudentsControllerGet(
          setStudentListData,
          setPageDetail,
          page,
          rowsInput,
          search,
          gradeInput,
          sectionInput,
          sortBy
        );
      })
      .catch((error) => { });
  };
  function gradesGet() {
    let token = sessionStorage.getItem("token");
    let schoolid = sessionStorage.getItem("schoolid");
    let branchid = sessionStorage.getItem("branchid");
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section/grades?branchId=${branchid}&schoolId=${schoolid}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((json) => {
        setGradesName(json.data.data);
      })
      .catch((err) => console.log(err));
  }
  const handleGradesInput = (e) => {
    setSectionInput("");
    setGradeInput(e.target.value);
    getSectionFromGrade(e.target.value)
  };
  const getSectionFromGrade = (gradeId) => {
    let schoolid = sessionStorage.getItem("schoolid");
    let branchid = sessionStorage.getItem("branchid");
    let token = sessionStorage.getItem("token");


    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section/sections?gradeId=${gradeId}&branchId=${branchid}&schoolId=${schoolid}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setSectionsName(response?.data.data);
      })
      .catch((err) => console.log(err));
  };







  const handleSectionInput = (e) => {
    setSectionInput(e.target.value);
  };
  const handleCancel = () => {
    setExpanded(false);
  };

  const handleAddStudent = () => {
    setExpanded(true);
  };

  const [successCreateModal, setSuccessCreateModal] = useState(false);
  const [successModalGeneral, setSuccessModalGeneral] = useState(false);

  const closeSuccessModal = () => {
    setSuccessCreateModal(false);
    setSuccessModalGeneral(false);
  };

  const [selectedCode, setSelectedCode] = useState("");

  const handleCodeChange = (event) => {
    setSelectedCode(event.target.value);
  };

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTooltipOpen = () => {
    setIsTooltipOpen(false);

  };


  const handleTooltipClose = () => {
    setIsTooltipOpen(false);

  };

  const shareHandler = (row) => {
    setUserId(row.userId);
    setUserName(row.userName);
    userId && share(userName, userId, persona);
    setShowModalShare(true);
    setShareClick(true)
  };

  return (
    <>
      <SchoolAdminBreadcrumb
        dashHome={dashHome}
        sectionName={'Add Students'}
        date={modifiedDate.data}
      />
      <article className={Studentprofile.article_31}>
        <div style={{ paddingTop: "20px" }} className={branchchangerstyle.Grid_school_name_branch_teacherid}>
          <div className={branchchangerstyle.school_name_teacherid}>
            <School_name />
          </div>
          <select
            className={branchchangerstyle.branchselect_Dropdown}
            name="branch"
            id="branch"
            value={schoolDetails.branch}
            onChange={handlerSchoolDetailsInputs}
          >
            {branchList.length > 0 ? (
              branchList.map((branchList) => {
                return (
                  <option value={branchList.branchId}>
                    {branchList.branch}
                  </option>
                );
              })
            ) : (
              <option className={branchchangerstyle.option} value=""></option>
            )}
          </select>
        </div>

        <div className={Studentprofile.grid_container_header_plan_31}>
          <div
            className={Studentprofile.item_plan1_31 + ' ' + 'az_cursor_pointer'}
            id="az-super-admin-add-student-tab-plan"
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/branchplan");
            }}
          >
            Plan
          </div>
          <div
            className={Studentprofile.item_plan2_31 + ' ' + 'az_cursor_pointer'}
            id="az-super-admin-add-student-tab-sections"
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/branchsection");
            }}
          >
            Sections
          </div>
          <div
            className={Studentprofile.item_plan3_31 + ' ' + 'az_cursor_pointer'}
            id="az-super-admin-add-student-tab-academicStaff"
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/teacherId");
            }}
          >
            Academic Staff
          </div>
          <div
            className={Studentprofile.item_plan4_31 + ' ' + 'az_cursor_pointer'}
            id="az-super-admin-add-student-tab-students"
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/student");
            }}
          >
            Students
          </div>
          <div
            className={Studentprofile.item_plan5_31 + ' ' + 'az_cursor_pointer'}
            id="az-super-admin-add-student-tab-changeStudentProfile"
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/csp");
            }}
          >
            Change Student Profile
          </div>
          <div
            className={Studentprofile.item_plan6_31 + ' ' + 'az_cursor_pointer'}
            id="az-super-admin-add-student-tab-Tokens"
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/tokenList");
            }}
          >
            Tokens
          </div>
          <div
            className={Studentprofile.item_plan7_31 + ' ' + 'az_cursor_pointer'}
            id="az-super-admin-add-student-tab-branchLogo"
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/logo");
            }}
          >
            Branch Logo
          </div>
        </div>

        {!expanded ? (
          <div className={Studentdynamic.schoollist_32}>
            <div className={Studentdynamic.schoollist_dropdown}>
              <span>
                <img
                  src={studentIcn}
                  style={{
                    verticalAlign: "middle",
                  }}
                  alt=""
                />{" "}
                Student List
              </span>
              <div className={Studentdynamic.Dropdown}>
                <select
                  name="grade"
                  id="grade"
                  value={gradeInput}
                  onChange={handleGradesInput}
                  className="cursorPointer"
                >
                  <option value="" style={{ textAlign: "center" }}>Grade </option>
                  <i class="fa-solid fa-chevron-down"></i>
                  {gradesName.length > 0 ? (
                    gradesName.map((gradesName) => {
                      return (
                        <option
                          className={Studentdynamic.option}
                          value={gradesName.id}
                        >
                          {gradesName.grade}
                        </option>
                      );
                    })
                  ) : (
                    <option className={Studentdynamic.option} value="">
                      Select
                    </option>
                  )}
                </select>
              </div>
              <div className={Studentdynamic.Dropdown}>
                <select
                  name="section"
                  id="section"
                  value={sectionInput}
                  onChange={handleSectionInput}
                  className="cursorPointer"
                >
                  <i class="fa-solid fa-chevron-down"></i>
                  <option style={{ textAlign: "center" }} value="">Section</option>
                  {sectionName.length > 0 ? (
                    sectionName.map((sectionName) => {
                      return (
                        <option
                          className={Studentdynamic.option}
                          value={sectionName.id}
                        >
                          {sectionName.section}
                        </option>
                      );
                    })
                  ) : (
                    <option className={Studentdynamic.option} value="">
                      Select
                    </option>
                  )}
                </select>
              </div>
            </div>

            <div>
              <div>
                <TextField
                  onChange={handleInputChange}
                  type={"text"}
                  value={query}
                  placeholder={"Search anything..."}
                  sx={{
                    "& .MuiInputBase-root": {
                      width: "247px !important",
                      height: "32px !important",
                      borderRadius: "50px !important",
                      fontFamily: "Poppins !important",
                      fontSize: "13px",
                      marginLeft: "250px !important",
                      background: "#FFFFFF",

                      opacity: 1,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <>
                        {showClear && (
                          <IconButton onClick={handleClear}>
                            <ClearIcon
                              style={{ color: "#F05262", fontSize: "1vw" }}
                            />
                          </IconButton>
                        )}
                        {!showClear && (
                          <IconButton>
                            <img
                              src={searchIcn}
                              style={{
                                verticalAlign: "top",
                                fontSize: "large",
                              }}
                              aria-hidden="true"
                            />
                          </IconButton>
                        )}
                      </>
                    ),
                  }}
                />

              </div>
            </div>
            <div>
              <button
                className={Studentdynamic.add_school_button_32}
                onClick={handleAddStudent}
              >
                <i class="fa-solid fa-circle-plus"></i> Add Student
              </button>
            </div>
          </div>
        ) : (
          <div className={Studentprofile.Input_container}>
            <form
              className={Studentprofile.add_schools_form_31}
              onsubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className={Studentprofile.input_grid_31}>
                <div className={Studentprofile.input_element_31}>
                  <input
                    className={Studentprofile.form_input_element_31 + ' ' + 'az_text_input'}
                    type="text"
                    name="firstName"
                    id="az-super-admin-add-student-text-input-firstName"
                    value={studentInputs.firstName}
                    placeholder=" "
                    autoComplete="none"
                    onChange={handlerStudentInputs}
                  />
                  <label htmlFor="az-super-admin-add-student-text-input-firstName">*First Name</label>
                  <p>
                    <span className={Studentprofile.logo}>
                      <i class="material-icons"></i> &#x26a0;
                    </span>
                    You{" "}
                    <span className={Studentprofile.logo}>cannot change </span>{" "}
                    the name after creating a profile
                  </p>
                </div>
                <div className={Studentprofile.input_element_31}>
                  <input
                    className={Studentprofile.form_input_element_31 + ' ' + 'az_text_input'}
                    type="text"
                    name="lastName"
                    id="az-super-admin-add-student-text-input-lastName"
                    value={studentInputs.lastName}
                    placeholder=" "
                    autoComplete="none"
                    onChange={handlerStudentInputs}
                  />
                  <label htmlFor="az-super-admin-add-student-text-input-lastName">*Last Name</label>
                  <p>
                    <span className={Studentprofile.logo2}>
                      <i class="material-icons"></i> &#x26a0;
                    </span>
                    You{" "}
                    <spam className={Studentprofile.logo2}>
                      {" "}
                      cannot change{" "}
                    </spam>{" "}
                    the name after creating a profile
                  </p>
                </div>
                <div className={Studentprofile.input_element_31}>
                  <input
                    className={Studentprofile.form_input_element_31 + ' ' + 'az_text_input'}
                    type="email"
                    name="email"
                    id="az-super-admin-add-student-text-input-email"
                    value={studentInputs.email}
                    placeholder=" "
                    autoComplete="none"
                    onChange={handlerStudentInputs}
                  />
                  {/* <label htmlFor="email">*Primary Email</label> */}
                  <label htmlFor="az-super-admin-add-student-text-input-email">Primary Email</label>
                </div>
                <div style={{ display: "flex", justifyContent: "space-around" }}>
                  <select
                    style={{ width: "60px", height: "48px", borderRadius: "8px" }}
                    value={selectedCode}
                    onChange={handleCodeChange}
                    className="az_select_1 cursorPointer"
                  >
                    <option value="+1">+91</option>
                    <option value="+44">
                      +44
                    </option>
                    <option value="+91">+1</option>
                  </select>
                  <div className={Studentprofile.input_element_31}>
                    <span
                      style={{ position: "absolute", top: "10px", left: "-32px" }}
                    >

                    </span>
                    <input
                      style={{ width: "210px" }}
                      className={
                        Studentprofile.form_input_element_31 + ' ' + 'az_text_input'
                      }
                      type="text"
                      name="primaryNumber"
                      id="az-super-admin-add-student-text-input-primaryNumber"
                      value={num}
                      maxLength={10}
                      placeholder=" "
                      onChange={(e) =>
                        handleKeyPress(e)
                      }
                    />

                    <label htmlFor="az-super-admin-add-student-text-input-primaryNumber">
                      *Primary-Number
                    </label>
                  </div>
                </div>
                <div className={Studentprofile.input_element_31}>
                  <input
                    className={`${Studentprofile.form_select_element_31 + ' ' + 'az_text_input'} cursorPointer `}
                    name="dob"
                    id="az-super-admin-add-student-text-input-dateOfBirth"
                    type="date"
                    onChange={handlerStudentInputs}
                    value={studentInputs.dob}
                  ></input>
                  {/* <label htmlFor="dob">*Date of Birth</label> */}
                  <label
                    htmlFor="az-super-admin-add-student-text-input-dateOfBirth"
                    className={
                      studentInputs.dob === "" ? Studentprofile.std_cat : "NONE"
                    }
                  >
                    Date of Birth
                  </label>
                  <label
                    htmlFor="dob"
                    className={
                      studentInputs.dob !== "" ? Studentprofile.std_cat : "NONE"
                    }
                  >
                    Date of Birth
                  </label>
                </div>
                <div className={Studentprofile.input_element_31}>
                  <select
                    className={`${Studentprofile.form_select_element_31 + ' ' + 'az_select_1'} cursorPointer `}
                    name="gender"
                    id="gender"
                    value={studentInputs.gender}
                    onChange={handlerStudentInputs}
                  >
                    <option value="" className={Studentprofile.option}>
                      Select
                    </option>
                    {genderData.length > 0 ? (
                      genderData.map((genderData) => {
                        return (
                          <option
                            className={Studentprofile.option}
                            value={genderData.code}
                          >
                            {genderData.name}
                          </option>
                        );
                      })
                    ) : (
                      <option className={Studentprofile.option} value="">
                        Select
                      </option>
                    )}
                  </select>
                  {/* <label htmlFor="Gender">*Gender</label> */}
                  <label
                    htmlFor="gender"
                    className={
                      studentInputs.gender === ""
                        ? Studentprofile.std_cat
                        : "NONE"
                    }
                  >
                    *Gender
                  </label>
                  <label
                    htmlFor="gender"
                    className={
                      studentInputs.gender !== ""
                        ? Studentprofile.std_cat
                        : "NONE"
                    }
                  >
                    *Gender
                  </label>
                </div>

                <div className={Studentprofile.input_element_31}>
                  <select
                    className={Studentprofile.form_select_element_31 + ' ' + 'az_select_1' + ' ' + 'cursorPointer'}
                    name="firstLanguageId"
                    id="firstLanguageId"
                    value={studentInputs.firstLanguageId}
                    onChange={handlerStudentInputs}
                  >
                    <option value="">Select</option>
                    {languageName.length > 0 ? (
                      languageName.map((languageName) => {
                        return (
                          <option
                            className={Studentprofile.option}
                            value={languageName.id}
                          >
                            {languageName.language}
                          </option>
                        );
                      })
                    ) : (
                      <option className={Studentprofile.option} value="">
                        Select
                      </option>
                    )}
                  </select>
                  {/* <label htmlFor="firstLanguageId">*Mother Tongue</label> */}
                  <label
                    htmlFor="firstLanguageId"
                    className={
                      studentInputs.firstLanguageId === ""
                        ? Studentprofile.std_cat
                        : "NONE"
                    }
                  >
                    Mother Tongue
                  </label>
                  <label
                    htmlFor="firstLanguageId"
                    className={
                      studentInputs.firstLanguageId !== ""
                        ? Studentprofile.std_cat
                        : "NONE"
                    }
                  >
                    Mother Tongue
                  </label>
                </div>

                <div className={Studentprofile.input_element_31}>
                  <select
                    className={Studentprofile.form_select_element_31 + ' ' + 'az_select_1' + ' ' + 'cursorPointer'}
                    name="secondLanguageId"
                    id="secondLanguageId"
                    value={studentInputs.secondLanguageId}
                    onChange={handlerStudentInputs}
                  >
                    <option value="">Select</option>
                    {languageName.length > 0 ? (
                      languageName.map((languageName) => {
                        return (
                          <option
                            className={Studentprofile.option}
                            value={languageName.id}
                          >
                            {languageName.language}
                          </option>
                        );
                      })
                    ) : (
                      <option className={Studentprofile.option} value="">
                        Select
                      </option>
                    )}
                  </select>
                  {/* <label for="secondLanguageId">*Second Language</label> */}
                  <label
                    htmlFor="secondLanguageId"
                    className={
                      studentInputs.secondLanguageId === ""
                        ? Studentprofile.std_cat
                        : "NONE"
                    }
                  >
                    Second Language
                  </label>
                  <label
                    htmlFor="secondLanguageId"
                    className={
                      studentInputs.secondLanguageId !== ""
                        ? Studentprofile.std_cat
                        : "NONE"
                    }
                  >
                    Second Language
                  </label>
                </div>
                <div className={Studentprofile.input_element_31}>
                  <input
                    className={Studentprofile.form_select_element_31 + ' ' + 'az_text_input' + ' ' + 'cursorPointer'}
                    name="admissionDate"
                    type="date"
                    id="az-super-admin-add-student-text-input-admissionDate"
                    value={studentInputs.admissionDate}
                    onChange={handlerStudentInputs}
                  ></input>
                  {/* <label for="admissionDate">*Admission Date</label> */}
                  <label
                    htmlFor="az-super-admin-add-student-text-input-admissionDate"
                    className={
                      studentInputs.admissionDate === ""
                        ? Studentprofile.std_cat
                        : "NONE"
                    }
                  >
                    Admission Date
                  </label>
                  <label
                    htmlFor="admissionDate"
                    className={
                      studentInputs.admissionDate !== ""
                        ? Studentprofile.std_cat
                        : "NONE"
                    }
                  >
                    Admission Date
                  </label>
                </div>
                <div className={Studentprofile.input_element_31}>
                  <select
                    className={Studentprofile.form_select_element_31 + ' ' + 'az_select_1' + ' ' + 'cursorPointer'}
                    name="gradeId"
                    id="gradeId"
                    value={studentInputs.gradeId}
                    onChange={handlerStudentInputs}
                  >
                    <option value="">Select</option>
                    {gradesection.length > 0 ? (
                      gradesection.map((gradesName) => {
                        return (
                          <option
                            className={Studentprofile.option}
                            value={gradesName.gradeId}
                          >
                            {gradesName.gradeName}
                          </option>
                        );
                      })
                    ) : (
                      <option className={Studentprofile.option} value="">
                        Select
                      </option>
                    )}
                  </select>
                  {/* <label for="Grade">*Grade</label> */}
                  <label
                    htmlFor="Grade"
                    className={
                      studentInputs.gradeId === ""
                        ? Studentprofile.std_cat
                        : "NONE"
                    }
                  >
                    *Grade
                  </label>
                  <label
                    htmlFor="Grade"
                    className={
                      studentInputs.gradeId !== ""
                        ? Studentprofile.std_cat
                        : "NONE"
                    }
                  >
                    *Grade
                  </label>
                </div>
                <div className={Studentprofile.input_element_31}>
                  <select
                    className={Studentprofile.form_select_element_31 + ' ' + 'az_select' + ' ' + 'cursorPointer'}
                    name="sectionId"
                    id="sectionId"
                    value={studentInputs.sectionId}
                    onChange={handlerStudentInputs}
                  >
                    <option value="">Select</option>
                    {sectionName.length > 0 ? (
                      sectionName.map((sectionName) => {
                        return (
                          <option
                            className={Studentprofile.option}
                            value={sectionName.id}
                          >
                            {sectionName.section}
                          </option>
                        );
                      })
                    ) : (
                      <option className={Studentprofile.option} value="">
                        Select
                      </option>
                    )}
                  </select>
                  {/* <label for="sectionId">*Section</label> */}
                  <label
                    htmlFor="sectionId"
                    className={
                      studentInputs.sectionId === ""
                        ? Studentprofile.std_cat
                        : "NONE"
                    }
                  >
                    *Section
                  </label>
                  <label
                    htmlFor="sectionId"
                    className={
                      studentInputs.sectionId !== ""
                        ? Studentprofile.std_cat
                        : "NONE"
                    }
                  >
                    *Section
                  </label>
                </div>

                <div className={Studentprofile.input_element_31}>
                  <select
                    className={Studentprofile.form_select_element_31 + ' ' + 'az_select' + ' ' + 'cursorPointer'}
                    name="studentCategoryId"
                    id="studentCategoryId"
                    onChange={handlerStudentInputs}
                    value={studentInputs.studentCategoryId}
                  >
                    <option value="">Select</option>
                    {studentCategoryName.length > 0 ? (
                      studentCategoryName.map((studentCategoryName) => {
                        return (
                          <option
                            className={Studentprofile.option}
                            value={studentCategoryName.id}
                          >
                            {studentCategoryName.studentCategory}
                          </option>
                        );
                      })
                    ) : (
                      <option className={Studentprofile.option} value="">
                        Select
                      </option>
                    )}
                  </select>
                  <label
                    htmlFor="studentCategoryId"
                    className={
                      studentInputs.studentCategoryId === ""
                        ? Studentprofile.std_cat
                        : "NONE"
                    }
                  >
                    Student Category
                  </label>
                  <label
                    htmlFor="studentCategoryId"
                    className={
                      studentInputs.studentCategoryId !== ""
                        ? Studentprofile.std_cat
                        : "NONE"
                    }
                  >
                    Student Category
                  </label>
                </div>
                <div className={Studentprofile.input_element_31}>
                  <input
                    className={Studentprofile.form_input_element_31 + ' ' + 'az_text_input'}
                    type="text"
                    name="address"
                    id="address"
                    value={studentInputs.address}
                    placeholder=" "
                    autoComplete="none"
                    onChange={handlerStudentInputs}
                  />
                  {/* <label for="address">*Present Address</label> */}
                  <label htmlFor="address">Present Address</label>
                </div>
                <div className={Studentprofile.input_element_31}>
                  <div
                    className={Studentprofile.error_box}
                    style={
                      isError
                        ? {
                          display: "flex",
                          height: "1rem",
                          // overflow: "hidden",
                        }
                        : { display: "none" }
                    }
                  >
                    {showError}
                  </div>
                </div>
                <div className={Studentprofile.input_element_31}>
                  <div className={Studentprofile.artical_button_container_31}>
                    <button
                      type="reset"
                      className={Studentprofile.artical_button_31_cancel}
                      id={Studentprofile.button_id_cancel}
                      // onClick={handleStudentTable}
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>

                <div className={Studentprofile.input_element_31}>
                  <div className={Studentprofile.artical_button_container_31}>
                    {isAddStudent ? (
                      <button
                        type="submit"
                        className={Studentprofile.artical_button_31}
                        id={Studentprofile.button_id}
                        onClick={handleStudentTable}
                        disabled={addDisabled}
                      >
                        Add
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className={Studentprofile.artical_button_31}
                        id={Studentprofile.button_id}
                        onClick={(e) => {
                          e.preventDefault();
                          if (studentInputs.firstName === "" ||
                            studentInputs.lastName === "" ||
                            num === "" ||
                            studentInputs.gender === "" ||
                            studentInputs.gradeId === "" ||
                            studentInputs.sectionId === "") {
                            setIsError(true);
                            setShowError("Please enter all the required values");
                            return;
                          }
                          // for (const input in studentInputs) {
                          //   if (studentInputs[input] === "") {
                          //     setIsError(true);
                          //     setShowError(
                          //       "Please enter all the required values"
                          //     );
                          //     return;
                          //   }
                          // }

                          // other validation condtions
                          if (!validateMobile(num)) {
                            setIsError(true);
                            setShowError("Phone number invalid");
                            return;
                          }
                          // if (!validateEmail(studentInputs.email)) {
                          //   setIsError("true");
                          //   setShowError("Please enter a valid email address");
                          //   return;
                          // }

                          if (!validateFirstName(studentInputs.firstName)) {
                            setIsError("true");
                            setShowError("Incorrect First Name");
                            return;
                          }

                          if (!validateLastName(studentInputs.lastName)) {
                            setIsError("true");
                            setShowError("Incorrect Last Name");
                            return;
                          }
                          if (studentInputs.dob && studentInputs.admissionDate) {

                            if (!validateDates(studentInputs.dob, studentInputs.admissionDate)) {
                              setIsError(true);
                              //alert("The DOB must be before the Admission date.")
                              setShowError("The DOB must be before the admission date.");
                              return;
                            }
                          }

                          Student_update_Id_put(studentInputs, num, setSuccess).then(
                            () => {
                              StudentsControllerGet(
                                setStudentListData,
                                setPageDetail,
                                page,
                                rowsInput,
                                search,
                                gradeInput,
                                sectionInput
                              );
                              setShowModalUpdated(true);
                              setIsAddStudent(true);
                              setNum("")
                              setStudentInputs({
                                firstName: "",
                                lastName: "",
                                email: "",
                                // mobile: "",
                                dob: "",
                                gender: "",
                                sectionId: "",
                                gradeId: "",
                                firstLanguageId: "",
                                secondLanguageId: "",
                                admissionDate: "",
                                studentCategoryId: "",
                                address: "",
                              });
                            }
                          );
                          // window.location.reload(true);
                          // navigate("/dashboard/studenttable")
                          setShareFirstName(
                            e.target.parentNode.parentNode.parentNode.parentNode
                              .parentNode.children[0].children[0].children[0]
                              .children[0].value
                          );
                          setShareLastName(
                            e.target.parentNode.parentNode.parentNode.parentNode
                              .parentNode.children[0].children[0].children[1]
                              .children[0].value
                          );
                        }}
                      >
                        UPDATE
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>

            <div>
              <div className={teacheridstyles.icon_remove_teacherid}>
                <div className={teacheridstyles.wrapper_teacherid}>
                  <label
                    for="img"
                    style={{
                      marginLeft: "25px",
                      color: "#272727",
                      position: "relative",
                      bottom: "3px",
                      fontSize: "15px"
                    }}
                  >
                    Upload CSV
                    <div className={teacheridstyles.icon}>
                      <div className={teacheridstyles.tooltip}>
                        Download Sample CSV
                      </div>
                      <span onClick={handleCsvFile}>
                        <img
                          src={Download}
                          alt="img"
                          style={{
                            position: "relative",
                            top: "3px",

                            fontSize: "large",
                          }}
                        />
                      </span>
                    </div>
                  </label>
                </div>
              </div>

              <div style={{ marginLeft: "9px" }}>
                <input
                  type="file"
                  name="file"
                  accept=".csv"
                  style={{ display: "none", marginLeft: "20px" }}
                  onChange={handleCSV}
                  className={teacheridstyles.custom_file_input}
                  id="testId"
                />
                <div className={`${teacheridstyles.chooseFile_btn_wrapper} cursorPointer`} style={{ marginLeft: "15px" }}>
                  <img src={Cf_img} alt="img" />
                  <label
                    htmlFor="testId"
                    className={`${teacheridstyles.chooseFile_btn} cursorPointer`}
                  >
                    Choose File
                  </label>
                </div>
                {isFilePicked && (
                  <button

                    onClick={handleUploadModal}
                    id={teacheridstyles.submit_teacherid}
                    type="submit"
                  >
                    Submit
                  </button>
                )}

              </div>
              {/* </div> */}

              {/* <div>
              <input
                type="file"
                name="file"
                accept=".csv"
                style={{ display: "none" }}
                onChange={handleCSV}
                className={teacheridstyles.custom_file_input}
                id="testId"
              />
              <div style={{marginLeft:"10px"}} className={teacheridstyles.chooseFile_btn_wrapper}>
                <img src={Cf_img} alt="img" />
                <label
                  htmlFor="testId"
                  className={teacheridstyles.chooseFile_btn}
                >
                  Choose File
                </label>
              </div>
                {isFilePicked && (
                  <button
                    onClick={handleUploadModal}
                    id={Studentprofile.submit_teacherid}
                    type="submit"
                  >
                    Submit
                  </button>
                )}
              </div> */}
              {/* <p>sampleteacher.csv</p>
                                    <p className={Studentprofile.remove_teacherid}><a href="">Remove</a></p>
                                    <input id={Studentprofile.submit_teacherid} type="submit"/> */}
            </div>
          </div>
        )}

        <div className={Studentprofile.section_list_table}>
          <table className={Studentprofile.table_section + ' ' + 'az_table'}>
            <thead className="az_table_head">
              <tr>
                <th>#</th>
                <th>
                  User Name{" "}
                  <img
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    width="11"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      console.log("firstName StudentsControllerGet")
                      await setSortOrder(!sortOrder);
                      await setSortBy("firstName");
                      await StudentsControllerGet(
                        setStudentListData,
                        setPageDetail,
                        page,
                        rowsInput,
                        search,
                        gradeInput,
                        sectionInput,
                        "firstName",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th>
                  {" "}
                  Grades{" "}
                  <img
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    width="11"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      console.log("grade StudentsControllerGet")
                      await setSortOrder(!sortOrder);
                      await setSortBy("grade");
                      await StudentsControllerGet(
                        setStudentListData,
                        setPageDetail,
                        page,
                        rowsInput,
                        search,
                        gradeInput,
                        sectionInput,
                        "grade",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th>
                  Section{" "}
                  <img
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    width="11"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      console.log("section StudentsControllerGet")
                      await setSortOrder(!sortOrder);
                      await setSortBy("section");
                      await StudentsControllerGet(
                        setStudentListData,
                        setPageDetail,
                        page,
                        rowsInput,
                        search,
                        gradeInput,
                        sectionInput,
                        "section",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th className={Studentprofile.cbse_table}>
                  Phone{" "}
                  <img
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    width="11"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      console.log("mobile StudentsControllerGet")
                      await setSortOrder(!sortOrder);
                      await setSortBy("mobile");
                      await StudentsControllerGet(
                        setStudentListData,
                        setPageDetail,
                        page,
                        rowsInput,
                        search,
                        gradeInput,
                        sectionInput,
                        "mobile",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th>
                  Email{" "}
                  <img
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    width="11"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      console.log("userName StudentsControllerGet")
                      await setSortOrder(!sortOrder);
                      await setSortBy("userName");
                      await StudentsControllerGet(
                        setStudentListData,
                        setPageDetail,
                        page,
                        rowsInput,
                        search,
                        gradeInput,
                        sectionInput,
                        "email",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th>
                  Number of Quiz{" "}
                  <img
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    width="11"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      console.log("numberOfQuiz StudentsControllerGet")
                      await setSortOrder(!sortOrder);
                      await setSortBy("numberOfQuiz");
                      await StudentsControllerGet(
                        setStudentListData,
                        setPageDetail,
                        page,
                        rowsInput,
                        search,
                        gradeInput,
                        sectionInput,
                        "numberOfQuiz",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                {/* <th>
                  User Id <i class="fa-solid fa-arrows-up-down"></i>
                </th> */}
                <th>
                  Last login{" "}
                  <img
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    width="11"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      console.log("lastLoginTime StudentsControllerGet")
                      await setSortOrder(!sortOrder);
                      await setSortBy("lastLoginTime");
                      await StudentsControllerGet(
                        setStudentListData,
                        setPageDetail,
                        page,
                        rowsInput,
                        search,
                        gradeInput,
                        sectionInput,
                        "lastLoginTime",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th>Active
                  <img
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    width="11"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      console.log("active StudentsControllerGet")
                      await setSortOrder(!sortOrder);
                      await setSortBy("active");
                      await StudentsControllerGet(
                        setStudentListData,
                        setPageDetail,
                        page,
                        rowsInput,
                        search,
                        gradeInput,
                        sectionInput,
                        "active",
                        !sortOrder
                      );
                    }}></img>

                </th>
                <th style={{ paddingRight: "30px" }}>Action</th>
              </tr>
            </thead>
            <tbody className="az_table_body">
              {studentListData.length > 0 ? (
                studentListData.map((studentListData, index) => {
                  return (
                    <tr
                      className={Studentprofile.data_row}
                      id={Studentprofile.data_row_tr}
                      key={index}
                    >
                      <td style={{ width: "40px" }}>{(page - 1) * rowsInput.rows + (index + 1)}</td>
                      <td className={`${Studentprofile.wrapdata} cursorPointer`}>
                        <div
                          onClick={(e) => {
                            e.preventDefault();
                            var studentid = studentListData.id;
                            sessionStorage.setItem("studentid", studentid);
                            isAddStudent
                              ? StudentsControllerIdGet(setStudentIdData)
                              : StudentsControllerIdGet(
                                setStudentIdData,
                                setStudentInputs
                              );
                            setShowModalEdit(true);
                          }}
                        >
                          {studentListData.userName}
                        </div>
                      </td>
                      <td style={{ width: "100px" }} className={Studentprofile.grade}>
                        {studentListData.grade}
                      </td>
                      <td style={{ width: "100px" }}>{studentListData.section}</td>
                      <td className={Studentprofile.wrapdata}>
                        {studentListData.mobile}
                      </td>
                      <td style={{ width: "180px", padding: "10px", wordBreak: "break-word" }}>
                        {studentListData.email}
                      </td>
                      <td style={{ width: "110px" }}>{studentListData.numberOfQuiz}</td>
                      {/* <td>
                        <div className={Studentprofile.wrapdata}>
                          <div className={Studentprofile.name}>
                            {studentListData.userId}
                          </div>
                        </div>
                      </td> */}
                      <td style={{ width: "110px" }}>{studentListData.lastLoginTime}</td>

                      {/* <td>{studentListData.active}</td> */}

                      <td
                        className={Studentprofile.cbse_table_head2}
                        style={{ padding: "0px" }}
                      >
                        <Switch
                          checked={studentListData.active}
                          onChange={(e) => {
                            switchHandler(e, studentListData.id);
                          }}
                        ></Switch>
                      </td>

                      <td style={{ width: "140px" }}>
                        <div className={Studentprofile.wrapdata}>
                          {studentListData.Action}
                        </div>
                        <div className={Studentprofile.wrapper_teacherid}>
                          <div
                            className={Studentprofile.icon}
                            onClick={() => {
                              setShowModalChangePassword(true);
                              setUserId(studentListData.userId);
                              setUserName(studentListData.userName);
                              setLoginPassword("");
                              setConfirmLoginPassword("");
                              setRegexPass(true);
                            }}
                          >
                            <div className={Studentprofile.tooltip + ' ' + 'az_tooltip'}>
                              Change Password
                            </div>
                            <span id={Studentprofile.change_password_teacherid}>
                              <img
                                className={
                                  dashboardContentStyles.btnHoverEffect
                                }
                                src={action_sa_Lock}
                                alt="Lock-btn"
                              />
                            </span>
                          </div>
                          <div className={Studentprofile.icon}>
                            <div className={Studentprofile.tooltip + ' ' + 'az_tooltip'}>Edit</div>
                            <span
                              onClick={(e) => {
                                setExpanded(true);
                                e.preventDefault();
                                var studentid = studentListData.id;
                                sessionStorage.setItem("studentid", studentid);
                                StudentsControllerIdGet(
                                  setStudentIdData,
                                  setStudentInputs
                                );
                                setNum(studentListData.mobile)
                                setIsAddStudent(false);
                              }}
                            >
                              <img
                                className={
                                  dashboardContentStyles.btnHoverEffect
                                }
                                src={action_sa_Edit}
                                alt="Lock-btn"
                              />
                            </span>
                          </div>
                          <div
                            className={Studentprofile.icon}
                            // onClick={handleShareModal}
                            onClick={() => shareHandler(studentListData)}
                          >
                            <div className={Studentprofile.tooltip + ' ' + 'az_tooltip'}>Share</div>
                            <span>
                              <img
                                className={
                                  dashboardContentStyles.btnHoverEffect
                                }
                                src={action_sa_Share}
                                alt="Lock-btn"
                              />
                            </span>
                          </div>
                          <div className={Studentprofile.icon}>
                            <div className={Studentprofile.tooltip + ' ' + 'az_tooltip'}>Remove</div>
                            <span>
                              <img
                                onClick={() => {
                                  showDeleteModal(studentListData);
                                }}
                                className={
                                  dashboardContentStyles.btnHoverEffect
                                }
                                src={action_sa_Delete}
                                alt="Lock-btn"
                              />
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <td
                  colSpan="10"
                  style={{
                    // "white-space": "nowrap",/
                    height: "23rem",
                    // overflow: "scroll",
                    // "text-overflow": "clip",
                    // margin: "0px 10px",
                  }}
                >
                  NO DATA AVAILABLE
                </td>
              )}
            </tbody>
          </table>
        </div>
        {/* pagination container - Start */}
        <div className={Studentprofile.pagination}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <span className={Studentprofile.rows}>
              {/* <label for="rows">Rows per page</label> */}
              <label htmlFor="rows">Rows per page</label>
              <select
                className="cursorPointer"
                name="rows"
                id="rows"
                value={rowsInput.rows}
                onChange={handlerRowsInputs}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </span>
          </form>
          <span>
            <Pagination1 pageDetail={pageDetail} handleChange={handleChange} />
          </span>

          {/* <div style={{ display: "block", padding: 30 }}>
            <h4>How to use Pagination Component in ReactJS?</h4>
            <Pagination
              component="div"
              count={start}
              page={pagination}
              // onPageChange={(e) => setPagination(link)}
              onClick={(e) => setPagination(2)}
            />
          </div> */}
        </div>

        {/* pagination container - End */}
      </article>
      {/* create student modal Begin */}
      <div
        className={studentmodal.flex_container}
        id={studentmodal.modal}
        style={showModal ? { display: "flex" } : { display: "none" }}
      >
        <div
          className={studentmodal.main_content}
          id={studentmodal.modal_content}
        >
          <span style={{ textAlign: "center" }}>
            <i
              style={{ color: "orange", fontSize: "50px" }}
              class="fa-regular fa-circle-check"
            ></i>
            {/* <img src={tickImage} alt="red checkmark" /> */}
          </span>

          <h5>You have successfully added student</h5>
          <h6>
            "{shareFirstName} {shareLastName} "
          </h6>
          <div className={studentmodal.modal_buttons}>
            <button
              className={studentmodal.choosefile_button_1}
              id={studentmodal.choose_button_id_1}
              onClick={handleStudentOk}
            >
              ok
            </button>
          </div>
        </div>
      </div>

      {/* create student modal End */}

      <div
        className={studentmodal.flex_container}
        id={studentmodal.modal}
        style={showModalUpdated ? { display: "flex" } : { display: "none" }}
      >
        <div
          className={studentmodal.main_content}
          id={studentmodal.modal_content}
        >
          <span style={{ textAlign: "center" }}>
            <i
              style={{ color: "orange", fontSize: "50px" }}
              class="fa-regular fa-circle-check"
            ></i>
            {/* <img src={tickImage} alt="red checkmark" /> */}
          </span>

          <h5>You have successfully Updated student</h5>
          <h6>
            "{shareFirstName} {shareLastName} "
          </h6>
          <div className={studentmodal.modal_buttons}>
            <button
              className={studentmodal.choosefile_button_1}
              id={studentmodal.choose_button_id_1}
              onClick={handleStudentUpdate}
            >
              ok
            </button>
          </div>
        </div>
      </div>

      {/* upload modal starts  */}
      <div
        className={Studentprofile.flex_container_teacherid}
        id={Studentprofile.modal_teacherid}
        style={showModalUpload ? { display: "flex" } : { display: "none" }}
      >
        <div
          className={Studentprofile.main_content_teacherid}
          id={Studentprofile.modal_content_teacherid}
        >

          <h5 className={Studentprofile.head}>
            {message !== 'Failed' ? message : "An error occurred during file upload."}
          </h5>

          {message !== 'Failed' ? <div class="row" style={{ textAlign: 'center' }}>

            <button
              className={Studentprofile.ok_btn}
              id={Studentprofile.ok_btn_1_teacherid}
              style={{ marginRight: '50%', backgroundColor: '#69ca69', border: 'none' }}
              onClick={downloadSucessCSV}
            >
              sucess csv
            </button>


            <button
              style={{ backgroundColor: '#FF0000', border: 'none' }}
              className={Studentprofile.ok_btn}
              id={Studentprofile.ok_btn_1_teacherid}
              onClick={downloadFailureCSV}
            >
              failure csv
            </button>


          </div> : <div></div>}


          <div className={Studentprofile.modal_buttons_teacherid}>
            <button
              className={Studentprofile.ok_btn}
              id={Studentprofile.ok_btn_1_teacherid}
              onClick={handleUploadModalOk}
            >
              OK
            </button>
          </div>
        </div>
      </div>
      {/* upload modal ends  */}

      {/* edit modal page starts */}
      <div
        className={studentedit.flex_container_teacherid}
        id={studentedit.modal_teacherid}
        style={showModalEdit ? { display: "flex" } : { display: "none" }}
      >
        <div
          className={studentedit.main_content_teacherid}
          id={studentedit.modal_content_teacherid}
        >
          <div className={studentedit.EditHeadContainer}>
            <h5 className={studentedit.head}>Student Details</h5>
            <i onClick={handleEditModalcross} class="fa-solid fa-xmark cursorPointer"></i>
          </div>
          <div className={studentedit.EditBox}>
            <div className={studentedit.Edithead}>
              <label className={studentedit.Input_head}> First Name</label>
              <label className={studentedit.Input}>
                {" "}
                {studentIdData.firstName}{" "}
              </label>
            </div>
            <div className={studentedit.Edithead}>
              <label className={studentedit.Input_head}> Last Name</label>
              <label className={studentedit.Input}>
                {studentIdData.lastName}
              </label>
            </div>
            <div className={studentedit.Edithead}>
              <label className={studentedit.Input_head}>
                {" "}
                Primary Phone no.
              </label>
              <label className={studentedit.Input}>
                {" "}
                {studentIdData.mobile}
              </label>
            </div>
            <div className={studentedit.Edithead}>
              <label className={studentedit.Input_head}> Primary Email</label>
              <label className={studentedit.Input}>
                {" "}
                {studentIdData.email}
              </label>
            </div>
            <div className={studentedit.Edithead}>
              <label className={studentedit.Input_head}> Date of Birth</label>
              <label className={studentedit.Input}> {studentIdData.dob}</label>
            </div>
            <div className={studentedit.Edithead}>
              <label className={studentedit.Input_head}> Gender </label>
              <label className={studentedit.Input}>
                {" "}
                {studentIdData.gender}
              </label>
            </div>
            <div className={studentedit.Edithead}>
              <label className={studentedit.Input_head}> Mother Tongue</label>
              <label className={studentedit.Input}>
                {" "}
                <div className={Studentprofile.wrapdata}>
                  {studentIdData.firstLanguage}{" "}
                </div>
              </label>
            </div>
            <div className={studentedit.Edithead}>
              <label className={studentedit.Input_head}>
                {" "}
                Second Language{" "}
              </label>
              <label className={studentedit.Input}>
                {" "}
                <div className={Studentprofile.wrapdata}>
                  {studentIdData.secondLanguage}
                </div>{" "}
              </label>
            </div>
            <div className={studentedit.Edithead}>
              <label className={studentedit.Input_head}> Admission Date </label>
              <label className={studentedit.Input}>
                {" "}
                {studentIdData.admissionDate}
              </label>
            </div>
            <div className={studentedit.Edithead}>
              <label className={studentedit.Input_head}> Grade</label>
              <label className={studentedit.Input}>
                {" "}
                {studentIdData.grade}{" "}
              </label>
            </div>
            <div className={studentedit.Edithead}>
              <label className={studentedit.Input_head}> Section</label>
              <label className={studentedit.Input}>
                {" "}
                {studentIdData.section}{" "}
              </label>
            </div>
            <div className={studentedit.Edithead}>
              <label className={studentedit.Input_head}>
                {" "}
                *Student Category{" "}
              </label>
              <label className={studentedit.Input}>
                {" "}
                <div className={Studentprofile.wrapdata}>
                  {studentIdData.studentCategory}
                </div>{" "}
              </label>
            </div>
            <div className={studentedit.Edithead} id={studentedit.Addressbox}>
              <label className={studentedit.Input_head}> Address </label>
              <label className={studentedit.Input}>
                {" "}
                {studentIdData.address}{" "}
              </label>
            </div>
          </div>

          <div className={studentedit.modal_buttons_teacherid}>
            <button
              className={studentedit.Edit_btn}
              id={studentedit.Edit_btn_1_teacherid}
              onClick={handleEditModalEdit}
            >
              <i class="fa-solid fa-pen"></i> Edit
            </button>
          </div>
        </div>
      </div>
      {/* edit modal page ends */}
      {/* change password modal starts  */}

      <div
        className={changepasswordstyle.flex_container_teacherid}
        id={changepasswordstyle.modal_teacherid}
        style={
          showModalChangePassword ? { display: "flex" } : { display: "none" }
        }
      >
        <form class={changepasswordstyle.setPassword_login_form}>
          <div class={changepasswordstyle.login_form_title}>
            <h5>Change Password</h5>
            <span>
              <i class="fa-solid fa-xmark cursorPointer" onClick={handleUpdatedPassword}></i>
            </span>
          </div>
          <div class={changepasswordstyle.password_container}>
            <fieldset className={changepasswordstyle.password_fieldset}>
              <legend>New Password</legend>
              <input
                type={passwordShown ? "text" : "password"}
                name="userPassword"
                id={changepasswordstyle.password}
                value={loginPassword}
                onChange={PassOnChange}
              />
              <i
                className={`bi ${passwordShown ? "bi-eye" : "bi-eye-slash"} ${changepasswordstyle.bi_eye_slash
                  }`}
                id={changepasswordstyle.togglePassword}
                onMouseEnter={() => {
                  toggleLoginPasswordVisibility("show");
                }}
                onMouseLeave={() => {
                  toggleLoginPasswordVisibility("dontShow");
                }}
              ></i>
            </fieldset>

          </div>
          <div class={changepasswordstyle.password_container}>
            <fieldset className={changepasswordstyle.password_fieldset}>
              <legend>Confirm Password</legend>
              <Tooltip title="Password length must be greater than or equal to 8,
                          must contain atleast one numeric, alphanumeric,
                          upper-case and lower-case alphabet"
                enterTouchDelay={1000000}
                open={isTooltipOpen}
                onOpen={handleTooltipOpen}
                onClose={handleTooltipClose}

              >
                <input
                  type={confirmPasswordShown ? "text" : "password"}
                  name="confirmPassword"
                  id={changepasswordstyle.password}
                  value={confirmLoginPassword}
                  onChange={(e) => setConfirmLoginPassword(e.target.value)}
                /></Tooltip>
              <i
                className={`bi ${confirmPasswordShown ? "bi-eye" : "bi-eye-slash"
                  } ${changepasswordstyle.bi_eye_slash}`}
                id={changepasswordstyle.togglePassword}
                onMouseEnter={() => {
                  toggleconfirmLoginPasswordVisibility("Confirmshow");
                }}
                onMouseLeave={() => {
                  toggleconfirmLoginPasswordVisibility("ConfirmdontShow");
                }}
              ></i>
            </fieldset>
            <span
              className={changepasswordstyle.error_span}
              style={isPassEmpty ? { display: "inline" } : { display: "none" }}
            >
              Password cannot be empty
            </span>
            <span
              class={changepasswordstyle.error_span}
              style={
                passwordMatch ? { display: "none" } : { display: "inline" }
              }
            >
              Passwords do not match
            </span>

            <span
              class={changepasswordstyle.error_span}
              style={regexPass ? { display: "none" } : { display: "inline" }}
            >

            </span>
          </div>
          <div class={changepasswordstyle.login_container}>
            <button
              class={changepasswordstyle.sp_login_btn}
              onClick={handleSubmit}
            >
              UPDATE
            </button>
          </div>
        </form>
      </div>

      {/* change password modal ends  */}

      {/* share modal starts  */}

      <div
        className={sharestyle.flex_container_teacherid}
        id={sharestyle.modal_teacherid}
        style={showModalShare ? { display: "flex" } : { display: "none" }}
      >
        <div
          className={sharestyle.main_content_teacherid}
          id={sharestyle.modal_content_teacherid}
        >
          <span style={{ textAlign: "center" }} className={sharestyle.img}>
            {/* <img src={tickImage} alt="red checkmark" /> */}
            <i
              style={{ color: "orange", fontSize: "50px" }}
              class="fa-regular fa-circle-check"
            ></i>
          </span>

          <h5 className={sharestyle.head}>Successfully shared User Id</h5>

          {/* <p className={sharestyle.para}>" {shareName}"</p> */}
          <p
            className={sharestyle.para}
            style={{ marginTop: "8px", fontWeight: "bold" }}
          >
            " {userName}"
          </p>
          <div className={sharestyle.modal_buttons_teacherid}>
            <button
              className={sharestyle.ok_btn}
              id={sharestyle.ok_btn_1_teacherid}
              onClick={handleShareModalOk}
            >
              OK
            </button>
          </div>
        </div>
      </div>
      {/* share modal starts  */}
      <CommonDeleteModal
        open={openDeleteModal}
        close={hideDeleteModal}
        deleteTitle={"Student"}
        deleteHandler={deleteHandler}
        deleteId={selectedItem?.id}
        deleteName={selectedItem?.firstName + " " + selectedItem?.lastName}
      />
      <Success_OK_Modal
        userType={"Student"}
        userName_Modal={SuccessDisplayName}
        open={successCreateModal}
        handleOk={closeSuccessModal}
      />
      <SuccesModalGeneral
        userType={"Student"}
        userName_Modal={SuccessDisplayName}
        open={successModalGeneral}
        handleOk={closeSuccessModal}
      />
      <ConfirmationModal
        open={toggleConfirmation}
        confirmationmessage={toggleMessage}
        close={handleConfirmationClose}
        updateToggle={updateToggle}
      />
    </>
  );
};
export default Student;