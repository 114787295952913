import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Addbranchtcss from "./../css/addbranch.module.css";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import SchoolControllerIdGet from "../API/school-controller/User_School_Id";
import BranchControllerGet from "../API/branch-controller/User_Branches";
import sharestyle from "../css/shareModal.module.css"; //create model css file
import Pagination1 from "./pagination1";
import City_Controller_getall from "../API_CONTROLLER/City_Controller/City_Controller_getall_school";
import boards_controller_getall from "../API_CONTROLLER/boards-controller/boards_controller_getall";
import plans_controller_getall from "../API_CONTROLLER/plans-controller/plans_controller_getall";
import BranchController_post from "../API/branch-controller/User_Branches_post";
import BranchControllerIdGet from "../API/branch-controller/User_Branches_id";
import GetLastModifiedAt from "../API/branch-controller/GetLastModifiedAt";
import Branch_update_Id_put from "../API/branch-controller/Branch_update_Id_put";
import { Button, Modal, Box, Typography } from "@mui/material";
import sortIcon from "../../../src/pages/img/sort_1.png";
import axios from "axios";
import { clear } from "@testing-library/user-event/dist/clear";
// import BranchDetails from "../js/data/branchDetails";
import dashHome from "../../../src/img/dashHome.svg";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import GreenAddButton from "../../components/common/GreenAddButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ConfirmationModal from "./ConfirmationModal";
import Switch from "../../components/common/Switch";
import branch_Icn from "../../../src/img/branch-list.svg";

//Action Icon Image
import action_SA_Edit from "../../../src/img/Edit.svg";

import add_circle_btn from "../../../src/img/add-circle-outline.svg";
import ConfigurationSuccessModal from "../../components/common/ConfigurationSuccessModal";

const AddbranchnoForm = () => {
  const navigate = useNavigate();
  // const [OpenPopup, setOpenPopup] = useState(false);
  // const [selectedRow, setSelectedRow] = useState();
  const [branchInputs, setbranchInputs] = useState({
    boardId: "",
    cityId: "",

    locality: "",
    // logoUrl: "",
    name: "",
    // schoolCode: "",
    plans: [
      {
        planId: "",
        planName: "",
      },
    ],
    pocEmail: "",
    // schoolId: "",
  });
  const [sortOrder, setSortOrder] = useState(false);
  const [sortBy, setSortBy] = useState("id");
  const [disabledField, setDisabledField] = useState(false);
  const [checkMapping, setCheckMapping] = useState(false);
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [num, setNum] = useState("")
  // update part
  const [isEditBranch, setIsEditBranch] = useState(false);
  const prevLocation = useLocation();
  if (prevLocation.state === "edit") {
    setIsEditBranch(true);
    prevLocation.state = null;
  }

  const token = sessionStorage.getItem("token");
  const [filterValue, setFilterValue] = useState();
  const [toggleMessage, settoggleMessage] = useState();
  const [toggleConfirmation, setToggleConfirmation] = useState(false);
  const [toggleDetails, setToggleDetails] = useState({});
  const [isUpdatedModal, setIsUpdateModal] = useState(false);

  const onCloseUpdateModal = () => {
    setIsUpdateModal(false)
  }

  const updateToggle = () => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches/${toggleDetails?.id}/active?active=${toggleDetails?.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: toggleDetails?.value,
        }
      )
      .then(function (response) {
        if (response?.data) {
          const find = branchData?.find(
            (item) => item.id === toggleDetails?.id
          );
          find.active = toggleDetails?.value;
          const clone = [...branchData];
          clone.forEach((data) => {
            data = find;
          });
          setFilterValue(clone);
          handleConfirmationClose();
          BranchControllerGet(setBranchData, setPageDetail, rowsInput, page);
        }
      })
      .catch((err) => console.log(err));
  };

  const switchHandler = async (e, id) => {
    setToggleDetails({});
    const value = e.target.checked;
    setToggleDetails({ id, value });
    if (value === false) {
      setToggleConfirmation(true);
      settoggleMessage("Are you sure you want to Deactivate this Branch");
    } else {
      setToggleConfirmation(true);
      settoggleMessage("Are you sure you want to Activate this Branch");
    }
  }
  const handleConfirmationClose = () => {
    setToggleConfirmation(false);
    setToggleDetails({});
  };

  console.log(toggleConfirmation, "switch");

  useEffect(() => {
    if (isEditBranch) {
      BranchControllerIdGet(setbranchInputs);
    }
  }, [isEditBranch]);

  const [showError, setShowError] = useState("");
  const [isError, setIsError] = useState(false);
  const handlerbranchInputs = async (e) => {
    //debugger
    if (e.target.name === "planId") {
      const currentPlan = e.target.value.split(",");
      setbranchInputs({
        ...branchInputs,
        plans: [
          {
            planId: currentPlan[0],
            planName: currentPlan[1],
          },
        ],
      });
      setIsSelectedPlanId(currentPlan[0])
    } else {
      setbranchInputs({
        ...branchInputs,
        [e.target.name]: e.target.value,
      });
    }
    if (e.target.name == "boardId") {
      const token = sessionStorage.getItem("token");
      const { data } = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/by-board-id?boardId=${e.target.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPlansList(data?.data);
    }
  };


  const handleKeyPress = (e) => {
    const result = e.target.value.replace(/[^0-9]/g, "");
    setNum(result);
  };

  function validateEmail(emailValue) {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(emailValue);
  }

  function validateMobile(mobileValue) {
    const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return regex.test(mobileValue);
  }

  const handleCreateBranch = (e) => {
    // e.preventDefault();
    for (const input in branchInputs) {
      if (branchInputs[input] === "") {
        setIsError(true);
        setShowError("Please enter all the required values");
        return;
      }
    }
    // setIsError(false);
    // other validation condtions
    if (!validateMobile(branchInputs.phoneNumber)) {
      setIsError(true);
      setShowError("Phone number invalid");
      // return;
    }
    // setIsError(false);
    if (!validateEmail(branchInputs.pocEmail)) {
      setIsError("true");
      setShowError("Please enter a valid email address");
      // return;
    }
    setIsError(false);

    BranchController_post(branchInputs, num, setSuccess, setShowError)
      .then((res) => {
        console.log(res)
        // if (!res || res === undefined) {
        //   // console.log(showError, sessionStorage.getItem("branchError"))
        //   setShowError(sessionStorage.getItem("branchError"));
        //   setTimeout(() => {
        //     setShowError("")
        //   }, 10000);
        //   return;
        // }
        setShowModalCreate(true);
        setbranchInputs({
          boardId: "",
          cityId: "",

          locality: "",
          name: "",
          // phoneNumber: "",
          plans: [
            {
              planId: "",
              planName: "",
            },
          ],
          pocEmail: "",
        })
        setNum("")
        setExpanded(false)
        BranchControllerGet(
          setBranchData,
          setPageDetail,
          rowsInput,
          page,
          sortBy,
          sortOrder
        )
        setIsSelectedPlanId("")
      }).catch((e) => {
        alert("error")
      })
  };

  const [success, setSuccess] = useState({});
  useEffect(() => {
    if (success.success === true) {
      // navigate("/dashboard/addbranchlist");
      BranchControllerGet(setBranchData, setPageDetail, rowsInput, page);
    }
    setIsError(true);
  }, [success]);

  // pagination starts
  const [rowsInput, setRowsInputs] = useState({
    rows: 10,
  });
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const handlerRowsInputs = (e) => {
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
  };
  // pagination ends

  const [pageDetail, setPageDetail] = useState({});

  // const handleCreateBranch = (e) => {
  //   e.preventDefault();
  //   updatebranchList(e, branchInputs);

  //   navigate("/dashboard/addbranchlist");
  // };

  // last update time starts
  const [planId, setplanid] = useState("")
  const [modifiedDate, setModifiedDate] = useState({});
  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);
  // last update time ends
  const [schoolIdData, setSchoolIdData] = useState({});
  const [branchData, setBranchData] = useState({});
  const [cityName, setCityName] = useState({});
  const [board, setBoard] = useState({});
  const [plansList, setPlansList] = useState([]);
  const [isSelectedPlanId, setIsSelectedPlanId] = useState('');
  useEffect(() => {
    SchoolControllerIdGet(setSchoolIdData);
    City_Controller_getall(setCityName);
    boards_controller_getall(setBoard);
    plans_controller_getall(setPlansList);
    return () => { };
  }, []);

  let userRole = localStorage.getItem("role");

  const [Expanded, setExpanded] = useState(false);
  const addBranchClickHandler = () => {
    setExpanded(true);
    setIsSelectedPlanId('')
    setPlansList([])
  };
  // const closeModal = () => {
  //   setOpenPopup(false);
  // };
  useEffect(() => {
    BranchControllerGet(setBranchData, setPageDetail, rowsInput, page);
    return () => { };
  }, [page, rowsInput]);

  async function confirmBranchApi(branchid) {
    let token = sessionStorage.getItem("token");
    let result = axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches/confirmation-api?id=${branchid}&operationType=TOGGLE`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setCheckMapping(res.data.data.mappingExists);
        console.log(res.data.data.mappingExists, "conAPI");
      })
      .catch((err) => console.log(err));
  }
  const handleCreateModalOk = () => {
    setShowModalCreate(false);
  };

  const [selectedCode, setSelectedCode] = useState("");

  const handleCodeChange = (event) => {
    setSelectedCode(event.target.value);
  };


  const callback = (res) => {
    console.log(res, "<<<<<<<< res")
    setbranchInputs(prevState => ({
      ...prevState,
      boardId: res?.boardId,
      cityId: res?.cityId,
      locality: res?.locality,
      name: res?.name,
      phoneNumber: res?.phoneNumber,
      schoolCode: res?.schoolId,
      plans: [
        {
          planId: res?.plans[0]?.planId,
          planName: res?.plans[0]?.planName,
        },
      ],
      pocEmail: res?.pocEmail,
    }));
    setIsSelectedPlanId(res?.plans[0]?.planId)
    getPlanListWithBoard(res?.boardId)
  }
  const getPlanListWithBoard = async (boardId) => {
    const token = sessionStorage.getItem("token");
    const { data } = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/by-board-id?boardId=${boardId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setPlansList(data?.data);
  }
  return (
    <>
      <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span className={dashboardContentStyles.link_icon}>
            <img
              src={dashHome}
              alt="no img"
              width="15px"
              height="20px"
              style={{ verticalAlign: "middle" }}
            />
          </span>
          {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>{" "}
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          {"  "}
          <span className={dashboardContentStyles.link_text}>
            Resigtered Schools
          </span>
          {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>{" "}
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <a>Add Branch</a>
        </div>
        <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>Last Update: {modifiedDate.data}</p>
        </div>
      </article>
      <article className={Addbranchtcss.add_schools}>
        <div className={Addbranchtcss.taken_width}>
          <div className={Addbranchtcss.edit_schools}>
            <div className={Addbranchtcss.school_logo_jpg}>
              <img
                src={schoolIdData.logoUrl}
                style={{ width: "10vw" }}
                alt="nav logo"
                width="200"
                height="150"
              />
            </div>
            <div className={Addbranchtcss.schoolName}>
              <label htmlFor="School Name" style={{ color: "orange" }}>School Name</label>
              <p title={schoolIdData.name}>
                {schoolIdData?.name?.length <= 12
                  ? schoolIdData?.name
                  : schoolIdData?.name?.slice(0, 12) + "..."}
              </p>
            </div>
            <div className={Addbranchtcss.cityName}>
              <label htmlFor="City Name" style={{ color: "orange" }}>City Name</label>
              <p title={schoolIdData.city} >
                {schoolIdData?.city?.length <= 10
                  ? schoolIdData?.city
                  : schoolIdData?.city?.slice(0, 10) + "..."}
              </p>
            </div>
            <div className={Addbranchtcss.pocEmail}>
              <label htmlFor="PocEmail" style={{ color: "orange" }}>PocEmail</label>
              <p title={schoolIdData.pocEmail} >
                {schoolIdData?.pocEmail?.length <= 15
                  ? schoolIdData?.pocEmail
                  : schoolIdData?.pocEmail?.slice(0, 15) + "..."}
              </p>
            </div>
            <div className={Addbranchtcss.phoneNumber}>
              <label htmlFor="PhoneNumber" style={{ color: "orange" }}>Phone Number</label>
              <p>{schoolIdData.phoneNumber}</p>
            </div>
            <div className={Addbranchtcss.website}>
              <label htmlFor="Website" style={{ color: "orange" }}>Website</label>
              <p>{schoolIdData.website}</p>
            </div>
            <div className={Addbranchtcss.SignatoryName}>
              <label htmlFor="Signatory Name" style={{ color: "orange" }}>Signatory Name</label>
              <p title={schoolIdData.signatoryName} >
                {schoolIdData?.signatoryName?.length <= 12
                  ? schoolIdData?.signatoryName
                  : schoolIdData?.signatoryName?.slice(0, 12) + "..."}
              </p>
            </div>
            <div className={Addbranchtcss.SignatoryRole}>
              <label htmlFor="Signatory Role" style={{ color: "orange" }}>Signatory Role</label>
              <p title={schoolIdData.signatoryRole} >
                {schoolIdData?.signatoryRole?.length <= 12
                  ? schoolIdData?.signatoryRole
                  : schoolIdData?.signatoryRole?.slice(0, 12) + "..."}
              </p>
            </div>
            <div className={Addbranchtcss.schoolType}>
              {/* <label htmlFor="School type">School Type</label>
              <p>production</p> */}
            </div>

            <div>
              <button
                className={Addbranchtcss.edit_button}
                id={Addbranchtcss.button_id_edit}
                type="button"
                name="edit-button"
                onClick={(e) => {
                  navigate("/dashboard/createschool");
                }}
              >
                {" "}
                <i className="fa fa-pencil"></i> <span>Edit</span>
              </button>
            </div>
          </div>
        </div>

        {!Expanded ? (
          <div
            className={Addbranchtcss.school_list_container}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className={Addbranchtcss.school_list_title}>
              <span className={Addbranchtcss.school_list}>
                <img
                  src={branch_Icn}
                  alt="no img"
                  style={{ verticalAlign: "middle", fontSize: "large" }}
                />
              </span>
              <span style={{ fontSize: "16px" }}> Branch List</span>
            </div>
            <div>
              <GreenAddButton
                onClick={addBranchClickHandler}
                variant="contained"
                size="small"
                sx={{
                  borderRadius: "16px",
                  backgroundColor: "#4caf50",
                  width: "16px",
                  height: "16px",
                }}
              >
                <AddCircleOutlineOutlinedIcon
                  style={{
                    marginRight: "8px",
                    marginBottom: "2px",
                    fontSize: "20px",
                    marginRight: "4px",
                  }}
                  sx={{ strokeWidth: -0.1 }}
                />
                {/* <img src={add_circle_btn} alt="no-img" style={{marginRight:"4px"}}/> */}
                Add Branch
              </GreenAddButton>
            </div>
          </div>
        ) : (
          <>
            {" "}
            <div className={Addbranchtcss.add_school_title}>
              <span className={Addbranchtcss.link_icon}>
                <img
                  src={branch_Icn}
                  alt="no img"
                  style={{ verticalAlign: "middle", fontSize: "large" }}
                />
              </span>
              <span> {isEditBranch ? "Update Branch" : "Add Branch"} </span>
            </div>
            <form
              className={Addbranchtcss.add_schools_form}
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className={Addbranchtcss.input_grid}>
                <div className={Addbranchtcss.input_element}>
                  <input
                    className={Addbranchtcss.form_input_element}
                    type="text"
                    name="name"
                    id="name"
                    value={branchInputs.name}
                    placeholder=" "
                    onChange={handlerbranchInputs}
                  />

                  <label htmlFor="name">*Branch Name</label>
                </div>
                <div className={Addbranchtcss.input_element}>
                  <select
                    className={Addbranchtcss.form_select_element}
                    name="cityId"
                    id="cityId"
                    value={branchInputs.cityId}
                    onChange={handlerbranchInputs}
                    placeholder=" "
                  >
                    <option
                      className={Addbranchtcss.option}
                      // disabled
                      selected
                      value=""
                    >
                      Select
                    </option>

                    {cityName.length > 0 ? (
                      cityName.map((cityName, index) => {
                        return (
                          <option
                            className={Addbranchtcss.option}
                            // value={cityName.city}
                            value={cityName.id}
                            id={cityName.id}
                          >
                            {cityName.city}
                          </option>
                        );
                      })
                    ) : (
                      <option
                        className={Addbranchtcss.option}
                        // value=""
                        disabled
                        selected
                        value="default"
                      >
                        Select
                      </option>
                    )}
                  </select>
                  {/* <label
                    htmlFor="cityId"
                    className={Addbranchtcss.label_name}
                    id={Addbranchtcss.city_name_id}
                  >
                    *Branch city
                  </label> */}
                  <label
                    htmlFor="cityId"
                    className={
                      branchInputs.cityId === ""
                        ? Addbranchtcss.std_cat
                        : "NONE"
                    }
                  >
                    *Branch city
                  </label>
                  <label
                    htmlFor="cityId"
                    className={
                      branchInputs.cityId !== ""
                        ? Addbranchtcss.std_cat
                        : "NONE"
                    }
                  >
                    *Branch city
                  </label>
                </div>
                <div className={Addbranchtcss.input_element}>
                  <input
                    className={Addbranchtcss.form_input_element}
                    type="text"
                    name="locality"
                    id="locality"
                    value={branchInputs.locality}
                    placeholder=" "
                    onChange={handlerbranchInputs}
                  />

                  <label htmlFor="locality">*Branch Locality</label>
                </div>
                <div className={Addbranchtcss.input_element}>
                  <input
                    className={Addbranchtcss.form_input_element}
                    type="text"
                    name="schoolCode"
                    id="schoolCode"
                    value={schoolIdData.code}
                    placeholder=""
                    onChange={handlerbranchInputs}
                    disabled
                  />
                  <label for="SchoolCode">*School Code</label>
                </div>
                <div className={Addbranchtcss.input_element}>
                  <input
                    className={Addbranchtcss.form_input_element}
                    type="email"
                    name="pocEmail"
                    id="pocEmail"
                    value={branchInputs.pocEmail}
                    placeholder=" "
                    onChange={handlerbranchInputs}
                  />
                  <label htmlFor="pocEmail">*POC Email</label>
                </div>

                <div style={{ display: "flex", justifyContent: "space-around" }}>
                  <select
                    style={{ width: "60px", height: "48px", borderRadius: "8px" }}
                    value={selectedCode}
                    onChange={handleCodeChange}
                  >
                    <option value="+1">+91</option>
                    <option value="+44">
                      +44
                    </option>
                    <option value="+91">+1</option>
                  </select>

                  <div className={Addbranchtcss.input_element}>
                    {/* <span
                    style={{ position: "absolute", top: "10px", left: "-32px" }}
                  >
                    +91
                  </span> */}
                    <input
                      className={Addbranchtcss.form_input_element}
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      value={num}
                      placeholder=""
                      maxLength={10}
                      onChange={(e) =>
                        handleKeyPress(e)
                      }
                    />
                    <label htmlFor="phoneNumber">*Phone Number</label>
                  </div>
                </div>
                <div className={Addbranchtcss.input_element}>
                  <select
                    className={Addbranchtcss.form_select_element}
                    name="boardId"
                    id="boardId"
                    value={branchInputs.boardId}
                    onChange={handlerbranchInputs}
                    disabled={(userRole === "SCHOOL_ADMIN" || checkMapping)}
                  >
                    <option
                      className={Addbranchtcss.option}
                      // selected
                      // disabled
                      // value="default"
                      value=""
                    >
                      Select
                    </option>
                    {board.length > 0 ? (
                      board.map((board, index) => {
                        return (
                          <option
                            className={Addbranchtcss.option}
                            // value={cityName.city}
                            value={board.id}
                            id={board.id}
                          >
                            {board.board}
                          </option>
                        );
                      })
                    ) : (
                      <option
                        className={Addbranchtcss.option}
                        // value=""
                        // // disabled
                        // // selected
                        value="default"
                      >
                        Select
                      </option>
                    )}
                  </select>
                  {/* <label for="cityName">*Boards</label> */}
                  {!isEditBranch && <label
                    htmlFor="cityId"
                    className={
                      branchInputs.boardId === ""
                        ? Addbranchtcss.std_cat
                        : "NONE"
                    }
                  >
                    *Board
                  </label>}
                  <label
                    htmlFor="cityId"
                    className={
                      branchInputs.boardId !== ""
                        ? Addbranchtcss.std_cat
                        : "NONE"
                    }
                  >
                    *Board
                  </label>
                </div>
                <div className={Addbranchtcss.input_element}>
                  <select
                    className={Addbranchtcss.form_select_element}
                    name="planId"
                    id="planId"
                    value={isSelectedPlanId}
                    onChange={handlerbranchInputs}
                    disabled={(userRole === "SCHOOL_ADMIN" || checkMapping)}
                  >
                    {/* <option
                      className={Addbranchtcss.option}
                      selected
                      // disabled
                      // value="default"
                      value=""
                    >
                      Select
                    </option> */}
                    {plansList?.length > 0 ? (
                      plansList?.map((item, index) => {
                        return (
                          <option key={index}
                            className={Addbranchtcss.option}
                            value={item.id}
                            id={item.id}
                            name={item.id}
                          >
                            {item?.plan ? item?.plan : ""}
                          </option>
                        );
                      })
                    ) : (
                      <option
                        className={Addbranchtcss.option}
                        // value=""
                        disabled
                        selected
                        value="default"
                      >
                        Select
                      </option>
                    )}
                  </select>
                  {/* <label for="Plan">*Plan</label> */}
                  {!isEditBranch && <label
                    htmlFor="planId"
                    className={
                      branchInputs.plans[0].planId === ""
                        ? Addbranchtcss.std_cat
                        : "NONE"
                    }
                  >
                    *Plan
                  </label>}
                  <label
                    htmlFor="planId"
                    className={
                      branchInputs.plans[0].planId !== ""
                        ? Addbranchtcss.std_cat
                        : "NONE"
                    }
                  >
                    *Plan
                  </label>
                </div>

                <div>
                  <div>

                    <div className={Addbranchtcss.input_element}>
                      <input
                        className={Addbranchtcss.form_input_element}
                        type="text"
                        name="branchCode"
                        id="branchCode"
                        value={schoolIdData.branchCode}
                        placeholder=""
                        onChange={handlerbranchInputs}
                      // disabled
                      />
                      <label for="BranchCode">*Branch Code</label>
                    </div>
                  </div>
                </div>

                <div>
                  <div
                    className={Addbranchtcss.error_box}
                    style={
                      isError
                        ? {
                          display: "block",
                          color: "red",
                        }
                        : { display: "none" }
                    }
                  >
                    {showError}
                  </div>
                </div>
                <div></div>
                <div className={Addbranchtcss.artical_button_container}>
                  <button
                    className={Addbranchtcss.artical_button_cancel}
                    id={Addbranchtcss.button_id_cancel}
                    onClick={(e) => {
                      setIsEditBranch(false)
                      e.preventDefault();
                      {
                        setbranchInputs({
                          cityId: "",
                          locality: "",
                          name: "",
                          // phoneNumber: "",
                          plans: [
                            {
                              planId: "",
                              planName: "",
                            },
                          ],
                          pocEmail: ""
                        });
                        setNum("")
                        setExpanded(false);
                        setIsSelectedPlanId("")
                        isEditBranch
                          ? BranchControllerGet(
                            setBranchData,
                            setPageDetail,
                            rowsInput,
                            page
                          )
                          : navigate("/dashboard/Registered%20Schools");
                      }
                    }}
                  >
                    Cancel
                  </button>
                  {isEditBranch ? (
                    <button
                      className={Addbranchtcss.artical_button}
                      id={Addbranchtcss.button_id}
                      onClick={() => {
                        Branch_update_Id_put(branchInputs, num, setSuccess)
                          .then(() => {
                            BranchControllerGet(
                              setBranchData,
                              setPageDetail,
                              rowsInput,
                              page,
                              sortBy,
                              sortOrder
                            )
                          })
                        setIsUpdateModal(true)
                        setIsError(false);
                        setSuccess(true);
                        // setbranchInputs({...branchInputs});
                        // window.location.reload(true);

                        setExpanded(false);
                        setIsEditBranch(false);
                        setbranchInputs({
                          cityId: "",
                          locality: "",
                          name: "",
                          // phoneNumber: "",
                          plans: [
                            {
                              planId: "",
                              planName: "",
                            },
                          ],
                          pocEmail: "",
                        });
                        setNum("")
                        setIsSelectedPlanId("")
                        setPlansList([])
                      }}
                    >
                      Update
                    </button>
                  ) : (
                    <>
                      {/* <span>asdsa dasd</span> */}
                      <button
                        className={Addbranchtcss.artical_button}
                        id={Addbranchtcss.button_id}
                        onClick={handleCreateBranch}
                      >
                        Create Branch
                      </button>
                    </>
                  )}
                </div>
              </div>

              <div className={Addbranchtcss.add_schools_btn}>
                <div className={Addbranchtcss.upload_logo_container}></div>
              </div>
            </form>
            <div className={Addbranchtcss.school_list_container}>
              <div className={Addbranchtcss.school_list_title}>
                <span className={Addbranchtcss.school_list}>
                  <img
                    src={branch_Icn}
                    alt="no img"
                    style={{ verticalAlign: "middle", fontSize: "large" }}
                  />
                </span>
                <span style={{ fontSize: "16px" }}> Branch List</span>
              </div>
            </div>
          </>
        )}

        <div className={Addbranchtcss.create_branch_list}>
          <table className={Addbranchtcss.table + ' ' + 'az_table'}>
            <thead className="az_table_head">
              <tr>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  #
                </th>
                <th scope="col">
                  <div>
                    {" "}
                    Branch name{" "}
                    <img
                      width="10"
                      height="15"
                      style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                      className="cursor-pointer"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("name");
                        await BranchControllerGet(
                          setBranchData,
                          setPageDetail,
                          rowsInput,
                          page,
                          "name",
                          !sortOrder
                        );
                      }}
                    />
                  </div>
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Branch city{" "}
                  <img
                    width="10"
                    height="15"
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("cityName");
                      await BranchControllerGet(
                        setBranchData,
                        setPageDetail,
                        rowsInput,
                        page,
                        "cityName",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Branch locality{" "}
                  <img
                    width="10"
                    height="15"
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("locality");
                      await BranchControllerGet(
                        setBranchData,
                        setPageDetail,
                        rowsInput,
                        page,
                        "locality",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Boards{" "}
                  <img
                    width="10"
                    height="15"
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("board");
                      await BranchControllerGet(
                        setBranchData,
                        setPageDetail,
                        rowsInput,
                        page,
                        "board",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Coordinators{" "}
                  <img
                    width="10"
                    height="15"
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("numberOfCoordinators");
                      await BranchControllerGet(
                        setBranchData,
                        setPageDetail,
                        rowsInput,
                        page,
                        "numberOfCoordinators",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Teachers{" "}
                  <img
                    width="10"
                    height="15"
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("numberOfTeachers");
                      await BranchControllerGet(
                        setBranchData,
                        setPageDetail,
                        rowsInput,
                        page,
                        "numberOfTeachers",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Principals{" "}
                  <img
                    width="10"
                    height="15"
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("numberOfPrincipals");
                      await BranchControllerGet(
                        setBranchData,
                        setPageDetail,
                        rowsInput,
                        page,
                        "numberOfPrincipals",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Students{" "}
                  <img
                    width="10"
                    height="15"
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("numberOfPrincipals");
                      await BranchControllerGet(
                        setBranchData,
                        setPageDetail,
                        rowsInput,
                        page,
                        "numberOfPrincipals",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Active
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="az_table_body">
              {branchData.length > 0 ? (
                branchData.map((branchData, index) => {
                  return (
                    <tr className={Addbranchtcss.data_row} key={index}>
                      <td
                        className={`${dashboardContentStyles.table_cell} cursorPointer `}
                        onClick={(e) => {
                          var branchid = branchData.id;
                          navigate("/dashboard/branchplan");
                          sessionStorage.setItem("branchid", branchid);
                          BranchControllerGet(
                            setBranchData,
                            setPageDetail,
                            rowsInput,
                            page
                          );
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className={`${dashboardContentStyles.table_cell} cursorPointer`}
                        onClick={(e) => {
                          var branchid = branchData.id;
                          navigate("/dashboard/branchplan");
                          sessionStorage.setItem("branchid", branchid);
                          BranchControllerGet(
                            setBranchData,
                            setPageDetail,
                            rowsInput,
                            page
                          );
                        }}
                      >
                        <div className={dashboardContentStyles.table_cell}>
                          {branchData.name}
                        </div>
                      </td>
                      <td
                        className={`${dashboardContentStyles.table_cell} cursorPointer`}
                        onClick={(e) => {
                          var branchid = branchData.id;
                          navigate("/dashboard/branchplan");
                          sessionStorage.setItem("branchid", branchid);
                          BranchControllerGet(
                            setBranchData,
                            setPageDetail,
                            rowsInput,
                            page
                          );
                        }}
                      >
                        {branchData.cityName}
                      </td>

                      <td
                        className={`${dashboardContentStyles.table_cell} cursorPointer`}
                        onClick={(e) => {
                          var branchid = branchData.id;
                          navigate("/dashboard/branchplan");
                          sessionStorage.setItem("branchid", branchid);
                          BranchControllerGet(
                            setBranchData,
                            setPageDetail,
                            rowsInput,
                            page
                          );
                        }}
                      >
                        {branchData.locality}
                      </td>
                      <td
                        className={`${dashboardContentStyles.table_cell} cursorPointer`}
                        onClick={(e) => {
                          var branchid = branchData.id;
                          navigate("/dashboard/branchplan");
                          sessionStorage.setItem("branchid", branchid);
                          BranchControllerGet(
                            setBranchData,
                            setPageDetail,
                            rowsInput,
                            page
                          );
                        }}
                      >
                        {branchData.board}
                      </td>
                      <td
                        className={`${dashboardContentStyles.table_cell} cursorPointer`}
                        onClick={(e) => {
                          var branchid = branchData.id;
                          navigate("/dashboard/branchplan");
                          sessionStorage.setItem("branchid", branchid);
                          BranchControllerGet(
                            setBranchData,
                            setPageDetail,
                            rowsInput,
                            page
                          );
                        }}
                      >
                        {branchData.numberOfCoordinators}
                      </td>
                      <td
                        className={`${dashboardContentStyles.table_cell} cursorPointer`}
                        onClick={(e) => {
                          var branchid = branchData.id;
                          navigate("/dashboard/branchplan");
                          sessionStorage.setItem("branchid", branchid);
                          BranchControllerGet(
                            setBranchData,
                            setPageDetail,
                            rowsInput,
                            page
                          );
                        }}
                      >
                        {branchData.numberOfTeachers}
                      </td>
                      <td
                        className={`${dashboardContentStyles.table_cell} cursorPointer`}
                        onClick={(e) => {
                          var branchid = branchData.id;
                          navigate("/dashboard/branchplan");
                          sessionStorage.setItem("branchid", branchid);
                          BranchControllerGet(
                            setBranchData,
                            setPageDetail,
                            rowsInput,
                            page
                          );
                        }}
                      >
                        {branchData.numberOfPrincipals}
                      </td>
                      <td
                        className={`${dashboardContentStyles.table_cell} cursorPointer`}
                        onClick={(e) => {
                          var branchid = branchData.id;
                          navigate("/dashboard/branchplan");
                          sessionStorage.setItem("branchid", branchid);
                          BranchControllerGet(
                            setBranchData,
                            setPageDetail,
                            rowsInput,
                            page
                          );
                        }}
                      >
                        {branchData.numberOfStudents}
                      </td>
                      <td>
                        {/* {tableRow.active} */}
                        <div>
                          <Switch
                            checked={branchData?.active}
                            onChange={(e) => switchHandler(e, branchData.id)}
                          />
                        </div>
                      </td>
                      <td className={Addbranchtcss.circle}>
                        {/* {tableRow.action} */}
                        {/* <i
                          className="fa fa-pencil"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            setExpanded(true)
                            e.preventDefault();
                            setIsEditBranch(true);
                            var branchid = branchData.id;
                            sessionStorage.setItem("branchid", branchid);

                            BranchControllerIdGet(setbranchInputs);
                             
                          }}
                        ></i> */}
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.preventDefault();
                            setExpanded(true);
                            setIsEditBranch(true);
                            var branchid = branchData.id;
                            sessionStorage.setItem("branchid", branchid);
                            BranchControllerIdGet(callback, setplanid, setNum);
                            confirmBranchApi(branchid);
                          }}
                          src={action_SA_Edit}
                          alt="Edit-Btn"
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan="10"
                    style={{
                      height: "23rem",
                    }}
                  >
                    NO DATA AVAILABLE
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* pagination container - Start */}
        <div className={Addbranchtcss.pagination}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <span className={Addbranchtcss.rows}>
              <label htmlFor="rows">Rows per page</label>
              <select
                name="rows"
                id="rows"
                value={rowsInput.rows}
                onChange={handlerRowsInputs}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </span>
          </form>
          <span>
            <Pagination1 pageDetail={pageDetail} handleChange={handleChange} />
          </span>
          {/* <branchDetails
            open={true}
            close={closeModal}
            {...branchData[selectedRow]}
          /> */}
        </div>

        {/* pagination container - End */}
        {/* <BranchDetails
          open={OpenPopup}
          close={() => {
            setOpenPopup(false)
          }}
          selectedBranch={selectedRow}
        /> */}
        <div
          className={sharestyle.flex_container_teacherid}
          id={sharestyle.modal_teacherid}
          style={showModalCreate ? { display: "flex" } : { display: "none" }}
        >
          <div
            className={sharestyle.main_content_teacherid}
            id={sharestyle.modal_content_teacherid}
          >
            <span className={sharestyle.img}>
              <CheckCircleOutlineIcon
                sx={{ fontSize: "70px", marginLeft: "10px", color: "#ffa500" }}
              />
            </span>

            <h5 className={sharestyle.head}>Successfully Created Branch</h5>

            {/* <p className={sharestyle.para}> "{teacherIDData.userId}" </p> */}
            <div className={sharestyle.modal_buttons_teacherid}>
              <button
                className={sharestyle.ok_btn}
                id={sharestyle.ok_btn_1_teacherid}
                onClick={handleCreateModalOk}
              >
                OK
              </button>
            </div>
          </div>
        </div>
        <ConfirmationModal
          open={toggleConfirmation}
          confirmationmessage={toggleMessage}
          close={handleConfirmationClose}
          updateToggle={updateToggle}
        />
      </article>

      <ConfigurationSuccessModal
        userType={"Branch Successfully Updated"}
        open={isUpdatedModal}
        handleOk={onCloseUpdateModal}
      />
    </>
  );
};
export default AddbranchnoForm;
