import sortIcon from "../../../../src/pages/img/sort_1.png";
import add_icon from "../../../../src/img/add-circle-outline.svg";
import dashHome from "../../../../src/img/dashHome.svg";
import {
    Box,
    Button,
    FormControl,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Table,
    Stack,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Modal,
    Card,
    CardContent,
    CardActions,
    Typography,
    Grid,

} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import dashboardContentStyles from "./../../css/dashboardContent.module.css";
import chapterStyles from "./../../css/createChapter.module.css";
import GetLastModifiedAt from "../../API/school-controller/GetLastModifiedAt";
import Passport_1 from "../../js/Student/Passport_1";
import Passport_2 from "./Passport_2";
import Passport_3 from "./Passport_3";
import Passport_4 from "./Passport_4";
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import YellowButton from "../../../components/common/yellowButton";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import action__Eye from "../../../../src/img/View_N.png";
import EditIconButton from "../../../components/common/EditIconButton";
import Student_Image_Open_Modal from "../Student/Student_Image_Open_Modal";
import CloseIcon from '@mui/icons-material/Close';


const ReadingPassbook = () => {

    const [sortOrder, setSortOrder] = useState(false);
    const [sortBy, setSortBy] = useState("id");
    const [passbookList, setPassbokkList] = useState();
    const [modifiedDate, setModifiedDate] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [uploadImageModel, setUploadImageModel] = useState(false);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [titleOfBook, setTitleOfBook] = useState('');
    const [open, setOpen] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadImageUrl, setUploadImageUrl] = useState("");
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState("");
    const [totalItems, setTotalItems] = useState(0);
    const [studentModel, setstudentModel] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [isFileValid, setIsFileValid] = useState(false);

    const [modalOpen, setModalOpen] = useState(false);

    const navigate = useNavigate();

    const student_grade = sessionStorage.getItem("gradeName");

    const keyToLabelMap = {

        characters: 'Characters ',
        favcharacters: 'Favourite Character ',
        rating: 'Star Value ',
        reviewtext: 'Review Text ',
        description: 'Describe the setting of the story ',
        summary: 'Summary of the Plot ',
        protagonist: 'Who is the protagonist? Describe the character ',
        antagonist: 'Who is the antagonist? Describe the character ',
        majorconflicts: 'What are the major conflicts within the story? ',
        minorconflicts: 'What are the minor conflicts within the story? ',
        turningpoint: 'What is the turning point or climax of the story? ',
        quote: 'Write a memorable quote/line/citation from the story and caption it ',
        motive: 'What is the motive that runs throughout the story? ',
        theme: 'What is the theme that runs throughout the story? ',
        storyend: 'How does the story end? ',
        recommendation: 'Would you recommend the book to somebody? Why or why not? ',
        comments: 'List five different words from the book and define them ',
        starRating: 'Rating ',
        storyFeedback: 'What I like/dislike about the story ',
        characterFeedback: 'What I like/dislike about the Main Character ',
        changes: 'What changes I want to make to the story ',
        maincharacters: 'Main Character ',
        otherCharacters: 'Other Characters '

    };

    const fetchReadingBookData = async () => {
        const response = await axios.get(
            `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/reading_submissions/search_submission_list?studentId=${sessionStorage.getItem("studentId")}&pageSize=${pageSize}&pageNumber=${page}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            }
        );
        setPassbokkList(response?.data?.data.data);
        setTotalItems(response?.data?.data.totalElements);
        console.log("data >>> ", response?.data?.data.data)
    };

    useEffect(() => {
        fetchReadingBookData();
    }, [pageSize, page]);

    useEffect(() => {
        fetchReadingBookData();
        GetLastModifiedAt(setModifiedDate);
    }, [])


    const handleChangePage = (event, newPage) => {
        setPage(newPage);

    };

    const showModel = () => {
        setstudentModel(true);
        setViewModalOpen(true);
    };


    const handleCloseViewModal = () => {
        setstudentModel(false);
        setViewModalOpen(false);
    };


    const handleViewClick = (studentData) => {
        setSelectedStudent(studentData);
        setViewModalOpen(true);
        showModel();
    };




    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));

        setPage(0);
    };


    const handleButtonClick = () => {
        setIsOpen(true);
    };

    const onClosePage = () => {
        setIsOpen(false);
        fetchReadingBookData();
    }



    // const truncateString = (str, num) => {,
    //     if (!str) return '-';
    //     if (str.length <= num) return str;
    //     return str.slice(0, num) + '...';
    // }

    const truncateString = (str, num) => {
        if (!str) return '-';
        if (str.length <= num) return str;
        return str.slice(0, num) + '...';
    }

    // const pageRefresh = () =>
    // {
    //     fetchReadingBookData();
    // }

    const handleImageUploadClick = () => {
        setUploadImageModel(true);
        setImagePreviewUrl('');
        setOpen(true);
    }

    const normalizedGrade = student_grade?.toLowerCase().trim();




    // const handleFileChange = (event) => {
    //     // setSelectedFile(event.target.files[0]);
    //     const file = event.target.files[0];
    //     if (file) {
    //         setSelectedFile(file);
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             setImagePreviewUrl(reader.result);
    //         };
    //         reader.readAsDataURL(file);
    //     }
    //     const formData = new FormData();
    //     formData.append('file', event.target.files[0]);
    //     console.log("formdata >>> ", formData);
    //     let token = sessionStorage.getItem("token");
    //     axios
    //         .post(
    //             `${process.env.REACT_APP_SWAGGER_URL}v1/api/file/upload-custom?path=Student-Service/Reading-Passport-Images`,
    //             formData,
    //             {
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     Authorization: `Bearer ${token}`,
    //                 },
    //             }
    //         )
    //         .then((res) => {
    //             console.log("uploadlog >>> ", res?.data?.data);
    //             setUploadImageUrl(res?.data?.data)

    //         })
    //         .catch((err) => console.log(err));
    // };

    const handleFileChange = (event) => {

        const file = event.target.files[0];
        if (!file) return;

        const validTypes = ['image/jpeg', 'image/png'];
        if (!validTypes.includes(file.type)) {
            alert("Invalid file type. Please select a JPEG or PNG image.");
            event.target.value = '';
            return;
        }


        setSelectedFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);


        const formData = new FormData();
        formData.append('file', file);

        console.log("formdata >>> ", formData);


        let token = sessionStorage.getItem("token");


        axios.post(
            `${process.env.REACT_APP_SWAGGER_URL}v1/api/file/upload-custom?path=Student-Service/Reading-Passport-Images`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                },
            }
        )
            .then((res) => {
                console.log("uploadlog >>> ", res?.data?.data);
                setUploadImageUrl(res?.data?.data);
            })
            .catch((err) => {
                console.error(err); // Log errors if any
                setUploadImageUrl(null);
            });
    };




    const handleUpload = () => {

        if (uploadImageUrl && titleOfBook.trim() !== '') {
            saveReview();
        } else {

            if (!uploadImageUrl) {
                alert('Please select a file to upload.');
            } else if (titleOfBook.trim() === '') {
                alert('Please enter the title of the book.');
            }
        }
    };



    const saveReview = () => {
        const payload =
        {
            "studentId": sessionStorage.getItem("studentId"),
            "studentName": sessionStorage.getItem("studentName"),
            "branchId": sessionStorage.getItem("branchId"),
            "schoolId": sessionStorage.getItem("schoolId"),
            "gradeId": sessionStorage.getItem("gradeId"),
            "grade": sessionStorage.getItem("gradeName"),
            "sectionId": sessionStorage.getItem("sectionId"),
            "section": sessionStorage.getItem("sectionName"),
            "titleOfBook": titleOfBook,
            "author": "",
            "bookDetails": {
                "book": {

                }
            },
            "reviewRating": {
                "bookRating": {
                    "bookRating": ""
                }
            },
            "imageUploaded": uploadImageUrl,

        };

        console.log("payload >>> ", payload);
        let token = sessionStorage.getItem("token");
        axios
            .post(
                `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/reading_submissions/add`,
                payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }

            )
            .then((res) => {
                console.log("res >>> ", res.data);
                setOpen(false);
                setSuccessModalOpen(true);
                // setSelectedFile(null);
                fetchReadingBookData();
                setTitleOfBook("");
                setSelectedFile(null);
                setImagePreviewUrl(null);

            })
            .catch((error) => {
                console.log(error.response.data.message);

            });
    }

    const closeModal = () => {
        setIsOpen(false);
    };

    const handleClose = () => {
        setOpen(false);


    };

    const closeSubmitModal = () => {
        setSuccessModalOpen(false);


    };



    return (
        <>
            {!isOpen ? (
                <div style={{ width: "100%" }}>
                    <article>

                        <div style={{ position: 'relative', margin: '14px' }}>
                            <span className={dashboardContentStyles.link_icon}>
                                <img
                                    src={dashHome}
                                    alt="no img"
                                    className={dashboardContentStyles.az_menu_icon}
                                />
                            </span>
                            <span
                                id={`az-breadscrum-home`}
                                className={dashboardContentStyles.az_menu_text}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
                                    sessionStorage.removeItem("sideNavSubMenuNames");
                                    navigate("/dashboard/template");
                                }}
                            >
                                Home
                            </span>
                            <span>
                                <i className="fa-solid fa-angle-right"
                                    style={{ color: "#828282" }} />
                            </span>
                            <span>
                                <i className="fa-solid fa-angle-right"
                                    style={{ color: "#828282" }} />
                            </span>
                            <span
                                className={dashboardContentStyles.az_selected_menu_text}
                            >
                                Passport
                            </span>
                        </div>

                        {/* <div className={dashboardContentStyles.dashboard_last_updated}>
                            <p>Last Update: {modifiedDate.data}</p>
                        </div> */}
                    </article>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column", // Align items in a column
                            alignItems: "flex-end", // Align items to the right
                            gap: "20px", // Add gap between items
                            marginTop: "20px",
                            marginRight: "20px"
                        }}
                    >

                        <div>
                            <div className={dashboardContentStyles.add_Chapter_Btn}>
                                <button
                                    className={dashboardContentStyles.add_Chapter_Btn}
                                    onClick={handleButtonClick}
                                >
                                    <img src={add_icon} alt="Add Icon" />
                                    <div>REVIEW - ONLINE</div>
                                </button>
                            </div>
                        </div>

                        <div>
                            <div className={dashboardContentStyles.add_Chapter_Btn}>
                                <button
                                    className={dashboardContentStyles.add_Chapter_Btn}
                                    onClick={handleImageUploadClick}
                                >
                                    <img src={add_icon} alt="Add Icon" />
                                    <div>REVIEW - UPLOAD IMAGE</div>
                                </button>
                            </div>
                        </div>
                    </div>

                    <Paper
                        style={{
                            marginTop: "15px",
                            width: "100%",
                            padding: "10px"
                        }}
                    >


                        {/* table */}
                        <Paper sx={{ width: "100%", overflow: "hidden" }}>
                            <TableContainer
                                sx={{
                                    overflow: "auto",
                                    paddingBottom: "4rem"
                                }}
                            >
                                <Table
                                    sx={{ minWidth: 650 }}
                                    stickyHeader={true}
                                    aria-label="customized table"
                                >
                                    <TableHead>
                                        <TableRow >
                                            <TableCell className={dashboardContentStyles.table_head}>
                                                #
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                className={dashboardContentStyles.table_head}
                                            >
                                                Book Name
                                                <img
                                                    style={{
                                                        verticalAlign: "middle",
                                                        paddingLeft: "3px",
                                                        marginBottom: "2px",
                                                    }}
                                                    className="cursor-pointer"
                                                    width="14"
                                                    height="15"
                                                    src={sortIcon}
                                                    onClick={async () => {
                                                        await setSortOrder(!sortOrder);
                                                        await setSortBy("BookName");
                                                        // await initData(!sortOrder, "id");
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                className={dashboardContentStyles.table_head}
                                            >
                                                Author
                                                <img
                                                    style={{
                                                        verticalAlign: "middle",
                                                        paddingLeft: "3px",
                                                        marginLeft: "5px",
                                                        marginBottom: "2px",
                                                    }}
                                                    className="cursor-pointer"
                                                    width="14"
                                                    height="15"
                                                    src={sortIcon}

                                                />
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                className={dashboardContentStyles.table_head}
                                            >
                                                Submitted On
                                                <img
                                                    style={{
                                                        verticalAlign: "middle",
                                                        marginLeft: "5px",
                                                        paddingLeft: "3px",
                                                        marginBottom: "2px",
                                                    }}
                                                    className="cursor-pointer"
                                                    width="14"
                                                    height="15"
                                                    src={sortIcon}
                                                    onClick={async () => {
                                                        await setSortOrder(!sortOrder);
                                                        await setSortBy("Review");
                                                        // await initData(!sortOrder, "id");
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                className={dashboardContentStyles.table_head}
                                            >
                                                Assigned Stars
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                className={dashboardContentStyles.table_head}
                                            >
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody sx={{

                                    }}>
                                        {passbookList?.length > 0 ? (
                                            passbookList.map((elem, i) => (
                                                <TableRow sx={{
                                                    // "&:nth-child(even)": {
                                                    //     background: "#F5F5F8"
                                                    // },
                                                    backgroundColor: i % 2 === 0 ? '#ffffff' : '#f5f5f8',
                                                    "&:last-child td, &:last-child th": {
                                                        borderBottom: "none !important",
                                                    },

                                                    "&:hover": {

                                                        "& td, & th": {
                                                            borderTop: "1px solid #ff9702",
                                                            borderBottom: "1px solid #ff9702",
                                                        },
                                                        " & th:first-of-type": {
                                                            borderLeft: "1px solid #ff9702",
                                                        },

                                                        "& td:first-of-type": {

                                                            borderLeft: "1px solid #ff9702",

                                                        },

                                                        "&:last-child td, &:last-child th": {
                                                            borderBottom: "1px solid #ff9702 !important",
                                                        },

                                                        "& td:last-of-type": {
                                                            borderRight: "1px solid #ff9702",

                                                        },

                                                        "& th:last-of-type": {
                                                            borderRight: "0",
                                                        },
                                                    },

                                                    "& td, & th": {
                                                        border: "1px solid transparent",
                                                        borderTop: "1px solid transparent",
                                                        borderBottom: "1px solid transparent",
                                                        "&:not(:last-child)": {
                                                            borderRight: "1px solid transparent",
                                                        },
                                                    },


                                                }} >
                                                    {/* index */}
                                                    <TableCell className={dashboardContentStyles.table_cell}>
                                                        {page * pageSize + i + 1}
                                                    </TableCell>
                                                    {/* index */}
                                                    <TableCell className={dashboardContentStyles.table_cell}>
                                                        <Tooltip title={elem.titleOfBook} arrow>
                                                            <span>
                                                                {truncateString(elem.titleOfBook, 50)}
                                                            </span>
                                                        </Tooltip>
                                                    </TableCell>
                                                    {/* index */}
                                                    <TableCell
                                                        className={dashboardContentStyles.table_cell}
                                                        align="center"
                                                    >
                                                        {truncateString(elem.author, 20)}
                                                    </TableCell>
                                                    {/* index */}
                                                    <TableCell
                                                        className={dashboardContentStyles.table_cell}
                                                        align="center"
                                                    >
                                                        {elem.dateOfSubmission ? elem.dateOfSubmission : '-'}
                                                    </TableCell>
                                                    {/* index */}
                                                    <TableCell className={dashboardContentStyles.table_cell} align="center">
                                                        {/* {elem.reviewRating && elem.reviewRating.bookRating && elem.reviewRating.bookRating.bookRating
                                                            ? elem.reviewRating.bookRating.bookRating
                                                            : '-'} */}
                                                        {elem.reviewRating?.bookRating?.starRating || elem.reviewRating?.bookRating?.rating || elem.reviewRating?.bookRating?.bookRating
                                                            ? elem.reviewRating.bookRating.starRating || elem.reviewRating.bookRating.rating || elem.reviewRating?.bookRating?.bookRating
                                                            : '-'}
                                                    </TableCell>
                                                    <TableCell
                                                        className={dashboardContentStyles.table_cell}
                                                        align="right"
                                                        style={{ paddingLeft: 50 }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                marginTop: "-10px",
                                                            }}
                                                        >
                                                            <EditIconButton
                                                                onClick={() => handleViewClick(elem)}
                                                            >
                                                                <Tooltip title="View" placement={"top"}>
                                                                    <img
                                                                        className={
                                                                            dashboardContentStyles.aRoleActionBtn
                                                                        }
                                                                        src={action__Eye}
                                                                        alt="Edit-Btn"
                                                                    />
                                                                </Tooltip>
                                                            </EditIconButton>
                                                        </div>
                                                    </TableCell>

                                                </TableRow>
                                            ))
                                        ) : (
                                            <td
                                                colSpan="10"
                                                style={{
                                                    height: "25rem",
                                                    textAlign: "center",
                                                    color: "grey",
                                                }}
                                            >
                                                {" "}
                                                NO DATA AVAILABLE{" "}
                                            </td>
                                        )}
                                    </TableBody>


                                </Table>
                            </TableContainer>
                            <TablePagination
                                style={{
                                    bottom: "0",
                                    width: "81vw",
                                    position: "fixed",
                                    backgroundColor: "white",
                                }}
                                rowsPerPageOptions={[10, 20, 50]}
                                component="div"
                                count={totalItems}
                                // count={passbookList?.length}
                                rowsPerPage={pageSize}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                showFirstButton={true}
                                showLastButton={true}
                            />

                        </Paper>
                    </Paper>

                </div>
            ) :
                (
                    (normalizedGrade === 'nursery' || normalizedGrade === 'junior kg' || normalizedGrade === 'senior kg')
                        ?
                        <Passport_1
                            onClosePage={onClosePage}
                        /> :
                        (
                            (normalizedGrade === 'grade 1' || normalizedGrade === 'grade 2')
                                ?
                                <Passport_2
                                    onClosePage={onClosePage} />
                                :
                                (
                                    (normalizedGrade === 'grade 3' || normalizedGrade === 'grade 4')
                                        ?
                                        <Passport_3
                                            onClosePage={onClosePage} />
                                        :
                                        (
                                            <Passport_4
                                                onClosePage={onClosePage}
                                            />
                                            // <Passport_3/>
                                        )
                                )
                        )
                )}

            {uploadImageModel ? (
                <Dialog open={open} onClose={handleClose} style={{ minWidth: '500px' }}>
                    <DialogTitle style={{ backgroundColor: '#403e75', color: 'white', textAlign: 'center' }}>
                        Upload Image
                    </DialogTitle>
                    <DialogContent style={{ padding: '20px' }}>
                        <textarea
                            placeholder="Title of the book"
                            value={titleOfBook}
                            maxLength={50}
                            onChange={(e) => setTitleOfBook(e.target.value)}
                            style={{
                                width: '100%',
                                height: '50px',
                                marginTop: '10px',
                                padding: '10px',
                                border: '1px solid #ccc',
                                boxShadow: 'inset 0 1px 3px rgba(0,0,0,0.1)',
                                fontSize: '16px',
                                color: '#333',
                                backgroundColor: '#f8f8f8',
                                resize: 'none',

                            }}

                        />
                        <input
                            type="file"
                            onChange={handleFileChange}
                            className="cursorPointer"
                            style={{ display: 'block', marginBottom: '10px' }}
                        />
                        {imagePreviewUrl && (
                            <img
                                src={imagePreviewUrl}
                                alt="Preview"
                                style={{ marginTop: 10, maxWidth: '100%', maxHeight: '400px', display: 'block', margin: 'auto' }}
                            />
                        )}
                        {/* <textarea
                            placeholder="Title of the book"
                            value={titleOfBook}
                            maxLength={50}
                            onChange={(e) => setTitleOfBook(e.target.value)}
                            style={{
                                width: '100%',
                                height: '50px',
                                marginTop: '10px',
                                padding: '10px',
                                border: '1px solid #ccc',
                                boxShadow: 'inset 0 1px 3px rgba(0,0,0,0.1)',
                                fontSize: '16px',
                                color: '#333',
                                backgroundColor: '#f8f8f8',

                            }}
                        /> */}
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'space-around', padding: '20px' }}>
                        <Button
                            onClick={handleClose}
                            variant="contained"
                            style={{
                                minWidth: '100px',
                                backgroundColor: '#999999',
                                color: 'white'
                            }}>
                            Cancel
                        </Button>
                        <Button
                            onClick={handleUpload}
                            disabled={!selectedFile}
                            variant="contained"
                            style={{
                                minWidth: '100px',
                                backgroundColor: '#403e75',
                                color: 'white',
                                opacity: selectedFile ? 1 : 0.5
                            }}>
                            Upload
                        </Button>
                    </DialogActions>
                </Dialog>

            ) : (
                <></>
            )

            }
            <Modal
                open={successModalOpen}
                onClose={closeSubmitModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 500,
                    bgcolor: "background.paper",
                    // border: "2px solid #000",
                    boxShadow: 24,
                    borderRadius: 2,
                    p: 4
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <div style={{ display: "flex", justifyContent: "center" }}>


                            <CheckCircleOutlinedIcon style={{ color: "#FFA700", fontSize: 130 }} />

                        </div>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                // flexDirection: "column",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    rowGap: "2rem",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        rowGap: "1.25rem",
                                    }}
                                >
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        Review submitted Successfully

                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        columnGap: "1rem",
                                    }}
                                >

                                    <YellowButton
                                        onClick={closeSubmitModal}
                                    >
                                        Ok
                                    </YellowButton>
                                </div>
                            </div>
                        </div>
                    </Typography>
                </Box>
            </Modal>
            {studentModel ? (
                <Dialog open={viewModalOpen} onClose={handleCloseViewModal}>

                    <DialogTitle
                        style={{
                            textAlign: "center",
                            fontSize: "24px",
                            fontWeight: "700",
                            color: "#403e75",
                            backgroundColor: "#f4f4f9",
                            borderBottom: "1px solid #ccc",
                            fontFamily: "Poppins, sans-serif",
                            position: "relative",
                        }}
                    >
                        Reading Passport Review
                        <IconButton
                            aria-label="close"
                            onClick={handleCloseViewModal}
                            style={{
                                color: "#403e75",
                                padding: "8px",
                                position: "absolute",
                                top: 0,
                                right: 0,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent
                        style={{
                            margin: "20px",
                            padding: "20px",
                            height: "400px",
                            overflowY: "auto",
                            backgroundColor: "#f4f4f9",
                            boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                            fontFamily: "Poppins, sans-serif",
                        }}
                    >
                        {selectedStudent && (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "20px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "20px",
                                        marginBottom: "20px",
                                    }}
                                >
                                    <span
                                        style={{
                                            margin: "0 10px",
                                            backgroundColor: "#ffa500",
                                            color: "#fff",
                                            padding: "5px 10px",
                                            textTransform: "capitalize",
                                        }}
                                    >
                                        {" "}
                                        {/* {selectedStudent.studentName} */}
                                        {selectedStudent.studentName.replace(/(?<!^)([A-Z])/g, ' $1').trim()}
                                    </span>
                                    <span
                                        style={{
                                            margin: "0 10px",
                                            backgroundColor: "#ffa500",
                                            color: "#fff",
                                            padding: "5px 10px",
                                        }}
                                    >
                                        {selectedStudent.grade}
                                    </span>
                                    <span
                                        style={{
                                            margin: "0 10px",
                                            backgroundColor: "#ffa500",
                                            color: "#fff",
                                            padding: "5px 10px",
                                        }}
                                    >
                                        {" "}
                                        {selectedStudent.section}
                                    </span>
                                </div>

                                <div
                                    style={{ borderTop: "3px solid #eee", paddingTop: "20px" }}
                                >
                                    <form
                                        style={{
                                            padding: "20px",
                                            backgroundColor: "#fff",
                                            borderRadius: "8px",
                                            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                        }}
                                    >

                                        <Typography
                                            variant="h5"
                                            component="div"
                                            style={{
                                                marginBottom: "10px",
                                                color: "#403e75",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {selectedStudent.titleOfBook || "No Title Available"}
                                        </Typography>

                                        {selectedStudent.author && (
                                            <>


                                                <Typography
                                                    variant="subtitle1"
                                                    component="div"
                                                    style={{ marginBottom: "20px", color: "#555" }}
                                                >
                                                    Author: {selectedStudent.author}
                                                </Typography>
                                            </>
                                        )}

                                        <Typography
                                            variant="h6"
                                            component="div"
                                            style={{ marginBottom: "20px", color: "#403e75" }}
                                        >
                                            Book Details
                                        </Typography>
                                        {selectedStudent &&
                                            Object.entries({
                                                ...selectedStudent.bookDetails.book,
                                                ...selectedStudent.reviewRating.bookRating,
                                            }).filter(([key, value]) => value).length > 0 ? (
                                            Object.entries({
                                                ...selectedStudent.bookDetails.book,
                                                ...selectedStudent.reviewRating.bookRating,
                                            })
                                                .filter(([key, value]) => value)
                                                .map(([key, value]) => (
                                                    <div
                                                        key={key}
                                                        style={{
                                                            marginBottom: "20px",
                                                        }}
                                                    >
                                                        <label
                                                            htmlFor={key}
                                                            style={{
                                                                display: "block",
                                                                marginBottom: "5px",
                                                                color: "#555",
                                                                fontWeight: "bold",
                                                                // textTransform: "capitalize",
                                                            }}
                                                        >
                                                            {/* {key.replace(/([A-Z])/g, " $1").trim()}: */}
                                                            {keyToLabelMap[key] || key.replace(/([A-Z])/g, " $1").trim()}:
                                                        </label>
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                minHeight: "50px",
                                                                padding: "10px",
                                                                border: "1px solid #ccc",
                                                                borderRadius: "4px",
                                                                color: "#666",
                                                                backgroundColor: "#f9f9f9",
                                                                overflowY: "auto",
                                                                whiteSpace: "pre-wrap",
                                                                wordBreak: "break-word",
                                                            }}
                                                        >
                                                            {value}
                                                        </div>
                                                    </div>
                                                ))
                                        ) : (
                                            <Typography variant="body2" style={{ color: "#999" }}>
                                                No data available
                                            </Typography>
                                        )}
                                    </form>
                                </div>

                                {selectedStudent.imageUploaded && (
                                    <div
                                        style={{
                                            textAlign: "center",
                                            marginTop: "20px",
                                            borderTop: "1px solid #eee",
                                            paddingTop: "20px",
                                        }}
                                    >
                                        <img
                                            src={selectedStudent.imageUploaded}
                                            alt="Uploaded Book"
                                            style={{
                                                maxWidth: "100%",
                                                height: "auto",
                                                borderRadius: "8px",
                                                boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                                                minHeight: '200px',
                                                maxHeight: '500px',
                                            }}
                                            onClick={() => {
                                                setSelectedImageUrl(selectedStudent.imageUploaded);
                                                setModalOpen(true);
                                            }}
                                        />

                                    </div>
                                )}
                            </div>
                        )}
                    </DialogContent>
                    <Student_Image_Open_Modal
                        selectedImageUrl={selectedImageUrl}
                        modalOpen={modalOpen}
                        setModalOpen={setModalOpen}
                    />


                    {/* <DialogActions>
                        <Button
                            onClick={handleCloseViewModal}
                            sx={{
                                color: "#fff",
                                backgroundColor: "#5A4EAD",
                                "&:hover": {
                                    backgroundColor: "#483D8B"
                                },
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions> */}
                </Dialog>

            ) : (
                <></>
            )}





        </>
    );
};

export default ReadingPassbook;