import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import regschool from "./../css/RegisteredSchool.module.css";
import dashboardContentStyles from "./../css/dashboardContent.module.css"; //header css
import Switch from "../../components/common/Switch";
import MappingAlert from "../../components/common/MappingAlert";
import sortIcon from "../../../src/pages/img/sort_1.png";
import SchoolAdminBreadcrumb from "./SchoolAdminBreadcrumb";
import commonsort from "../js/CommonSort"

//Add School Button
import GreenAddButton from "../../components/common/GreenAddButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ConfirmationModal from "./ConfirmationModal";
// Total Count Icons
import school_icon from "../../../src/img/school-icon.svg";
import teachers_icon from "../../../src/img/Teacher_02.svg";
import coordinators_icon from "../../../src/img/HOD_02.svg";
import principals_icon from "../../../src/img/principals-icon.svg";
import students_icon from "../../../src/img/student-icon.svg";

// import paginatedList from "./pagination";
// import Pagination from "@material-ui/lab/Pagination";
import SchoolControllerAll from "../API/school-controller/user_schools_all";
import GetLastModifiedAt from "../API/school-controller/GetLastModifiedAt";
import SchoolControllerGet from "../API/school-controller/User_Schools";
import SchoolControllerCountGet from "../API/school-controller/User_Schools_Count";
import boards_controller_getall from "../API_CONTROLLER/boards-controller/boards_controller_getall";
import Pagination1 from "./pagination1";
import dashHome from "../../../src/img/dashHome.svg";
import ClearIcon from "@mui/icons-material/Clear";
import searchIcn from '../../../src/img/search.svg';
import {
  IconButton,
  TextField,
} from "@mui/material";
const RegisteredSchool = (Props) => {
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [isMappingExist, setIsMappingExist] = useState(false);
  const [dropdownTogle, setDropdownTogle] = useState(false);
  const [checkedSelect, setcheckedSelect] = useState("Board");
  const [boardId, setBoardId] = useState([]);
  // pagination ends
  const [rowsInput, setRowsInputs] = useState({ rows: 10 });
  const [page, setPage] = useState(1);
  const [pageDetail, setPageDetail] = useState({});
  const [schoolListData, setSchoolListData] = useState([]);
  const [schoolCount, setSchoolCount] = useState({});
  const [board, setBoard] = useState({});
  const [mappingAlert, setMappingAlert] = useState("");
  // const [schoolList , setSchoolList] = useState("")
  const [sortOrder, setSortOrder] = useState(false);
  const [sortBy, setSortBy] = useState("id");
  const [totalele, setTotalelements] = useState(0);

  const [showModalEdit, setShowModalEdit] = useState(false);
  let schoolList = Props.schoolList;
  const handleAddSchool = (e) => {
    e.preventDefault();
    navigate("/dashboard/createschool", {
      state: "edit",
    });
  };

  const showAlertHandler = () => {
    // setOpenDeleteModal(true);
    setShowAlert(true);
  };

  const hideAlertHandler = () => {
    setShowAlert(false);
  };

  // const checkSchoolMapping = async (id, value) => {
  //   const schoolMap = await axios.get(
  //     `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/schools/check-mapping?id=${id}&operationType=TOGGLE`,
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     }
  //   );

  //   let mappingRes = schoolMap.data.data;

  //   if (mappingRes?.length > 54) {
  //     setIsMappingExist(true);
  //     setShowAlert(true);
  //   } else {
  //     handletoggleChange(id, value);
  //   }
  // };
  const [updatedData, setUpdatedData] = useState();
  const [toggleDetails, setToggleDetails] = useState({});
  const [toggleMessage, settoggleMessage] = useState();
  const [toggleConfirmation, setToggleConfirmation] = useState(false);
  const [filterValue, setFilterValue] = useState([]);
  const handleConfirmationClose = () => {
    setToggleConfirmation(false);
    setToggleDetails({});
  };

  const [toggleActive, setToggleActive] = useState("");

  const updateToggle = () => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/schools/${toggleDetails?.id}/active?active=${toggleDetails?.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: toggleDetails?.value,
        }
      )
      .then(function (response) {
        SchoolControllerGet(
          rowsInput,
          setSchoolListData,
          setPageDetail,
          page,
          boardId,
          search
        );
        handleConfirmationClose();
        if (response?.data) {
          const find = updatedData?.find(
            (item) => item.id === toggleDetails?.id
          );
          find.active = toggleDetails?.value;
          const clone = [...updatedData];
          clone.forEach((data) => {
            data = find;
          });
          setFilterValue(clone);
        }
      })
      .catch((err) => console.log(err));
  };

  const switchHandler = async (e, id) => {
    setToggleDetails({});
    const value = e.target.checked;
    setToggleDetails({ id, value });
    if (value === false) {
      setToggleConfirmation(true);
      settoggleMessage("Are you sure you want to Deactivate this School");
    } else {
      setToggleConfirmation(true);
      settoggleMessage("Are you sure you want to Activate this School");
    }
  }

  // const handletoggleChange = (id, value) => {
  //   // const value = e.target.checked;
  //   var active = !value
  //   // setToggleActive(e.target.checked);
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/schools/${id}/active?active=${active}`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       SchoolControllerGet(
  //         rowsInput,
  //         setSchoolListData,
  //         setPageDetail,
  //         page,
  //         boardId,
  //         search
  //       );
  //     })
  //     .catch((err) => console.log(err));
  // };

  const handleDropdownTogle = () => {
    setDropdownTogle(dropdownTogle ? false : true);
  };

  const [boardCheckId, setBoardCheckId] = useState("")

  const handlerCheckedSelect = (e) => {
    e.preventDefault();
    setBoardCheckId(e.target.id)
    let TempBoardId = []
    if (e.target.checked === true) {
      setcheckedSelect(e.target.value);
      TempBoardId = [...boardId, e.target.id]
    } else {
      TempBoardId = boardId.filter((v) => v != e.target.id);
      setcheckedSelect("Board");
    }
    setBoardId(TempBoardId);

    let schoolid = sessionStorage.getItem("schoolid");
    let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/schools/counts?boardId=${TempBoardId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        setSchoolCount(resp.data.data)
      })
      .catch(err => console.log(err))

  };
  const icseSchoolList = schoolList.filter(
    (school) => school.schoolType1 === "ICSE"
  );

  // search starts
  const [isSearch, setisSearch] = useState(true);

  const [search, setSearch] = useState("");
  const [showClear, setShowClear] = useState(false);
  const [query, setQuery] = useState("");

  const handleClear = () => {
    setQuery("");
    setShowClear(false);
    SchoolControllerGet(
      rowsInput,
      setSchoolListData,
      setPageDetail,
      page,
      boardId,
      search
    );
  };

  const handleInputChange = (e) => {
    const inputValue = e?.target?.value;
    if (inputValue) {
      let result = [...totalele];
      result = [...totalele]?.filter((data) => {
        return data?.name?.toLowerCase().includes(inputValue?.toLowerCase());
      });
      setSchoolListData(result)
    } else {
      SchoolControllerGet(
        rowsInput,
        setSchoolListData,
        setPageDetail,
        page,
        boardId,
        search
      );
    }
    setQuery(inputValue);
    setShowClear(inputValue !== "");
  };




  useEffect(() => {
    if (isSearch) {
      searchingData()
    }
  }, [])

  const searchingData = async () => {
    setisSearch(false);
    let token = sessionStorage.getItem("token");
    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/schools?pageNumber=0&pageSize=100&sortBy=${sortBy || "id"}&order=${sortOrder || false}&search=${search}&boardId=${boardId}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        }
      },)
    const totlaele = result?.data?.data?.totalElements
    let response = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/schools?pageNumber=0&pageSize=${totlaele}&sortBy=${sortBy || "id"}&order=${sortOrder || false}&search=${search}&boardId=${boardId}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        }
      },)
    const totalData = response?.data?.data?.data;
    var sortedData = await commonsort(response?.data?.data?.data || [], sortBy, sortOrder);
    setTotalelements(totalData);
  }













  useEffect(() => {
    if (search.search === "") {
      SchoolControllerGet(
        rowsInput,
        setSchoolListData,
        setPageDetail,
        page,
        boardId,
        search,

      );

      return () => { };
    }
  }, [rowsInput, page, boardId, search]);
  // search ends
  // pagination starts

  const handleChange = (event, value) => {
    setPage(value);
  };
  const handlerRowsInputs = (e) => {
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
    setPage(1);
  };
  useEffect(() => {
    SchoolControllerGet(
      rowsInput,
      setSchoolListData,
      setPageDetail,
      page,
      boardId,
      search,
      sortBy,
      sortOrder
    );

    return () => { };
  }, [rowsInput, page, boardId.sortBy, sortOrder]);

  async function SchoolControllerCountGet(setSchoolCount) {
    let token = sessionStorage.getItem("token");
    let schoolid = sessionStorage.getItem("schoolid");
    let boardId = sessionStorage.getItem("boardId")

    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/schools/counts?`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        setSchoolCount(resp.data.data)
      })
      .catch(err => console.log(err))

  }

  useEffect(() => {
    SchoolControllerCountGet(setSchoolCount);
    return () => { };
  }, [schoolListData.id]);
  useEffect(() => {
    boards_controller_getall(setBoard);
    return () => { };
  }, []);

  // last update time starts
  const [modifiedDate, setModifiedDate] = useState({});
  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);
  // last update time ends
  return (
    <>
      <SchoolAdminBreadcrumb
        dashHome={dashHome}
        sectionName={'Registered Schools'}
        date={modifiedDate.data}
      />
      <article className={regschool.add_schools} style={{ paddingTop: "10px" }}>
        <div className={regschool.grid_container}>
          <div className={regschool.dropdown}>
            <button
              style={{ display: "flex" }}
              className={regschool.dropbtn}
              name="checked"
              onClick={handleDropdownTogle}
            >
              {checkedSelect}
              <i className="fa fa-angle-down" style={{ marginTop: "1px" }}></i>
            </button>

            <div
              className={regschool.dropdown_content}
              style={dropdownTogle ? { display: "block" } : { display: "none" }}
            >
              {board?.length > 0 ? (
                board.map((board, index) => {
                  return (
                    <div key={index} className={regschool.checked} value="ICSE">
                      <input
                        type="checkbox"
                        onChange={handlerCheckedSelect}
                        name="radio"
                        id={board.id}
                        value={board.board}
                        checked={boardId.includes(board.id)}
                      />
                      <span key={index} className={regschool.text}>
                        {board.board}
                      </span>
                      <hr></hr>
                    </div>
                  );
                })
              ) : (
                <div></div>
              )}
            </div>
          </div>

          <div className={regschool.grid_item}>
            <div className={regschool.column} style={{ marginLeft: "25px" }}>
              <span className={regschool.link_icon_item2}>
                {/* <i className="fas fa-university"> </i> */}
                <img src={school_icon} alt="icon" />
              </span>
              <span
                className={regschool.school_data}
                style={{ marginRight: "35px" }}
              >
                <span>{schoolCount.schoolCount}</span>
                <span>Schools</span>
              </span>
            </div>
            <div
              className={regschool.column}
              style={{ marginRight: "55px", marginLeft: "15px" }}
            >
              <span className={regschool.link_icon_item2}>
                {/* <i className="fa-solid fa-chalkboard-user"></i> */}
                <img src={teachers_icon} alt="icon" />
              </span>
              <span
                className={regschool.school_data}
                style={{ marginRight: "20px" }}
              >
                <span>{schoolCount.teacherCount}</span>
                <span>Teachers</span>
              </span>
            </div>{" "}
            <div
              className={regschool.column}
              style={{ marginRight: "40px", paddingRight: "20px" }}
            >
              <span className={regschool.link_icon_item2}>
                {/* <i className="fa-solid fa-user-tie"></i> */}
                <img src={coordinators_icon} alt="icon" />
              </span>
              <span
                className={regschool.school_data}
                style={{ paddingLeft: "6px" }}
              >
                <span>{schoolCount.coordinatorCount}</span>
                <span>Coordinators</span>
              </span>
            </div>
            <div className={regschool.column} style={{ paddingRight: "12px" }}>
              <span className={regschool.link_icon_item2}>
                {/* <i className="fa-solid fa-user-tie"></i> */}
                <img src={principals_icon} alt="icon" />
              </span>
              <span
                className={regschool.school_data}
                style={{ paddingRight: "8px" }}
              >
                <span>{schoolCount.principalCount}</span>
                <span>Principals</span>
              </span>
            </div>
            <div className={regschool.column}>
              <span className={regschool.link_icon_item2}>
                {" "}
                {/* <i className="fa-solid fa-chalkboard-user"></i> */}
                <img src={students_icon} alt="icon" />
              </span>
              <span
                className={regschool.school_data}
                style={{ paddingRight: "20px" }}
              >
                <span>{schoolCount.studentCount}</span>
                <span>Students</span>
              </span>
            </div>
          </div>
        </div>

        <div className={regschool.school_list_container}>
          <div className={regschool.school_list_title}>
            <span className={regschool.school_list}>
              <i className="fas fa-school"> </i>
            </span>
            <span> Schools List</span>
          </div>
          <div className={regschool.school_list_btn_container}>
            <div >
              <TextField
                onChange={handleInputChange}
                type={"text"}
                value={query}
                placeholder={"Search anything..."}
                sx={{
                  "& .MuiInputBase-root": {
                    width: "247px !important",
                    height: "32px !important",
                    borderRadius: "50px !important",
                    fontFamily: "Poppins !important",
                    fontSize: "13px",
                    marginLeft: "30px !important",
                    background: "#FFFFFF",
                    opacity: 1,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      {showClear && (
                        <IconButton onClick={handleClear}>
                          <ClearIcon
                            style={{ color: "#F05262", fontSize: "1vw" }}
                          />
                        </IconButton>
                      )}
                      {!showClear && (
                        <IconButton>
                          <img
                            src={searchIcn}
                            style={{
                              verticalAlign: "top",
                              fontSize: "large",
                            }}
                            aria-hidden="true"
                          />
                        </IconButton>
                      )}
                    </>
                  ),
                }}
              />
            </div>

            <div style={{ paddingLeft: "20px" }}>
              <GreenAddButton
                className={regschool.add_school_button}
                type="submit"
                value="Add School"
                // onClick={handleAddSchool}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/dashboard/createschool", { state: "edit" });
                  sessionStorage.removeItem("schoolid");
                }}
              >
                <i className="fa fa-plus-circle"></i>
                <span className={regschool.Add_school}> Add School</span>
                {/* <AddCircleOutlineOutlinedIcon
                  style={{
                    marginRight: "5px",
                    marginBottom: "2px",
                    fontSize: "22px",
                  }}
                /> 
                Add School */}
              </GreenAddButton>
            </div>
          </div>
        </div>

        <div className={regschool.school_list_table}>
          {/* <article className={regschool.main_container}> */}
          <table className={regschool.table + ' ' + 'az_table'}>
            <thead className="az_table_head">
              <tr>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  #
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Schools Name{" "}
                  <img
                    style={{ verticalAlign: "middle", marginBottom: "2px" }}
                    width="10"
                    height="15"
                    className="cursor-pointer"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("name");
                      await SchoolControllerGet(
                        rowsInput,
                        setSchoolListData,
                        setPageDetail,
                        page,
                        boardId,
                        search,
                        "name",
                        !sortOrder,
                        sortBy
                      );
                    }}
                  />
                </th>

                <th scope="col" className={dashboardContentStyles.table_head}>
                  Board{" "}
                  <img
                    style={{ verticalAlign: "middle", marginBottom: "2px" }}
                    width="10"
                    height="15"
                    className="cursor-pointer"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("boardList");
                      await SchoolControllerGet(
                        rowsInput,
                        setSchoolListData,
                        setPageDetail,
                        page,
                        boardId,
                        search,
                        "boardList",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Branches{" "}
                  <img
                    style={{ verticalAlign: "middle", marginBottom: "2px" }}
                    width="10"
                    height="15"
                    className="cursor-pointer"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("numberOfBranches");
                      await SchoolControllerGet(
                        rowsInput,
                        setSchoolListData,
                        setPageDetail,
                        page,
                        boardId,
                        search,
                        "numberOfBranches",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Active
                  <img
                    style={{ verticalAlign: "middle", marginBottom: "2px" }}
                    width="10"
                    height="15"
                    className="cursor-pointer"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("active");
                      await SchoolControllerGet(
                        rowsInput,
                        setSchoolListData,
                        setPageDetail,
                        page,
                        boardId,
                        search,
                        "name",
                        !sortOrder,
                        sortBy
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Coordinators{" "}
                  <img
                    style={{ verticalAlign: "middle", marginBottom: "2px" }}
                    width="10"
                    height="15"
                    className="cursor-pointer"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("numberOfCoordinators");
                      await SchoolControllerGet(
                        rowsInput,
                        setSchoolListData,
                        setPageDetail,
                        page,
                        boardId,
                        search,
                        "numberOfCoordinators",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Teachers{" "}
                  <img
                    style={{ verticalAlign: "middle", marginBottom: "2px" }}
                    width="10"
                    height="15"
                    className="cursor-pointer"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("numberOfPrincipals");
                      await SchoolControllerGet(
                        rowsInput,
                        setSchoolListData,
                        setPageDetail,
                        page,
                        boardId,
                        search,
                        "numberOfPrincipals",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Principals{" "}
                  <img
                    style={{ verticalAlign: "middle", marginBottom: "2px" }}
                    width="10"
                    height="15"
                    className="cursor-pointer"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("numberOfStudents");
                      await SchoolControllerGet(
                        rowsInput,
                        setSchoolListData,
                        setPageDetail,
                        page,
                        boardId,
                        search,
                        "numberOfPrincipals",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Students{" "}
                  <img
                    style={{ verticalAlign: "middle", marginBottom: "2px" }}
                    width="10"
                    height="15"
                    className="cursor-pointer"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("numberOfStudents");
                      await SchoolControllerGet(
                        rowsInput,
                        setSchoolListData,
                        setPageDetail,
                        page,
                        boardId,
                        search,
                        "numberOfStudents",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="az_table_body">
              {schoolListData?.length > 0 ? (
                schoolListData?.map((schoolListData, index) => {
                  return (
                    <tr className={regschool.data_row} key={index}>
                      <td
                        className={`${dashboardContentStyles.table_cell} cursorPointer`}
                        onClick={(e) => {
                          e.preventDefault();
                          { schoolListData.active === true && navigate("/dashboard/AddbranchnoForm") }

                          var schoolid = schoolListData.id;
                          sessionStorage.setItem("schoolid", schoolid);
                        }}
                      >
                        {(page - 1) * rowsInput.rows + (index + 1)}
                      </td>
                      <td
                        onClick={(e) => {
                          e.preventDefault();
                          var schoolid = schoolListData.id;
                          sessionStorage.setItem("schoolid", schoolid);
                          if (schoolListData.active === true) { return navigate("/dashboard/AddbranchnoForm"); }
                        }}
                      >
                        <div
                          key={index}
                          className={`${dashboardContentStyles.table_celll} cursorPointer`}
                        >
                          {schoolListData.name}
                        </div>
                      </td>

                      <td
                        style={{ textAlign: "center" }}
                        onClick={(e) => {
                          e.preventDefault();

                          var schoolid = schoolListData.id;
                          sessionStorage.setItem("schoolid", schoolid);
                          if (schoolListData.active === true) { return navigate("/dashboard/AddbranchnoForm"); }
                        }}
                      >
                        <div>
                          {schoolListData?.boardList?.length > 0 ? (
                            schoolListData.boardList.map((elem, i) => {
                              return (
                                <p
                                  className={`${dashboardContentStyles.table_cell} cursorPointer`}
                                >
                                  {(i ? ", " : "") + elem?.board}
                                </p>
                              );
                            })
                          ) : (
                            <p>-</p>
                          )}
                        </div>
                      </td>
                      <td
                        className={`${dashboardContentStyles.table_cell} cursorPointer`}
                        onClick={(e) => {
                          e.preventDefault();

                          var schoolid = schoolListData.id;
                          sessionStorage.setItem("schoolid", schoolid);
                          if (schoolListData.active === true) { return navigate("/dashboard/AddbranchnoForm"); }
                        }}
                      >
                        {schoolListData.numberOfBranches}
                      </td>
                      <td>
                        <Switch
                          checked={schoolListData.active}
                          onChange={(e) => {
                            switchHandler(e, schoolListData.id, schoolListData.active);
                          }}
                        />
                      </td>
                      <td
                        className={`${dashboardContentStyles.table_cell} cursorPointer`}
                        onClick={(e) => {
                          e.preventDefault();

                          var schoolid = schoolListData.id;
                          sessionStorage.setItem("schoolid", schoolid);
                          if (schoolListData.active === true) { return navigate("/dashboard/AddbranchnoForm"); }
                        }}
                      >
                        {schoolListData.numberOfCoordinators}
                      </td>
                      <td
                        className={`${dashboardContentStyles.table_cell} cursorPointer`}
                        onClick={(e) => {
                          e.preventDefault();

                          var schoolid = schoolListData.id;
                          sessionStorage.setItem("schoolid", schoolid);
                          if (schoolListData.active === true) { return navigate("/dashboard/AddbranchnoForm"); }
                        }}
                      >
                        {schoolListData.numberOfTeachers}
                      </td>
                      <td
                        className={`${dashboardContentStyles.table_cell} cursorPointer`}
                        onClick={(e) => {
                          e.preventDefault();

                          var schoolid = schoolListData.id;
                          sessionStorage.setItem("schoolid", schoolid);
                          if (schoolListData.active === true) { return navigate("/dashboard/AddbranchnoForm"); }
                        }}
                      >
                        {schoolListData.numberOfPrincipals}
                      </td>
                      <td
                        className={`${dashboardContentStyles.table_cell} cursorPointer`}
                        onClick={(e) => {
                          e.preventDefault();

                          var schoolid = schoolListData.id;
                          sessionStorage.setItem("schoolid", schoolid);
                          if (schoolListData.active === true) { return navigate("/dashboard/AddbranchnoForm"); }
                        }}
                      >
                        {schoolListData.numberOfStudents}
                      </td>
                      <td className={regschool.create_branch}>
                        <input
                          type="submit"
                          value="Create Branch"
                          onClick={(e) => {
                            e.preventDefault();
                            var schoolid = schoolListData.id;
                            sessionStorage.setItem("schoolid", schoolid);
                            if (schoolListData.active === true) { return navigate("/dashboard/addBranch"); }
                          }}
                          className={`${regschool.create_branch_btn} cursorPointer`}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <td
                  colSpan="10"
                  style={{
                    height: "23rem",
                  }}
                >
                  NO DATA AVAILABLE
                </td>
              )}
            </tbody>
          </table>
          {/* </article> */}
        </div>

        {/* pagination container - Start */}
        <div className={regschool.pagination}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <span className={regschool.rows}>
              <label htmlFor="rows">Rows per page</label>
              <select
                className="cursorPointer"
                name="rows"
                id="rows"
                value={rowsInput.rows}
                onChange={handlerRowsInputs}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </span>
          </form>
          <span>
            <Pagination1 pageDetail={pageDetail} handleChange={handleChange} />
          </span>
        </div>
        {/* pagination container - End */}
        <MappingAlert
          open={showAlert}
          onClose={hideAlertHandler}
          close={hideAlertHandler}
          alert="No Permission to delete or deactivate as it has existing mapping in the workflow...!"
        />
        <ConfirmationModal
          open={toggleConfirmation}
          confirmationmessage={toggleMessage}
          close={handleConfirmationClose}
          updateToggle={updateToggle}
        />
      </article>
    </>
  );
};

export default RegisteredSchool;
