import React, { useEffect, useState, Select } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Studentdynamic from "./../css/student32.module.css";
import studentedit from "./../css/EditModel.module.css";
import changepasswordstyle from "../css/changePassword.module.css";
import sharestyle from "../css/shareModal.module.css";
import tickImage from "./../img/tick-inside-a-circle.svg";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import GradesControllerGetall from "../API_CONTROLLER/grades-controller/grades_controller_getall";
import SectionsControllerGetall from "../API_CONTROLLER/section-Controller/section_controller_getall";
import StudentsControllerGet from "../API/student-controller/userStudentsGet";
import branchchangerstyle from "../css/School_Branch_Changer.module.css";
import Branches_Changer_School_Id from "../API/branch-controller/Branches_Changer_School_Id";
import StudentsControllerIdGet from "../API/student-controller/userStudentsIdGet";
import Pagination1 from "./pagination1";
import School_name from "./school_name"; //school name changer
import GetLastModifiedAt from "../API/student-controller/GetLastModifiedAt";
import addstudentstyle from "./../css/NavbarAddStudentModal.module.css";
import SchoolControllerAll from "../API/school-controller/user_schools_all";
import User_Branches_School_Id from "../API/branch-controller/User_Branches_School_Id";
// import SchoolControllerIdGet from "../API/school-controller/User_School_Id";
import axios from "axios";
import Switch from "../../components/common/Switch";
import CommonDeleteModal from "./CommonDeleteModal";
import sortIcon from "../../../src/pages/img/sort.png";
import dashHome from "../../../src/img/dashHome.svg";

//Action Icon Images
import action_sa_Lock from "../../../src/img/password.svg";
import action_sa_Edit from "../../../src/img/Edit.svg";
import action_sa_Share from "../../../src/img/Share.svg";
import action_sa_Delete from "../../../src/img/Delete.svg";
import ConfirmationModal from "./ConfirmationModal";
import EditIconButton from "../../components/common/EditIconButton";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import LockIconButton from "../../components/common/LockIconButton";
import ShareIconButton from "../../components/common/ShareIconButton";
import { Tooltip } from "@mui/material";

import searchIcn from "../../../src/img/search.svg";
import changePasswordPut from "../ChangePassword";
import share from "../Share";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import studentIcn from "../../../src/img/student-list.png";

const Studenttable = (props) => {
  const navigate = useNavigate();

  const handleEdit = (e) => {
    navigate("/dashboard/student", {
      state: "edit",
    });
  };

  const [selectedItem, setSelectedItem] = useState({});
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [schoolIdData, setSchoolIdData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showModalShare, setShowModalShare] = useState(false);
  const [loginPassword, setLoginPassword] = useState("");
  const [confirmLoginPassword, setConfirmLoginPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [shareFirstName, setShareFirstName] = useState("");
  const [shareLastName, setShareLastName] = useState("");
  const [sharePhone, setSharePhone] = useState("");
  const [shareDob, setShareDob] = useState("");
  const [shareGender, setShareGender] = useState("");
  const [shareMotherTongue, setShareMotherTongue] = useState("");
  const [shareLanguag2, setShareLanguag2] = useState("");
  const [shareAdmDate, setShareAdmDate] = useState("");
  const [shareGrade, setShareGrade] = useState("");
  const [shareSection, setShareSection] = useState("");
  const [shareCategory, setShareCategory] = useState("");
  const [shareAddress, setShareAddress] = useState("");
  const [shareEmail, setShareEmail] = useState("");
  const [sortOrder, setSortOrder] = useState(false);
  const [sortBy, setSortBy] = useState("id");
  // -------------------
  const [showAddStudentModal, setShowAddStudentModal] = useState(false);
  const [showAddAcademicModal, setShowAddAcademicModal] = useState(false);
  const [schoolList, setSchoolList] = useState({});
  // const [gradeList, setGradesName] = useState({});
  const [gradeList, setGradeList] = useState({});
  const [sectionList, setSectionList] = useState({});
  const [isValid, setValidation] = useState(false);
  let token = sessionStorage.getItem("token");

  const [studentDetails, setStudentDetails] = useState({
    studentschool: "",
    studentbranch: "",
    grade: "",
    section: "",
  });
  const [grdes, setgrdesList] = useState([]);
  const [sects, setSects] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const showDeleteModal = (id) => {
    setSelectedItem(id);
    setOpenDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedItem("");
  };

  const handleDeleteStudent = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/students/${selectedItem.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        StudentsControllerGet(
          setStudentListData,
          setPageDetail,
          page,
          rowsInput,
          search,
          gradeInput,
          sectionInput
        );
        hideDeleteModal();
      })
      .catch((err) => console.log(err));
  };
  const [updatedData, setUpdatedData] = useState();
  const [toggleDetails, setToggleDetails] = useState({});
  const [toggleMessage, settoggleMessage] = useState();
  const [toggleConfirmation, setToggleConfirmation] = useState(false);
  const [filterValue, setFilterValue] = useState([]);
  const handleConfirmationClose = () => {
    setToggleConfirmation(false);
    setToggleDetails({});
  };

  const updateToggle = () => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/students/${toggleDetails?.id}/active?active=${toggleDetails?.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: toggleDetails?.value,
        }
      )
      .then(function (response) {
        StudentsControllerGet(
          setStudentListData,
          setPageDetail,
          page,
          rowsInput,
          search,
          gradeInput,
          sectionInput
        );
        handleConfirmationClose();
        if (response?.data) {
          const find = updatedData?.find(
            (item) => item.id === toggleDetails?.id
          );
          find.active = toggleDetails?.value;
          const clone = [...updatedData];
          clone.forEach((data) => {
            data = find;
          });
          setFilterValue(clone);
        }
      })
      .catch((err) => console.log(err));
  };

  const switchHandler = async (e, id) => {
    setToggleDetails({});
    const value = e.target.checked;
    setToggleDetails({ id, value });
    if (value === false) {
      setToggleConfirmation(true);
      settoggleMessage("Are you sure you want to Deactivate this Student");
    } else {
      setToggleConfirmation(true);
      settoggleMessage("Are you sure you want to Activate this Student");
    }
  }

  // const handletoggleChange = (id, e) => {
  //   e.preventDefault();
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/students/${id}/active?active=${e.target.checked}`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     .then((res) =>
  //       StudentsControllerGet(
  //         setStudentListData,
  //         setPageDetail,
  //         page,
  //         rowsInput,
  //         search,
  //         gradeInput,
  //         sectionInput
  //       )
  //     )
  //     .catch((err) => console.log(err));
  // };

  const handlerStudentDetailsInputs = (e) => {
    setStudentDetails({
      ...studentDetails,
      [e.target.name]: e.target.value,
    });
    if (e.target.name == "studentbranch") {
      axios
        .get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section/grades?branchId=${e.target.value}&schoolId=${studentDetails.studentschool}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log("bububbu", response.data.data);
          setgrdesList([...response.data.data]);
        })
        .catch((err) => console.log(err));
      //http://3.111.249.182:9001v1/api/user/grade-section/sections?gradeId=jjj&branchId=kkk&schoolId=lll
    }
    if (e.target.name == "grade") {
      axios
        .get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section/sections?gradeId=${e.target.value}&branchId=${studentDetails.studentbranch}&schoolId=${studentDetails.studentschool}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setSects([...response.data.data]);
        })
        .catch((err) => console.log(err));
      //http://3.111.249.182:9001v1/api/user/grade-section/sections?gradeId=jjj&branchId=kkk&schoolId=lll
    }
  };
  let schoolid = sessionStorage.getItem("schoolid");
  sessionStorage.setItem("schoolid", schoolid);

  const handleCross = (e) => {
    setShowAddStudentModal(false);
    setShowAddAcademicModal(false);
    let role = localStorage.getItem("role");
    if (role === "SCHOOL_ADMIN") {
      navigate("/dashboard/Branches");

      sessionStorage.setItem("sideNavMainMenuNames", "Schools");
      sessionStorage.setItem("sideNavSubMenuNames", "Branches");
    } else {
      navigate("/dashboard/Registered%20Schools");

      // SS for Highlighthing the Sidenav Menu Name
      sessionStorage.setItem("sideNavMainMenuNames", "Schools");
      sessionStorage.setItem("sideNavSubMenuNames", "Registered Schools");
    }
    sessionStorage.getItem("schoolid");
    sessionStorage.setItem("schoolid", schoolid);
  };
  const handleAddStudentSubmit = (e) => {
    e.preventDefault();
    // navigate("/dashboard/student");
    // props.handleHeaderTitle("Registered Schools"); // addedd by sg
    if (
      !studentDetails.studentschool ||
      !studentDetails.studentbranch ||
      !studentDetails.grade ||
      !studentDetails.section
    ) {
      setValidation(true);
      return;
    }
    setShowAddStudentModal(false);
    var schoolid = studentDetails.studentschool;
    sessionStorage.setItem("schoolid", schoolid);
    var branchid = studentDetails.studentbranch;
    sessionStorage.setItem("branchid", branchid);
    var gradeid = studentDetails.grade;
    sessionStorage.setItem("gradeid", gradeid);
    var sectionid = studentDetails.section;
    sessionStorage.setItem("sectionid", sectionid);
    navigate("/dashboard/student");

  };
  // -----------------------------------
  const toggleLoginPasswordVisibility = (isShown) => {
    if (isShown === "show") {
      setPasswordShown(true);
    } else {
      setPasswordShown(false);
    }
  };
  const toggleconfirmLoginPasswordVisibility = (isConfirmShown) => {
    if (isConfirmShown === "Confirmshow") {
      setConfirmPasswordShown(true);
    }
    if (isConfirmShown === "ConfirmdontShow") {
      setConfirmPasswordShown(false);
    }
  };
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [isPassEmpty, setIsPassEmpty] = useState(false);
  const [shareName, setShareName] = useState("");

  const handleSubmit = (e) => {
    changePasswordPut(userName, userId, password, persona);

    e.preventDefault();
    if (!loginPassword) {
      setIsPassEmpty(true);
      setPasswordMatch(true);
      return;
    }
    if (loginPassword !== confirmLoginPassword) {
      setIsPassEmpty(false);
      setPasswordMatch(false);
      return;
    }
    const pass = validatePassword(loginPassword);
    if (pass === false) {
      setRegexPass(pass);
      setIsTooltipOpen(true);
      return;
    }
    setPasswordMatch(true);
    setShowModalChangePassword(false);
  };

  const handleChangePassword = (e) => {
    setShowModal(true);
  };

  const handleUpdatedPassword = (e) => {
    setShowModalChangePassword(false);
  };

  const handleShareModal = (e) => {
    setShareName(
      e.target.parentNode.parentNode.parentNode.parentNode.parentNode
        .children[2].textContent
    );

    setShowModalShare(true);
  };

  const handleShareModalOk = (e) => {
    setShowModalShare(false);
  };

  const handleEditModal = (e) => {
    setShareFirstName(e.target.parentNode.children[3].textContent);
    setShareLastName(e.target.parentNode.children[4].textContent);
    setSharePhone(e.target.parentNode.children[5].textContent);
    setShareEmail(e.target.parentNode.children[2].textContent);
    setShareDob(e.target.parentNode.children[6].textContent);
    setShareGender(e.target.parentNode.children[7].textContent);
    setShareMotherTongue(e.target.parentNode.children[8].textContent);
    setShareLanguag2(e.target.parentNode.children[9].textContent);
    setShareAdmDate(e.target.parentNode.children[10].textContent);
    setShareGrade(e.target.parentNode.children[14].textContent);
    setShareSection(e.target.parentNode.children[15].textContent);
    setShareCategory(e.target.parentNode.children[11].textContent);
    setShareAddress(e.target.parentNode.children[12].textContent);
    setShowModalEdit(true);
  };

  const handleEditModalEdit = (e) => {
    setShowModalEdit(false);
    navigate("/dashboard/student");
  };
  const handleEditModalcross = (e) => {
    setShowModalEdit(false);
    navigate("/dashboard/Add%20Students");
  };

  const handleAddStudent = (e) => {
    setShowAddStudentModal(true);
    // navigate('dashboard/student')
  };
  const handleAddStudentroute = () => {
    navigate("/dashboard/student");
  };
  // add function for pagination

  // const StudentList = props.StudentList;
  const [gradeInput, setGradeInput] = useState("");
  const [sectionInput, setSectionInput] = useState("");

  // const [pagination, setPagination] = useState(1);
  //grade and section handler starts
  const handleGradesInput = (e) => {
    setGradeInput(e.target.value);
  };
  const handleSectionInput = (e) => {
    setSectionInput(e.target.value);
  };
  //grade and section handler ends

  // pagination starts
  const [rowsInput, setRowsInputs] = useState({
    rows: 10,
  });
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const handlerRowsInputs = (e) => {
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
    setPage(1);
  };
  const [pageDetail, setPageDetail] = useState({});
  // pagination ends

  const [studentListData, setStudentListData] = useState([]);
  const [gradesName, setGradesName] = useState({});
  const [sectionName, setSectionsName] = useState({});
  const [studentIdData, setStudentIdData] = useState({});
  useEffect(() => {
    GradesControllerGetall(setGradesName);
    SectionsControllerGetall(setSectionsName);
    return () => { };
  }, []);
  // console.log(studentIdData);
  // useEffect(() => {
  //   StudentsControllerIdGet();
  //   return () => {};
  // }, []);

  useEffect(() => {
    StudentsControllerGet(
      setStudentListData,
      setPageDetail,
      page,
      rowsInput,
      search,
      gradeInput,
      sectionInput
    );

    return () => { };
  }, [
    rowsInput,
    page,
    sessionStorage.getItem("branchid"),
    gradeInput,
    sectionInput,
  ]);

  // search starts
  const [search, setsearch] = useState({
    search: "",
  });
  const handleSearch = (e) => {
    const search = {
      search: e.target.value,
    };
    StudentsControllerGet(
      setStudentListData,
      setPageDetail,
      page,
      rowsInput,
      search,
      gradeInput,
      sectionInput
    );
  };
  const handleSearchbtn = (e) => {
    e.preventDefault();
    StudentsControllerGet(
      setStudentListData,
      setPageDetail,
      page,
      rowsInput,
      search,
      gradeInput,
      sectionInput
    );
  };

  useEffect(() => {
    if (search.search === "") {
      StudentsControllerGet(
        setStudentListData,
        setPageDetail,
        page,
        rowsInput,
        search,
        gradeInput,
        sectionInput,
        sortBy,
        sortOrder
      );

      return () => { };
    }
  }, [rowsInput, page, search, sortOrder]);

  // search ends
  useEffect(() => {
    SchoolControllerAll(setSchoolIdData);
    return () => { };
  }, [studentListData]);
  console.log(schoolIdData, "yy");
  // last update time starts
  const [modifiedDate, setModifiedDate] = useState({});
  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);
  // last update time ends

  //BranchController starts
  const [branchList, setBranchList] = useState([]);

  const [schoolDetails, setSchoolDetails] = useState({
    branch: sessionStorage.getItem("branchid"),
  });

  const handlerSchoolDetailsInputs = (e) => {
    setSchoolDetails({
      ...schoolDetails,
      [e.target.name]: e.target.value,
      schoolName: e.target.id,
    });
  };
  useEffect(() => {
    // branch input for school //
    User_Branches_School_Id(schoolDetails, setBranchList, studentDetails);
    return () => { };
  }, [schoolDetails.school, studentDetails]);

  useEffect(() => {
    var branchid = schoolDetails.branch;
    sessionStorage.setItem("branchid", branchid);
    Branches_Changer_School_Id(setBranchList);
    return () => { };
  }, [schoolDetails]);
  useEffect(() => {
    handleAddStudent();
    //school Input
    SchoolControllerAll(setSchoolList);
    //Grade input
    GradesControllerGetall(setGradesName);
    //section input for student//
    SectionsControllerGetall(setSectionsName);
    return () => { };
  }, []);
  //BranchController ends

  const PassOnChange = (e) => {
    setLoginPassword(e.target.value);
    setPassword(e.target.value);
  };
  const [userName, setUserName] = useState();
  const [userId, setUserId] = useState();
  const [password, setPassword] = useState();
  const persona = "STUDENT";
  const [regexPass, setRegexPass] = useState(true);
  const [showModalChangePassword, setShowModalChangePassword] = useState(false);

  function validatePassword(passValue) {
    const regex = new RegExp(
      "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*+_()=])(?=\\S+$).{8,}$"
    );
    return regex.test(passValue);
  }
  const handleSubmitPass = (e) => {
    changePasswordPut(userName, userId, password, persona);

    e.preventDefault();
    if (!loginPassword) {
      setIsPassEmpty(true);
      setPasswordMatch(true);
      return;
    }
    if (loginPassword !== confirmLoginPassword) {
      setIsPassEmpty(false);
      setPasswordMatch(false);
      return;
    }
    const pass = validatePassword(loginPassword);
    if (pass === false) {
      setRegexPass(pass);
      return;
    }
    setPasswordMatch(true);
    setShowModalChangePassword(false);
  };

  useEffect(() => {
    share(userName, userId, persona);
  }, [userName, userId]);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTooltipOpen = () => {
    setIsTooltipOpen(false);

  };


  const handleTooltipClose = () => {
    setIsTooltipOpen(false);

  };
  return (
    <>
      <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span className={dashboardContentStyles.link_icon}>
            <img
              src={dashHome}
              alt="no img"
              width="15px"
              height="20px"
              style={{ verticalAlign: "middle" }}
            />
          </span>
          {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>{" "}
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          {"  "}
          <span className={dashboardContentStyles.link_text}>
            Resigtered Schools
          </span>
          {"  "}
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          {"  "}
          <a>Add Students</a>
        </div>
        <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>Last Update: {modifiedDate.data}</p>
        </div>
      </article>
      <article className={Studentdynamic.article_32}>
        <div className={branchchangerstyle.Grid_school_name_branch_teacherid}>
          <div className={branchchangerstyle.school_name_teacherid}>
            {/* <School_name /> */}
            <i class="fa-solid fa-house-user" aria-hidden="true"></i>{" "}
            {schoolIdData.name}
          </div>
          <select
            className={branchchangerstyle.branchselect_Dropdown}
            name="branch"
            id="branch"
            value={schoolDetails.branch}
            onChange={handlerSchoolDetailsInputs}
          >
            {branchList.length > 0 ? (
              branchList.map((branchList) => {
                return (
                  <option value={branchList.branchId}>
                    {branchList.branch}
                  </option>
                );
              })
            ) : (
              <option className={branchchangerstyle.option} value=""></option>
            )}
          </select>
        </div>

        <div className={Studentdynamic.grid_container_header_plan_32}>
          <div
            className={Studentdynamic.item_plan1_32}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/branchplan");
            }}
          >
            Plan
          </div>
          <div
            className={Studentdynamic.item_plan2_32}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/branchsection");
            }}
          >
            Sections
          </div>
          <div
            className={Studentdynamic.item_plan3_32}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/teacherId");
            }}
          >
            Academic Staff
          </div>
          <div
            className={Studentdynamic.item_plan4_32}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/student");
            }}
          >
            Students
          </div>
          <div
            className={Studentdynamic.item_plan5_32}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/csp");
            }}
          >
            Change Student Profile
          </div>
          <div
            className={Studentdynamic.item_plan6_32}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/tokenList");
            }}
          >
            Tokens
          </div>
          <div
            className={Studentdynamic.item_plan7_32}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/logo");
            }}
          >
            Branch Logo
          </div>
        </div>

        <div className={Studentdynamic.schoollist_32}>
          <div className={Studentdynamic.schoollist_dropdown}>
            <span>
              <img
                src={studentIcn}
                style={{
                  verticalAlign: "middle",
                }}
                alt=""
              />{" "}
              Student List
            </span>
            <div className={Studentdynamic.Dropdown}>
              <select
                name="grade"
                id="grade"
                value={gradeInput}
                onChange={handleGradesInput}
                style={{
                  textAlign: "center",
                  lineHeight: " 12px"
                }}
              >
                <option value="" style={{ textAlign: "center" }}>
                  Grade{" "}
                </option>
                <i class="fa-solid fa-chevron-down"></i>
                {gradesName.length > 0 ? (
                  gradesName.map((gradesName) => {
                    return (
                      <option
                        className={Studentdynamic.option}
                        value={gradesName.id}
                      >
                        {gradesName.grade}
                      </option>
                    );
                  })
                ) : (
                  <option className={Studentdynamic.option} value="">
                    Select
                  </option>
                )}
              </select>
            </div>
            <div className={Studentdynamic.Dropdown}>
              <select
                name="section"
                id="section"
                value={sectionInput}
                style={{ textAlign: "center" }}
                onChange={handleSectionInput}
                size={"small"}
              >
                <i class="fa-solid fa-chevron-down"></i>
                <option value="" style={{ textAlign: "center" }}>
                  Section
                </option>
                {sectionName.length > 0 ? (
                  sectionName.map((sectionName) => {
                    return (
                      <option
                        className={Studentdynamic.option}
                        value={sectionName.id}
                      >
                        {sectionName.section}
                      </option>
                    );
                  })
                ) : (
                  <option className={Studentdynamic.option} value="">
                    Select
                  </option>
                )}
              </select>
            </div>
          </div>

          <div className={Studentdynamic.school_list_search_container_32}>
            <div
              className={Studentdynamic.school_list_search_container}
              style={{ width: "247px", height: "32px" }}
            >
              <input
                style={{ width: "247px", height: "32px" }}
                type="search"
                name="search"
                id="search"
                placeholder="Name/Email/Mobile..."
                Value={search.search}
                onChange={handleSearch}
              />
              <span>
                <img
                  src={searchIcn}
                  style={{
                    verticalAlign: "top",
                    fontSize: "large",
                  }}
                  onClick={handleSearchbtn}
                />
              </span>
            </div>
          </div>
          <div>
            <button
              className={Studentdynamic.add_school_button_32}
              onClick={handleAddStudentroute}
            >
              <i class="fa-solid fa-circle-plus"></i> Add Student
            </button>
          </div>
        </div>

        <div className={Studentdynamic.section_list_table}>
          <table className={Studentdynamic.table_section}>
            <thead className={Studentdynamic.thead_32}>
              <tr className={Studentdynamic.table_head_section}>
                <td className={Studentdynamic.cbse_checkbox}>
                  <input type="checkbox" id="test2" />
                  <label for="test2"></label>{" "}
                </td>
                <td className={Studentdynamic.cbse_table}>#</td>
                <td className={Studentdynamic.wrapdata}>
                  User Name
                  <img
                    style={{ verticalAlign: "middle" }}
                    className="cursor-pointer"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("firstName");
                      // await StudentsControllerGet(
                      //   setStudentListData,
                      //   setPageDetail,
                      //   page,
                      //   rowsInput,
                      //   search,
                      //   gradeInput,
                      //   sectionInput,
                      //   "firstName",
                      //   !sortOrder                          )
                    }}
                  />
                </td>
                <td className={Studentdynamic.cbse_table}>
                  {" "}
                  Grades
                  <img
                    style={{ verticalAlign: "middle" }}
                    className="cursor-pointer"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("grade");
                      await StudentsControllerGet(
                        setStudentListData,
                        setPageDetail,
                        page,
                        rowsInput,
                        search,
                        gradeInput,
                        sectionInput,
                        "grade",
                        !sortOrder
                      );
                    }}
                  />
                </td>
                <td className={Studentdynamic.cbse_table}>
                  Section
                  <img
                    style={{ verticalAlign: "middle" }}
                    className="cursor-pointer"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("section");
                      await StudentsControllerGet(
                        setStudentListData,
                        setPageDetail,
                        page,
                        rowsInput,
                        search,
                        gradeInput,
                        sectionInput,
                        "section",
                        !sortOrder
                      );
                    }}
                  />
                </td>
                <td className={Studentdynamic.cbse_table}>
                  Number of Quiz
                  <img
                    style={{ verticalAlign: "middle" }}
                    className="cursor-pointer"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("numberOfQuiz");
                      await StudentsControllerGet(
                        setStudentListData,
                        setPageDetail,
                        page,
                        rowsInput,
                        search,
                        gradeInput,
                        sectionInput,
                        "numberOfQuiz",
                        !sortOrder
                      );
                    }}
                  />
                </td>
                {/* <td className={Studentdynamic.cbse_table}>
                  User Id
                  <i class="fa-solid fa-arrows-up-down"></i>
                </td> */}
                <td className={Studentdynamic.cbse_table}>
                  Last login
                  <img
                    style={{ verticalAlign: "middle" }}
                    className="cursor-pointer"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("lastLoginTime");
                      await StudentsControllerGet(
                        setStudentListData,
                        setPageDetail,
                        page,
                        rowsInput,
                        search,
                        gradeInput,
                        sectionInput,
                        "lastLoginTime",
                        !sortOrder
                      );
                    }}
                  />
                </td>
                <td className={Studentdynamic.cbse_table}>Active
                  <img
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    width="11"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("active");
                      await StudentsControllerGet(
                        setStudentListData,
                        setPageDetail,
                        page,
                        rowsInput,
                        search,
                        gradeInput,
                        sectionInput,
                        "active",
                        !sortOrder
                      );
                    }}></img>
                </td>
                <td
                  className={Studentdynamic.cbse_table}
                  style={{ paddingRight: "35px" }}
                >
                  Action
                </td>
              </tr>
            </thead>

            <tbody className={Studentdynamic.data_table}>
              {studentListData.length > 0 ? (
                studentListData.map((studentListData, index) => {
                  return (
                    <tr
                      className={Studentdynamic.data_row}
                      id={Studentdynamic.data_row_hover}
                      key={index}
                    >
                      <td className={Studentdynamic.cbse_checkbox}>
                        <input type="checkbox" id="test2" />
                        <label for="test2"></label>{" "}
                      </td>
                      <td
                      // onClick={handleEditModal}
                      >
                        {(page - 1) * rowsInput.rows + (index + 1)}
                      </td>

                      <td
                        // onClick={handleEditModal}
                        className={Studentdynamic.userName}
                      >
                        <div
                          className={Studentdynamic.wrapdata}
                          onClick={(e) => {
                            e.preventDefault();
                            var studentid = studentListData.id;
                            sessionStorage.setItem("studentid", studentid);
                            StudentsControllerIdGet(setStudentIdData);
                            setShowModalEdit(true);
                          }}
                        >
                          {studentListData.userName}
                        </div>
                      </td>
                      <td
                        // onClick={handleEditModal}
                        className={Studentdynamic.type_of_school}
                      >
                        {studentListData.grade}
                      </td>
                      <td
                        // onClick={handleEditModal}
                        className={Studentdynamic.type_of_school}
                      >
                        {studentListData.section}
                      </td>

                      <td>{studentListData.numberOfQuiz}</td>
                      {/* <td>
                        <div className={Studentdynamic.userId}>
                          {studentListData.userId}
                        </div>
                      </td> */}

                      <td>{studentListData.lastLoginTime}</td>

                      <td>
                        {/* <label className={Studentdynamic.active_switch}>
                          <input
                            type="checkbox"
                            checked={studentListData.active}
                          />
                          <span className={Studentdynamic.active_slider}></span>
                        </label> */}
                        <Switch
                          checked={studentListData.active}
                          onChange={(e) => {
                            switchHandler(e, studentListData.id);
                          }}
                        ></Switch>
                      </td>
                      <td>
                        <div className={Studentdynamic.wrapdata}>
                          {studentListData.Action}
                        </div>
                        <div className={Studentdynamic.wrapper_teacherid}>
                          <div
                            className={Studentdynamic.icon}
                            onClick={() => {
                              setShowModalChangePassword(true);
                              setUserId(studentListData.userId);
                              setUserName(studentListData.userName);
                              setLoginPassword("");
                              setConfirmLoginPassword("");
                              setRegexPass(true);
                            }}
                          >
                            <div className={Studentdynamic.tooltip}>
                              Change Password
                            </div>
                            <span id={Studentdynamic.change_password_teacherid}>
                              <img
                                className={
                                  dashboardContentStyles.btnHoverEffect
                                }
                                src={action_sa_Lock}
                                alt="Lock-btn"
                              />
                            </span>
                          </div>
                          <div className={Studentdynamic.icon}>
                            <div className={Studentdynamic.tooltip}>Edit</div>
                            <span
                              onClick={(e) => {
                                e.preventDefault();
                                var studentid = studentListData.id;
                                sessionStorage.setItem("studentid", studentid);
                                // StudentsControllerIdGet(setStudentIdData);
                                navigate("/dashboard/student", {
                                  state: "edit",
                                });
                              }}
                            >
                              <img
                                className={
                                  dashboardContentStyles.btnHoverEffect
                                }
                                src={action_sa_Edit}
                                alt="Lock-btn"
                              />
                            </span>
                          </div>
                          <div
                            className={Studentdynamic.icon}
                            // onClick={handleShareModal}
                            onClick={() => {
                              setUserName(studentListData.userName);
                              setUserId(studentListData.userId);
                              share(userName, userId, persona);
                              setShowModalShare(true);
                            }}
                          >
                            <div className={Studentdynamic.tooltip}>Share</div>
                            <span>
                              <img
                                className={
                                  dashboardContentStyles.btnHoverEffect
                                }
                                src={action_sa_Share}
                                alt="Lock-btn"
                              />
                            </span>
                          </div>
                          <div className={Studentdynamic.icon}>
                            <div className={Studentdynamic.tooltip}>Remove</div>
                            <span>
                              <img
                                onClick={() => {
                                  showDeleteModal(studentListData);
                                }}
                                className={
                                  dashboardContentStyles.btnHoverEffect
                                }
                                src={action_sa_Delete}
                                alt="Lock-btn"
                              />
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <td
                  colSpan="10"
                  style={{
                    // "white-space": "nowrap",/
                    height: "23rem",
                    // overflow: "scroll",
                    // "text-overflow": "clip",
                    // margin: "0px 10px",
                  }}
                >
                  NO DATA AVAILABLE
                </td>
              )}
            </tbody>
          </table>
        </div>
        <div className={Studentdynamic.pagination}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <span className={Studentdynamic.rows}>
              <label for="rows">Rows per page</label>
              <select
                name="rows"
                id="rows"
                value={rowsInput.rows}
                onChange={handlerRowsInputs}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </span>
          </form>
          <span>
            <Pagination1 pageDetail={pageDetail} handleChange={handleChange} />
          </span>
        </div>
        <div
          className={studentedit.flex_container_teacherid}
          id={studentedit.modal_teacherid}
          style={showModalEdit ? { display: "flex" } : { display: "none" }}
        >
          <div
            className={studentedit.main_content_teacherid}
            id={studentedit.modal_content_teacherid}
          >
            <div className={studentedit.EditHeadContainer}>
              <h5 className={studentedit.head}>Student </h5>
              <i onClick={handleEditModalcross} class="fa-solid fa-xmark cursorPointer"></i>
            </div>
            <div className={studentedit.EditBox}>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}> First Name</label>
                <label className={studentedit.Input}>
                  {" "}
                  {studentIdData.firstName}{" "}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}> Last Name</label>
                <label className={studentedit.Input}>
                  {studentIdData.lastName}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}>
                  {" "}
                  Primary Phone no.{" "}
                </label>
                <label className={studentedit.Input}>
                  {" "}
                  {studentIdData.mobile}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}> Primary Email</label>
                <label className={studentedit.Input}>
                  {" "}
                  {studentIdData.email}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}> Date of Birth</label>
                <label className={studentedit.Input}>
                  {" "}
                  {studentIdData.dob}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}> Gender </label>
                <label className={studentedit.Input}>
                  {" "}
                  {studentIdData.gender}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}> Mother Tongue</label>
                <label className={studentedit.Input}>
                  {" "}
                  <div className={Studentdynamic.wrapdata}>
                    {studentIdData.firstLanguage}{" "}
                  </div>
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}>
                  {" "}
                  Second Language{" "}
                </label>
                <label className={studentedit.Input}>
                  {" "}
                  <div className={Studentdynamic.wrapdata}>
                    {studentIdData.secondLanguage}
                  </div>{" "}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}>
                  {" "}
                  Admission Date{" "}
                </label>
                <label className={studentedit.Input}>
                  {" "}
                  {studentIdData.admissionDate}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}> Grade</label>
                <label className={studentedit.Input}>
                  {" "}
                  {studentIdData.grade}{" "}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}> Section</label>
                <label className={studentedit.Input}>
                  {" "}
                  {studentIdData.section}{" "}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}>
                  {" "}
                  Student Category{" "}
                </label>
                <label className={studentedit.Input}>
                  {" "}
                  <div className={Studentdynamic.wrapdata}>
                    {studentIdData.studentCategory}
                  </div>{" "}
                </label>
              </div>
              <div className={studentedit.Edithead} id={studentedit.Addressbox}>
                <label className={studentedit.Input_head}> Address </label>
                <label className={studentedit.Input}>
                  {" "}
                  {studentIdData.address}{" "}
                </label>
              </div>
            </div>

            <div className={studentedit.modal_buttons_teacherid}>
              <button
                className={studentedit.Edit_btn}
                id={studentedit.Edit_btn_1_teacherid}
                onClick={handleEditModalEdit}
              >
                <i class="fa-solid fa-pen"></i> Edit
              </button>
            </div>
          </div>
        </div>

        {/* change password modal starts  */}

        <div
          className={changepasswordstyle.flex_container_teacherid}
          id={changepasswordstyle.modal_teacherid}
          style={
            showModalChangePassword ? { display: "flex" } : { display: "none" }
          }
        >
          <form class={changepasswordstyle.setPassword_login_form}>
            <div class={changepasswordstyle.login_form_title}>
              <h5>Change Password</h5>
              <span>
                <i
                  class="fa-solid fa-xmark cursorPointer"
                  onClick={handleUpdatedPassword}
                ></i>
              </span>
            </div>
            <div class={changepasswordstyle.password_container}>
              <fieldset className={changepasswordstyle.password_fieldset}>
                <legend>New Password</legend>
                <input
                  type={passwordShown ? "text" : "password"}
                  name="userPassword"
                  id={changepasswordstyle.password}
                  value={loginPassword}
                  onChange={PassOnChange}
                />
                <i
                  className={`bi ${passwordShown ? "bi-eye" : "bi-eye-slash"} ${changepasswordstyle.bi_eye_slash
                    }`}
                  id={changepasswordstyle.togglePassword}
                  onMouseEnter={() => {
                    toggleLoginPasswordVisibility("show");
                  }}
                  onMouseLeave={() => {
                    toggleLoginPasswordVisibility("dontShow");
                  }}
                ></i>
              </fieldset>
              <span
                className={changepasswordstyle.error_span}
                style={
                  isPassEmpty ? { display: "inline" } : { display: "none" }
                }
              >
                Password cannot be empty
              </span>
            </div>
            <div class={changepasswordstyle.password_container}>
              <fieldset className={changepasswordstyle.password_fieldset}>
                <legend>Confirm Password</legend>
                <Tooltip title="Password length must be greater than or equal to 8,
                          must contain atleast one numeric, alphanumeric,
                          upper-case and lower-case alphabet"
                  enterTouchDelay={1000000}
                  open={isTooltipOpen}
                  onOpen={handleTooltipOpen}
                  onClose={handleTooltipClose}

                >
                  <input
                    type={confirmPasswordShown ? "text" : "password"}
                    name="confirmPassword"
                    id={changepasswordstyle.password}
                    value={confirmLoginPassword}
                    onChange={(e) => setConfirmLoginPassword(e.target.value)}
                  /></Tooltip>
                <i
                  className={`bi ${confirmPasswordShown ? "bi-eye" : "bi-eye-slash"
                    } ${changepasswordstyle.bi_eye_slash}`}
                  id={changepasswordstyle.togglePassword}
                  onMouseEnter={() => {
                    toggleconfirmLoginPasswordVisibility("Confirmshow");
                  }}
                  onMouseLeave={() => {
                    toggleconfirmLoginPasswordVisibility("ConfirmdontShow");
                  }}
                ></i>
              </fieldset>
              <span
                class={changepasswordstyle.error_span}
                style={
                  passwordMatch ? { display: "none" } : { display: "inline" }
                }
              >
                Passwords do not match
              </span>

              <span
                class={changepasswordstyle.error_span}
                style={regexPass ? { display: "none" } : { display: "inline" }}
              >

              </span>
            </div>
            <div class={changepasswordstyle.login_container}>
              <button
                class={changepasswordstyle.sp_login_btn}
                onClick={handleSubmit}
              >
                UPDATE
              </button>
            </div>
          </form>
        </div>

        {/* change password modal ends  */}

        {/* share modal starts  */}

        <div
          className={sharestyle.flex_container_teacherid}
          id={sharestyle.modal_teacherid}
          style={showModalShare ? { display: "flex" } : { display: "none" }}
        >
          <div
            className={sharestyle.main_content_teacherid}
            id={sharestyle.modal_content_teacherid}
          >
            <span className={sharestyle.img}>
              <CheckCircleOutlineIcon
                sx={{ fontSize: "70px", marginLeft: "10px", color: "#ffa500" }}
              />{" "}
            </span>

            <h5 className={sharestyle.head}>Successfully shared User Id</h5>

            <p className={sharestyle.para}> {userName}</p>
            <div className={sharestyle.modal_buttons_teacherid}>
              <button
                className={sharestyle.ok_btn}
                id={sharestyle.ok_btn_1_teacherid}
                onClick={handleShareModalOk}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </article>

      {/* -------------------------parth25/07----------------------------------------------------------------------- */}
      <div
        className={addstudentstyle.flex_container}
        id={addstudentstyle.modal}
        style={showAddStudentModal ? { display: "flex" } : { display: "none" }}
      >
        <div
          className={addstudentstyle.main_content}
          id={addstudentstyle.modal_content}
        >
          <div className={addstudentstyle.main_content_head}>
            <div>Add Student</div>
            <div className="cursorPointer">
              <i class="fa-solid fa-xmark" onClick={handleCross}></i>
            </div>
          </div>

          <div className={addstudentstyle.main_content_box_head}>
            School Details
          </div>
          <div className={addstudentstyle.main_content_box}>
            <div className={addstudentstyle.input_element + ' ' + 'az_select_Add_student_Modal_select'}>
              <select
                className={`${addstudentstyle.form_select_element + ' ' + 'az_select'} cursorPointer`}
                name="studentschool"
                id="studentschool"
                value={studentDetails.studentschool}
                onChange={handlerStudentDetailsInputs}
              // style={{lineHeight:"5px",fontSize:"12px"}}
              >
                <option value="">Select</option>
                {/* <option value="Select">Select</option> */}
                {schoolList.length > 0 ? (
                  schoolList.map((schoolList, index) => {
                    return (
                      <option value={schoolList.schoolId}>
                        {schoolList.schoolName}
                      </option>
                    );
                  })
                ) : (
                  <option className={addstudentstyle.option} value=""></option>
                )}
              </select>
              {/* <label for="SchoolName">*School Name</label> */}
              <label
                htmlFor="studentschool"
                className={
                  studentDetails.studentschool === ""
                    ? addstudentstyle.std_cat
                    : "NONE"
                }
              >
                *School Name
              </label>
              <label
                htmlFor="studentschool"
                className={
                  studentDetails.studentschool !== ""
                    ? addstudentstyle.std_cat
                    : "NONE"
                }
              >
                *School Name
              </label>
              {isValid && !studentDetails.studentschool && (
                <p style={{ fontSize: "15px", color: "red", padding: "5px" }}>
                  School Name is Required
                </p>
              )}
            </div>
            <div className={addstudentstyle.input_element + ' ' + 'az_select_Add_student_Modal_select'}>
              <select
                className={`${addstudentstyle.form_select_element + ' ' + 'az_select'} cursorPointer`}
                name="studentbranch"
                id="studentbranch"
                value={studentDetails.studentbranch}
                onChange={handlerStudentDetailsInputs}
              // style={{lineHeight:"5px",fontSize:"12px"}}
              >
                <option value="">Select</option>
                {branchList.length > 0 ? (
                  branchList.map((branchList, index) => {
                    return (
                      <option value={branchList.branchId}>
                        {branchList.branch}
                      </option>
                    );
                  })
                ) : (
                  <option className={addstudentstyle.option} value=""></option>
                )}
              </select>
              {/* <label for="BranchName">*Branch Name</label> */}
              <label
                htmlFor="studentbranch"
                className={
                  studentDetails.studentbranch === ""
                    ? addstudentstyle.std_cat
                    : "NONE"
                }
              >
                *Branch Name
              </label>
              <label
                htmlFor="studentbranch"
                className={
                  studentDetails.studentbranch !== ""
                    ? addstudentstyle.std_cat
                    : "NONE"
                }
              >
                *Branch Name
              </label>
              {isValid && !studentDetails.studentbranch && (
                <p style={{ fontSize: "15px", color: "red", padding: "5px" }}>
                  Branch Name is Required
                </p>
              )}
            </div>

            <div className={addstudentstyle.input_element + ' ' + 'az_select_Add_student_Modal_select'}>
              <select
                className={`${addstudentstyle.form_select_element + ' ' + 'az_select'} cursorPointer`}
                name="grade"
                id="Grade"
                value={studentDetails.grade}
                onChange={handlerStudentDetailsInputs}
                style={{ lineHeight: "5px", fontSize: "12px" }}
              >
                <option value="">Select</option>
                {grdes && grdes.length > 0 ? (
                  grdes.map((gradesName, index) => {
                    return (
                      <option value={gradesName.id}>{gradesName.grade}</option>
                    );
                  })
                ) : (
                  <option className={addstudentstyle.option} value=""></option>
                )}
              </select>
              {/* <label for="Grade">*Grade</label> */}
              <label
                htmlFor="Grade"
                className={
                  studentDetails.grade === "" ? addstudentstyle.std_cat : "NONE"
                }
              >
                *Grade
              </label>
              <label
                htmlFor="Grade"
                className={
                  studentDetails.grade !== "" ? addstudentstyle.std_cat : "NONE"
                }
              >
                *Grade
              </label>
              {isValid && !studentDetails.grade && (
                <p style={{ fontSize: "15px", color: "red", padding: "5px" }}>
                  Grade is Required
                </p>
              )}
            </div>
            <div className={addstudentstyle.input_element + ' ' + 'az_select_Add_student_Modal_select'}>
              <select
                className={`${addstudentstyle.form_select_element + ' ' + 'az_select'} cursorPointer`}
                name="section"
                id="section"
                value={studentDetails.section}
                onChange={handlerStudentDetailsInputs}
                style={{ lineHeight: "5px", fontSize: "12px" }}
              >
                <option value="">Select</option>
                {sects && sects.length > 0 ? (
                  sects.map((sectionName, index) => {
                    return (
                      <option value={sectionName.id}>
                        {sectionName.section}
                      </option>
                    );
                  })
                ) : (
                  <option className={addstudentstyle.option} value=""></option>
                )}
              </select>
              {/* <label for="section">*Section</label> */}
              <label
                htmlFor="section"
                className={
                  studentDetails.section === ""
                    ? addstudentstyle.std_cat
                    : "NONE"
                }
              >
                *Section
              </label>
              <label
                htmlFor="section"
                className={
                  studentDetails.section !== ""
                    ? addstudentstyle.std_cat
                    : "NONE"
                }
              >
                *Section
              </label>
              {isValid && !studentDetails.section && (
                <p style={{ fontSize: "15px", color: "red", padding: "5px" }}>
                  Section Name is Required
                </p>
              )}
            </div>
          </div>
          <div className={addstudentstyle.modal_buttons}>
            <button
              className={addstudentstyle.choosefile_button_2}
              id={addstudentstyle.choose_button_id_2}
              onClick={handleAddStudentSubmit}
            >
              SUBMIT
            </button>
          </div>
        </div>
      </div>
      <CommonDeleteModal
        open={openDeleteModal}
        close={hideDeleteModal}
        deleteTitle={"Section"}
        deleteHandler={handleDeleteStudent}
        deleteId={selectedItem?.id}
        deleteName={selectedItem?.firstName}
      />
      <ConfirmationModal
        open={toggleConfirmation}
        confirmationmessage={toggleMessage}
        close={handleConfirmationClose}
        updateToggle={updateToggle}
      />
    </>
  );
};
export default Studenttable;
