import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import {
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Chip,
  Input,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ControlPoint } from "@mui/icons-material";
import { blue } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import Studentdynamic from "./../css/student32.module.css";
import { SearchOutlined } from "@mui/icons-material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { MenuProps, subjectAndSubtopicColumn } from "./Data";
import Switch from "../../components/common/Switch";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ViewSubtopicModal from "./ViewSubtopicModal";
import axios from "axios";
import EditIconButton from "../../components/common/EditIconButton";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import HoverGreyCancelButton from "../../components/common/HoverGreyCancelButton";
import BlueButton from "../../components/common/BlueButton";
import CommonDeleteModal from "./CommonDeleteModal";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
// import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import SubtopicDeleteModal from "./SubtopicDeleteModal";
import ConfirmationModal from "./ConfirmationModal";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";
import UpdatedModal from "./UpdatedModal";
//Action Icon Images
import action__Edit from "../../../src/img/Edit.svg";
import action__Delete from "../../../src/img/Delete.svg";

//sort icon
import sortIcon from "../../../src/pages/img/sort_1.png";
import Success_OK_Modal from "../../components/common/Success_OK_Modal";
import { inputLabelClasses } from "@mui/material/InputLabel";
import commonsort from "../js/CommonSort"
const SubjectAndSubtopic = () => {
  const [addSubAndTopicVisible, setAddSubAndTopicVisible] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [subtopicList, setSubtopicList] = useState([]);
  const [subject, setSubject] = useState("");
  const [updatedData, setUpdatedData] = useState();
  const [updatedSubtopicData, setUpdatedSubtopicData] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSkilledSubject, setIsSkilledSubect] = useState(false)
  const [selectedSubtopic, setSelectedSubtopic] = useState();
  const [pageSize, setPageSize] = useState(100);
  const [filterValue, setFilterValue] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [subTopicData, setSubTopicData] = useState();
  const [subjectType, setSubjectType] = useState([]);
  const [selectedSubjectTypeId, setSelectedSubjectId] = useState("");
  const token = sessionStorage.getItem("token");
  const [modifiedDate, setModifiedDate] = useState({});
  const [values, setValues] = useState([]);
  const [currValue, setCurrValue] = useState([]);
  const [myData, setMydata] = useState([]);
  const [message, setMessage] = useState("");
  const [Deletemodal, setDeleteModal] = useState(false);
  const [id, setId] = useState("");
  const [toggleMessage, settoggleMessage] = useState();
  const [toggleConfirmation, setToggleConfirmation] = useState(false);
  const [validation, setValidation] = useState(false);
  const navigate = useNavigate();

  //sort
  const [sortOrder, setSortOrder] = useState(true);
  const [sortBy, setSortBy] = useState("id");

  const handleChange = (e) => {
    setCurrValue(e.target.value);
  };

  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);

  // const sTopicmap = () =>{
  //   values?.map((elem)=>{
  //     "subTopic" : elem
  //   })
  // }

  const handleDelete = (item, index) => {
    let arr = [...values];
    arr.splice(index, 1);
    setValues(arr);
  };

  const handleKeyUp = (e) => {
    // if (e.keyCode == 32) {
    // setMydata((oldState) => [...oldState, currValue]);
    values.push({ subTopic: currValue, active: true });
    setCurrValue("");
  };
  // myData.forEach((data)=>{
  // })
  // }

  async function GetLastModifiedAt(ModifiedDate) {
    let token = sessionStorage.getItem("token");
    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/last-modified-at`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((json) => {
        ModifiedDate(json.data)
      })
      .catch(err => console.log(err))
  }

  const showDeleteModal = async (row) => {
    setId(row?.id);
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/confirmation-api?id=${row.id}&operationType=DELETE`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setMessage(response?.data?.data?.message);
    setOpenDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const showModal = (item) => {
    setSelectedSubtopic(item);

    // const response = await axios.get(
    //   `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/${id}`,
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // );
    // if (response?.data?.data) {
    //   setSelectedSubtopic(response?.data?.data);
    // }
    setVisibleModal(true);
  };
  const hideModal = () => {
    setVisibleModal(false);
    fetchData(pageSize);
  };
  const subjectHandler = (event) => {
    const value = event.target.value;
    setSubject(value);
  };
  const fetchData = async (pageSize) => {
    // console.log("fetchData")
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/page?pageSize=${pageSize}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data.data) {
      const totalElement = response?.data?.data?.totalElements;
      if (totalElement >= pageSize) {
        setPageSize(totalElement);
      }
      // console.log(response?.data?.data?.data)

      var sorted = await commonsort(response?.data?.data?.data || [], sortBy, sortOrder);
      setUpdatedData(sorted);
    }
    const subject = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log("subject",subject)
    const subTopic = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/sub-topics/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const subjectType = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subject-types/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setSubjectType(subjectType?.data?.data);
    setSubTopicData(subTopic?.data?.data);
    setUpdatedSubtopicData(subject.data.data);
  };
  useEffect(() => {
    if (pageSize) {
      fetchData(pageSize);
    }
  }, [pageSize, sortOrder]);

  useEffect(() => {
    setFilterValue(updatedData);
  }, [updatedData]);

  const updateToggle = () => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/toggle?id=${toggleDetails?.id}&active=${toggleDetails?.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: toggleDetails?.value,
        }
      )
      .then(function (response) {
        if (response?.data) {
          const find = updatedData?.find(
            (item) => item.id === toggleDetails?.id
          );
          find.active = toggleDetails?.value;
          const clone = [...updatedData];
          clone.forEach((data) => {
            data = find;
          });
          setUpdatedData(clone);
          handleConfirmationClose();
        }
      })
      .catch(function (response) {
        // console.log(response);
      });
  };
  const [toggleDetails, setToggleDetails] = useState({});
  const switchHandler = async (id, e) => {
    setToggleDetails({});
    const value = e.target.checked;
    if (!value) {
      const response = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/confirmation-api?id=${id}&operationType=TOGGLE`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setToggleConfirmation(true);
      settoggleMessage(response?.data?.data?.message);
      setToggleDetails({ id, value });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/toggle?id=${id}&active=${value}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            data: value,
          }
        )
        .then(function (response) {
          if (response?.data) {
            const find = updatedData?.find((item) => item.id === id);
            find.active = value;
            const clone = [...updatedData];
            clone.forEach((data) => {
              data = find;
            });
            setUpdatedData(clone);
            handleConfirmationClose();
          }
        })
        .catch(function (response) {
          // console.log(response);
        });
    }
  };

  const handleConfirmationClose = () => {
    setToggleConfirmation(false);
    setToggleDetails({});
  };
  const [entryName, setEntryName] = useState("")

  const addSubjectAndSubtopicHandler = () => {
    const subtopic = [];
    let subjectTypeId = "";
    subtopicList.forEach((topic) => {
      const filterValue = subTopicData.find((item) => item?.subTopic === topic);
      const subType = subjectType.find(
        (item) => item?.subjectType === filterValue?.subjectType
      );
      if (filterValue) {
        subtopic.push(filterValue);
        subjectTypeId = subType.id;
      }
    });
    // const subTopicData=[]
    // values.forEach(data=>{
    //   subTopicData.push({subTopic:data})
    // })

    const newValue = {
      subject: subject,
      subTopicsList: values,
      // subjectTypeId: subjectTypeId,
      hideSubtopics: isDisabled,
    }; setEntryName(subject, values)
    if (
      !newValue.subject
    ) {
      setValidation(true);
      return;
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects`,
      data: newValue,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function (response) {
        setSuccessCreateModal(true)
        setValidation(false)
        if (response.data.data) {
          setUpdatedData([...updatedData, response.data.data]);
          setValues([]);
        }
      })
      .catch(function (response) {
        console.log(response);
      });
    setAddSubAndTopicVisible(false);
    setSubject("");
    setSubtopicList([]);
    setIsDisabled(false);
    setMydata([]);
  };

  const [updateName, setUpdateName] = useState("")

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const disableHandler = (e) => {
    const disabled = e.target.checked;
    setIsDisabled(disabled);
    const clone = [...subTopicData];
    clone.forEach((item) => {
      item.active = disabled;
    });
    setSubTopicData(clone);
  };
  const setSkilledSubject = (e) => {
    // console.log("e.target.checked",e.target.checked)
    const disabled = e.target.checked;
    setIsSkilledSubect(disabled);
  };
  const selectSubjectHandler = (e) => {
    const id = e.target.value;
    if (id) {
      const filterValue = updatedData?.filter((item) => item?.id === id);
      setFilterValue(filterValue);
    } else {
      setFilterValue(updatedData);
    }
  };
  const selectSubtopicHandler = (e) => {
    const id = e.target.value;
    setSubtopicList(id);
  };
  const editSubjectAndSubtopicHandler = (id) => {
    const subtopic = [];
    subtopicList?.forEach((topic) => {
      const filterValue = subTopicData?.find(
        (item) => item?.subTopic === topic
      );
      if (filterValue) {
        subtopic.push(filterValue);
      }
    });

    // const subTopicData=[]
    // values.forEach(data=>{
    //   subTopicData.push({subTopic:data,active:true})
    // })

    const data = {
      subject: subject,
      skilledSubject: isSkilledSubject,
      hideSubtopics: isDisabled,
      subTopicsList: values ?? [],
      // subjectTypeId: selectedSubjectTypeId,
    };

    axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/${id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        setSuccesUpdatesModal(true)
        setUpdateName(subject, values)
        if (response.data.data) {
          // console.log(response.data.data)
          const find = updatedData?.find(
            (item) => item.id === response?.data?.data?.id
          );
          find.subject = response.data?.data?.subject;
          const clone = [...updatedData];
          clone.forEach((data) => {
            data = find;
          });
          //setUpdatedData([...updatedData,response.data.data]);
          setValues([]);
          fetchData(100);
        }
      })
      .catch(function (response) {
        console.log(response);
      });
    setSubject("");
    setSelectedSubjectId("");
    setAddSubAndTopicVisible(false);
    setIsEdit(false);
    setIsDisabled(false);
    setSubtopicList([]);
  };

  const deleteSubjectAndSubtopicHandler = () => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (response?.data) {
          // console.log(response?.data)
          const find = updatedData?.filter((data) => data.id !== id);
          setFilterValue(find);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
    hideDeleteModal();
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const onSearch = async (event) => {
    // console.log("onSearch called")
    let value = event?.target?.value?.toLowerCase();
    let result = updatedData;
    if (value) {
      const response = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/page?pageSize=${pageSize}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.data) {
        // console.log(response.data.data)
        const totalElement = response?.data?.data?.totalElements;
        if (totalElement >= pageSize) {
          setPageSize(totalElement);
        }
        setFilterValue(response?.data?.data?.data);
        setPage(0);
      }
    } else {
      setFilterValue(result);
      setPage(0);
    }

  };
  const handleSearchbtn = (e) => {
    e.preventDefault();
    fetchData(pageSize);
  };

  const editClickHandler = (row) => {
    const subtopic = row?.subTopics?.map((item) => item?.subTopic);
    setAddSubAndTopicVisible(true);
    setIsEdit(true);
    setSelectedItem(row);
    // setSubject(row?.subject);
    setSelectedSubjectId(row?.subjectTypeId);
    // setValues(subtopic);
    // console.log("editClickHandler")
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/${row.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data.data)
        setIsSkilledSubect(res.data.data.skilledSubject)
        setSubject(res.data.data.subject);
        setIsDisabled(res.data.data.hideSubtopics)
        setValues(
          res.data?.data?.subTopics.length > 0 ? res.data.data.subTopics : []
        );
        // let tempData = res.data.data.subTopics
        // console.log(tempData,"puneeeee");
        // let ty= []
        // values.forEach((data)=>{
        //   ty.push(data.subTopic);
        // });
        // console.log(ty,"aaaaaaa")
      });
  };
  const [succesUpdatesModal, setSuccesUpdatesModal] = useState(false)
  const [successCreateModal, setSuccessCreateModal] = useState(false)
  const closeSuccessModal = () => {
    setSuccessCreateModal(false)
  }
  const closeModal = () => {
    setSuccesUpdatesModal(false)
  }

  const filterStyle = {

    "&.MuiOutlinedInput-root": {
      "& > fieldset": {
        border: "1px solid #D6D5DF !important",
        fontSize: "13px !important",
      }
    },
    "&.MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        border: "1px solid #272727 !important",

      }
    },
    fontSize: "14px !important",
    fontFamily: "Poppins",
  }

  const labelStyle = {
    fontSize: "14px",
    "&.Mui-focused": {
      color: "orange",
      fontWeight: 600,
      fontFamily: "Poppins",
      fontSize: "13px",

    }
  }

  const textfieldStyle = {
    fontSize: "14px !important",
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        border: "1px solid #272727 !important",
        fontSize: "13px !important",
      }
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#ffa500 !important",
      fontSize: "13px !important",
      fontWeight: 600,
      fontFamily: "Poppins"
    },
    "& .MuiInputLabel-root": {
      color: "#B5B6B6 !important",
      fontSize: "14px"
    },
    "& .MuiInputBase-root": {
      height: "48px !important",
      width: "270px !important",
      borderRadius: "150px !important",

      "& .MuiInputBase-input": {
        color: "#ffa500",

      }


    },

  }

  const CustomTooltip = ({ title, children, ...props }) => (
    <Tooltip
      title={title}
      arrow
      {...props}
      PopperProps={{
        modifiers: [
          {
            name: 'arrow',
            options: {
              element: '[data-popper-arrow]',
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, -16],
            },
          }
        ],
      }}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#ffa500',
            color: 'white',
            boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)',
            fontSize: '10px',
            fontWeight: 500,
            padding: '9px 20px',
            borderRadius: '5px',
          },
        },
        arrow: {
          sx: {
            color: '#ffa500',
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );

  return (
    <>
      <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span
            className={dashboardContentStyles.link_icon}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
              sessionStorage.removeItem("sideNavSubMenuNames");
              navigate("/dashboard/template");
            }}
          >

            <img
              src={dashHome}
              alt="no img"
              // width="15px"
              // height="20px"
              // style={{ verticalAlign: "middle" }}
              className={dashboardContentStyles.az_menu_icon}
            />
          </span>
          {/* {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>
          {"  "} */}
          <span
            className={dashboardContentStyles.az_menu_text}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
              sessionStorage.removeItem("sideNavSubMenuNames");
              navigate("/dashboard/template");
            }}
          >
            Home
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          {/* {"  "} */}
          <span className={dashboardContentStyles.az_menu_text}
            style={{ cursor: 'default' }}>
            Master
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>

          {/* {"  "} */}
          {/* <a>Subject And Subtopic</a> */}
          <span
            className={`${dashboardContentStyles.az_selected_menu_text} cursorDefault`}
          >
            Subject And Subtopic
          </span>

        </div>
        <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>Last Update: {modifiedDate.data}</p>
        </div>
      </article>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          marginTop: "60px",
          gap: "16px",
          width: "100%",
        }}
      >
        {addSubAndTopicVisible && (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "#606060",
                columnGap: "10px",
              }}
            >
              <MenuBookOutlinedIcon style={{ fontSize: "30px" }} />
              <div style={{ fontSize: "1.1vw", fontWeight: 600 }}>
                {isEdit
                  ? "Edit Subject and Subtopic"
                  : "Add Subject and Subtopic"}
              </div>
            </div>
            <Paper
              style={{
                padding: "20px 20px",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: "1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    columnGap: "1rem",
                  }}
                >
                  <FormControl>
                    <TextField
                      sx={textfieldStyle}
                      id="demo-helper-text-aligned-no-helper"
                      label="*Subject"
                      autoComplete="off"
                      onChange={subjectHandler}
                      variant="outlined"
                      value={subject}
                    />
                    {validation && subject.length == 0 && (
                      <span style={{ color: "red", padding: "5px" }}>
                        Subject is Required
                      </span>
                    )}
                  </FormControl>
                  <FormControl>
                    <TextField
                      sx={textfieldStyle}
                      value={currValue}
                      onChange={handleChange}
                      label="Sub-topic"
                      // onKeyDown={handleKeyUp}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <ControlPoint
                              style={{
                                color: "white",
                                backgroundColor: "orange",
                                borderRadius: "50%",
                                marginRight: "10px",
                                cursor: 'pointer'
                              }}
                              onClick={handleKeyUp}
                            />
                          </InputAdornment>
                        ),
                      }}
                    // IconComponent={() => (
                    //   <ControlPoint
                    //   onClick={()=>{
                    //     // e.preventDefault();
                    //     handleKeyUp();
                    //   }}
                    //   style={{color :'white' ,backgroundColor:"orange" , borderRadius:'50%',marginRight : '10px'}}/>
                    // )}
                    />
                  </FormControl>

                  <div>
                    {values?.map((item, index) => (
                      <Chip
                        sx={{
                          '& .MuiChip-deleteIcon': {
                            color: 'tomato',
                          },
                        }}
                        style={{
                          backgroundColor: "#ccd1fd",
                          color: "black",
                          fontSize: "18px",
                          borderRadius: "6px",
                          padding: "20px",
                          margin: "5px",
                        }}
                        size="small"
                        onDelete={() => handleDelete(item, index)}
                        label={item.subTopic}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <Checkbox
                    style={{ color: "#fb9d2a" }}
                    checked={isDisabled}
                    onChange={(e) => disableHandler(e)}
                  />
                  <span
                    style={{
                      fontSize: "1.1vw",
                      fontWeight: "600",
                    }}
                  >
                    Subtopic Display Disable
                  </span>
                </div>
                <div>
                  <Checkbox
                    style={{ color: "#fb9d2a" }}
                    checked={isSkilledSubject}
                    onChange={(e) => setSkilledSubject(e)}
                  />
                  <span
                    style={{
                      fontSize: "1.1vw",
                      fontWeight: "600",
                    }}
                  >
                    SKilled Subject
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: "1rem",
                  }}
                >
                  <HoverGreyCancelButton
                    variant="outlined"
                    onClick={() => {
                      setAddSubAndTopicVisible(false);
                      setIsEdit(false);
                      setSubject("");
                      setSelectedItem("");
                      setValues([]);
                      setValidation(false)
                    }}
                  >
                    Cancel
                  </HoverGreyCancelButton>
                  {isEdit ? (
                    <BlueButton
                      onClick={() =>
                        editSubjectAndSubtopicHandler(selectedItem.id)
                      }
                    >
                      Update
                    </BlueButton>
                  ) : (
                    <BlueButton onClick={() => addSubjectAndSubtopicHandler()}>
                      Create
                    </BlueButton>
                  )}
                </div>
              </div>
            </Paper>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "#606060",
                columnGap: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: "10px",
                }}
              >
                <MenuBookOutlinedIcon style={{ fontSize: "2.2vw" }} />
                <div
                  style={{
                    fontSize: "1.1vw",
                    fontWeight: "600",
                  }}
                >
                  Subject and Subtopic
                </div>
              </div>

              <FormControl
                sx={{
                  width: "156px", height: "36px", marginRight: "10px",
                }}
                size="small"
              >
                <InputLabel sx={labelStyle} id="subject-select">Select Subject</InputLabel>
                <Select
                  labelId="subject-select"
                  id="subject-select"
                  input={<OutlinedInput label="Select Subject" />}
                  MenuProps={MenuProps}
                  sx={filterStyle}
                  style={{
                    borderRadius: 150,
                    background: "#fff",
                    height: '100%'
                  }}
                  onChange={(e) => selectSubjectHandler(e)}
                >
                  <MenuItem
                    className={dashboardContentStyles.menu_item}
                    value={""}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: '#FFA500',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#FFA500',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    All
                  </MenuItem>
                  {updatedSubtopicData?.map((name) => (
                    <MenuItem
                      key={name?.id}
                      value={name?.id}
                      className={dashboardContentStyles.menu_item}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: '#FFA500',
                          color: "#FFFFFF"
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: '#FFA500',
                          color: "#000000"
                        },
                        "&:not(.Mui-selected):hover": {
                          backgroundColor: '#fcedc2',
                          color: '#000000',
                        },
                      }}
                    >
                      {name?.subject}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "1rem",
              }}
            >
              <div
                className={
                  Studentdynamic.school_list_search_container_32
                }
              >
                <div
                  className={
                    Studentdynamic.school_list_search_container
                  }
                  style={{
                    height: "32px !important",
                  }}
                >
                  <input
                    type="search"
                    name="search"
                    id="search"
                    placeholder="Search..."
                    onChange={onSearch}
                    style={{
                      width: "247px",
                      height: "32px !important",
                    }}
                  />
                  <span>
                    <img
                      src={searchIcn}
                      style={{
                        verticalAlign: "top",
                        fontSize: "large",
                        cursor: 'pointer'
                      }}
                      aria-hidden="true"
                      onClick={handleSearchbtn}
                    />
                  </span>
                </div>
              </div>

              {!addSubAndTopicVisible && (
                <GreenAddButton onClick={() => setAddSubAndTopicVisible(true)}>
                  <AddCircleOutlineOutlinedIcon />
                  Add Subject and Subtopic
                </GreenAddButton>
              )}
            </div>
          </div>
        </div>
        <Paper>
          <TableContainer
            style={{ marginBottom: "90px" }}
            sx={{ maxHeight: 640, overflow: "auto" }}
          >
            <Table
              sx={{ minWidth: 650 }}
              stickyHeader={true}
              aria-label="customized table"
            >
              <TableHead
              >
                <TableRow>
                  {subjectAndSubtopicColumn.map((item) => {
                    return (
                      <TableCell
                        style={{

                          //color: "blue",
                          //fontWeight: 600,
                          paddingRight: "60px",
                          // background: "#d9dcfe",
                          color: "#ffffff",
                          fontFamily: "poppins",
                          fontWeight: 400,
                          background: '#403E75',
                          fontSize: "1vw",
                          //textAlign: "center",

                        }}
                        key={item.id}
                        align={item.align}
                      >
                        {item.id === 2 || item.id === 3 || item.id === 4 ? (
                          <>
                            {item.dataIndex}
                            <img
                              className="cursor-pointer"
                              width="10"
                              height="15"
                              style={{
                                verticalAlign: "middle",
                                margin: "2px 0 5px 3px",
                              }}
                              src={sortIcon}
                              onClick={async () => {
                                console.log(item.id)
                                await setSortOrder(!sortOrder);
                                (await item.id) === 3
                                  ? setSortBy("subject")
                                  : (await item.id) === 4 ? setSortBy("subTopics") : setSortBy("skilledSubject");
                                // await initData(!sortOrder, "id");
                              }}
                            />
                          </>
                        ) : (
                          <> {item.dataIndex}</>
                        )}
                        {/* {item.dataIndex} */}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {filterValue
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    return (
                      <TableRow
                        key={row.id}
                        sx={{
                          // "&:last-child td, &:last-child th": { border: 0 },

                          backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f8',
                          "&:last-child td, &:last-child th": {
                            borderBottom: "none !important",
                          },

                          "&:hover": {

                            "& td, & th": {
                              borderTop: "1px solid #ff9702",
                              borderBottom: "1px solid #ff9702",
                            },
                            " & th:first-of-type": {
                              borderLeft: "1px solid #ff9702",
                            },

                            "& td:first-of-type": {
                              borderLeft: "0",

                            },

                            "&:last-child td, &:last-child th": {
                              borderBottom: "1px solid #ff9702 !important",
                            },

                            "& td:last-of-type": {
                              borderRight: "1px solid #ff9702",

                            },

                            "& th:last-of-type": {
                              borderRight: "0",
                            },
                          },

                          "& td, & th": {
                            border: "1px solid transparent",
                            borderTop: "1px solid transparent",
                            borderBottom: "1px solid transparent",
                            "&:not(:last-child)": {
                              borderRight: "1px solid transparent",
                            },
                          },
                        }}

                        className={dashboardContentStyles.table_rw}

                      >
                        <TableCell
                          className={dashboardContentStyles.table_cell}
                          component="th"
                          scope="row"
                        >
                          {page * rowsPerPage + (index + 1)}
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() => showModal(row)}
                          style={{
                            cursor: "pointer",
                            display: "tableCell",
                            flexDirection: "row",
                            height: "100%",
                          }}
                        >
                          <div
                            className={dashboardContentStyles.table_cell_name}
                            style={{ display: "flex" }}
                          >
                            {row?.subTopics?.length
                              ? row.subTopics?.map((item, i) => {
                                return (
                                  <div key={item?.id}>
                                    {(i ? ", " : "") + item?.subTopic}
                                  </div>
                                );
                              })
                              : `-`}
                          </div>
                        </TableCell>
                        <TableCell
                          align="left"
                          // onClick={() => handleOpen(row)}
                          className={dashboardContentStyles.table_cell}
                        >
                          {row.subject}
                        </TableCell>
                        <TableCell
                          align="center"
                          // onClick={() => handleOpen(row)}
                          className={dashboardContentStyles.table_cell}
                        >
                          {row.skilledSubject ? "TRUE" : "FALSE"}
                        </TableCell>
                        <TableCell align="right">
                          <Switch
                            checked={row?.active}
                            onChange={(e) => switchHandler(row?.id, e)}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <EditIconButton
                            onClick={() => {
                              editClickHandler(row);
                            }}
                          >
                            <CustomTooltip title="Edit" placement={"top"}>
                              {/* <EditOutlinedIcon style={{ fontSize: "1.7vw" }} /> */}
                              <img
                                className={
                                  dashboardContentStyles.aRoleActionBtn
                                }
                                src={action__Edit}
                                alt="Edit-Btn"
                              />
                            </CustomTooltip>
                          </EditIconButton>

                          <DeleteIconButton
                            onClick={() => {
                              showDeleteModal(row);
                              setSelectedItem(row);
                            }}
                          >
                            <CustomTooltip title="Remove" placement={"top"}>
                              {/* <DeleteOutlinedIcon
                                style={{ fontSize: "1.7vw" }}
                              /> */}
                              <img
                                className={
                                  dashboardContentStyles.aRoleActionBtn
                                }
                                src={action__Delete}
                                alt="Dlt-Btn"
                              />
                            </CustomTooltip>
                          </DeleteIconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              bottom: "0",
              width: "80.5%",
              position: "fixed",
              backgroundColor: "white",
            }}
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={filterValue?.length || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPage}
            showFirstButton={true}
            showLastButton={true}
          />
        </Paper>
        <ViewSubtopicModal
          open={visibleModal}
          onClose={hideModal}
          subtopicList={selectedSubtopic}
        />
        <SubtopicDeleteModal
          open={openDeleteModal}
          close={hideDeleteModal}
          deleteTitle={"Subtopic"}
          deleteHandler={deleteSubjectAndSubtopicHandler}
          deleteId={selectedItem?.id}
          deleteName={message}
        />
        <ConfirmationModal
          open={toggleConfirmation}
          confirmationmessage={toggleMessage}
          close={handleConfirmationClose}
          updateToggle={updateToggle}
        />
        <Success_OK_Modal
          userType={"Subject and Subtopic"}
          userName_Modal={
            entryName
          }
          open={successCreateModal}
          handleOk={closeSuccessModal}
        />
        <UpdatedModal
          userType={"Subject and Subtopic"}
          userName_Modal={
            updateName
          }
          open={succesUpdatesModal}
          handleOk={closeModal}
          close={closeModal}
        />
      </div>
    </>
  );
};
export default SubjectAndSubtopic;
