import {
  Radio,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  CircularProgress,
  ListItemText,
  Box,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { SearchOutlined } from "@mui/icons-material";
import LibraryBooksRoundedIcon from "@mui/icons-material/LibraryBooksRounded";
import React, { useEffect, useState } from "react";
import { MenuProps, subjectMappingColumn } from "./Data";
import Studentdynamic from "./../css/student32.module.css";
import Switch from "../../components/common/Switch";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditIconButton from "../../components/common/EditIconButton";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import HoverGreyCancelButton from "../../components/common/HoverGreyCancelButton";
import BlueButton from "../../components/common/BlueButton";
import ViewSubjectMappingModal from "./ViewSubjectMappingModal";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
// import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import CommonDeleteModal from "./CommonDeleteModal";
import ConfirmationModal from "./ConfirmationModal";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";
import Success_OK_Modal_SubjectMapping from "./Success_OK_Modal_SubjectMapping";
import UpdatedModalSubjectMapping from "./UpdatedModalSubjectMapping";
import Addbranchtcss from "./../css/addbranch.module.css";
import { Checkbox } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import sub_map_Img from "../../../src/img/Masters.svg";
import { styled } from '@mui/material/styles';
//Action Icon Images
import action__Edit from "../../../src/img/Edit.svg";
import action__Delete from "../../../src/img/Delete.svg";

//sort icon
import sortIcon from "../../../src/pages/img/sort_1.png";
import commonsort from "../js/CommonSort"
import axios from "axios";
const SubjectMapping = () => {
  const token = sessionStorage.getItem("token");
  const [visibleAddSubject, setVisibleAddSubject] = useState(false);
  const [updatedData, setUpdatedData] = useState();
  const [filterValue, setFilterValue] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [visibleSubjectModal, setVisibleSubjectModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedValue, setSelectedValue] = useState();
  const [selectedSkilledSubject, setSelectedSkilledSubject] = useState(true);
  const [subjectList, setSubjectList] = useState();
  const [showSubTopic, setShowSubTopic] = useState(true);
  const [gradeList, setGradeList] = useState();
  const [boardList, setBoardList] = useState();
  const [subtopicList, setSubtopicList] = useState();
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedSubtopic, setSelectedSubtopic] = useState([]);
  const [selectedBoard, setSelectedBoard] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [modifiedDate, setModifiedDate] = useState({});
  const [validation, setValidation] = useState(false);
  const [deletemodal, setDeleteModal] = useState(false);
  const [item, setItem] = useState({});
  const [message, setMessage] = useState();
  const [toggleMessage, settoggleMessage] = useState();
  const [toggleConfirmation, setToggleConfirmation] = useState(false);
  const navigate = useNavigate();

  //sort
  const [sortOrder, setSortOrder] = useState(true);
  const [sortBy, setSortBy] = useState("id");

  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);
  const showVisibleAddSubject = () => {
    setVisibleAddSubject(true);
  };
  const hideVisibleAddSubject = () => {
    setVisibleAddSubject(false);
    setIsEdit(false);
    setSelectedGrade("");
    setSelectedSubtopic([]);
    setSelectedBoard("");
    setSelectedSubject("");
  };
  const fetchData = async (pageSize) => {
    console.log("sortBy", sortBy, page)
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subject-mapping/page?pageNumber=${page}&pageSize=500&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("length 123", response?.data?.data.length, response?.data?.data.data)
    if (response?.data?.data?.data?.length > 0) {
      const totalElement = response?.data?.data?.totalElements;
      if (totalElement >= pageSize) {
        setPageSize(totalElement);
      }
      // response.data.data.data = await commonsort(response?.data?.data?.data || [], sortBy, sortOrder)
      setUpdatedData(response?.data?.data?.data);
    } else {
      console.log("else called")
    }
  };
  const fetchSubjectData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setSubjectList(response?.data?.data);
  };
  const fetchBoardData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/boards/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setBoardList(response?.data?.data);
  };
  const fetchGradeData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/grades/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setGradeList(response?.data?.data || []);
  };

  async function GetLastModifiedAt(ModifiedDate) {
    let token = sessionStorage.getItem("token");
    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subject-mapping/last-modified-at`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((json) => {
        ModifiedDate(json.data)
      })
      .catch(err => console.log(err))
  }

  const fetchSubtopic = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/sub-topics/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setSubtopicList(response?.data?.data);
  };
  useEffect(() => {
    fetchData(pageSize);
  }, [pageSize, sortOrder]);
  useEffect(() => {
    fetchSubjectData();
    fetchGradeData();
    fetchBoardData();
    fetchSubtopic();
  }, []);
  useEffect(() => {
    setFilterValue(updatedData);
  }, [updatedData]);
  const skillValueHandler = (event) => {
    // console.log(event.target.value,"skillValueHandler",typeof event.target.value)
    var val = event.target.value == "true" ? true : false;
    // console.log(val,typeof val)
    setSelectedSkilledSubject(val);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const showSubjectModal = (item) => {
    setSelectedValue(item);
    setVisibleSubjectModal(true);
  };
  const hideSubjectModal = () => {
    setVisibleSubjectModal(false);
    fetchData(pageSize);
  };
  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const onSearch = async (event) => {
    let value = event?.target?.value?.toLowerCase();
    let result = updatedData;
    if (value) {
      result = updatedData?.filter((data) => {
        return data?.subject?.toLowerCase().includes(value);
      });
    }
    setFilterValue(result);
    setPage(0);
  };
  const handleSearchbtn = (e) => {
    e.preventDefault();
    fetchData(pageSize);
  };
  const [boardId, setBoardId] = useState();
  const [boardkey, setBoardkey] = useState("");
  const [subjectKey, setSubjectKey] = useState("");
  const [gradekey, setgradekey] = useState("");
  const [subjectId, setSubjectId] = useState();
  const [gradeId, setGradeId] = useState();

  const selectBoardHandler = (e) => {
    const id = e.target.value;
    setBoardId(id);
    // setBoardKey(Math.random() * 20);
    // setgradekey(Math.random() * 20);
    // setSubjectKey(Math.random() * 20);

    if (id !== "all") {
      if (gradeId && !subjectId) {
        const filterValue = updatedData?.filter(
          (item) => item.gradeId === gradeId && item.boardId === id
        );
        setFilterValue(filterValue);
      } else if (!gradeId && subjectId) {
        const filterValue = updatedData?.filter(
          (item) => item.subjectId === subjectId && item.boardId === id
        );
        setFilterValue(filterValue);
      } else if (gradeId && subjectId) {
        const filterValue = updatedData?.filter(
          (item) =>
            item.gradeId === gradeId &&
            item.subjectId === subjectId &&
            item.boardId === id
        );
        setFilterValue(filterValue);
      } else {
        const filterValue = updatedData?.filter((item) => item.boardId === id);
        setFilterValue(filterValue);
      }
    } else {
      setFilterValue(updatedData);
    }
    setPage(0);
  };
  const selectGradeHandler = (e) => {
    const id = e.target.value;
    setGradeId(id);
    if (id !== "all") {
      if (boardId && !subjectId) {
        const filterValue = updatedData?.filter(
          (item) => item.gradeId === id && item.boardId == boardId
        );
        setFilterValue(filterValue);
      } else if (!boardId && subjectId) {
        const filterValue = updatedData?.filter(
          (item) => item.gradeId === id && item.subjectId === subjectId
        );
        setFilterValue(filterValue);
      } else if (boardId && subjectId) {
        const filterValue = updatedData?.filter(
          (item) =>
            item.gradeId === id &&
            item.subjectId === subjectId &&
            item.boardId == boardId
        );
        setFilterValue(filterValue);
      } else {
        const filterValue = updatedData?.filter((item) => item.gradeId === id);
        setFilterValue(filterValue);
      }
    } else {
      setFilterValue(updatedData);
    }
    setPage(0);
  };

  const selectSubjectHandler = (e) => {
    const id = e.target.value;
    console.log("selected subject", id, subjectList);

    const selectedSubject = subjectList.find(val => val.id === String(id));
    console.log(selectedSubject)
    console.log(selectedSubject?.hideSubtopics, selectedSubject.subjectId)
    if (selectedSubject && selectedSubject.hideSubtopics) {
      setShowSubTopic(false);
      // console.log("false")
      // alert("subjmap22 false");
    } else {
      // console.log("true")
      setShowSubTopic(true);
      // alert("subjmap222 true");
    }
    console.log(subtopicList)

    setSubjectId(id);
    if (id !== "all") {
      if (boardId && gradeId) {
        const filterValue = updatedData?.filter(
          (item) =>
            item.subjectId === id &&
            item.boardId == boardId &&
            item.gradeId == gradeId
        );
        setFilterValue(filterValue);
      } else if (!boardId && gradeId) {
        const filterValue = updatedData?.filter(
          (item) => item.subjectId === id && item.gradeId == gradeId
        );
        setFilterValue(filterValue);
      } else if (boardId && !gradeId) {
        const filterValue = updatedData?.filter(
          (item) => item.subjectId === id && item.boardId == boardId
        );
        setFilterValue(filterValue);
      } else {
        const filterValue = updatedData?.filter(
          (item) => item.subjectId === id
        );
        setFilterValue(filterValue);
      }
    } else {
      setFilterValue(updatedData);
    }
    setPage(0);
  };
  const [toggleDetails, setToggleDetails] = useState({});
  const switchHandler = async (subjectId, boardId, gradeId, e) => {
    setToggleDetails({});
    const value = e.target.checked;
    if (!value) {
      const data = {
        subjectId: subjectId,
        boardId: boardId,
        gradeId: gradeId,
        active: value,
        operationType: "TOGGLE",
      };
      axios
        .put(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subject-mapping/confirmation-api`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          console.log(response);
          setToggleConfirmation(true);
          settoggleMessage(response?.data?.data?.message);
          setToggleDetails({ subjectId, boardId, gradeId, active: value });
        })
        .catch(function (response) { });
    } else {
      axios
        .put(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subject-mapping/toggle-active`,
          { subjectId, boardId, gradeId, active: value },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            data: value,
          }
        )
        .then(function (response) {
          if (response?.data) {
            fetchData(pageSize);
            handleConfirmationClose();
          }
        })
        .catch(function (response) {
          // console.log(response);
        });
    }
  };

  const handleConfirmationClose = () => {
    setToggleConfirmation(false);
    setToggleDetails({});
  };

  const updateToggle = () => {
    const { subjectId, boardId, gradeId, active } = toggleDetails;
    axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subject-mapping/toggle-active`,
        { subjectId, boardId, gradeId, active },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: toggleDetails?.value,
        }
      )
      .then(function (response) {
        if (response?.data) {
          fetchData(pageSize);
          handleConfirmationClose();
        }
      })
      .catch(function (response) {
        // console.log(response);
      });
  };

  // const switchHandler = (subjectId, boardId, gradeId, e) => {
  //   const value = e.target.checked;
  //   const data = {
  //     active: value,
  //   };
  //   axios
  //     .put(
  //       `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subject-mapping/toggle-active?subjectId=${subjectId}&boardId=${boardId}&gradeId=${gradeId}&active=${value}`,
  //       data,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     .then(function (response) {
  //       fetchData(pageSize);
  //     })
  //     .catch(function (response) {});
  // };

  console.log(selectedSubtopic, "subs");
  const addSubjectAndMapping = (subjectId, boardId, gradeId, subTopic) => {
    if (
      !SubjectMapping ||
      subjectId.length == 0 ||
      boardId.length == 0 ||
      // selectedBoard.length==0 ||
      gradeId.length == 0
    ) {
      setValidation(true);
      return;
    }
    const subTopicId = [];
    subTopic.forEach((topic) => {
      const filterValue = subtopicList.find((item) => item?.subTopic === topic);
      if (filterValue) {
        subTopicId.push(filterValue.id);
      }
    });
    const newValue = {
      subjectId: subjectId,
      boardId: boardId,
      gradeId: [`${gradeId}`],
      subTopicId: subTopic.length > 0 ? subTopic : null,
      skilledSubject: selectedSkilledSubject,
    };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subject-mapping`,
      data: newValue,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function (response) {
        setBoardkey(Math.random() * 20);
        setgradekey(Math.random() * 20);
        setSubjectKey(Math.random() * 20);

        fetchData();
        setTimeout(() => {
          setSuccessCreateModal(true);
        }, 1000);
        // if (response.data.data) {
        //   setFilterValue([...updatedData, response?.data?.data[0]]);
        // }
      })
      .catch(function (response) {
        console.log("error ==> ", response);
      });
    hideVisibleAddSubject();
  };

  const handleSubject = (value) => {
    setSelectedSubject(value);
    axios({
      method: "get",
      url: `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/sub-topics/all?subjectId=${value}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function (response) {
        setSubtopicList(response.data.data);
      })
      .catch(function (response) {
        console.log("error ==> ", response);
      });
  };

  const editAddSubjectAndMapping = (subjectId, boardId, gradeId, subTopic) => {
    setSuccesUpdatesModal(true);
    console.log(subTopic);
    if (
      !SubjectMapping ||
      subjectId.length == 0 ||
      boardId.length == 0 ||
      gradeId.length == 0
    ) {
      setValidation(true);
      return;
    }
    const subTopicId = [];
    subTopic.forEach((topic) => {
      const filterValue = subtopicList.find((item) => item?.subTopic === topic);
      if (filterValue) {
        subTopicId.push(filterValue.id);
      }
    });
    const updatedValue = {
      subjectId: subjectId,
      boardId: boardId,
      gradeId: [`${gradeId}`],
      subTopicId: selectedSubtopic,
      skilledSubject: Boolean(selectedSkilledSubject),
    };
    axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subject-mapping/update`,
        updatedValue,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        fetchData(pageSize);
      })
      .catch(function (response) {
        console.log(response);
      });
    hideVisibleAddSubject();
  };

  const deleteSubjectMapping = (row) => {
    setDeleteModal(true);
    const { subjectId, boardId, gradeId } = row;
    const temp = {
      subjectId: subjectId,
      boardId: boardId,
      gradeId: gradeId,
      operationType: "DELETE",
    };

    axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subject-mapping/confirmation-api`,
        temp,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        console.log(response);
        setMessage(response?.data?.data?.message);
        setItem(row);
      });
  };

  const hideDeleteModal = () => {
    setDeleteModal(false);
  };

  const deleteSubjectAndMapping = () => {
    const { subjectId, boardId, gradeId, subTopicId, active } = item;
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {
        subjectId: subjectId,
        boardId: boardId,
        gradeId: gradeId,
        active: active,
        operationType: "DELETE",
      },
    };

    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subject-mapping`,
        config
      )
      .then(function (response) {
        fetchData(pageSize);
        hideDeleteModal();
      })
      .catch(function (response) {
        console.log(response);
        hideDeleteModal();
      });
  };
  const editClickHandler = (row) => {
    const subtopic = row?.subTopics?.map((item) => item.subtopic);
    console.log(row, "row", row.hideSubtopics);
    const subtopicRow = row.subTopics?.map((subtopic) => subtopic.subtopicId);
    setShowSubTopic(row.hideSubtopics ? false : true)
    setSelectedBoard(row?.boardId);
    setSelectedGrade(row?.gradeId);
    handleSubject(row?.subjectId);
    (row.subTopics === null || row.hideSubtopics)
      ? setSelectedSubtopic([])
      : setSelectedSubtopic(subtopicRow);//reg
    setSelectedSkilledSubject(row?.skilledSubject);
    setIsEdit(true);
    showVisibleAddSubject();
  };

  const [succesUpdatesModal, setSuccesUpdatesModal] = useState(false);
  const [successCreateModal, setSuccessCreateModal] = useState(false);
  const closeSuccessModal = () => {
    setSuccessCreateModal(false);
  };

  const closeModal = () => {
    setSuccesUpdatesModal(false);
  };

  const filterStyle = {
    "&.MuiOutlinedInput-root": {
      "& > fieldset": {
        border: "1px solid #D6D5DF !important",
        fontSize: "13px !important",
      },
    },
    "&.MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        border: "1px solid #272727 !important",
      },
    },
    fontSize: "14px !important",
    fontFamily: "Poppins",
  };

  const labelStyle = {
    fontSize: "14px",
    "&.Mui-focused": {
      color: "orange",
      fontFamily: "Poppins",
      fontSize: "13px",
    },
  };

  const CustomTooltip = ({ title, children, ...props }) => (
    <Tooltip
      title={title}
      arrow
      {...props}
      PopperProps={{
        modifiers: [
          {
            name: 'arrow',
            options: {
              element: '[data-popper-arrow]',
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, -16],
            },
          }
        ],
      }}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#ffa500',
            color: 'white',
            boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)',
            fontSize: '10px',
            fontWeight: 500,
            padding: '9px 20px',
            borderRadius: '5px',
          },
        },
        arrow: {
          sx: {
            color: '#ffa500',
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );

  //-------| Custom Radio Button Starts |-------//
  const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 15,
    height: 15,
    border: "2px solid #FD8C00",
    boxShadow: "0px 2px 2px #FA5B1685"
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#FD8C00',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 8,
      height: 8,
      margin: "2px 0 2px 1.5px",
      background: 'white',
      borderRadius: '50%',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#FD8C00',
    },
  });


  const onSort = async (sortOrder, item) => {
    console.log(sortOrder, item, filterValue)
    const id = await item.id;
    var sortby = id === 2 ? "subject" : id === 3 ? "subTopics" : id === 4 ? "board" : id === 5 ? "grade" : "skilledSubject";
    console.log(id, sortby, "iddddddddddd", sortOrder, item);
    // var sorted = await commonsort(filterValue || [], sortby, sortOrder)
    // setFilterValue([])
  }


  function BpRadio(props) {
    return (
      <Radio
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }
  //-------| Custom Radio Button Ends |-------//

  return (
    <>
      <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span
            className={dashboardContentStyles.link_icon}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
              sessionStorage.removeItem("sideNavSubMenuNames");
              navigate("/dashboard/template");
            }}
          >
            <img
              src={dashHome}
              alt="no img"
              // width="15px"
              // height="20px"
              // style={{ verticalAlign: "middle" }}
              className={dashboardContentStyles.az_menu_icon}
            />
          </span>
          {/* {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>
          {"  "} */}
          <span
            className={dashboardContentStyles.az_menu_text}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
              sessionStorage.removeItem("sideNavSubMenuNames");
              navigate("/dashboard/template");
            }}
          >
            Home
          </span>

          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          {/* {"  "} */}
          <span className={dashboardContentStyles.az_menu_text}
            style={{ cursor: 'default' }}>
            Master
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          {/* {"  "} */}
          {/* <a>Subject Mapping</a> */}
          <span
            className={`${dashboardContentStyles.az_selected_menu_text} cursorDefault`}
          >
            Subject Mapping
          </span>
        </div>
        <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>Last Update: {modifiedDate.data}</p>
        </div>
      </article>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          marginTop: "60px",
          gap: "16px",
          width: "100%",
        }}
      >
        {visibleAddSubject && (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "#606060",
                columnGap: "10px",
              }}
            >
              <img src={sub_map_Img} alt="" />
              <div style={{ fontSize: "1vw", fontWeight: 600 }}>
                {isEdit ? "Edit Subject Mapping" : "Add Subject Mapping"}
              </div>
            </div>
            <TableContainer
              component={Paper}
              style={{
                width: "100%",
                padding: "30px 20px",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    columnGap: "1rem",
                  }}
                >

                  <FormControl>
                    <div className={Addbranchtcss.input_element}>
                      <select
                        style={{ width: "230px", overflow: "hidden", textOverflow: "ellipsis", borderRadius: "150px", cursor: 'pointer' }}
                        className={Addbranchtcss.form_select_element}
                        name="cityId"
                        id="cityId"
                        value={selectedBoard}
                        onChange={(e) => setSelectedBoard(e.target.value)}
                        placeholder=" "
                      >
                        <option
                          className={Addbranchtcss.option}
                          selected
                          value=""
                        >
                          Select
                        </option>

                        {boardList?.length > 0 ? (
                          boardList.map((item, index) => {
                            return (
                              <option
                                className={Addbranchtcss.option}
                                value={item?.id}
                                id={item.id}
                              >
                                {item?.board}
                              </option>
                            );
                          })
                        ) : (
                          <option
                            className={Addbranchtcss.option}
                            // value=""
                            disabled
                            selected
                            value="default"
                          >
                            Select
                          </option>
                        )}
                      </select>

                      <label
                        htmlFor="cityId"
                        className={
                          selectedBoard === "" ? Addbranchtcss.std_cat : "NONE"
                        }
                      >
                        *Board
                      </label>
                      <label
                        htmlFor="cityId"
                        className={
                          selectedBoard !== "" ? Addbranchtcss.std_cat : "NONE"
                        }
                      >
                        *Board
                      </label>
                    </div>
                    {validation && !selectedBoard && (
                      <span style={{ color: "red", padding: "5px" }}>
                        Board is Required
                      </span>
                    )}
                  </FormControl>

                  <FormControl>
                    <div className={Addbranchtcss.input_element}>
                      <select
                        style={{ width: "250px", overflow: "hidden", textOverflow: "ellipsis", borderRadius: "150px", cursor: 'pointer' }}
                        className={Addbranchtcss.form_select_element}
                        name="cityId"
                        id="cityId"
                        value={selectedGrade}
                        onChange={(e) => setSelectedGrade(e.target.value)}
                        placeholder=" "
                      >
                        <option
                          className={Addbranchtcss.option}
                          selected
                          value=""
                        >
                          Select
                        </option>

                        {gradeList.length > 0 ? (
                          gradeList.map((item, index) => {
                            return (
                              <option
                                className={Addbranchtcss.option}
                                value={item?.id}
                                id={item.id}
                              >
                                {item?.grade}
                              </option>
                            );
                          })
                        ) : (
                          <option
                            className={Addbranchtcss.option}
                            // value=""
                            disabled
                            selected
                            value="default"
                          >
                            Select
                          </option>
                        )}
                      </select>

                      <label
                        htmlFor="cityId"
                        className={
                          selectedGrade === "" ? Addbranchtcss.std_cat : "NONE"
                        }
                      >
                        *Grades
                      </label>
                      <label
                        htmlFor="cityId"
                        className={
                          selectedGrade !== "" ? Addbranchtcss.std_cat : "NONE"
                        }
                      >
                        *Grades
                      </label>
                    </div>
                    {validation && !selectedGrade && (
                      <span style={{ color: "red", padding: "5px" }}>
                        Grade(s) is Required
                      </span>
                    )}
                  </FormControl>

                  <FormControl>
                    <div className={Addbranchtcss.input_element}>
                      <select

                        style={{ width: "250px", overflow: "hidden", textOverflow: "ellipsis", borderRadius: "150px", cursor: 'pointer' }}
                        className={Addbranchtcss.form_select_element}
                        name="cityId"
                        id="cityId"
                        value={selectedSubject}
                        onChange={(e) => {
                          handleSubject(e.target.value);
                          setSelectedSubtopic([]);
                        }}
                        placeholder=" "
                      >
                        <option
                          className={Addbranchtcss.option}
                          disabled
                          selected
                          value=""
                        >
                          Select
                        </option>

                        {subjectList?.length > 0 ? (
                          subjectList.map((item, index) => {
                            return (
                              <option
                                className={Addbranchtcss.option}
                                // value={cityName.city}
                                value={item.id}
                                id={item.id}
                              >
                                {item.subject}
                              </option>
                            );
                          })
                        ) : (
                          <option
                            className={Addbranchtcss.option}
                            // value=""
                            disabled
                            selected
                            value="default"
                          >
                            Select
                          </option>
                        )}
                      </select>

                      <label
                        htmlFor="cityId"
                        className={
                          selectedSubject === ""
                            ? Addbranchtcss.std_cat
                            : "NONE"
                        }
                      >
                        *Subject
                      </label>
                      <label
                        htmlFor="cityId"
                        className={
                          selectedSubject !== ""
                            ? Addbranchtcss.std_cat
                            : "NONE"
                        }
                      >
                        *Subject
                      </label>
                    </div>
                    {validation && !selectedSubject && (
                      <span style={{ color: "red", padding: "5px" }}>
                        Subject is Required
                      </span>
                    )}
                  </FormControl>

                  <FormControl>
                    <InputLabel
                      id="demo-multiple-checkbox-label"
                      sx={{
                        fontSize: "13px",
                        color: "#B5B6B6",
                        fontWeight: "500",
                        marginBottom: 6,
                        "&.Mui-focused": {
                          color: "orange",
                        },

                      }}
                    >
                      Subtopic
                    </InputLabel>
                    <Select
                      sx={{
                        borderRadius: "150px",
                        width: "250px",
                        height: "50px",
                        "& .css-10hburv-MuiTypography-root": {
                          fontSize: "14px !important",
                        },
                        "&.MuiOutlinedInput-root": {
                          "& > fieldset": {
                            border: "1px solid #7A7A7A !important",
                          },
                        },
                        "&.MuiOutlinedInput-root.Mui-focused": {
                          "& > fieldset": {
                            border: "1px solid #272727 !important",
                          },
                          ".css-fnnx9f-MuiFormLabel-root-MuiInputLabel-root": {
                            fontSize: "16px",
                            fontFamily: "Poppins",
                            color: "#fd8c00 !important",
                            backgroundColor: "#fff",
                            fontWeight: "500 !important",
                          },
                        },
                        fontSize: "14px !important",
                      }}
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      value={selectedSubtopic}
                      name="first"
                      onChange={(e) => {
                        setSelectedSubtopic(e?.target?.value);
                        console.log(e.target.value, "idd");
                      }}
                      input={<OutlinedInput label="Subtopic" />}
                      // renderValue={(selected) => selected.join(", ")}
                      renderValue={(selected) =>
                        selected
                          ?.map((obj) => {
                            console.log(obj, "tty");
                            return subtopicList?.find((item) => {
                              return item.id === obj;
                            })?.subTopic;
                          })
                          .join(", ")
                      }
                      IconComponent={(props) => (
                        <ExpandMoreIcon
                          {...props} sx={{ fontSize: "18px", }}
                        />
                      )}
                    >
                      {subtopicList?.map((subtopic) => (
                        <MenuItem key={subtopic.id} value={subtopic.id}>
                          <Checkbox
                            checked={
                              selectedSubtopic?.indexOf(subtopic.id) > -1
                            }
                          />
                          <ListItemText primary={subtopic.subTopic} />
                        </MenuItem>
                      ))}
                    </Select>
                    {validation &&
                      selectedSubtopic.length === 0 &&
                      subtopicList.length > 0 && (
                        <span style={{ color: "red", padding: "5px" }}>
                          Subtopic is Required
                        </span>
                      )}{" "}
                  </FormControl>

                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "20px"
                }}
              >
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: "#FFA700",
                      font: "normal normal 600 12px/ 20px Poppins",
                      marginBottom: "5px"
                    }}
                  >
                    *Skill Subject
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="true"
                    name="radio-buttons-group"
                    style={{ display: "flex", flexDirection: "row" }}
                    value={selectedSkilledSubject}
                    onChange={(e) => skillValueHandler(e)}
                  >
                    <FormControlLabel
                      value="true"
                      control={<BpRadio />}
                      label={
                        <Typography sx={{ fontSize: "13px", fontWeight: "bold", opacity: "0.9" }}>Yes</Typography>
                      }
                    />
                    <FormControlLabel
                      sx={{ fontSize: "14px" }}
                      value="false"
                      control={<BpRadio />}
                      label={
                        <Typography sx={{ fontSize: "13px", fontWeight: "bold", opacity: "0.9" }}>No</Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: "1rem",
                  }}
                >
                  <HoverGreyCancelButton
                    variant="outlined"
                    onClick={() => {
                      hideVisibleAddSubject();
                    }}
                  >
                    Cancel
                  </HoverGreyCancelButton>
                  {isEdit ? (
                    <BlueButton
                      onClick={() =>
                        editAddSubjectAndMapping(
                          selectedSubject,
                          selectedBoard,
                          selectedGrade,
                          selectedSubtopic
                        )
                      }
                    >
                      Update
                    </BlueButton>
                  ) : (
                    <BlueButton
                      onClick={() =>
                        addSubjectAndMapping(
                          selectedSubject,
                          selectedBoard,
                          selectedGrade,
                          selectedSubtopic
                        )
                      }
                    >
                      Create
                    </BlueButton>
                  )}
                </div>
              </div>
            </TableContainer>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "#606060",
                columnGap: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <img src={sub_map_Img} alt="" />
                <div
                  style={{
                    fontSize: "1vw",
                    fontWeight: "600",
                    marginLeft: "5px",
                    color: "#354052",
                    opacity: "0.8",
                  }}
                >
                  Subject Mapping
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: "1px",
                }}
              >
                {/*-------| Filter Section Starts  |-------*/}
                <Box sx={{ minWidth: 120 }}>
                  <FormControl
                    size="small"
                    fullWidth
                    sx={{
                      width: "113px", height: "30px",
                      "& .MuiInputLabel-root": {
                        lineHeight: 0.8,
                      },
                    }}
                  >
                    <InputLabel sx={labelStyle} id="board-select">
                      Board
                    </InputLabel>
                    <Select
                      labelId="board-select"
                      id="board-select"
                      input={
                        <OutlinedInput
                          sx={{ fontSize: "10px !important" }}
                          label="Board"
                        />
                      }
                      MenuProps={MenuProps}
                      sx={filterStyle}
                      style={{
                        borderRadius: 150,
                        background: "#fff",
                        fontSize: "14px !important",
                        height: "100%",
                      }}
                      // key={boardkey * 20}
                      onChange={selectBoardHandler}
                      IconComponent={(props) => (
                        <ExpandMoreIcon
                          {...props} sx={{ fontSize: "18px", }}
                        />
                      )}
                    >
                      {boardList?.map((item) => (
                        <MenuItem
                          key={item?.id}
                          value={item?.id}
                          className={dashboardContentStyles.menu_item}
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: '#FFA500',
                              color: "#FFFFFF"
                            },
                            "&.Mui-selected:hover": {
                              backgroundColor: '#FFA500',
                              color: "#000000"
                            },
                            "&:not(.Mui-selected):hover": {
                              backgroundColor: '#fcedc2',
                              color: '#000000',
                            },
                          }}
                        >
                          <ListItemText
                            sx={{ overflow: 'hidden !important', }}
                            primary={item?.board}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <Box sx={{ minWidth: 120 }}>
                  <FormControl
                    size="small"
                    fullWidth
                    sx={{
                      width: "113px", height: "30px",
                      "& .MuiInputLabel-root": {
                        lineHeight: 0.8,
                      },
                    }}
                  >
                    <InputLabel sx={labelStyle} id="grade-select">
                      Grade
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="grade-select"
                      id="grade-select"
                      input={<OutlinedInput label="Grade" />}
                      sx={filterStyle}
                      style={{
                        borderRadius: 150,
                        background: "#fff",
                        height: "100%",
                      }}
                      MenuProps={MenuProps}
                      // key={gradekey * 20}
                      onChange={selectGradeHandler}
                      IconComponent={(props) => (
                        <ExpandMoreIcon
                          {...props} sx={{ fontSize: "18px", }}
                        />
                      )}
                    >
                      {gradeList?.map((item) => (
                        <MenuItem
                          key={item.id}
                          value={item.id}
                          className={dashboardContentStyles.menu_item}
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: '#FFA500',
                              color: "#FFFFFF"
                            },
                            "&.Mui-selected:hover": {
                              backgroundColor: '#FFA500',
                              color: "#000000"
                            },
                            "&:not(.Mui-selected):hover": {
                              backgroundColor: '#fcedc2',
                              color: '#000000',
                            },
                          }}
                        >
                          <ListItemText
                            sx={{ overflow: 'hidden !important', }}
                            primary={item.grade}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <Box sx={{ minWidth: 160 }}>
                  <FormControl
                    size="small"
                    fullWidth
                    sx={{
                      width: "156px", height: "30px",
                      "& .MuiInputLabel-root": {
                        lineHeight: 0.8,
                      },
                    }}
                  >
                    <InputLabel sx={labelStyle} id="subject-select">
                      Select Subject
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="subject-select"
                      id="subject-select"
                      input={<OutlinedInput label="Select Subject" />}
                      sx={filterStyle}
                      style={{
                        borderRadius: 150,
                        background: "#fff",
                        height: "100%",
                      }}
                      // key={subjectKey * 20}
                      onChange={selectSubjectHandler}
                      IconComponent={(props) => (
                        <ExpandMoreIcon
                          {...props} sx={{ fontSize: "18px", }}
                        />
                      )}
                    >
                      {subjectList?.map((item) => (
                        <MenuItem
                          key={item?.id}
                          value={item?.id}
                          className={dashboardContentStyles.menu_item}
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: '#FFA500',
                              color: "#FFFFFF"
                            },
                            "&.Mui-selected:hover": {
                              backgroundColor: '#FFA500',
                              color: "#000000"
                            },
                            "&:not(.Mui-selected):hover": {
                              backgroundColor: '#fcedc2',
                              color: '#000000',
                            },
                          }}
                        >
                          <ListItemText
                            sx={{ overflow: 'hidden !important', }}
                            primary={item?.subject}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                {/*-------| Filter Section Ends  |-------*/}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "1rem",
              }}
            >
              <div className={Studentdynamic.school_list_search_container_32}>
                <div className={Studentdynamic.school_list_search_container}>
                  <input
                    type="search"
                    name="search"
                    id="search"
                    placeholder="Search..."
                    onChange={onSearch}
                    style={{
                      width: "247px",
                      height: "32px",
                    }}
                  />
                  <span>
                    <img
                      src={searchIcn}
                      style={{
                        verticalAlign: "top",
                        fontSize: "large",
                        cursor: 'pointer'
                      }}
                      aria-hidden="true"
                      onClick={handleSearchbtn}
                    />
                  </span>
                </div>
              </div>
              {!visibleAddSubject && (
                <GreenAddButton onClick={() => showVisibleAddSubject()}>
                  Subject Mapping
                </GreenAddButton>
              )}
            </div>
          </div>
        </div>
        <Paper>
          <TableContainer
            style={{ marginBottom: "90px" }}
            sx={{ maxHeight: 640, overflow: "auto" }}
          >
            <Table
              sx={{ minWidth: 650 }}
              stickyHeader={true}
              aria-label="customized table"
            >
              <TableHead>

                <TableRow>
                  {subjectMappingColumn.map((item, index) => {
                    return (
                      <TableCell
                        className={dashboardContentStyles.table_head}
                        key={index}
                        align={item.align}
                      >
                        {item.id === 2 ||
                          item.id === 3 ||
                          item.id === 4 ||
                          item.id === 5 ||
                          item.id === 6 ? (
                          <>
                            {item.dataIndex}
                            <img
                              className="cursor-pointer"
                              width="11"
                              height="15"
                              style={{
                                verticalAlign: "middle",
                                margin: "2px 0 4px 3px",
                              }}
                              src={sortIcon}
                              onClick={async () => {
                                console.log("=====", item.id)
                                await setSortOrder(!sortOrder);
                                (async () => {
                                  const id = await item.id;
                                  id === 2
                                    ? setSortBy("subject")
                                    : id === 3
                                      ? setSortBy("subTopics")
                                      : id === 4
                                        ? setSortBy("board")
                                        : id === 5
                                          ? setSortBy("grade")
                                          : setSortBy("skilledSubject");
                                })();
                                await onSort(!sortOrder, item);
                              }}
                            />
                          </>
                        ) : (
                          <> {item.dataIndex}</>
                        )}
                        {/* {item.dataIndex} */}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {filterValue
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    return (
                      <TableRow
                        key={row.id}
                        sx={{
                          // "&:last-child td, &:last-child th": { border: 0 },

                          backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f8',
                          "&:last-child td, &:last-child th": {
                            borderBottom: "none !important",
                          },

                          "&:hover": {

                            "& td, & th": {
                              borderTop: "1px solid #ff9702",
                              borderBottom: "1px solid #ff9702",
                            },
                            " & th:first-of-type": {
                              borderLeft: "1px solid #ff9702",
                            },

                            "& td:first-of-type": {
                              borderLeft: "0",

                            },

                            "&:last-child td, &:last-child th": {
                              borderBottom: "1px solid #ff9702 !important",
                            },

                            "& td:last-of-type": {
                              borderRight: "1px solid #ff9702",

                            },

                            "& th:last-of-type": {
                              borderRight: "0",
                            },
                          },

                          "& td, & th": {
                            border: "1px solid transparent",
                            borderTop: "1px solid transparent",
                            borderBottom: "1px solid transparent",
                            "&:not(:last-child)": {
                              borderRight: "1px solid transparent",
                            },
                          },
                        }}

                        // hover={true}
                        // style={{
                        //   background: "#f9f8f8",
                        // }}
                        className={dashboardContentStyles.table_rw}
                      >
                        <TableCell
                          className={dashboardContentStyles.table_cell}
                          component="th"
                          scope="row"
                        >
                          {page * rowsPerPage + (index + 1)}
                        </TableCell>
                        <TableCell
                          className={dashboardContentStyles.table_cell_name}
                          align="left"
                        >
                          {row?.subject}
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() => showSubjectModal(row)}
                          className={dashboardContentStyles.table_cell_name}
                        >
                          {row?.subTopics?.map((item, i) => {
                            return (
                              <div key={item?.subtopicId}>
                                {(i ? ", " : "") + item?.subtopic}
                              </div>
                            );
                          })}
                        </TableCell>
                        <TableCell
                          className={dashboardContentStyles.table_cell}
                          align="center"
                        >
                          {row?.board}
                        </TableCell>
                        <TableCell
                          className={dashboardContentStyles.table_cell}
                          align="center"
                        >
                          {row?.grade}
                        </TableCell>
                        <TableCell
                          className={dashboardContentStyles.table_cell}
                          align="center"
                        >
                          {row?.skilledSubject}
                        </TableCell>
                        <TableCell align="center">
                          <Switch
                            checked={row?.active}
                            onChange={(e) =>
                              switchHandler(
                                row?.subjectId,
                                row?.boardId,
                                row?.gradeId,
                                e
                              )
                            }
                          />
                        </TableCell>
                        <TableCell
                          className={dashboardContentStyles.table_cell}
                          align="right"
                          style={{ paddingLeft: 50 }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <EditIconButton
                              onClick={() => {
                                editClickHandler(row);
                              }}
                            >
                              <CustomTooltip title="Edit" placement={"top"}>
                                <img
                                  className={
                                    dashboardContentStyles.aRoleActionBtn
                                  }
                                  src={action__Edit}
                                  alt="Edit-Btn"
                                />
                              </CustomTooltip>
                            </EditIconButton>
                            <DeleteIconButton
                              onClick={() => deleteSubjectMapping(row)}
                            >
                              <CustomTooltip title="Remove" placement={"top"}>
                                <img
                                  className={
                                    dashboardContentStyles.aRoleActionBtn
                                  }
                                  src={action__Delete}
                                  alt="Dlt-Btn"
                                />
                              </CustomTooltip>
                            </DeleteIconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              bottom: "0",
              width: "80.5%",
              position: "fixed",
              backgroundColor: "white",
            }}
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={filterValue?.length || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPage}
            showFirstButton={true}
            showLastButton={true}
          />
        </Paper>
        <ViewSubjectMappingModal
          open={visibleSubjectModal}
          onClose={hideSubjectModal}
          subject={selectedValue}
        />
        <CommonDeleteModal
          open={deletemodal}
          close={hideDeleteModal}
          deleteTitle={"Subtopic"}
          deleteHandler={deleteSubjectAndMapping}
          deleteId={item?.id}
          deleteName={message}
        />
        <ConfirmationModal
          open={toggleConfirmation}
          confirmationmessage={toggleMessage}
          close={handleConfirmationClose}
          updateToggle={updateToggle}
        />
        <Success_OK_Modal_SubjectMapping
          userType={"You have successfully Added Subject Mapping"}
          // userName_Modal={
          //   "Subject Mapping"
          // }
          open={successCreateModal}
          handleOk={closeSuccessModal}
        />
        <UpdatedModalSubjectMapping
          userType={"Subject Mapping"}
          // userName_Modal={
          //   "Subject Mapping"
          // }
          open={succesUpdatesModal}
          handleOk={closeModal}
          close={closeModal}
        />
      </div>
    </>
  );
};
export default SubjectMapping;
