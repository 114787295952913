import React, { useEffect, useState } from 'react';
import './DashboardTab.css'; // You can define your styles here
import axios from 'axios';
import ChapterWiseTable from './ChapterWiseTable';
import ProgressTable from './ProgressTable';
import ChartDetails from './ChartDetails';
import { fetchDashboardSubjectWisePerformance, fetchPrincipalDashboardQuizPerformanceForTeacher } from '../PrincipalModule/dashboardNew/api/apis';
import { updatedGetDataStructureFormatted } from '../PrincipalModule/dashboardNew/DataHelper';
import { isNOTNullOrUndefined } from '../../../utils/helper';
import { fetchDashboardChapterWisePerformance, fetchQuizPerformance } from '../Teacher/Apis/TeacherDashboard';
import { updatedGetDataStructureFormattedTeacher } from "../../js/Teacher/Dashboard/Components/DataHelper";
import { FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import dashboardContentStyles from "../../css/dashboardContent.module.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


const DashboardTabs = ({ userType, gradeList, selectedGrade, handleSelectGrade, isSelectedGradeSectionCard }) => {
  const [activeTab, setActiveTab] = useState('Chapter Wise');
  const [academicYearList, setAcademicYearList] = useState([]);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedProTableSub, setSelectedProTableSub] = useState('');
  const [chapterTableData, setChapterTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [progressTableData, setProgressTableData] = useState([]);
  const [onSubTopicRenderArray, setonSubTopicRenderArray] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedGradeSectionCard, setSelectedGradeSectionCard] = useState(null);
  const [quizPerformanceData, setQuizPerformanceData] = useState([]);
  const [subjectWisePerformanceData, setSubjectWisePerformanceData] = useState([]);
  const [selectedSubjectWiseCard, setSelectedSubjectWiseCard] = useState(null);
  const [chapterPerformanceData, setChapterPerformanceData] = useState([]);
  const [previousSelectedParams, setPreviousSelectedParams] = useState({
    grade: '',
    subject: '',
  })

  const token = sessionStorage.getItem("token");
  const teacherId = sessionStorage.getItem("teacherId");


  // fetchAcademicYear API call 

  useEffect(() => {
    const fetchAcademicYearData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/all`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setAcademicYearList(res?.data.data)
        setSelectedAcademicYear(res?.data.data[0].id)
      } catch (error) {
        console.error("Error fetching academic year data:", error);
        setAcademicYearList([]);
      }
    };

    fetchAcademicYearData();
  }, [])




  // fetchSubjectList API call 

  useEffect(() => {

    const fetchSubjectData = async () => {
      try {
        const boardId = sessionStorage.getItem('boardId');
        const sectionId = sessionStorage.getItem('sectionId');
        const teacherId = sessionStorage.getItem('teacherId');
        let url;

        if (!selectedGrade) return;

        // switch (userType) {
        //   case "Teacher":
        //     url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/subjects?teacherId=${teacherId}&gradeId=${selectedGrade}`;
        //     break;
        //   case "Principal":
        //      url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subject-mapping/subjects?boardId=${boardId}&gradeId=${selectedGrade}`;
        //     break;
        //   default:
        //     throw new Error("Invalid user type");
        // }

        url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/subjects?teacherId=${teacherId}&gradeId=${selectedGrade}`;

        const response = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response?.data?.data) {
          setSubjectList(response.data.data);
          setSelectedSubject(response.data.data[0]?.subjectId);
        } else {
          throw new Error("No data received");
        }
      } catch (error) {
        console.error("Error fetching subject data:", error);
        console.log("Error fetching subject data:", error);
      }
    };

    fetchSubjectData();

  }, [selectedGrade]);


  const subjectChangeHandler = (e) => {
    setSelectedSubject(e.target.value);
  };


  // fetchChapterTable API call 

  const fetchChapterTableData = async () => {
    const { grade, subject } = previousSelectedParams || {};
    setChapterTableData([])
    isClearQuizPerformanceTab()
    // switch (userType) {
    //   case "Teacher":
    //     url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/dashboard/chapter-wise-quiz-performance-dashboard?teacherId=${teacherId}&gradeId=${grade}&subjectId=${subject}`
    //     break;
    //   case "Principal":
    //     // url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/dashboard/chapter-wise-quiz-performance-dashboard-admin?boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&gradeId=${grade}&subjectId=${subject}`
    //     url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/report/chapter-wise-quiz-performance-dashboard?boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&gradeId=${grade}&subjectId=${subject}`
    //     break;
    //   default:
    //     throw new Error("Invalid userType");
    // }


    var item = {
      // "boardId": sessionStorage.getItem("boardId"),
      // "schoolId": sessionStorage.getItem("schoolId"),
      // "branchId": sessionStorage.getItem("branchId"),
      // "gradeId": grade,
      // "subjectId": subject,
      "boardId": sessionStorage.getItem("boardId"),
      "schoolId": sessionStorage.getItem("schoolId"),
      "branchId": sessionStorage.getItem("branchId"),
      "gradeId": grade,
      "subjectId": subject
    };
    try {
      const response = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/file/report/dashTableReport`, item, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("response", response?.data);
      // const updatedArray = response?.data.map(obj => ({
      //   ...obj,
      //   releaseDate: obj?.releaseDate.split('T')[0]
      // }));
      const updatedArray = response?.data.map(obj => {
        // Check if releaseDate exists and is valid
        if (obj?.releaseDate) {
          let releaseDate = new Date(obj.releaseDate);
          releaseDate.setTime(releaseDate.getTime() + (5 * 60 + 30) * 60 * 1000);
          const formattedDate = releaseDate.toISOString().split('T')[0];
          return {
            ...obj,
            quizRelease: formattedDate,
            releaseDate: formattedDate
          };
        }
        return obj;
      });
      console.log("updatedArray", updatedArray)
      setChapterTableData(updatedArray);
      setCurrentPage(1);
    } catch (err) {
      console.log("Error:", err);
      setChapterTableData([]);
    }

    // cmntd on 20-9-24 bcz of new api integrate
    // const response = await axios.get(url,
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // );
    // console.log(response?.data?.data)
    // const data = response?.data?.data;
    // setChapterTableData(data);
    // setCurrentPage(1);
    // } catch (error) {
    //   console.error("Error fetching academic year data:", error);
    //   setChapterTableData([]);
    // }
  };

  // fetchProgressTable API call 

  let subTopicHideOrNot = subjectList?.find(sub => sub?.subjectId === previousSelectedParams?.subject);
  let progressTableSubject = subjectList?.find(sub => sub?.subjectId === previousSelectedParams?.subject);

  const fetchProgressTable = async () => {
    const { grade, subject } = previousSelectedParams || {};
    isClearQuizPerformanceTab();
    setSelectedProTableSub(selectedSubject)
    setProgressTableData([]);
    let boardId = sessionStorage.getItem("boardId");
    let branchId = sessionStorage.getItem("branchId");
    let schoolId = sessionStorage.getItem("schoolId");

    let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/chaptercount_progress?boardId=${boardId}&branchId=${branchId}&schoolId=${schoolId}&gradeId=${grade}&subjectId=${subject}`
    try {
      const response = await axios.get(url,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response?.data?.data;
      if (subTopicHideOrNot?.hideSubtopics) {
        onRenderData(data)
      } else {
        convertToRenderArray(data)
      }
    } catch (error) {
      console.error("Error fetching progress data:", error);
      setProgressTableData([]);
    }
  };


  const convertToRenderArray = (data) => {

    setonSubTopicRenderArray([]);
    let renderArray = [];

    data.sections.forEach(section => {
      section.subTopics.forEach(subTopic => {
        let existingSubTopic = renderArray.find(item => item.subTopic === subTopic.subTopic);

        if (existingSubTopic) {
          let existingSection = existingSubTopic.section.find(item => item.section === section.section);
          if (existingSection) {
            existingSection.totalChapters = subTopic.totalChapters;
            existingSection.chaptersCompleted += subTopic.chaptersCompleted;
            existingSection.testReleased += subTopic.testReleased;
          } else {
            existingSubTopic.section.push({
              section: section.section,
              totalChapters: subTopic.totalChapters,
              chaptersCompleted: subTopic.chaptersCompleted,
              testReleased: subTopic.testReleased
            });
          }
        } else {
          renderArray.push({
            subTopic: subTopic.subTopic,
            section: [{
              section: section.section,
              totalChapters: subTopic.totalChapters,
              chaptersCompleted: subTopic.chaptersCompleted,
              testReleased: subTopic.testReleased
            }]
          });
        }
      });
    });
    setonSubTopicRenderArray(renderArray);
    setProgressTableData([]);
  };

  const onRenderData = (data) => {
    const sectionAggregates = data?.sections?.reduce((acc, entry) => {
      const section = entry.section;
      if (!acc[section]) {
        acc[section] = { section: entry?.section, chaptersCompleted: 0, testReleased: 0, totalChapters: entry?.totalChapters };
      }
      acc[section].chaptersCompleted += entry?.chaptersCompleted;
      acc[section].testReleased += entry?.testReleased;
      return acc;
    }, {});

    const renderArray = Object?.values(sectionAggregates);
    setProgressTableData(renderArray);
    setonSubTopicRenderArray([]);
  }


  // fetchQuizPerformance API call 

  const fetchQuizPerformanceApiCall = async () => {
    const { grade, subject } = previousSelectedParams || {};
    setQuizPerformanceData([]);
    setChapterPerformanceData([]);
    setSubjectWisePerformanceData([]);
    let response;
    response = await fetchPrincipalDashboardQuizPerformanceForTeacher(
      grade,
      subject
    );
    saveQuizPerformanceDataTeacher(response);
  };

  const saveQuizPerformanceDataTeacher = async (response) => {
    setQuizPerformanceData([]);
    const cdata = response;
    let formatted;
    if (userType === 'Principal') {
      formatted = updatedGetDataStructureFormatted(cdata);
    } else {
      formatted = updatedGetDataStructureFormattedTeacher(cdata);
    }
    // formatted = formatted?.filter(v => v.skilledSubject === true) || [];
    setQuizPerformanceData(formatted);
    setSelectedGradeSectionCard(formatted[0]);
    isSelectedGradeSectionCard(formatted[0]);
  };

  const fetchSubjectWiseData = async () => {
    setSelectedSubjectWiseCard(null);

    const response = await fetchDashboardSubjectWisePerformance(
      selectedGradeSectionCard?.gradeId,
      teacherId,
      selectedGradeSectionCard?.sectionId
    );
    //to do format the data to showcase subtopics and subjects linear
    let arr = [];
    response?.subjectDetails?.forEach((obj) => {
      if (obj?.subTopicDetails?.length > 0) {
        obj?.subTopicDetails?.forEach((item) => {
          if (item.hasOwnProperty("avgScorePercentageUQ")) {
            let objItem = { ...item };
            objItem.id = item?.subTopicId;
            arr.push(objItem);
          }
        });
      } else if (obj.hasOwnProperty("avgScorePercentageUQ")) {
        let objItem = { ...obj };
        objItem.id = obj?.subjectId;
        arr.push(objItem);
      }
    });

    console.log(arr, "formatted array");
    setSubjectWisePerformanceData(arr);

    // setSelectedGradeSectionCard(response[0])
  };


  const fetchChapterWiseData = async () => {
    const { gradeId, sectionId, subjectId, subtopicId } = selectedGradeSectionCard || {};
    setChapterPerformanceData([]);
    const response = await fetchDashboardChapterWisePerformance(
      gradeId,
      subjectId,
      sectionId,
      subtopicId
    );
    let arr = [];
    response?.chapters?.forEach((item) => {
      let objItem = {
        id: item?.chapterId,
        scorePercentage: item?.quizAverage,
        averagePercentage: item?.globalQuizAverage,
        chapterName: item?.chapter,
        emberStudents: item?.emberStudents,
        studentPercentageInEmber: item?.studentPercentageInEmber,
      };
      arr.push(objItem);
    });
    setChapterPerformanceData(arr);

  };

  const onSelectedCard = (item) => {
    setSelectedGradeSectionCard(item)
    isSelectedGradeSectionCard(item)
  }

  useEffect(() => {
    if (isNOTNullOrUndefined(selectedGradeSectionCard)) {
      if (userType === 'Principal') {
        fetchSubjectWiseData();
      } else {
        fetchChapterWiseData();
      }
    }
  }, [selectedGradeSectionCard]);


  const isClearQuizPerformanceTab = () => {
    setSelectedGradeSectionCard('');
    setQuizPerformanceData('');
    isSelectedGradeSectionCard('');
    setChapterPerformanceData('');
  }

  const isOnSubmit = () => {
    setPreviousSelectedParams(prevState => ({
      ...prevState,
      grade: selectedGrade,
      subject: selectedSubject
    }));
    setIsSubmit(true);
  }

  useEffect(() => {
    if (isSubmit) {
      isFetchApiCall();
    }
  }, [previousSelectedParams, isSubmit]);

  const isFetchApiCall = () => {

    switch (activeTab) {
      case 'Chapter Wise':
        fetchChapterTableData();
        break;
      case 'Progress':
        fetchProgressTable();
        break;
      case 'Quiz Performance':
        fetchQuizPerformanceApiCall();
        break;
      default:
        return null;
    }

  }

  useEffect(() => {
    if (isSubmit) {
      isFetchApiCall();
    }
  }, [activeTab])



  const renderContent = () => {
    const { grade, subject } = previousSelectedParams || {};

    switch (activeTab) {
      case 'Chapter Wise':
        return <ChapterWiseTable
          userType={userType}
          tableData={chapterTableData}
          selectedGrade={grade}
          selectedSubject={subject}
        />
      case 'Progress':
        return <ProgressTable
          subTopicHideOrNot={subTopicHideOrNot}
          progressTableData={progressTableData}
          onSubTopicRenderArray={onSubTopicRenderArray}
          selectedSubject={progressTableSubject}
        />
      case 'Quiz Performance':
        return <ChartDetails
          quizPerformanceData={quizPerformanceData}
          selectedGradeSectionCard={selectedGradeSectionCard}
          setSelectedGradeSectionCard={onSelectedCard}
          subjectWisePerformanceData={subjectWisePerformanceData}
          setSelectedSubjectWiseCard={setSelectedSubjectWiseCard}
          selectedSubjectWiseCard={selectedSubjectWiseCard}
          userType={userType}
          chapterPerformanceData={chapterPerformanceData} />;
      default:
        return null;
    }
  };


  return <div className="dashboard-table-container">
    <div className="dashboard-dropdown-list-header">
      <div className="dropdown-list">
        {/* <div className="dropdown-container">
          <select className="dropdown" onChange={handleSelectGrade} value={selectedGrade}>
            <option value="">select Grade</option>
            {gradeList && gradeList?.map((o) => {
              return (
                <option value={o?.value}>{o?.label}</option>
              )
            })}
          </select>
        </div> */}
        {/* <div className="dropdown-container">
          <select className="dropdown" onChange={subjectChangeHandler} value={selectedSubject}>
            <option value="">Select Subject</option>
            {subjectList && subjectList?.map((o) => {
              return (
                <option value={o?.subjectId}>{o?.subject}</option>
              )
            })}
          </select>
        </div> */}
        {/* <div className="dropdown-container">
          <select className="dropdown" disabled value={selectedAcademicYear}>
            <option value="">Select Year</option>
            {academicYearList && academicYearList?.map((o) => {
              return (
                <option value={o?.id}>{o?.academicYear}</option>
              )
            })}
          </select>
        </div> */}

        <FormControl >
          <InputLabel
            sx={{
              fontSize: '13px',
              color: '#e7ab3c',
              top: '-10px',
              '&.MuiInputLabel-shrink': {
                top: '0',
              },
              '&.Mui-focused': {
                color: '#e7ab3c'
              }
            }}
            className='dropDownLabel'
            id="demo-simple-select-label"
          >
            Grade
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            input={<OutlinedInput label='Grades ' />}
            MenuProps={{
              PaperProps: {
                sx: {
                  width: '150px',
                  marginTop: '2px',
                  maxHeight: '190px',
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: '2px',
                  },
                  '&::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: '#888',
                    borderRadius: '4px',
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    background: '#555',
                  },
                },
              },
              MenuListProps: {
                sx: {
                  padding: '0px',
                  overflowY: 'auto',
                },
              },
            }}
            IconComponent={(props) => (
              <ExpandMoreIcon
                {...props}
                sx={{ fontSize: "1.5vw" }}
              />
            )}
            sx={{
              width: '150px',
              height: '30px',
              padding: '10px',
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#000',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#000',
              },
              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#e7ab3c',
              }
            }}
            className='dropdownSelect'
            value={selectedGrade}
            onChange={handleSelectGrade}
          >
            {gradeList && gradeList?.map((o) => {
              return (
                <MenuItem
                  value={o?.value}
                  key={o?.value}
                  className={dashboardContentStyles.menu_item}
                  sx={{
                    fontSize: '10px',
                    backgroundColor: 'white',
                    '&:hover': {
                      backgroundColor: '#f4d788',
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#e7ab3c',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                    },
                  }}
                >
                  <ListItemText primary={o?.label} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl >
          <InputLabel
            sx={{
              fontSize: '13px',
              color: '#e7ab3c',
              top: '-10px',
              '&.MuiInputLabel-shrink': {
                top: '0',
              },
              '&.Mui-focused': {
                color: '#e7ab3c'
              }
            }}
            className='dropDownLabel'
            id="demo-simple-select-label"
          >
            Subject
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            input={<OutlinedInput label='Subjects ' />}
            MenuProps={{
              PaperProps: {
                sx: {
                  width: '150px',
                  marginTop: '2px',
                  maxHeight: '190px',
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: '2px',
                  },
                  '&::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: '#888',
                    borderRadius: '4px',
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    background: '#555',
                  },
                },
              },
              MenuListProps: {
                sx: {
                  padding: '0px',
                  overflowY: 'auto',
                },
              },
            }}
            IconComponent={(props) => (
              <ExpandMoreIcon
                {...props}
                sx={{ fontSize: "1.5vw" }}
              />
            )}
            sx={{
              width: '150px',
              height: '30px',
              padding: '10px',
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#000',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#000',
              },
              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#e7ab3c',
              }
            }}
            className='dropdownSelect'
            value={selectedSubject}
            onChange={subjectChangeHandler}
          >
            {subjectList && subjectList?.map((o) => {
              return (
                <MenuItem
                  value={o?.subjectId}
                  key={o?.subjectId}
                  className={dashboardContentStyles.menu_item}
                  sx={{
                    fontSize: '10px',
                    backgroundColor: 'white',
                    '&:hover': {
                      backgroundColor: '#f4d788',
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#e7ab3c',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                    },
                  }}
                >
                  <ListItemText primary={o?.subject} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl >
          <InputLabel
            sx={{
              fontSize: '13px',
              color: '#e7ab3c',
              top: '-10px',
              '&.MuiInputLabel-shrink': {
                top: '0',
              },
              '&.Mui-focused': {
                color: '#e7ab3c'
              }
            }}
            className='dropDownLabel'
            id="demo-simple-select-label"
          >
            Academic Year
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            input={<OutlinedInput label='Academic Years ' />}
            MenuProps={{
              PaperProps: {
                sx: {
                  width: '150px',
                  marginTop: '2px',
                  maxHeight: '190px',
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: '2px',
                  },
                  '&::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: '#888',
                    borderRadius: '4px',
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    background: '#555',
                  },
                },
              },
              MenuListProps: {
                sx: {
                  padding: '0px',
                  overflowY: 'auto',
                },
              },
            }}
            IconComponent={(props) => (
              <ExpandMoreIcon
                {...props}
                sx={{ fontSize: "1.5vw" }}
              />
            )}
            sx={{
              width: '150px',
              height: '30px',
              padding: '10px',
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#000',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#000',
              },
              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#e7ab3c',
              }
            }}
            className='dropdownSelect'
            value={selectedAcademicYear}
            disabled
          >
            {academicYearList && academicYearList?.map((o) => {
              return (
                <MenuItem
                  value={o?.id}
                  key={o?.id}
                  className={dashboardContentStyles.menu_item}
                  sx={{
                    fontSize: '10px',
                    backgroundColor: 'white',
                    '&:hover': {
                      backgroundColor: '#f4d788',
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#e7ab3c',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                    },
                  }}
                >
                  <ListItemText primary={o?.academicYear} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <div className="dropdown-container">
          <button className="submit-btn" onClick={() => isOnSubmit()}>Submit</button>
        </div>
      </div>
    </div>
    <div className="tabs">
      <div className="tab-buttons">
        <button onClick={() => setActiveTab('Chapter Wise')} className={activeTab === 'Chapter Wise' ? 'active' : ''}>
          Chapter Wise
        </button>
        <button onClick={() => setActiveTab('Progress')} className={activeTab === 'Progress' ? 'active' : ''}>
          Progress
        </button>
        <button onClick={() => setActiveTab('Quiz Performance')} className={activeTab === 'Quiz Performance' ? 'active' : ''}>
          Quiz Performance
        </button>
      </div>
      <div className="tab-content">
        {renderContent()}
      </div>
    </div>
  </div>
};

export default DashboardTabs;
